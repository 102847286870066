import React, { useMemo, useCallback, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Table } from 'antd';

import { PRESTIGE_LIST } from '../utils';

import WagyuLoader from '../../../../../components/WagyuLoader';

import logo from '../../../../../assets/img/logo.png';

import styles from './Ranks.module.scss';

function Tables(props) {
    const { data, youLeaderBoard, tournamentType, tournamentScoreValue } = props;

    const requesting = useSelector(state => state?.wallet?.leaderBoardRes?.requesting);
    const username = useSelector(state => state?.user?.userInfo?.result?.username ?? '');

    const columns = useMemo(() => {
        return [
            {
                title: "Rank",
                dataIndex: "rank",
                key: "rank",
                width: '8%',
                render: text => <p className={styles.value}>{text}th</p>
            },
            {
                title: "PFP",
                dataIndex: "pfp",
                key: "pfp",
                width: '8%',
                render: (text) => {
                    return (
                        <img src={text || logo} alt="" className={styles.pfp} />
                    )
                }
            },
            {
                title: "Username",
                dataIndex: "username",
                key: "username",
                width: '30%',
                render: text => {
                    if (youLeaderBoard) {
                        if (username === text) return <p className={styles.value}>{text} (You)</p>
                    }

                    return <p className={styles.value}>{text}</p>
                }
            },
            {
                title: "Prestige",
                dataIndex: "prestige",
                key: "prestige",
                width: '10%',
                render: text => {
                    return (
                        <div className={styles.prestige}>
                            <img src={PRESTIGE_LIST[text - 1]} alt="" className={styles.levelIcon} />
                            <p className={styles.value}>{text}</p>
                        </div>
                    )
                }
            },
            {
                title: tournamentScoreValue || (tournamentType === '2' ? "Zombies Killed" : 'XP GAINED'),
                dataIndex: "score",
                key: "score",
                width: '24%',
                align: 'center',
                render: text => <p className={styles.value}>{text}</p>
            },
            // {
            //     title: "TOTAL XP GAINED",
            //     dataIndex: "xpGained",
            //     key: "xpGained",
            //     width: '24%',
            //     align: 'center',
            //     render: text => <p className={styles.value}>{text}</p>
            // },
            {
                title: "Prize",
                dataIndex: "prize",
                key: "prize",
                width: '20%',
                render: text => <p className={styles.value}>{text} Gold ZBUX</p>
            }
        ];
    }, [username, youLeaderBoard, tournamentType, tournamentScoreValue]);

    const renderLoading = useCallback(() => {
        return (
            <div className={styles.loadMoreContainer}>
                <WagyuLoader customStyle={styles.loadMore} />
            </div>
        )
    }, []);

    useEffect(() => {
        const tbody = document.getElementsByClassName('ant-table-row');
        const first = tbody[0];

        if (youLeaderBoard) {
            first?.classList.add('active')
        } else {
            first?.classList.remove('active')
        }
    }, [youLeaderBoard])

    return (
        <div className={styles.tables}>
            <Table
                rowClassName={() => 'rowName'}
                columns={columns}
                dataSource={data}
                bordered={false}
                pagination={false}
                loading={{
                    spinning: requesting,
                    indicator: renderLoading()
                }}
                rowKey='rank'
            />
        </div>
    )
}

export default React.memo(Tables);
