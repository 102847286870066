import React from 'react';
import { useHistory } from 'react-router-dom';

import loadoutsIcon from './img/loadouts.png';
import lucas from './img/lucas.png';

import armoryIcon from '../Inventory/img/armory.png';
import devideIcon from '../Inventory/img/devide.png';

import { PAGE_WEAPON_LOADOUTS_PATH, PAGE_CHARACTER_SKIN_PATH, PAGE_WEAPON_SKIN_PATH } from '../../Router/constants';

import PageSlug from '../../../../components/PageSlug';

import styles from './Armory.module.scss';

function MyArmory() {
    const history = useHistory();

    const handleOnClickLoadout = () => {
        history.push(PAGE_WEAPON_LOADOUTS_PATH);
    }

    const handleOnClickCharacterSkin = () => {
        history.push(PAGE_CHARACTER_SKIN_PATH);
    }
    const handleOnClickWeaponSkin = () => {
        history.push(PAGE_WEAPON_SKIN_PATH);
    }

    return (
        <div className={styles.container}>
            <PageSlug
                slug={'My Armory'}
                description={''}
                hasDevide
            />

            <div className={styles.content}>
                <div className={styles.box}>
                    <section className={styles.card} onClick={() => handleOnClickLoadout()}>
                        <p className={styles.title}>Weapon Loadout NFTs</p>
                        <img src={loadoutsIcon} alt="" className={styles.img1} />
                    </section>

                    <img src={devideIcon} alt="" className={styles.divide1} />

                    <section className={styles.card} onClick={() => handleOnClickWeaponSkin()}>
                        <p className={styles.title}>Weapon Skins</p>
                        <img src={armoryIcon} alt="" className={styles.img2} />
                    </section>

                    <img src={devideIcon} alt="" className={styles.divide2} />

                    <section className={styles.card} onClick={() => handleOnClickCharacterSkin()}>
                        <p className={styles.title}>Character Skins</p>
                        <img src={lucas} alt="" className={styles.img3} />
                    </section>
                </div>
            </div>
        </div>
    )
}

export default React.memo(MyArmory);