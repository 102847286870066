import BigNumber from 'bignumber.js'
import { BIG_TEN } from './bigNumber'

export const verifyChainId = chainId => {
    if (typeof chainId !== 'number' && typeof chainId !== 'string') {
        return '0x1';
    }

    if (typeof chainId === 'number') {
        return '0x' + chainId.toString(16);
    }

    if (!chainId.startsWith('0x')) {
        return '0x' + parseInt(chainId.replace(/\D+/g, '')).toString(16);
    }

    return chainId;
};

export const getAccountSymbol = (chainId = undefined) => {
    chainId = chainId ? verifyChainId(chainId) : verifyChainId(window?.ethereum?.chainId);

    if (['0x1', '0x01', '0x5', '0x05'].includes(chainId)) return 'ETH';

    if (['0x38', '0x61'].includes(chainId)) return 'BNB';

    if (['0x89', '0x13881'].includes(chainId)) return 'MATIC';

    return 'ETH';
}

export const getChainName = chainId => {
    chainId = verifyChainId(chainId);

    switch (chainId) {
        case '0x1':
        case '0x01':
            return 'Ethereum Mainnet';

        case '0x5':
        case '0x05':
            return 'Ethereum Testnet';

        case '0x38':
            return 'Binance Smart Chain Mainnet';

        case '0x61':
            return 'Binance Smart Chain Testnet';

        case '0x89':
            return 'Polygon Mainnet';

        case '0x13881':
            return 'Polygon Testnet';
    
        default:
            return 'Ethereum Mainnet';
    }
}

export const getProvider = chainId => {
    chainId = verifyChainId(chainId);

    switch (chainId) {
        case '0x1':
        case '0x01':
            return process.env.REACT_APP_ETHER_WEB3_PROVIDER_MAINNET_HTTPS;

        case '0x5':
        case '0x05':
            return process.env.REACT_APP_ETHER_WEB3_PROVIDER_TESTNET_HTTPS;

        case '0x38':
            return process.env.REACT_APP_BSC_WEB3_PROVIDER_MAINNET_HTTPS;

        case '0x61':
            return process.env.REACT_APP_BSC_WEB3_PROVIDER_TESTNET_HTTPS;

        case '0x89':
            return process.env.REACT_APP_POLYGON_WEB3_PROVIDER_MAINNET_HTTPS;

        case '0x13881':
            return process.env.REACT_APP_POLYGON_WEB3_PROVIDER_TESTNET_HTTPS;
    
        default:
            return process.env.REACT_APP_ETHER_WEB3_PROVIDER_TESTNET_HTTPS;
    }
};

export const getBlockExplorerUrls = chainId => {
    chainId = verifyChainId(chainId);

    switch (chainId) {
        case '0x1':
        case '0x01':
            return 'https://etherscan.io/';

        case '0x5':
        case '0x05':
            return 'https://goerli.etherscan.io/';

        case '0x38':
            return 'https://bscscan.com/';

        case '0x61':
            return 'https://testnet.bscscan.com/';

        case '0x89':
            return 'https://explorer.matic.network/';

        case '0x13881':
            return 'https://mumbai.polygonscan.com/';
    
        default:
            return 'https://goerli.etherscan.io/';
    }
};

export const getDecimalAmount = (amount, decimals = 18) => {
    return new BigNumber(amount).times(BIG_TEN.pow(decimals))
  };
  
export const getBalanceAmount = (amount, decimals = 18) => {
    return new BigNumber(amount).dividedBy(BIG_TEN.pow(decimals))
};

export const getBalanceNumber = (balance, decimals = 18) => {
    return getBalanceAmount(balance, decimals).toNumber()
};