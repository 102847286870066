import { createAction, createActions } from 'redux-actions';

import OwnershipApi from './api';

export const reloadOwnership = createAction('RELOAD_OWNERSHIP');
export const reloadOwnershipDetail = createAction('RELOAD_OWNERSHIP_DETAIL');

export const { fetchAllOwnershipRequest, fetchAllOwnershipSuccess, fetchAllOwnershipFail } = createActions({
    FETCH_ALL_OWNERSHIP_REQUEST: () => {},
    FETCH_ALL_OWNERSHIP_SUCCESS: data => ({ data }),
    FETCH_ALL_OWNERSHIP_FAIL: error => ({ error }),
});

export const { fetchOwnershipDetailRequest, fetchOwnershipDetailSuccess, fetchOwnershipDetailFail } = createActions({
    FETCH_OWNERSHIP_DETAIL_REQUEST: () => {},
    FETCH_OWNERSHIP_DETAIL_SUCCESS: data => ({ data }),
    FETCH_OWNERSHIP_DETAIL_FAIL: error => ({ error }),
});

export const { progressOwnershipRequest, progressOwnershipSuccess, progressOwnershipFail } = createActions({
    PROGRESS_OWNERSHIP_REQUEST: () => {},
    PROGRESS_OWNERSHIP_SUCCESS: data => ({ data }),
    PROGRESS_OWNERSHIP_FAIL: error => ({ error }),
});

export const { fetchExclusivesBurnedRequest, fetchExclusivesBurnedSuccess, fetchExclusivesBurnedFail } = createActions({
    FETCH_EXCLUSIVES_BURNED_REQUEST: () => {},
    FETCH_EXCLUSIVES_BURNED_SUCCESS: data => ({ data }),
    FETCH_EXCLUSIVES_BURNED_FAIL: error => ({ error }),
});

export const { exclusivesBurnedRequest, exclusivesBurnedSuccess, exclusivesBurnedFail } = createActions({
    EXCLUSIVES_BURNED_REQUEST: () => {},
    EXCLUSIVES_BURNED_SUCCESS: data => ({ data }),
    EXCLUSIVES_BURNED_FAIL: error => ({ error }),
});

export const { exclusivesBoostRequest, exclusivesBoostSuccess, exclusivesBoostFail } = createActions({
    EXCLUSIVES_BOOST_REQUEST: () => {},
    EXCLUSIVES_BOOST_SUCCESS: data => ({ data }),
    EXCLUSIVES_BOOST_FAIL: error => ({ error }),
});

export const fetchAllOwnership = (params, config) => async (dispatch) => {
    dispatch(fetchAllOwnershipRequest());

    return OwnershipApi.fetchOwnership(params, config)
        .then(({ data }) => {
            dispatch(fetchAllOwnershipSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(fetchAllOwnershipFail(error));
            return error;
        });
}

export const fetchOwnershipDetail = (tokenId, contractAddress) => async (dispatch) => {
    dispatch(fetchOwnershipDetailRequest());

    return OwnershipApi.fetchOwnershipDetail({ tokenId, contractAddress })
        .then(({ data }) => {
            dispatch(fetchOwnershipDetailSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(fetchOwnershipDetailFail(error));
            return error;
        });
}

export const progressOwnership = (body) => async (dispatch) => {
    dispatch(progressOwnershipRequest());

    return OwnershipApi.progressOwnership(body)
        .then(({ data }) => {
            dispatch(progressOwnershipSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(progressOwnershipFail(error));
            return error;
        });
}

export const fetchExclusivesBurned = (address) => async (dispatch) => {
    dispatch(fetchExclusivesBurnedRequest());

    try {
        const data = await OwnershipApi.fetchExclusiveBurned(address);

        return dispatch(fetchExclusivesBurnedSuccess(data));
    } catch (error) {
        const { data } = error;

        return dispatch(fetchExclusivesBurnedFail(data));
    }
}

export const exclusivesBurned = (body) => async (dispatch) => {
    dispatch(exclusivesBurnedRequest());

    try {
        const data = await OwnershipApi.addExclusiveBurned(body);

        return dispatch(exclusivesBurnedSuccess(data));
    } catch (error) {
        const { data } = error;

        return dispatch(exclusivesBurnedFail(data));
    }
}

export const exclusivesBoost = (body) => async (dispatch) => {
    dispatch(exclusivesBoostRequest());

    try {
        const data = await OwnershipApi.addExclusiveBoost(body);

        return dispatch(exclusivesBoostSuccess(data));
    } catch (error) {
        const { data } = error;

        return dispatch(exclusivesBoostFail(data));
    }
}