/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import $ from 'jquery';

import { WEAPON_LIST } from './utils';

import coffinRollingAudio from './audio/coffin-rolling.mp3';
import weaponRevealAudio from './audio/weapon-reveal.mp3';

import styles from './WeaponSlide.module.scss';

window.$ = $;
window.jQuery = $;

function WordSlider(props) {
    const { selected, isSliderStart } = props;

    const [isRolling, setRolling] = useState(false);

    // const [randed, setRanded] = useState('');
    // const [startSlide, setStartSlide] = useState(false);
    // const [isShowSeleted, setShowSelected] = useState(false);

    // const isVisible = useSelector(state => state?.app?.weaponRandomVisible);
    const { isMobile } = useSelector(state => state?.app);
    const rewardsRes = useSelector(state => state?.lootConffin?.rewardsRes?.result ?? []);
    const lootCoffinReward = useSelector(state => state?.lootConffin?.unlockCoffinRes?.result?.lootCoffinReward);

    const item = rewardsRes && rewardsRes.find(e => e.name === lootCoffinReward);

    const selectedBorderBottom = useMemo(() => {
        if (!item) return '4rem solid #0ec800';

        switch (item?.rarityType) {
            case 'Common':
                return '4rem solid #0ec800';
            case 'Rare':
                return '4rem solid #3075ff';
            case 'Epic':
                return '4rem solid #8200d4';
            case 'Legendary':
                return '4rem solid #ffc600';
            default:
                return '4rem solid #0ec800';
        }
    }, [item]);

    // const renderItems = useMemo(() => {
    //     return WEAPON_LIST.map((weaponItem) => {
    //         return (
    //             <div key={weaponItem.id}>
    //                 {weaponItem.id === '5' ? (
    //                     <img
    //                         src={item?.skinUrl || WEAPON_LIST[8].img}
    //                         alt={weaponItem.id}
    //                         className={cn(styles.item, styles.classRedItem, {
    //                             [styles[item?.rarityType]]: item?.rarityType
    //                         })}
    //                     />
    //                 ) : (
    //                     <img
    //                         src={weaponItem.img}
    //                         alt={weaponItem.id}
    //                         className={cn(styles.item, styles.classRedItem, {
    //                             [styles[weaponItem.rarityType]]: weaponItem.rarityType
    //                         })}
    //                     />
    //                 )}
    //             </div>
    //         )
    //     })
    // }, [item]);

    // DEFINE RANDOM LIST
    useEffect(() => {
        $(`.${styles.raffleRollerContainer}`).css({
            transition: "sdf",
            "margin-left": "0rem",
        }, 10).html('');

        const list = [...WEAPON_LIST, ...WEAPON_LIST];

        const randomList = list.sort(() => Math.random() - 0.5);

        for (let i = 0; i < randomList.length; i++) {
            let element = `
                <div class="${styles.itemContainer}">
                    <div
                        id="CardNumber${i + 1}"
                        class="${cn(styles.item, styles.classRedItem, {
                            [styles[list[i].rarityType]]: list[i].rarityType
                        })}"
                        style="background: url('${list[i].img}') no-repeat center / 100% 145%;"
                    />
                </div>
            `;

            $(element).appendTo(`.${styles.raffleRollerContainer}`);
        }
    }, []);

    // GO ROLL AFTER WE HAVE DURATION SLIDER
    useEffect(() => {
        if (!isSliderStart) return;
        
        let sliderRollingTimeout = setTimeout(() => {
            $(`.${styles.raffleRollerContainer}`).css({
                transition: `all 6.5s cubic-bezier(.08, .6, 0, .93)`
            });
    
            if (isMobile) {
                $(`.${styles.raffleRollerContainer}`).css('margin-left', `-7125rem`);
            } else {
                $(`.${styles.raffleRollerContainer}`).css('margin-left', `-6890rem`);
            }

            setRolling(true);
        }, 500);

        return () => {
            clearTimeout(sliderRollingTimeout);
            setRolling(false);
        }
    }, [isSliderStart, isMobile]);

    useEffect(() => {
        if (!item) return;
        
        $('#CardNumber35').css({
            "background-image": "url("+item?.skinUrl+")",
            "border-bottom": selectedBorderBottom
        });
    }, [item?.skinUrl]);

    // useEffect(() => {
    //     if (!selected) return;

    //     const showSelected = setTimeout(() => {
    //         setShowSelected(true);
    //     }, 2000);

    //     setStartSlide(false);

    //     return () => {
    //         clearTimeout(showSelected);
    //     }
    // }, [selected]);

    // useEffect(() => {
    //     if (!isVisible) return;

    //     const startTimeout = setTimeout(() => {
    //         setStartSlide(true);
    //         setRanded(randomInt(0,42));
    //     }, 5010);

    //     return () => {
    //         setRanded('');
    //         setShowSelected(false);
    //         clearTimeout(startTimeout);
    //     }
    // }, [isVisible]);

    // useEffect(() => {
    //     if (!randed) return;

    //     const slick = document.getElementsByClassName('center');

    //     if (isEmpty(slick)) return;

    //     $(".center").addClass(`${styles.isShow}`);

    //     $(".center").slick({
    //         arrows: false,
    //         dots: false,
    //         infinite: true,
    //         centerMode: true,
    //         autoplay: false,
    //         autoplaySpeed: 100,
    //         speed: 0,
    //         slidesToShow: 7,
    //         slidesToScroll: 3,
    //         useTransform: false,
    //         variableWidth: true,
    //         draggable: false
    //     }).on('beforeChange', (event, slick, current, next) => {
    //         $('.slick-slide.gt2').removeClass('gt2');
    //         $('.slick-slide.gt1').removeClass('gt1');
    //         $('.slick-slide.lt1').removeClass('lt1');
    //         $('.slick-slide.lt2').removeClass('lt2');

    //         const lt2 = (current < next && current > 0) ? current - 1 : next - 2;
    //         const lt1 = (current < next && current > 0) ? current : next - 1;
    //         const gt1 = ((current < next || current - next > Math.abs(1)) || next === 0) ? next + 1 : current;
    //         const gt2 = ((current < next || current - next > Math.abs(1)) || next === 0) ? next + 2 : current + 1;

    //         $(`.slick-slide[data-slick-index="${lt2}"]`).addClass('lt2');
    //         $(`.slick-slide[data-slick-index="${lt1}"]`).addClass('lt1');
    //         $(`.slick-slide[data-slick-index="${gt1}"]`).addClass('gt1');
    //         $(`.slick-slide[data-slick-index="${gt2}"]`).addClass('gt2');

    //         // Clone processing when moving from 5 to 0
    //         if (current === 5 && next === 0) {
    //             $(`.slick-slide[data-slick-index="${current - 1}"]`).addClass('lt2');
    //             $(`.slick-slide[data-slick-index="${current}"]`).addClass('lt1');
    //             $(`.slick-slide[data-slick-index="${current + 2}"]`).addClass('gt1');
    //             $(`.slick-slide[data-slick-index="${current + 3}"]`).addClass('gt2');
    //         }

    //         // Clone processing when moving from 0 to 5
    //         if (current === 0 && next === 5) {
    //             $(`.slick-slide[data-slick-index="${current - 1}"]`).addClass('gt2');
    //             $(`.slick-slide[data-slick-index="${current}"]`).addClass('gt1');
    //             $(`.slick-slide[data-slick-index="${current - 2}"]`).addClass('lt1');
    //             $(`.slick-slide[data-slick-index="${current - 3}"]`).addClass('lt2');
    //         }
    //     });

    //     const slickPlay = setTimeout(() => {
    //         $(".center").slick('slickPlay', true);
    //     }, 1000);

    //     const autoplaySpeed300 = setTimeout(() => {
    //         $(".center").slick('slickSetOption', 'autoplaySpeed', 300);
    //     }, 5000);

    //     const autoplaySpeed400 = setTimeout(() => {
    //         $(".center").slick('slickSetOption', 'autoplaySpeed', 400);
    //     }, 6000);

    //     const autoplaySpeed500 = setTimeout(() => {
    //         $(".center").slick('slickSetOption', 'autoplaySpeed', 500);
    //     }, 6500);

    //     return () => {
    //         clearTimeout(slickPlay);
    //         clearTimeout(autoplaySpeed300);
    //         clearTimeout(autoplaySpeed400);
    //         clearTimeout(autoplaySpeed500);

    //         $(".center").slick('unslick', 'none');

    //         $(`.${styles.item}`).addClass(`${styles.isNone}`);
    //     }
    // }, [randed]);

    return (
        <div className={cn(styles.container)}>
            {/* <div className={cn(styles.selectedContainer, {
                [styles.isShow]: !isShowSeleted
            })}>
                {isShowSeleted && <img alt={'skin'} className={styles.itemSelected} src={item?.skinUrl} />}
            </div>

            <section className={cn('center', 'slider', {
                [styles.isNone]: !startSlide
            })}>
                {renderItems}
            </section>

            {startSlide && <div className={styles.loader} />} */}

            <div className={cn(styles.selectedContainer, {
                [styles.isShow]: !selected
            })}>
                {selected && <img alt={'skin'} className={styles.itemSelected} src={item?.skinUrl} />}
            </div>  
            
            <div
                className={cn(styles.raffleRoller, {
                    [styles.isShow]: isSliderStart
                })}
            >
                <div className={styles.raffleRollerHolder}>
                    <div className={styles.raffleRollerContainer} style={{ marginLeft: 0 }}>
                    </div>
                </div>

                <div className={styles.devide} />
            </div>

            {isSliderStart && <div className={styles.loader} />}

            {isRolling ? (
                <audio id="coffinRolling" autoPlay={true}>
                    <source src={coffinRollingAudio} type="audio/mpeg" />
                </audio>
            ) : null}

            {selected ? (
                <audio id="weaponReveal" autoPlay={true}>
                    <source src={weaponRevealAudio} type="audio/mpeg" />
                </audio>
            ) : null}
        </div>
    )
}

export default React.memo(WordSlider);
