import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { ethers } from 'ethers';
import Web3 from 'web3';

import ContentWrapper from './ContentWrapper';
import Step1 from './Step1';
import Step2 from './Step2';
import Modal from '../Modal';

import { deposit } from 'store/modules/Deposit/actions';
import { switchNetwork, onApprove } from 'store/modules/User/actions';
import { checkRate, withDraw, handleSwapIn } from 'store/modules/Withdraw/actions';
import { setRecentModalVisible, setToast, setReloadHistories } from 'store/modules/App/actions';

import { geTokenAbiOfBlockchain } from '../../utils/common';
import {
    formatNumber,
    // toWeiDecimals
} from '../../utils/bigNumber';
import { getAbiBaseCoin, getContractAddress } from '../../utils/contractHelpers';

import styles from './WagyuSwap.module.scss';

function WagyuSwap(props) {
    const dispatch = useDispatch();

    const { isVisible, onCancel } = props;

    const [step, setStep] = useState(1);

    const { isMobile } = useSelector(state => state?.app);
    const { username } = useSelector(state => state?.user?.userInfo?.result ?? {});
    const address = useSelector(state => state?.user?.userAccount?.accounts[0] ?? '');
    const chainId = useSelector(state => state?.user?.chainId ?? 1);
    const signKey = useSelector(state => state?.user?.userAccount?.signKey ?? '');

    const dropdownList = useSelector(state => state?.pools?.allPoolsListRes ?? []);
    const swapInLoading = useSelector(state => state?.withdraw?.swapInRes?.requesting);
    const withdrawLoading = useSelector(state => state?.withdraw?.withDrawnRes?.requesting);
    const approveLoading = useSelector(state => state?.user?.approveRes?.requesting);
    const switchNetworkLoading = useSelector(state => state?.user?.switchNetwork?.requesting);
    const disableWithdraw = useSelector(state => state?.withdraw?.exchangeStatusRes?.result);

    const accountName = `${username?.charAt(0).toUpperCase() + username?.slice(1)}`;

    const handleOnNext = useCallback(() => {
        setStep(step + 1);
    }, [step]);

    const handleOnSwap = useCallback(() => {
        onCancel();
        setStep(1);
        dispatch(setRecentModalVisible(true));
    }, [dispatch, onCancel])

    const handleOnCancel = () => {
        onCancel();
        setStep(1);
    };

    const handleOnOpenRecent = useCallback(() => {
        onCancel();
        dispatch(setRecentModalVisible(true));
    }, [dispatch, onCancel]);

    const handleOnChangeInput = debounce((params, cb) => {
        dispatch(checkRate(params))
            .then(res => {
                cb(formatNumber(res?.data?.amount, '', 8, ''));
            })
            .catch(err => err)
    }, 700);

    const handleOnSwitchNetwork = async (value, cb) => {
        dispatch(switchNetwork(value.chainId, value.blockchain))
            .then(res => {
                if (res) {
                    cb.setFirstItem(value);
                    cb.setKeyDropdown(value.label);
                } else {
                    dispatch(setToast({ title: 'SWITCH NETWORK FAILED', detail: 'User rejected the request.' }))
                }
            })
            .catch(err => {
                return err;
            })
    }

    const handleOnApprove = (value) => {
        try {
            const tokenAbi = geTokenAbiOfBlockchain(value.networkName);
            const contract = new window.web3.eth.Contract(tokenAbi, value.tokenAddress);
            const spenderAddress = getContractAddress(value.chainId);

            const callbacks = () => dispatch(setReloadHistories(Date.now()));

            dispatch(onApprove(contract, spenderAddress, address, ethers.constants.MaxUint256.toString(), callbacks))
                .then(res => {
                    if (res?.code === 4001) {
                        dispatch(setToast({ title: 'SWITCH NETWORK FAILED', detail: res?.message }))
                    }
                })
                .catch(err => {
                    dispatch(setToast({ title: 'SWITCH NETWORK FAILED', detail: 'User rejected the request.' }))
                    return err;
                })
        } catch (error) {
            dispatch(setToast({ title: 'SWITCH NETWORK FAILED', detail: 'Something went wrong' }))
            return error;
        }
    }

    const handleOnReceipt = (receipt, error = null) => {
        dispatch(deposit({ chainId: `0x${Number(chainId).toString(16)}`, transactionHash: receipt?.transactionHash }))
            .then(res => {
                if (res?.status >= 500) {
                    dispatch(setToast({ title: 'SWAP FAILED', detail: 'Something went wrong' }))
                } else if ((res?.status === 401)) {
                    dispatch(setToast({ title: 'TOKEN IS EXPIRED', detail: '' }));
                } else if (res?.status >= 400) {
                    dispatch(setToast({ title: 'SWAP FAILED', detail: res?.data?.message }));
                } else if (res?.status === 200 || res?.status === 201) {
                    dispatch(setReloadHistories(Date.now()))
                    handleOnSwap()
                }
            })
            .catch(err => {
                dispatch(setToast({ title: 'SWAP FAILED', detail: 'Something went wrong' }));
                return err;
            })
    }


    const handleOnSubmit = (isSwap, item, balance, zbuxBalance) => {
        if (!isSwap) {
            const abi = getAbiBaseCoin(chainId);
            const contractAddress = getContractAddress(chainId);

            const balanceWei = Web3.utils.toWei(balance, 'ether')

            try {
                const contract = new window.web3.eth.Contract(abi, contractAddress);
                const params = {
                    swapIn: item.isBaseCoin ? balance : 0,
                    amount: balanceWei,
                    // amount: toWeiDecimals(balance, item.decimals),
                    token: item.tokenAddress,
                    decimals: item.decimals
                }
                
                const callbacks = {
                    onReceipt: handleOnReceipt,
                }

                dispatch(handleSwapIn(contract, address, params, callbacks))
                    .then(res => {
                        if (res?.code === 4001) {
                            dispatch(setToast(({ title: 'SWAP FAILED', detail: res?.message })));
                        }
                    })
                    .catch(err => {
                        dispatch(setToast(({ title: 'SWAP FAILED', detail: 'Something went wrong' })));
                        return err;
                    })
            } catch (error) {
                return error
            }
        } else {
            const body = {
                amount: Number(zbuxBalance),
                token: item.value.toUpperCase(),
                chainId: chainId,
                sign: signKey
            }

            dispatch(withDraw(body))
                .then((res) => {
                    if (res?.status >= 500) {
                        dispatch(setToast({ title: 'SWAP FAILED', detail: 'Something went wrong' }))
                    } else if ((res?.status === 401)) {
                        dispatch(setToast({ title: 'TOKEN IS EXPIRED', detail: '' }));
                    } else if (res?.status >= 400) {
                        dispatch(setToast({ title: 'SWAP FAILED', detail: res?.data?.message }));
                    } else if (res?.status === 200 || res?.status === 201) {
                        dispatch(setReloadHistories(Date.now()))
                        handleOnSwap()
                    }
                })
                .catch(err => {
                    dispatch(setToast({ title: 'SWAP FAILED', detail: 'Something went wrong' }));
                    return err;
                })
        }
    }

    const renderContent = () => {
        switch (step) {
            case 1:
                return <Step1 name={username} address={address} onNext={handleOnNext} />

            case 2:
                return  <Step2
                            onChange={handleOnChangeInput}
                            onSubmit={handleOnSubmit}
                            onSwitchNetwork={handleOnSwitchNetwork}
                            onApprove={handleOnApprove}
                            list={dropdownList}
                            disableWithdraw={disableWithdraw}
                        />

            default:
                break;
        }
    }

    return (
        <Modal
            visible={isVisible}
            closable={false}
            centered
            onCancel={() => {
                if (swapInLoading || withdrawLoading || approveLoading || switchNetworkLoading) return;

                return handleOnCancel()
            }}
            width={isMobile ? '340rem' : '397rem'}
            wrapperClass={styles.container}
            wrapperBody={styles.wrapperBody}
            custom={true}
        >
            <ContentWrapper
                step={step}
                name={accountName}
                onClose={() => {
                    if (swapInLoading || withdrawLoading || approveLoading || switchNetworkLoading) return;

                    return handleOnCancel()
                }}
                openRecent={handleOnOpenRecent}
            >
                {renderContent()}
            </ContentWrapper>
        </Modal>
    );
}

export default WagyuSwap;