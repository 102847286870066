import React from 'react';
import { useDispatch } from 'react-redux';

import Modal from '../Modal';

import closeIcon from '../DownloadModal/img/close.png';
import logo from '../../assets/landingLayout/logo.png';

import { disconnect } from '../../store/modules/User/actions';

import styles from './LogoutModal.module.scss';

function LogoutModal(props) {
    const dispatch = useDispatch();

    const { isVisible, onCancel } = props;

    const logout = () => {
        onCancel();
        dispatch(disconnect());
    }

    return (
        <Modal
            visible={isVisible}
            closable={false}
            centered
            onCancel={onCancel}
            width={'325rem'}
            wrapperClass={styles.container}
            wrapperBody={styles.wrapperBody}
        >
            <div className={styles.content}>
                <img src={closeIcon} alt="" className={styles.close} onClick={() => onCancel()}/>

                <img src={logo} alt="" className={styles.logo} />

                <header className={styles.header}>
                    <h2 className={styles.heading}>Logout</h2>
                    <p className={styles.detail}>Are you sure you want to logout?</p>
                </header>

                <section className={styles.btnContainer}>
                    <div className={styles.yseBtn} onClick={() => logout()}>
                        Yes
                    </div>

                    <div className={styles.cancelBtn} onClick={() => onCancel()}>
                        Cancel
                    </div>
                </section>
            </div>
        </Modal>
    );
}

export default LogoutModal;