import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import Modal from '../Modal';

import closeIcon from './img/close.png';
import macbookBeta from './img/mac-beta.png';
import appleIcon from './img/apple-icon.png';
import windowBeta from './img/window-beta.png';
import windowIcon from './img/windows-icon.png';
import divide from './img/divide.png';

import styles from './DownloadModal.module.scss';

function DownloadModal(props) {
    const { isVisible, onCancel } = props;

    const { isMobile } = useSelector(state => state?.app);
    const { data } = useSelector(state => state?.app?.allSettings?.result ?? []);

    const getMacLink = data && data.find(e => e.key === 'mac');
    const getWindowsLink = data && data.find(e => e.key === 'windows');
    const getMacVersion = data && data.find(e => e.key === 'mac-version');
    const getWindowsVersion = data && data.find(e => e.key === 'windows-version');

    return (
        <div>
            <Modal
                visible={isVisible}
                closable={false}
                centered
                onCancel={onCancel}
                width={isMobile ? '325rem' : '625rem'}
                wrapperClass={styles.container}
                wrapperBody={styles.wrapperBody}
            >
                <div className={styles.content}>
                    <img src={closeIcon} alt="" className={styles.close} onClick={() => onCancel()}/>

                    <header className={styles.header}>
                        <h2 className={styles.heading}>Download Undead Blocks</h2>
                        <p className={styles.detail}>Download the Undead Blocks, available to the Public for PC and Mac.</p>
                    </header>

                    <section className={styles.info}>
                        <div className={styles.macbook}>
                            <img src={macbookBeta} alt="" className={styles.macbookBeta} />

                            <div className={styles.infoBody}>
                                <h2>MAC</h2>
                                <p className={styles.versionInfo}>
                                    Version number {getMacVersion?.value}
                                </p>

                                <section className={styles.recommendSpec}>
                                    <span className={styles.recommendHeading}>Recommended Specs:</span>
                                    <p className={styles.recommendText}>
                                        Macbook Pro 2020 ((64-bit OS required)) <br />
                                        Core i5 <br />
                                        16gb RAM  <br />
                                        At least 12gb of available storage <br />
                                        <span className={styles.note}>Mac M1 Optimization still in progress*</span>
                                    </p>
                                </section>
                            </div>

                            <div
                                className={cn(styles.downloadBtn, {
                                [   styles.disable]: !getMacLink?.value
                                })}
                                onClick={() => getMacLink?.value && window.open(getMacLink?.value, '_blank')}
                            >
                                <p className={styles.labelBtn}>Download for</p>
                                <img src={appleIcon} alt="" className={styles.appleIcon} />
                                <p className={styles.labelBtn}>Mac</p>
                            </div>
                        </div>

                        <img src={divide} alt="" className={styles.divide} />

                        <div className={styles.windows}>
                            <img src={windowBeta} alt="" className={styles.windowsBeta} />

                            <div className={styles.infoBody}>
                                <h2>WINDOWS</h2>
                                <p className={styles.versionInfo}>
                                    Version number {getWindowsVersion?.value}
                                </p>

                                <section className={styles.recommendSpec}>
                                    <span className={styles.recommendHeading}>Recommended Specs:</span>
                                    <p className={styles.recommendText}>
                                        Windows 10 ((64-bit OS required)) <br />
                                        Core i5 10400 card GTX 750 TI ram or equivalent <br />
                                        16gb RAM <br />
                                        At least 12gb of available storage
                                    </p>
                                </section>
                            </div>

                            <div
                                className={cn(styles.downloadBtn, {
                                    [styles.disable]: !getWindowsLink?.value
                                })}
                                onClick={() => getWindowsLink?.value && window.open(getWindowsLink?.value, '_blank')}
                            >
                                <p className={styles.labelBtn}>Download for</p>
                                <img src={windowIcon} alt="" className={styles.appleIcon} />
                                <p className={styles.labelBtn}>Windows</p>
                            </div>
                        </div>
                    </section>
                </div>
            </Modal>
        </div>
    );
}

export default React.memo(DownloadModal);