import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import bubble from '../../img/bubble.png';
import coffinOpeningVid from 'assets/videos/coffin-opening.webm';

import Modal from 'components/Modal';
import WeaponSlide from 'components/WeaponSlide';
import closeIcon from 'components/DownloadModal/img/close.png';
import WagyuLoader from 'components/WagyuLoader';

import { PAGE_ARMORY_PATH, PAGE_LOOT_CONFINS_PATH } from '../../../../Router/constants';
import { setWeaponRandomVisible } from 'store/modules/App/actions';

import styles from './ViewModal.module.scss';

function ViewModal(props) {
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        selected,
        isSliderStart,
        endLoading,
        onCancel
    } = props;

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const { isMobile } = useSelector(state => state?.app);
    const isVisible = useSelector(state => state?.app?.weaponRandomVisible);
    const rewardsRes = useSelector(state => state?.lootConffin?.rewardsRes?.result ?? []);
    const lootCoffinReward = useSelector(state => state?.lootConffin?.unlockCoffinRes?.result?.lootCoffinReward);

    const item = rewardsRes && rewardsRes.find(e => e.name === lootCoffinReward);

    useEffect(() => {
        if (selected && item) {
            setTitle(`${item?.weapon} - ${item?.skin}`);
            setDescription('');

            return;
        }

        setTitle('Open Loot Coffin');
        setDescription('We are opening your loot coffin, good luck Survivor.');
    }, [selected, item]);

    const handleOnClickViewArmory = () => {
        dispatch(setWeaponRandomVisible(false));
        history.push(PAGE_ARMORY_PATH);
    }

    const handleOnClickViewInventory = () => {
        dispatch(setWeaponRandomVisible(false));
        history.push(PAGE_LOOT_CONFINS_PATH);
    }

    return (
        <Modal
            visible={isVisible}
            closable={false}
            centered 
            onCancel={onCancel}
            width={isMobile ? '86%' : '800rem'}
            height={isMobile ? '550rem' : '650rem'}
            wrapperClass={styles.container}
            wrapperBody={styles.wrapperBody}
        >
            <div className={styles.content}>
                <img src={closeIcon} alt="" className={styles.close} onClick={() => onCancel()}/>

                <header className={styles.header}>
                    <h2 className={styles.heading}>{title}</h2>
                    <p className={styles.detail}>
                        {description}
                    </p>
                </header>

                <section className={styles.box}>
                    <WeaponSlide
                        selected={selected}
                        isSliderStart={isSliderStart}
                    />

                    <div style={{ width: '100%' }}>
                        {isMobile ? (
                            <div className={cn(styles.loadingWagyu, {
                                [styles.none]: endLoading
                            })}>
                                <WagyuLoader customStyle={styles.loading} />
                            </div>
                        ) : (
                            <video
                                width={'100%'}
                                height={'100%'}
                                // muted
                                autoPlay
                                className={cn(styles.openingCoffinVideo, {
                                    [styles.none]: selected
                                })}
                            >
                                <source
                                    src={coffinOpeningVid}
                                    type="video/webm"
                                />
                            </video>
                        )}
                    </div>

                    <img src={bubble} alt="" className={styles.leftBubble} />
                    <img src={bubble} alt="" className={styles.rightBubble} />
                </section>

                <div
                    className={cn(styles.buttonContainer, {
                        [styles.show]: selected
                    })}
                >
                    <section className={styles.viewArmory} onClick={() => handleOnClickViewArmory()}>
                        Weapon Skin Inventory
                    </section>

                    <section className={styles.viewLoot} onClick={() => handleOnClickViewInventory()}>
                        Loot Coffin Inventory
                    </section>
                </div>
            </div>
        </Modal>
    );
}

export default React.memo(ViewModal);