import React from 'react';
import { Input } from 'antd';
import cn from 'classnames';
import { get } from 'lodash';

import styles from './InputField.module.scss';

function InputField(props) {
    const {
        field,
        form,
        label,
        placeholder,
        disabled,
        errorWidth,
        errorPassword,
        clearError
    } = props;

    const { name } = field;
    const { errors = {}, touched = {}, handleChange } = form;

    const fieldError = get(errors, `${name}`);
    const errorPasswordMess = errorPassword ? errorPassword[0] : '';
    const placeHolderCustom = placeholder && `${placeholder.charAt(0).toUpperCase() + placeholder.slice(1)}`;

    const handleOnChange = (e) => {
        errorPassword && clearError && clearError();
        handleChange(e);
    }

    return (
        <div className={styles.inputWrapper}>
            <div className={styles.inputHeader}>
                {label && <h2 className={styles.label}>{label}</h2>}
                {fieldError && touched[name] && <span className={cn(styles.error, errorWidth)}>{fieldError}</span>}
                {errorPasswordMess && <span className={cn(styles.error, errorWidth)}>{errorPasswordMess}</span>}
            </div>

            <Input.Password
                className={cn(styles.input)}
                id={name}
                {...field}
                onChange={handleOnChange}
                type={'password'}
                iconRender={(visible) => (
                    <span style={{ color: '#8b98d1' }}>{visible ? 'HIDE' : 'SHOW'}</span>
                )}
                placeholder={placeHolderCustom || ''}
                disabled={disabled}
                autoComplete={'off'}
            />
        </div>
    )
}

export default InputField;