import { handleActions } from "redux-actions";
import {persistReducer} from 'redux-persist';
import localForage from 'localforage';

const initialState = {
    registerRes: {
        error: null,
        result: null,
        requesting: false,
    },
    existsRes: {
        error: null,
        result: null,
        requesting: false,
    },
    loginRes: {
        error: null,
        result: null,
        requesting: false,
    },
    forgotPasswordRes: {
        error: null,
        result: null,
        requesting: false,
    },
    verifyEmailRes: {
        error: null,
        result: null,
        requesting: false,
    },
    finishVerifyRes: {
        error: null,
        result: null,
        requesting: false,
    },
    resetPasswordRes: {
        error: null,
        result: null,
        requesting: false,
    },
    changePasswordRes: {
        error: null,
        result: null,
        requesting: false,
    },
    assignAddressRes: {
        error: null,
        result: null,
        requesting: false,
    },
    leaderBoardRes: {
        error: null,
        result: null,
        requesting: false,
    },
    twitchValidateRes: {
        error: null,
        result: null,
        requesting: false,
    }
};
  
let walletReducer = handleActions({
    /** REGISTER ACCOUNT **/
    REGISTER_ACCOUNT_REQUEST: (state) => ({
        ...state,
        registerRes: {
            ...state.registerRes,
            requesting: true
        },
    }),
    REGISTER_ACCOUNT_SUCCESS: (state, { payload }) => ({
        ...state,
        registerRes: {
            ...state.registerRes,
            result: payload,
            requesting: false,
            error: null,
        },
    }),
    REGISTER_ACCOUNT_FAIL: (state, { payload }) => ({
        ...state,
        registerRes: {
            ...state.registerRes,
            requesting: false,
            error: payload.error
        },
    }),

    /** LOGIN ACCOUNT **/
    LOGIN_ACCOUNT_REQUEST: (state) => ({
        ...state,
        loginRes: {
            ...state.loginRes,
            requesting: true
        },
    }),
    LOGIN_ACCOUNT_SUCCESS: (state, { payload }) => ({
        ...state,
        loginRes: {
            ...state.loginRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    LOGIN_ACCOUNT_FAIL: (state, { payload }) => ({
        ...state,
        loginRes: {
            ...state.loginRes,
            requesting: false,
            error: payload.error
        },
    }),

    /** LOGIN ACCOUNT **/
    ASSIGN_ADDRESS_REQUEST: (state) => ({
        ...state,
        assignAddressRes: {
            ...state.assignAddressRes,
            requesting: true
        },
    }),
    ASSIGN_ADDRESS_SUCCESS: (state, { payload }) => ({
        ...state,
        assignAddressRes: {
            ...state.assignAddressRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    ASSIGN_ADDRESS_FAIL: (state, { payload }) => ({
        ...state,
        assignAddressRes: {
            ...state.assignAddressRes,
            requesting: false,
            error: payload.error
        },
    }),

    // CLEAR LOGIN ERROR
    CLEAR_LOGIN_ERROR: (state) => ({
        ...state,
        loginRes: {
            ...state.loginRes,
            error: null
        }
    }),

    /** CHECK EXISTS ACCOUNT **/
    CHECK_EXISTS_REQUEST: (state) => ({
        ...state,
        existsRes: {
            ...state.existsRes,
            requesting: true
        },
    }),
    CHECK_EXISTS_SUCCESS: (state, { payload }) => ({
        ...state,
        existsRes: {
            ...state.existsRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    CHECK_EXISTS_FAIL: (state, { payload }) => ({
        ...state,
        existsRes: {
            ...state.existsRes,
            requesting: false,
            error: payload.error
        },
    }),

    /** CHANGE PASSWORD ACCOUNT **/
    CHANGE_PASSWORD_REQUEST: (state) => ({
        ...state,
        changePasswordRes: {
            ...state.changePasswordRes,
            requesting: true
        },
    }),
    CHANGE_PASSWORD_SUCCESS: (state, { payload }) => ({
        ...state,
        changePasswordRes: {
            ...state.changePasswordRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    CHANGE_PASSWORD_FAIL: (state, { payload }) => ({
        ...state,
        changePasswordRes: {
            ...state.changePasswordRes,
            requesting: false,
            error: payload.error
        },
    }),

    /** FORGOT PASSWORD ACCOUNT **/
    FORGOT_PASSWORD_REQUEST: (state) => ({
        ...state,
        forgotPasswordRes: {
            ...state.forgotPasswordRes,
            requesting: true
        },
    }),
    FORGOT_PASSWORD_SUCCESS: (state, { payload }) => ({
        ...state,
        forgotPasswordRes: {
            ...state.forgotPasswordRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    FORGOT_PASSWORD_FAIL: (state, { payload }) => ({
        ...state,
        forgotPasswordRes: {
            ...state.forgotPasswordRes,
            requesting: false,
            error: payload.error
        },
    }),

    /** RESET PASSWORD ACCOUNT **/
    RESET_PASSWORD_REQUEST: (state) => ({
        ...state,
        resetPasswordRes: {
            ...state.resetPasswordRes,
            requesting: true
        },
    }),
    RESET_PASSWORD_SUCCESS: (state, { payload }) => ({
        ...state,
        resetPasswordRes: {
            ...state.resetPasswordRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    RESET_PASSWORD_FAIL: (state, { payload }) => ({
        ...state,
        resetPasswordRes: {
            ...state.resetPasswordRes,
            requesting: false,
            error: payload.error
        },
    }),

    /** VERIFY EMAIL **/
    VERIFY_EMAIL_REQUEST: (state) => ({
        ...state,
        verifyEmailRes: {
            ...state.verifyEmailRes,
            requesting: true
        },
    }),
    VERIFY_EMAIL_SUCCESS: (state, { payload }) => ({
        ...state,
        verifyEmailRes: {
            ...state.verifyEmailRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    VERIFY_EMAIL_FAIL: (state, { payload }) => ({
        ...state,
        verifyEmailRes: {
            ...state.verifyEmailRes,
            requesting: false,
            error: payload.error
        },
    }),

    /** FINISH VERIFY **/
    FINISH_VERIFY_REQUEST: (state) => ({
        ...state,
        finishVerifyRes: {
            ...state.finishVerifyRes,
            requesting: true
        },
    }),
    FINISH_VERIFY_SUCCESS: (state, { payload }) => ({
        ...state,
        finishVerifyRes: {
            ...state.finishVerifyRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    FINISH_VERIFY_FAIL: (state, { payload }) => ({
        ...state,
        finishVerifyRes: {
            ...state.finishVerifyRes,
            requesting: false,
            error: payload.error
        },
    }),

    /** FETCH LEADER BOARD **/
    FETCH_LEADER_BOARD_REQUEST: (state) => ({
        ...state,
        leaderBoardRes: {
            ...state.leaderBoardRes,
            requesting: true
        },
    }),
    FETCH_LEADER_BOARD_SUCCESS: (state, { payload }) => ({
        ...state,
        leaderBoardRes: {
            ...state.leaderBoardRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    FETCH_LEADER_BOARD_FAIL: (state, { payload }) => ({
        ...state,
        leaderBoardRes: {
            ...state.leaderBoardRes,
            requesting: false,
            error: payload.error
        },
    }),

    /** TWITCH VALIDATE **/
    TWITCH_VALIDATE_REQUEST: (state) => ({
        ...state,
        twitchValidateRes: {
            ...state.twitchValidateRes,
            requesting: true
        },
    }),
    TWITCH_VALIDATE_SUCCESS: (state, { payload }) => ({
        ...state,
        twitchValidateRes: {
            ...state.twitchValidateRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    TWITCH_VALIDATE_FAIL: (state, { payload }) => ({
        ...state,
        twitchValidateRes: {
            ...state.twitchValidateRes,
            requesting: false,
            error: payload.error
        },
    })
}, initialState);

walletReducer = persistReducer({
    key: 'wallet',
    storage: localForage,
    whitelist: ['userInfoRes']
}, walletReducer);

export default walletReducer;