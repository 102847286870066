import React from 'react';

import { FOOTER_ITEMS } from './constants';

import styles from './Footer.module.scss';

function Footer() {
    return (
        <div className={styles.container}>
            <p className={styles.title}>Follow Undead Blocks</p>

            <section className={styles.footerList}>
                {FOOTER_ITEMS.map(e => (
                    <a href={e.path} target="_blank" rel="noreferrer" key={e.label} className={styles.footerItem}>
                        <img src={e.icon} alt="" className={styles.footerIcon} style={{ width: e.styling.width, height: e.styling.height }} />
                    </a>
                ))}
            </section>
        </div>
    );
}

export default Footer;