import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { times } from 'lodash';

import firstIcon from '../img/pagination/first.png';
import lastIcon from '../img/pagination/last.png';
import prevIcon from '../img/pagination/prev.png';
import nextIcon from '../img/pagination/next.png';

import styles from './Pagination.module.scss';

function Pagination(props) {
    const { totalPage, currentPage, setCurrentPage } = props;

    const [pageList, setPageList] = useState([]);

    useEffect(() => {
        const calculateList = (page, total) => {
            const list = [];

            times(total, i => list.push((i + 1)));

            if (total <= 5) {
                return list;
            }
        
            if (page <= 3) {
                return [
                    ...list.slice(0, page + 4),
                ];
            }
        
            if (page + 3 > total) {
                return [
                    ...list.slice(total - 5, total)
                ]
            }
        
            return [
                ...list.slice(page - 3, page + 2),
            ]
        }

        setPageList(calculateList(currentPage, totalPage));
    }, [currentPage, totalPage]);

    const handleOnPrev = () => {
        if (currentPage === 1) return;
    
        setCurrentPage(currentPage - 1);
    }

    const handleOnNext = () => {
        if (currentPage === totalPage) return;

        setCurrentPage(currentPage + 1);
    }

    const handleOnFirst = () => {
        if (currentPage === 1) return;

        setCurrentPage(1);
    }

    const handleOnLast = () => {
        if (currentPage === totalPage) return;

        setCurrentPage(totalPage);
    }

    return (
        <div className={styles.container}>
            <div className={styles.pagRow}>
                <section
                    className={cn(styles.element, {
                        [styles.isDisabled]: currentPage === 1
                    })}
                    onClick={handleOnFirst}
                >
                    <img
                        src={firstIcon}
                        alt=""
                        className={styles.navButton}
                    />
                </section>

                <section
                    className={cn(styles.element, {
                        [styles.isDisabled]: currentPage === 1
                    })}
                    onClick={handleOnPrev}
                >
                    <img
                        src={prevIcon}
                        alt=""
                        className={styles.subNavButton}
                    />
                </section>

                {pageList.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className={cn(styles.element, {
                                [styles.isActive]: item === currentPage
                            })}
                            onClick={() => setCurrentPage(item)}
                        >
                            <p className={styles.currentPage}>{item}</p>
                        </div>
                    )
                })}

                <section
                    className={cn(styles.element, {
                        [styles.isDisabled]: currentPage === totalPage
                    })}
                    onClick={handleOnNext}
                >
                    <img
                        src={nextIcon}
                        alt=""
                        className={styles.subNavButton}
                    />
                </section>

                <section
                    className={cn(styles.element, {
                        [styles.isDisabled]: currentPage === totalPage
                    })}
                    onClick={handleOnLast}
                >
                    <img
                        src={lastIcon}
                        alt=""
                        className={styles.navButton}
                    />
                </section>
            </div>
        </div>
    );
}

export default React.memo(Pagination);