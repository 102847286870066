import React from 'react';
import cn from 'classnames';

import logo from '../../assets/landingLayout/logo.png';

import BaseBackground from './BaseBackground';
import BaseBanner from './BaseBanner';

import style from './LandingLayout.module.scss';

export default function LandingLayout(props) {
    const { wrapperClass } = props;

    return (
        <div className={cn(style.container)}>
            <main className={cn(style.main, wrapperClass)}>
                <BaseBackground />
                <div className={style.contentBox}>
                    <section className={style.left}>
                        {props.children}
                    </section>

                    <section className={style.right}>
                        <img src={logo} alt="" className={style.logo} />
                        <BaseBanner />
                    </section>
                </div>
            </main>
        </div>
    )
}