import React from 'react';
import { useSelector } from 'react-redux';

import banner from '../../../../assets/dashboardLayout/dashboard/banner.png';
import bannerMobile from '../../../../assets/dashboardLayout/dashboard/banner-mobile.png';
import logoBanner from '../../../../assets/dashboardLayout/dashboard/logo-banner.png';

import CardList from './CardList';

import styles from './Dashboard.module.scss';

function DashboardPage() {
    const { isMobile } = useSelector(state => state.app);

    return (
        <div className={styles.container}>
            <header className={styles.headerBanner}>
                <img src={isMobile ? bannerMobile : banner} alt="" className={styles.banner} />
                <img src={logoBanner} alt="" className={styles.logoBanner} />
            </header>

            <section className={styles.content}>
                <h2 className={styles.title}>WAGYU DASHBOARD</h2>
                <p className={styles.detail}>Explore Undead Blocks</p>
            </section>

            <CardList />
        </div>
    );
}

export default DashboardPage;