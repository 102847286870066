import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import cn from 'classnames';

import NftItem from './NftIem';

import WagyuLoader from '../WagyuLoader';
import NftDetail  from './NFTDetail';

import styles from './NFTList.module.scss';

function NftList(props) {
    const {
        list = [],
        setOffset = () => {},
        hasMore,
        loading,
        progress,
        loadFirst,
        wrappedClass
    } = props;

    const [isDetailVisible, setDetailVisible] = useState(false);
    const [itemDetail, setItemDetail] = useState({});

    const handleOnViewDetail = (item) => {
        setItemDetail(item);
        setDetailVisible(true);
    };

    const handleOnCancel = () => {
        setDetailVisible(false);
    };

    const handleGetTransferId = (item) => {
        window.localStorage.setItem('transferId', item.tokenId);
    };

    return (
        <>
            {isEmpty(list) && !loadFirst ? (
                <div className={styles.empty}>
                    Your Weapon Loadout NFTS is empty
                </div>
            ) : (
                <div className={cn(styles.container, wrappedClass)}>
                    {list.map((e, i) => (
                        <NftItem
                            key={e.id}
                            item={e}
                            setOffset={setOffset}
                            hasMore={hasMore}
                            loading={loading}
                            index={i}
                            list={list}
                            loadFirst={loadFirst}
                            onViewDetail={handleOnViewDetail}
                        />
                    ))}
                </div>
            )}

            {!loadFirst && loading && (
                <div className={styles.loadMoreContainer}>
                    <WagyuLoader customStyle={styles.loadMore} />
                </div>
            )}

            <NftDetail
                isVisible={isDetailVisible}
                item={itemDetail}
                progress={progress}
                onCancel={handleOnCancel}
                onGetTransferId={handleGetTransferId}
            />
        </>
    )
}

export default NftList;