import { isEmpty } from 'lodash';

import eth from './img/swap/eth.png';
import undead from './img/swap/undead.png';
import usdc from './img/swap/usdc.png';
import bnb from './img/swap/bnb.png';
import busd from './img/swap/busd.png';
import matic from './img/swap/matic.png';
import usdt from './img/swap/usdt.png';

export const ICONS = {
    ETH: eth,
    UNDEAD: undead,
    USDC: usdc,
    BNB: bnb,
    BUSD: busd,
    USDT: usdt,
    MATIC: matic
}

export const NETWORK_INFO = (chainId, token) => {
    switch (chainId) {
        case '0x5':
        case '0x1':
        case '0x01':
        case '0x05':
            return `${token} (ERC-20)`;
        case '0x61':
        case '0x38':
            return `${token} (BEP-20)`;
        case '0x13881':
        case '0x89':
            return `${token} (Polygon)`
    
        default:
            break;
    }
}

export const checkBaseCoin = (token) => {
    switch (token) {
        case 'ETH':
        case 'BNB':
        case 'MATIC':
            return true;
        case 'USDC':
        case 'BUSD':
        case 'USDT':
        case 'UNDEAD':
            return false;

        default:
            break;
    }
}

export const getBlockchain = (chainId) => {
    switch (chainId) {
        case '0x5':
        case '0x1':
        case '0x01':
        case '0x05':
            return 'ether';
        case '0x61':
        case '0x38':
            return 'bsc';
        case '0x13881':
        case '0x89':
            return 'polygon'
    
        default:
            break;
    }
}

export const getDisableNetwork = (chainId) => {
    let isDisabled = null;

    if (window?.gamestop?.connected) {
        switch (chainId) {
            case '0x1':
            case '0x5':
            case '0x01':
            case '0x05':
                isDisabled = false;
                break;
        
            default:
                isDisabled = true;
                break;
        }
    } else {
        isDisabled = false;
    }

    return isDisabled;
}

// Pools list getting from response that are handled by here to use for over the app
export const getPoolsList = (initLists) => {
    if (isEmpty(initLists)) return [];

    const newList = initLists.map((list) => ({
        label: NETWORK_INFO(list.chainId, list.token),
        value: list.token,
        icon: ICONS[list.token],
        networkName: list.token,
        chainId: list.chainId,
        tokenAddress: list.address,
        decimals: list.decimals,
        isBaseCoin: checkBaseCoin(list.token),
        blockchain: getBlockchain(list.chainId),
        isDisabled: getDisableNetwork(list.chainId)
    }));

    return newList;
}

// Return a key that is base coin to default
export const getDropdownItem = (lists, chainId, cb) => {
    if (isEmpty(lists)) return {};

    switch (chainId) {
        case 1:
        case 4:
            return cb(lists.find(e => e.networkName === 'ETH'))

        case 56:
        case 97:
            return cb(lists.find(e => e.networkName === 'BNB'))

        case 137:
        case 80001:
            return cb(lists.find(e => e.networkName === 'MATIC'))
    
        default:
            break;
    }
}