import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import Modal from 'components/Modal';
import closeIcon from 'components/DownloadModal/img/close.png';
import bubble from '../../PurcharseLoot/img/bubble.png';

import { PAGE_ARMORY_PATH, PAGE_LOOT_CONFINS_PATH } from '../../../Router/constants';

import styles from './ViewModal.module.scss';

function ViewModal(props) {
    const history = useHistory();

    const { item, onCancel } = props;

    const { isMobile } = useSelector(state => state?.app);

    const handleOnClickViewArmory = () => {
        history.push(PAGE_ARMORY_PATH);
        onCancel();
    }

    const handleOnClickViewInventory = () => {
        history.push(PAGE_LOOT_CONFINS_PATH);
        onCancel();
    }

    return (
        <Modal
            visible={!!item}
            closable={false}
            centered 
            onCancel={onCancel}
            width={isMobile ? '86%' : '800rem'}
            height={isMobile ? '550rem' : '650rem'}
            wrapperClass={styles.container}
            wrapperBody={styles.wrapperBody}
        >
            <div className={styles.content}>
                <img src={closeIcon} alt="" className={styles.close} onClick={() => onCancel()}/>

                <header className={styles.header}>
                    <h2 className={styles.heading}>{item?.rewardWeapon} - {item?.rewardSkin}</h2>
                </header>

                <section className={styles.box}>
                    <img src={bubble} alt="" className={styles.leftBubble} />
                    <img src={bubble} alt="" className={styles.rightBubble} />

                    <div className={styles.itemContainer}>
                        <img alt={'skin'} className={styles.itemSelected} src={item?.variants[0]?.skin || item?.media} />
                    </div>
                </section>

                <div
                    className={cn(styles.buttonContainer)}
                >
                    <section className={styles.viewArmory} onClick={() => handleOnClickViewArmory()}>
                        Weapon Skin Inventory
                    </section>

                    <section className={styles.viewLoot} onClick={() => handleOnClickViewInventory()}>
                        Loot Coffin Inventory
                    </section>
                </div>
            </div>
        </Modal>
    )
}

export default React.memo(ViewModal);