import Logger from 'js-logger';
import { get } from 'lodash';

import { CONTRACT_TIMEOUT, ERROR_CODE_TIME_OUT, MESSAGE_TIMEOUT_WITHDRAW } from './constants';


export async function swapIn(presentContract, address, params, callbacks) {
  return presentContract.methods.swapIn(params.amount, params.token)
    // .send({ from: address, value: toWeiDecimals(params.swapIn, params.decimals) })
    .send({ from: address, value: params.amount })
    .on('sent', (sent) => {
      Logger.log(`sent ${sent}`);
    })
    .on('error', (error) => {
      Logger.log(`swapIn error ${error}`);

      return error;
    })
    .on('transactionHash', async (hash) => {
      await waitForReceipt(hash, callbacks.onReceipt, 0);
    })
    .on('receipt', (receipt) => {
      return receipt;
    });
}

export async function swapOut(presentContract, address, params, callbacks) {
  const sendParams = {
    from: address
  };

  try {
    sendParams.gas = await window.web3.eth.estimateGas({
      from: address,
      to: presentContract.options.address,
      data: presentContract.methods.swapOut(
        params.swapId,
        params.token,
        params.amount,
        params.sigTime,
        params.signatures,
      ).encodeABI(),
    });
  } catch (error) {}

  try {
    return presentContract.methods.swapOut(
        params.swapId,
        params.token,
        params.amount,
        params.sigTime,
        params.signatures,
      )
      .send(sendParams)
      .on('sent', (sent) => {
        Logger.log(`sent ${sent}`);
      })
      .on('error', (error) => {
        Logger.log(`swapOut error ${error}`);

        return error;
      })
      .on('transactionHash', async (hash) => {
        callbacks.onTransactionHash(hash);

        // await waitForReceipt(hash, callbacks.onReceipt, 0);
      })
      .on('receipt', (receipt) => {
        callbacks.onReceipt(receipt);
        return receipt;
      });
  } catch (error) {
    Logger.log(`swapOut error ${error}`);
    return error;
  }

}

export const approve = (contract, spenderAddress, address, amount, cb) => {
  return contract.methods.approve(spenderAddress, amount).send({ from: address })
    .on('error', function(error) {
      Logger.log(`approve error ${error}`);
      // cb(null, { ...error, description: error.message });
      return error;
    })
    .on('transactionHash', (tx) => {
      waitForReceipt(tx, cb, 0);
      return tx.transactionHash;
    });
};

export const getAllowance = (tokenContract, account, contractAddress) => {
  return tokenContract.methods.allowance(account, contractAddress).call();
};

export const getBalanceOf = (contract, address) => {
  return contract.methods.balanceOf(address).call();
};

export async function buyPackage(presentContract, address, params, cb) {
  return presentContract.methods.buyPackage(params.pkgId,
      params.pkgAmount,
      params.price,
      params.sigTime,
      params.signature)
    .send({ from: address })
    .on('sent', (sent) => {
      Logger.log(`sent ${sent}`);
    })
    .on('error', (error) => {
      Logger.log(`buyPackage error ${error}`);

      return error;
    })
    .on('transactionHash', (hash) => {
      // callbacks.onTransactionHash(hash);
      waitForReceipt(hash, cb.onReceipt, 0);

      return hash.transactionHash;
    })
    .on('receipt', (receipt) => {
      // callbacks.onReceipt(receipt);
      return receipt;
    });
}

export const waitForReceipt = (hash, cb, timeout, cbLog = () => { }) => {
  return window.web3 ? window.web3.eth.getTransactionReceipt(hash, function(error, receipt) {
    if (timeout >= (CONTRACT_TIMEOUT * 60 * 1000)) {
      return cb({ code: ERROR_CODE_TIME_OUT, description: MESSAGE_TIMEOUT_WITHDRAW });
    }

    if (error) {
      Logger.log(`waitForReceipt ${error}`);
      cb({ ...error, description: error.message });
    }

    if (receipt !== null) {
      Logger.log(`waitForReceipt receipt ${receipt} ${error}`);
      const status = get(receipt, 'status', false);
      // Transaction went through
      if (cb) {
        if (status) {
          cb(receipt);
          cbLog(receipt);
        } else {
          cb(null, null);
        }
      }
    } else {
      // Try again in 1 second
      window.setTimeout(function() {
        waitForReceipt(hash, cb, (timeout + 1000), cbLog);
      }, 1000);
    }
  }) : '';
};
