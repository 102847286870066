import http from '../../../services/http';

export default class Imx {
    static fetchAllDeposits() {
        return http.get('https://api.ropsten.x.immutable.com/v1/deposits');
    }

    static fetchDepositDetail(id) {
        return http.get(`https://api.ropsten.x.immutable.com/v1/deposits/${id}`);
    }

    static hasConnectedEver(user) {
        const endPoint = process.env.REACT_APP_DEV === '1'
            ?  process.env.REACT_APP_IMX_API_TESTNET
            :  process.env.REACT_APP_IMX_API_MAINNET

        return http.get(`${endPoint}/${user}`);
    }
}
