import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Collapse } from 'antd';
import { isEmpty } from 'lodash';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import logo from 'assets/img/logo.png';
import arrowIcon from 'assets/dashboardLayout/navigation/arrow.png';
 
import {
    NAVIGATION_ITEMS,
    NAVIGATION_OPTIONS
} from './constants';

import { InventoryChildren } from '../../Router/constants';

import { getAccountSymbol } from 'utils/web3';
import { getBalanceOfToken } from 'utils/bigNumber';
import { setConnectModalVisible } from 'store/modules/App/actions';

import styles from './Header.module.scss';

const { Panel } = Collapse;

function HeaderNavigation(props) {
    const dispatch = useDispatch();
    const { navKey } = props;

    const [dropdown, setDropdown] = useState([]);

    const { isConnect } = useSelector(state => state?.user?.connectWallet ?? {});

    const chainId = useSelector(state => state?.user?.chainId ?? 1);
    const account = useSelector(state => state?.user?.userAccount?.accounts[0] ?? '');
    const balance = useSelector(state => state?.user?.userAccount?.balance);

    const { username, avatar } = useSelector(state => state?.user?.userInfo?.result ?? {});
    const loadingUser = useSelector(state => state?.user?.userInfo?.requesting);
    // const { loadingConnectAccount } = useSelector(state => state?.user ?? {});

    const currentPage = useCallback((item) => {
        return navKey === item.key || (item.key === '/my-inventory' && InventoryChildren.includes(navKey));
    }, [navKey]);

    const renderNavItem = useCallback((item) => {
        return (
            item.path ? (
                item.disabled ? (
                    <div
                        key={item.label}
                        // onMouseEnter={() => {
                        //     setNavKey(item.key);
                        // }}
                        // onMouseLeave={() => {
                        //     setNavKey('');
                        // }}
                        onClick={() => {}}
                    >
                        <li
                            className={cn(styles.navItem, {
                                [styles.current]: currentPage(item)
                            })}
                        >
                            <img
                                src={(currentPage(item) || item.key === navKey) ? item.iconActive : item.iconDeactive}
                                alt={item.label}
                                className={styles.icon}
                                style={{ width: item.styling.width, height: item.styling.height, marginRight: item.styling.marginRight }}
                            />
                            <span className={styles.label}>{item.label}</span>
                        </li>
                    </div>
                ) : (
                    <Link
                        key={item.label}
                        to={item.path}
                        // onMouseEnter={() => {
                        //     setNavKey(item.key);
                        // }}
                        // onMouseLeave={() => {
                        //     setNavKey('');
                        // }}
                        onClick={() => {
                            if (item.requireConnected) {
                                !isConnect ? dispatch(setConnectModalVisible(true)) : item.action && dispatch(item.action(true))
                            } else {
                                !item.disabled && item.action && dispatch(item.action(true));
                            }
                        }}
                    >
                        <li
                            className={cn(styles.navItem, {
                                [styles.current]: currentPage(item)
                            })}
                        >
                            <img
                                src={(currentPage(item) || item.key === navKey) ? item.iconActive : item.iconDeactive}
                                alt={item.label}
                                className={styles.icon}
                                style={{ width: item.styling.width, height: item.styling.height, marginRight: item.styling.marginRight }}
                            />
                            <span className={styles.label}>{item.label}</span>
                        </li>
                    </Link>
                )
            ) : (
                <div
                    key={item.label}
                    // onMouseEnter={() => {
                    //     setNavKey(item.key);
                    // }}
                    // onMouseLeave={() => {
                    //     setNavKey('');
                    // }}
                    onClick={() => {
                        if (item.requireConnected) {
                            !isConnect ? dispatch(setConnectModalVisible(true)) : item.action && dispatch(item.action(true))
                        } else {
                            !item.disabled && item.action && dispatch(item.action(true));
                        }
                    }}
                >
                    <li
                        className={cn(styles.navItem, {
                            [styles.current]: currentPage(item)
                        })}
                    >
                        <img
                            src={(currentPage(item) || item.key === navKey) ? item.iconActive : item.iconDeactive}
                            alt={item.label}
                            className={styles.icon}
                            style={{ width: item.styling.width, height: item.styling.height, marginRight: item.styling.marginRight }}
                        />
                        <span className={styles.label}>{item.label}</span>
                    </li>
                </div>
            )
        )
    }, [dispatch, isConnect, navKey, currentPage]);

    return (
        <div className={styles.container}>
            <nav className={styles.nav}>
                <ul className={styles.navList}>
                    {NAVIGATION_ITEMS.map((e) => (
                        renderNavItem(e)
                    ))}
                </ul>
            </nav>

            <div className={styles.divide} />

            <nav className={styles.collapse}>
                <section className={styles.walletInfo}>
                    {isConnect ? (
                            <div className={styles.hasWallet}>
                                <div className={styles.left}>
                                    {getBalanceOfToken(balance)} {getAccountSymbol(chainId)}
                                </div>

                                <div className={styles.right}>
                                    <p>{account && account.substring(0, 4)}......{account && account.substring(account.length - 3)}</p>
                                    <div className={styles.jazzIcon}>
                                        <Jazzicon
                                            paperStyles={{ maxHeight: '100%', maxWidth: '100%' }}
                                            diameter={25}
                                            seed={jsNumberForAddress(account || '')}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={styles.noWallet} onClick={() => dispatch(setConnectModalVisible(true))}>
                                <div className={styles.connectBtn}>CONNECT WALLET</div>
                            </div>
                        )
                    }
                </section>

                <Collapse
                    bordered={false}
                    defaultActiveKey={[]}
                    className={styles.collapseCustom}
                    onChange={e => setDropdown(e)}
                >
                    <Panel
                        header={
                            <div className={styles.headerCustom}>
                                {loadingUser ? (
                                    <div className={styles.loadingAvatar} />
                                ) : (
                                    avatar ? (
                                        <img
                                            src={avatar}
                                            alt=""
                                            className={cn(styles.avatar)}
                                        />
                                    ) : (
                                        <div className={styles.noAvatar}>
                                            <img
                                                src={logo}
                                                alt=""
                                                className={styles.circle}
                                            />
                                        </div>
                                    )
                                )}

                                {loadingUser ? (
                                    <div className={styles.loadingUsername} />
                                ) : (
                                    <p className={cn(styles.headerLbl)}>
                                        {username}
                                    </p>
                                )}

                                <img
                                    src={arrowIcon}
                                    alt=""
                                    className={cn(styles.arrow, {
                                        [styles.isDropdown]: !isEmpty(dropdown)
                                    })}
                                />
                            </div>
                        }
                        key="1"
                    >
                        {NAVIGATION_OPTIONS.map(e => (
                            <section
                                className={styles.contentCustom}
                                key={e.key}
                                onClick={() => !loadingUser && e.action && dispatch(e.action(true))}
                            >
                                {loadingUser ? (
                                    <div className={styles.loadingContent} />
                                ) : (
                                    <p className={styles.contentLbl}>{e.label}</p>
                                )}
                            </section>
                        ))}
                    </Panel>
                </Collapse>
            </nav>
        </div>
    );
}

export default React.memo(HeaderNavigation);