import React from 'react';
import { useHistory } from 'react-router-dom';

import PageSlug from '../../../../components/PageSlug';

import lootIcon from './img/loot.png';
import armoryIcon from './img/armory.png';
import devideIcon from './img/devide.png';

import { PAGE_LOOT_CONFINS_PATH, PAGE_ARMORY_PATH } from '../../Router/constants';

import styles from './Inventory.module.scss';

function MyInventory() {
    const history = useHistory();

    const handleOnClickLootConffins = () => {
        history.push(PAGE_LOOT_CONFINS_PATH);
    }

    const handleOnClickArmory = () => {
        history.push(PAGE_ARMORY_PATH);
    }

    return (
        <div className={styles.container}>
            <PageSlug
                slug={'MY INVENTORY'}
                description={''}
                hasDevide
            />

            <div className={styles.content}>
                <div className={styles.box}>
                    <section className={styles.lootCard} onClick={() => handleOnClickLootConffins()}>
                        <p className={styles.titleCard}>Loot Coffins</p>
                        <img src={lootIcon} alt="" className={styles.lootIcon} />
                    </section>

                    <img src={devideIcon} alt="" className={styles.divide} />

                    <section className={styles.armoryCard} onClick={() => handleOnClickArmory()}>
                        <p className={styles.titleCard}>Armory</p>
                        <img src={armoryIcon} alt="" className={styles.armoryIcon} />
                    </section>
                </div>
            </div>
        </div>
    )
}

export default React.memo(MyInventory);