import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, Field } from 'formik';
import * as yup from 'yup';
import cn from 'classnames';

import accountIcon from '../../assets/landingLayout/account.png';

import Hcapcha from '../../components/Hcapcha';
import InputField from '../../components/Form/InputField';
import PasswordField from '../../components/Form/PasswordField';
import Spinner from '../../components/Spinner';

import { setConnectModalVisible } from '../../store/modules/App/actions';

import styles from './Form.module.scss';

const regexUsername = /^\w{6,20}$/; // eslint-disable-next-line
const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/; // eslint-disable-next-line

const validationSchema = yup.object().shape({
    username: yup
        .string()
        .matches(regexUsername, 'Username only letters, numbers and at least 6 characters')
        .min(6)
        .max(20)
        .required('Username is required'),
    email: yup
        .string()
        .email()
        .required('Email is required'),
    password: yup
        .string()
        .matches(regexPassword, 'Password must contain lowercase, uppercase letters and at least 8 characters')
        .min(8)
        .required('Password is required'),
    rePassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Re-Password must match Password')
        .required('Re-Password is required')
});

function CreateAccountForm(props) {
    const dispatch = useDispatch();

    const {
        captcha,
        isSuccess,
        isError,

        onSubmit,
        setCaptcha,
        onCheckExists,
        onNavigate
    } = props;

    const initialValues = {
        username: '',
        email: '',
        password: '',
        rePassword: ''
    };

    const { isConnect } = useSelector(state => state?.user?.connectWallet);
    const { loadingConnectAccount } = useSelector(state => state?.user ?? {});
    const account = useSelector(state => state?.user?.userAccount?.accounts[0] ?? '');
    const { requesting } = useSelector(state => state.wallet?.registerRes);
    const existsResult = useSelector(state => state?.wallet?.existsRes?.result ?? {});

    const myAddress = isConnect
        ? `${account.substring(0, 5)}...${account.substring(account.length - 4)}`
        : null

    return (
        <div className={styles.container}>
            <h2 className={styles.titleForm}>Create Wagyu Games Account</h2>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(e, cb) => onSubmit(e, cb)}
            >
                {formikProps => {
                    // eslint-disable-next-line
                    const { values, errors, touched, handleSubmit } = formikProps;

                    const disabled = !values.username
                        || !values.email || !values.password || !values.rePassword || !captcha
                        || existsResult?.username || existsResult?.isBlacklist || existsResult?.email

                    return (
                        <Form>
                            <Field
                                component={InputField}
                                name={'username'}
                                label={'Username'}
                                placeholder={'username...'}
                                type={'text'}
                                required
                                onCheckExists={onCheckExists}
                                exists={existsResult?.username || existsResult?.isBlacklist}
                            />
            
                            <Field
                                component={InputField}
                                name={'email'}
                                label={'Email'}
                                placeholder={'email...'}
                                type={'email'}
                                required
                                onCheckExists={onCheckExists}
                                exists={existsResult?.email}
                            />
            
                            <Field
                                component={PasswordField}
                                name={'password'}
                                label={'Password'}
                                placeholder={'password...'}
                                required
                            />
            
                            <Field
                                component={PasswordField}
                                name={'rePassword'}
                                label={'Re-Password'}
                                placeholder={'re-password...'}
                                required
                            />

                            {/* {isConnect && existsResult?.address && (
                                <p className={styles.addressExists}>
                                    This address registered already
                                </p>
                            )} */}
            
                            <div
                                className={cn(styles.metamask, {
                                    // [styles.highlight]: !isConnect || existsResult?.address
                                })}
                            >
                                <section className={styles.left}>
                                    <p
                                        className={cn(styles.connectText, {
                                            [styles.noConnect]: !isConnect
                                        })}
                                    >
                                        {isConnect ? 'Connect with Metamask (optional)' : 'Connect Wallet (optional)'}
                                    </p>
            
                                    <div className={styles.account}>
                                        {isConnect && <img src={accountIcon} alt="" className={styles.accountIcon} />}

                                        <span className={styles.accountAddress}>
                                            {myAddress}
                                        </span>
                                    </div>
                                </section>
            
                                <section className={styles.right}>
                                    <div
                                        className={cn(styles.changeBtn)}
                                        onClick={() => dispatch(setConnectModalVisible(true))}
                                    >
                                        {isConnect ? 'Change' : 'Connect'}
                                        {loadingConnectAccount && <Spinner alignRight />}
                                    </div>
                                </section>
                            </div>
            
                            <div className={styles.hcaptcha}>
                                <Hcapcha
                                    getToken={setCaptcha}
                                    isSuccess={isSuccess}
                                    isError={isError}
                                />
                            </div>
            
                            <div
                                className={cn(styles.createBtn, {
                                    [styles.notAllowed]: disabled
                                })}
                                onClick={() => {
                                    !disabled && !requesting && handleSubmit()
                                }}
                            >
                                Create Account
                                {requesting && <Spinner alignRight />}
                            </div>
            
                            <div className={styles.footer}>
                                <span
                                    className={styles.firstText}
                                >
                                    By registering, you agree to the&nbsp;
                                    <span className={styles.lastText} onClick={onNavigate}>
                                        Terms of Service
                                    </span>
                                </span>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    );
}

export default CreateAccountForm;

