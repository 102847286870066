import React from 'react';
import { Input } from 'antd';
import cn from 'classnames';
import { get } from 'lodash';

import styles from './InputField.module.scss';

function InputField(props) {
    const {
        field,
        form,
        type,
        label,
        prefix,
        suffix,
        placeholder,
        disabled,
        onCheckExists,
        exists,
    } = props;

    const { name } = field;
    const { errors = {}, touched = {}, handleChange } = form;
    const fieldError = get(errors, `${name}`);

    const errorMess = exists && touched[name]
        ? `${name.charAt(0).toUpperCase() + name.slice(1)} exists already`
        : fieldError && touched[name] && `${fieldError}`;
    
    const placeHolderCustom = placeholder && `${placeholder.charAt(0).toUpperCase() + placeholder.slice(1)}`;

    const handleOnChange = (e) => {
        handleChange(e);

        const params = {
            email: field?.name === 'email' ? e.target.value : form?.values?.email,
            username: field?.name === 'username' ? e.target.value : form?.values?.username
        }

        if (onCheckExists) {
            onCheckExists(params)
        };
    }

    return (
        <div className={styles.inputWrapper}>
            <div className={styles.inputHeader}>
                {label && <h2 className={styles.label}>{label}</h2>}
                <span className={styles.error}>{errorMess}</span>
            </div>

            <Input
                className={cn(styles.input)}
                id={name}
                {...field}
                value={field.value}
                onChange={handleOnChange}
                type={type || 'text'}
                prefix={prefix || ''}
                suffix={suffix || ''}
                placeholder={placeHolderCustom || ''}
                disabled={disabled}
                autoComplete={'off'}
            />
        </div>
    );
}

export default InputField;