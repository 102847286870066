import { createActions, createAction } from 'redux-actions';
import {
    LOADING_REQUEST,
    LOADING_SUCCESS,
    SET_CONNECT_MODAL_VISIBLE,
    SET_THEME,
    SET_IS_DESKTOP,
    SET_IS_MOBILE,
    SET_IS_TABLET,
    SET_IS_SCROLLED, SET_MENU_OPEN
} from './reducer';

import AppApi from './api';

export const setTheme = createAction(SET_THEME);
export const setMenuOpen = createAction(SET_MENU_OPEN);
export const setIsScrolled = createAction(SET_IS_SCROLLED);
export const setIsMobile = createAction(SET_IS_MOBILE);
export const setIsTablet = createAction(SET_IS_TABLET);
export const setIsDesktop = createAction(SET_IS_DESKTOP);
export const setConnectModalVisible = createAction(SET_CONNECT_MODAL_VISIBLE);
export const setToast = createAction('SET_TOAST');
export const setDownloadModalVisible = createAction('SET_DOWNLOAD_MODAL_VISIBLE');
export const setLogoutModalVisible = createAction('SET_LOGOUT_MODAL_VISIBLE');
export const setChangePasswordModalVisible = createAction('SET_CHANGE_PASSWORD_MODAL_VISIBLE');
export const setEditProfileModalVisible = createAction('SET_EDIT_PROFILE_MODAL_VISIBLE');
export const setSwapModalVisible = createAction('SET_SWAP_MODAL_VISIBLE');
export const setRecentModalVisible = createAction('SET_RECENT_MODAL_VISIBLE');
export const setClaimModalVisible = createAction('SET_CLAIM_MODAL_VISIBLE');
export const setWrongNetworkVisible = createAction('SET_WRONG_NETWORK_VISIBLE');
export const setUpdateAvatarVisible = createAction('SET_UPDATE_AVATAR_VISIBLE');
export const setWeaponRandomVisible = createAction('SET_WEAPON_RANDOM_VISIBLE');
export const setReloadHistories = createAction('SET_RELOAD_HISTORIES');

const { loadingRequest, loadingSuccess } = createActions({
    [LOADING_REQUEST]: () => { },
    [LOADING_SUCCESS]: () => { }
});

export const loadApp = () => dispatch => {
    dispatch(loadingRequest());

    setTimeout(() => dispatch(loadingSuccess()), 3500)
};

export const turnOffModal = () => (dispatch) => {
    dispatch(setUpdateAvatarVisible(false));
    dispatch(setDownloadModalVisible(false));
    dispatch(setEditProfileModalVisible(false));
    dispatch(setSwapModalVisible(false));
    dispatch(setRecentModalVisible(false));
    dispatch(setClaimModalVisible(false));
    dispatch(setUpdateAvatarVisible(false));
}

const { fetchAllSettingsRequest, fetchAllSettingsSuccess, fetchAllSettingsFail } = createActions({
    FETCH_ALL_SETTINGS_REQUEST: () => {},
    FETCH_ALL_SETTINGS_SUCCESS: data => ({ data }),
    FETCH_ALL_SETTINGS_FAIL: error => ({ error }),
});

export const fetchAllSettings = () => (dispatch) => {
    dispatch(fetchAllSettingsRequest());

    return AppApi.fetchSettings()
        .then(({ data }) => {
            dispatch(fetchAllSettingsSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(fetchAllSettingsFail(error));
            return error;
        });
}