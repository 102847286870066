import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { isEmpty } from 'lodash';

import Modal from 'components/Modal';

import closeIcon from 'components/DownloadModal/img/close.png';
import Spinner from 'components/Spinner';
import bubble from '../../PurcharseLoot/img/bubble.png';

import styles from './SelectedEnough.module.scss';

function Enough(props) {
    const {
        list,
        isVisible,
        loadingButton,
        onSubmit,
        onCancel
    } = props;

    const { isMobile } = useSelector(state => state?.app);
    const isConnectImx = useSelector(state => state?.imx?.userImxInfo?.result?.address ?? '');

    const buttonLabel = useMemo(() => {
        if (!isConnectImx) return 'Connect IMX';

        return 'BURN'
    }, [isConnectImx]);

    return (
        <Modal
            visible={isVisible}
            closable={false}
            centered 
            onCancel={onCancel}
            width={isMobile ? '86%' : '640rem'}
            wrapperClass={styles.container}
            wrapperBody={styles.wrapperBody}
        >
            <div className={styles.content}>
                <img src={closeIcon} alt="" className={styles.close} onClick={() => onCancel()} />

                <header className={styles.header}>
                    <h2 className={styles.heading}>Ready to run the Rarity Booster?</h2>
                </header>

                <section className={styles.box}>
                    <img src={bubble} alt="" className={styles.leftBubble} />
                    <img src={bubble} alt="" className={styles.rightBubble} />
                    
                    <div className={styles.list}>
                        {!isEmpty(list) && list.map((item) => (
                            <div
                                key={item.id}
                                style={{ background: `url(${item.thumbnail}) no-repeat center / 100%` }}
                                className={styles.item}
                            >
                                <p className={styles.name}>{item.rewardWeapon} - {item.rewardSkin}</p>
                            </div>
                        ))}
                    </div>
                </section>

                <div
                    className={cn(styles.buttonContainer)}
                >
                    <section
                        className={styles.buyLoot}
                        onClick={() => !loadingButton && onSubmit()}
                    >
                        {loadingButton ? <Spinner width={20} /> : buttonLabel}
                    </section>

                    <section className={styles.cancel} onClick={() => onCancel()}>
                        Cancel
                    </section>
                </div>
            </div>
        </Modal>
    );
}

export default React.memo(Enough);