import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import logo from 'assets/dashboardLayout/logo.png';

import Header from '../Header';
import Footer from '../Footer';

import styles from './Sidebar.module.scss';

function Sidebar(props) {
    const { pathname } = props.location;

    const [navKey, setNavKey] = useState(pathname);

    useEffect(() => {
        setNavKey(pathname);

        return () => {};
    }, [pathname]);

    return (
        <div className={styles.container}>
            <div className={styles.logoContainer}>
                <Link to={'/'}>
                    <img src={logo} alt="logo" className={styles.logo} />
                </Link>
            </div>

            <Header navKey={navKey} />
            <Footer />
        </div>
    );
}

export default React.memo(Sidebar);