import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import moment from 'moment';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import gradient from 'chartjs-plugin-gradient';

import styles from './Chart.module.scss';

Chart.register(gradient)

export const UserData = [
    {
        id: 1,
        // year: moment('20220716').format('Do MMMM'),
        year: '16th July',
        userGain: 30000,
        userLost: 823
    },
    {
        id: 2,
        // year: moment('20220717').format('Do MMMM'),
        year: '17th July',
        userGain: 40000,
        userLost: 345
    },
    {
        id: 3,
        // year: moment('20220718').format('Do MMMM'),
        year: '18th July',
        userGain: 30000,
        userLost: 555
    },
    {
        id: 4,
        // year: moment('20220719').format('Do MMMM'),
        year: '19th July',
        userGain: 40000,
        userLost: 4555
    },
    {
        id: 5,
        // year: moment('20220720').format('Do MMMM'),
        year: '20th July',
        userGain: 45000,
        userLost: 234
    },
    {
        id: 6,
        // year: moment('20220721').format('Do MMMM'),
        year: '21th July',
        userGain: 35000,
        userLost: 234
    },
];

function LineChart() {
    // eslint-disable-next-line
    const [data, setData] = useState({
        labels: UserData && UserData.map((data) => data.year),
        datasets: [
            {
                yAxisID: 'yAxis',
                xAxisID: 'xAxis',
                data: UserData && UserData.map((data) => data.userGain),
                cubicInterpolationMode: 'monotone',
                borderColor: '#3075ff',
                borderWidth: 3
            },
        ],
    });

    const { isMobile, isTablet } = useSelector(state => state.app);
    const { loaded } = useSelector(state => state?.app?.load ?? {});

    const sizeLargerXaxis = isTablet ? '9rem' : '13rem';
    const sizeXaxis = isMobile ? '8rem' : sizeLargerXaxis;

    useEffect(() => {
        if (!loaded) return;

        const canvas = document.getElementById('chart-custom');
        const ctx = canvas.getContext('2d');

        const gradient = ctx.createLinearGradient(0, 0, 0, 185);

        gradient.addColorStop(0, 'rgba(48, 117, 255, 0.6)');
        gradient.addColorStop(1, 'rgba(48, 117, 255, 0)');

        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, 200, 100);

        setData({
            labels: UserData && UserData.map((data) => data.year),
            datasets: [
                {
                    yAxisID: 'yAxis',
                    xAxisID: 'xAxis',
                    data: UserData && UserData.map((data) => data.userGain),
                    cubicInterpolationMode: 'monotone',
                    borderColor: '#3075ff',
                    borderWidth: 5,
                    backgroundColor: gradient,
                    fill: {
                        above: gradient,
                        // below: 'rgba(48, 117, 255, 0.5)',
                        target: { value: 0 }
                    },
                },
            ],
        })
    }, [loaded])

    return (
        <div className={styles.container}>
            <Line
                id={'chart-custom'}
                data={data}
                type='line'
                options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    interaction: {
                        intersect: false,
                    },
                    plugins: {
                        legend: {
                            display: false
                        }
                    },
                    scales: {
                        xAxis: {
                            ticks: {
                                color: '#fff',
                                font: {
                                    size: sizeXaxis,
                                    lineHeight: 1,
                                    weight: 500
                                },
                            },
                            title: {
                                display: false
                            },
                            grid: {
                                drawOnChartArea: false
                            },
                        },
                        yAxis: {
                            suggestedMin: 20000,
                            suggestedMax: 50000,
                            ticks: {
                                display: false,
                            },
                            title: {
                                display: false
                            },
                            grid: {
                                drawOnChartArea: false
                            }
                        }
                    }
                }}
            />
        </div>
    )
}

export default React.memo(LineChart);