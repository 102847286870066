import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniqBy } from 'lodash';

import { fetchAllOwnership } from 'store/modules/Ownership/actions';
import { ourRequest } from 'services/http';

function useWeaponSkins(props) {
    const dispatch = useDispatch();

    const { offset, typeSelected } = props;

    const [hasMore, setHasMore] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const [weaponSkinList, setWeaponSkinList] = useState([]);

    const { logged } = useSelector(state => state?.user ?? {});
    const loaded = useSelector(state => state?.app?.load?.loaded);
    // const isReloadOwnership = useSelector(state => state?.ownership?.reloadOwnership);
    const address = useSelector(state => state?.user?.userAccount?.accounts[0] ?? '')?.toLowerCase();

    // FIRST LOAD
    useEffect(() => {
        if (!loaded) return;
        if (!logged) return;
        if (offset > 0) return;

        setFirstLoading(true);

        const params = {
            address,
            armoryType: 'WEAPON_SKIN_BURN',
            rarityType: typeSelected,
            limit: 20,
            skip: 0
        }

        dispatch(fetchAllOwnership(params, {
            cancelToken: ourRequest.token
        }))
            .then(res => {
                setWeaponSkinList(res?.data);
                setHasMore(res?.data?.length > 9);
                setFirstLoading(false);
            })
            .catch(err => {
                return err;
            })
    }, [
        loaded,
        logged,
        offset,
        address,
        typeSelected,
        // isReloadOwnership,
        dispatch
    ]);

    /* LOAD MORE */
    useEffect(() => {
        if (offset === 0) return;

        const params = {
            address,
            armoryType: 'WEAPON_SKIN_BURN',
            rarityType: typeSelected,
            limit: 20,
            skip: offset
        }

        dispatch(fetchAllOwnership(params, {
            cancelToken: ourRequest.token
        }))
            .then(res => {
                setWeaponSkinList(prevState => uniqBy([...prevState, ...res?.data], 'id'));
                setHasMore(res?.data?.length > 9);
            })
            .catch(err => {
                return err;
            })
    }, [
        offset,
        address,
        typeSelected,
        // isReloadOwnership,
        dispatch
    ]);

    return { weaponSkinList, hasMore, firstLoading, setWeaponSkinList };
}

export default useWeaponSkins;