import React from 'react';

import logo from '../../assets/landingLayout/logo.png';

import styles from './VerifyEmail.module.scss';

function Step1(props) {
    const { onNext, avatar } = props;

    const title = avatar ? 'Email has been verified success' : 'Email has been verified';
    const buttonLabel = avatar ? 'Close' : 'Next'

    return (
        <div className={styles.content}>
            <img src={logo} alt="" className={styles.logo} />

            <h2 className={styles.title}>{title}</h2>

            {!avatar && <p className={styles.description}>please click next to complete this stage </p>}

            <div className={styles.btnContainer} onClick={onNext}>
                {buttonLabel}
            </div>
        </div>
    );
}

export default Step1;