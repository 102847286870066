import { handleActions } from 'redux-actions';
import {persistReducer} from 'redux-persist';
import localForage from 'localforage';
import {THEME_TYPES} from './constants';

const initialState = {
    isScrolled: false,
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    isMenuOpen: false,
    connectModalVisible: false,
    load: {
        requesting: false,
        loaded: false
    },
    isDarkMode: false,
    theme: THEME_TYPES.LIGHT,
    hasToast: null,
    downloadModalVisible: false,
    logoutModalVisible: false,
    changePasswordModalVisible: false,
    editProfileModalVisible: false,
    swapModalVisible: false,
    recentModalVisible: false,
    claimModalVisible: false,
    wrongNetworkVisible: false,
    updateAvatarVisible: false,
    weaponRandomVisible: false,
    reloadHistories: null,
    allSettings: {
        result: null,
        requesting: false,
        error: null
    }
};

export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_MENU_OPEN = 'SET_MENU_OPEN';
export const SET_IS_SCROLLED = 'SET_IS_SCROLLED';
export const SET_IS_TABLET = 'SET_IS_TABLET';
export const SET_IS_DESKTOP = 'SET_IS_DESKTOP';
export const SET_CONNECT_MODAL_VISIBLE = 'SET_CONNECT_MODAL_VISIBLE';
export const LOADING_REQUEST = 'LOADING_REQUEST';
export const LOADING_SUCCESS = 'LOADING_SUCCESS';
export const SET_THEME = 'SET_THEME';

let appReducer = handleActions({
    [SET_MENU_OPEN]: (state, {payload}) => ({
        ...state,
        isMenuOpen: payload,
    }),
    [SET_IS_SCROLLED]: (state, {payload}) => ({
        ...state,
        isScrolled: payload,
    }),
    [SET_THEME]: (state, {payload}) => ({
        ...state,
        theme: payload,
    }),
    [SET_CONNECT_MODAL_VISIBLE]: (state, {payload}) => ({
        ...state,
        connectModalVisible: payload,
    }),
    [SET_IS_MOBILE]: (state) => ({
        ...state,
        isMobile: true,
        isTablet: false,
        isDesktop: false
    }),
    [SET_IS_TABLET]: (state) => ({
        ...state,
        isMobile: false,
        isTablet: true,
        isDesktop: false
    }),
    [SET_IS_DESKTOP]: (state) => ({
        ...state,
        isMobile: false,
        isTablet: false,
        isDesktop: true
    }),
    [LOADING_REQUEST]: (state) => ({
        ...state,
        load: {
            ...state.load,
            requesting: true
        }
    }),
    [LOADING_SUCCESS]: (state) => ({
        ...state,
        load: {
            ...state.load,
            requesting: false,
            loaded: true
        }
    }),
    SET_TOAST: (state, { payload }) => ({
        ...state,
        hasToast: payload
    }),
    SET_DOWNLOAD_MODAL_VISIBLE: (state, { payload }) => ({
        ...state,
        downloadModalVisible: payload
    }),
    SET_LOGOUT_MODAL_VISIBLE: (state, { payload }) => ({
        ...state,
        logoutModalVisible: payload
    }),
    SET_CHANGE_PASSWORD_MODAL_VISIBLE: (state, { payload }) => ({
        ...state,
        changePasswordModalVisible: payload
    }),
    SET_EDIT_PROFILE_MODAL_VISIBLE: (state, { payload }) => ({
        ...state,
        editProfileModalVisible: payload
    }),
    SET_SWAP_MODAL_VISIBLE: (state, { payload }) => ({
        ...state,
        swapModalVisible: payload
    }),
    SET_RECENT_MODAL_VISIBLE: (state, { payload }) => ({
        ...state,
        recentModalVisible: payload
    }),
    SET_CLAIM_MODAL_VISIBLE: (state, { payload }) => ({
        ...state,
        claimModalVisible: payload
    }),
    SET_WRONG_NETWORK_VISIBLE: (state, { payload }) => ({
        ...state,
        wrongNetworkVisible: payload
    }),
    SET_UPDATE_AVATAR_VISIBLE: (state, { payload }) => ({
        ...state,
        updateAvatarVisible: payload
    }),
    SET_WEAPON_RANDOM_VISIBLE: (state, { payload }) => ({
        ...state,
        weaponRandomVisible: payload
    }),
    SET_RELOAD_HISTORIES: (state, { payload }) => ({
        ...state,
        reloadHistories: payload
    }),

    /** FETCH ALL SETTINGS **/
    FETCH_ALL_SETTINGS_REQUEST: (state) => ({
        ...state,
        allSettings: {
            ...state.allSettings,
            requesting: true
        }
    }),
    FETCH_ALL_SETTINGS_SUCCESS: (state, { payload }) => ({
        ...state,
        allSettings: {
            ...state.allSettings,
            requesting: false,
            result: payload.data,
            error: null
        }
    }),
    FETCH_ALL_SETTINGS_FAIL: (state, { payload }) => ({
        ...state,
        allSettings: {
            ...state.allSettings,
            requesting: false,
            error: payload.error
        }
    })
}, initialState);

appReducer = persistReducer({
    key: 'app',
    storage: localForage,
    whitelist: ['isDarkMode', 'isAutoplay']
}, appReducer);

export default appReducer;
