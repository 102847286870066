import {
    Link,
    // ETHTokenType
} from '@imtbl/imx-sdk'

let link = new Link(process.env.REACT_APP_LAYER2_PROVIDER);

export const METHOD_TYPES = {
    DEPOSIT: 'deposit',
    TRANSFER: 'transfer',
    WITHDRAW: 'withDraw'
}

export async function imxHistory() {
    link.history({})
}

export async function setup(connector) {
    try {
        const result = await link.setup({
            providerPreference: connector
        })

        return result;
    } catch (error) {
        return error;
    }
}

export async function deposit(type, tokenId, tokenAddress) {
    try {
        let result = await link.deposit({
            type,
            tokenId: String(tokenId),
            tokenAddress
        })

        return result;
    } catch (error) {
        return 'Failed';
    }
}

export async function transfer(params) {
    try {
        let result = await link.transfer(params)

        return result;
    } catch (error) {
        return 'Failed';
    }
}

export async function batchNftTransfer(params) {
    try {
        let result = await link.batchNftTransfer(params)

        return result;
    } catch (error) {
        return 'Failed';
    }
}

export async function prepareWithdraw(type, tokenId, tokenAddress) {
    try {
        const res = await link.prepareWithdrawal({
            type,
            tokenId,
            tokenAddress
        })

        return res;
    } catch (error) {
        return 'Failed';
    }
}

export async function completeWithdraw(type, tokenId, tokenAddress) {
    try {
        const completeRes = await link.completeWithdrawal({
            type,
            tokenId,
            tokenAddress
        })

        return completeRes;
    } catch (error) {
        return 'Failed';
    }
}