import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import backArrow from '../../assets/landingLayout/back-arrow.png';

import ForgotPasswordForm from './Form';
import SuccessModal from './SuccessModal';
import LandingLayout from '../../layouts/LandingLayout';

import { forgotPassword } from '../../store/modules/Wallet/actions';
import { setToast } from '../../store/modules/App/actions';

import styles from './ForgotPassword.module.scss';

function ForgotPassword() {
    const dispatch = useDispatch();

    const [captcha, setCaptcha] = useState('');
    const [isSuccess, setSuccess] = useState(false);
    const [isError, setError] = useState('');

    const { isConnect } = useSelector(state => state?.user?.connectWallet);

    const handleOnBack = () => {
        window.history.back();
    };

    const handleOnSubmit = (values, cb) => {
        if (!captcha) return;

        const params = {
            account: values?.account.trim(),
            captcha: captcha
        };

        dispatch(forgotPassword(params))
            .then((res) => {
                if (res?.status >= 500) {
                    dispatch(setToast({ title: 'FAILED', detail: 'Something went wrong' }))
                    setError(new Date().getTime());
                } else if ((res?.status >= 400)) {
                    setError(new Date().getTime());
                    dispatch(setToast({ title: 'FAILED', detail: res?.data?.message }));
                } else {
                    setSuccess(true);

                    cb.resetForm();
                }
            })
            .catch(err => {
                setError(new Date().getTime());
                return err;
            })
    }

    return (
        <LandingLayout wrapperClass={styles.wrapperClass}>
            <div className={styles.container}>
                <section
                    className={styles.header}
                    onClick={() => handleOnBack()}
                >
                    <img src={backArrow} alt="" className={styles.backArrow} />
                    <span>Back</span>
                </section>

                <section className={styles.contentBox}>
                    <ForgotPasswordForm
                        onSubmit={handleOnSubmit}
                        setCaptcha={setCaptcha}
                        captcha={captcha}
                        isSuccess={isSuccess}
                        isError={isError}
                        isConnect={isConnect}
                    />
                </section>

                <SuccessModal
                    visible={isSuccess}
                    onCancel={() => {
                        setSuccess(false);
                        window.history.back();
                    }}
                />
            </div>
        </LandingLayout>
    );
}

export default ForgotPassword;