import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';

import backArrow from '../../assets/landingLayout/back-arrow.png';

import CreateAccountForm from './Form';
// import SuccessModal from './SuccessModal';

import GoingApp from '../../components/GoingAppModal';
import LandingLayout from '../../layouts/LandingLayout';

import { PAGE_DASHBOARD_PATH, PAGE_TERM_OF_SERVICE_PATH } from '../../router/constants';

import { setToast, setUpdateAvatarVisible } from '../../store/modules/App/actions';
import { registerAccount, checkExists } from '../../store/modules/Wallet/actions';
import {
    setLogged,
    disconnect,
    setUserNonce,
    // setUserAccounts,
    // fetchUserInfo,
    fetchUserInfoSuccess
} from '../../store/modules/User/actions';

// import http from '../../services/http';

import { goToApp } from '../../utils/common';

import styles from './CreateAccount.module.scss';

function CreateAccount() {
    const dispatch = useDispatch();
    const history = useHistory();

    const [captcha, setCaptcha] = useState('');
    const [isSuccess, setSuccess] = useState(false);
    const [isError, setError] = useState('');
    // const [email, setEmail] = useState('');

    const { logged } = useSelector(state => state?.user ?? {});
    const { isConnect } = useSelector(state => state?.user?.connectWallet ?? {});
    const { signKey } = useSelector(state => state?.user?.userAccount ?? {});

    const account = useSelector(state => state?.user?.userAccount?.accounts[0] ?? '');
    const userToken = useSelector(state => state?.user?.userAccount?.token ?? '');
    const existsResult = useSelector(state => state?.wallet?.existsRes?.result ?? {});
    const { username } = useSelector(state => state?.user?.userInfo?.result ?? {});
    const { updateAvatarVisible } = useSelector(state => state?.app ?? {});

    const isGoApp = goToApp(userToken, username);

    // Do not check exit wallet
    // useEffect(() => {
    //     if (!isConnect) return;

    //     dispatch(checkExists({ address: account }))
    //         .then(res => res)
    //         .catch(err => err)

    // }, [dispatch, isConnect, account, isSuccess]);

    useEffect(() => {
        const fromLogin = history?.location?.state?.fromLogin;

        if (fromLogin) return dispatch(disconnect());
    }, [dispatch, history])

    useEffect(() => {
        if (!isGoApp) return;

        if (!updateAvatarVisible) {
            dispatch(setLogged(true));

            logged && history.push(PAGE_DASHBOARD_PATH);
        }
    }, [dispatch, isGoApp, logged, history, updateAvatarVisible]);

    const handleUpdateAvatar = (value) => {
        dispatch(setUpdateAvatarVisible(value));
    };

    const handleOnBack = () => {
        const { action = '' } = history;

        if (action === 'POP') {
            history.push('/');
        } else {
            history.goBack();
        }
    };

    const handleOnSubmit = (values, cb) => {
        if (!captcha || existsResult?.username || existsResult?.isBlacklist || existsResult?.email) return;

        const body = isConnect ? {
            address: account,
            username: values?.username.trim(),
            email: values?.email.trim(),
            password: values?.password,
            captcha: captcha,
            sign: signKey
        } : {
            username: values?.username.trim(),
            email: values?.email.trim(),
            password: values?.password,
            captcha: captcha,
        }

        // setEmail(values?.email.trim());

        dispatch(registerAccount(body))
            .then((res) => {
                if (res?.status >= 500) {
                    dispatch(setToast({ title: 'FAILED', detail: 'Something went wrong' }))
                    setError(new Date().getTime());
                } else if ((res?.status >= 400)) {
                    setError(new Date().getTime());
                    dispatch(setToast({ title: 'FAILED', detail: res?.data?.message }));
                } else {
                    setSuccess(true);
                    handleUpdateAvatar(true);
                    cb.resetForm();

                    window.localStorage.setItem('tempoAddress', res?.data?.address);
                    window.localStorage.setItem('nonce', res?.data?.nonce);
                    window.localStorage.setItem('dateNow', Date.now());
                        
                    dispatch(setUserNonce({ nonce: res?.data?.nonce }));

                    dispatch(fetchUserInfoSuccess({ ...res?.data }))
                        .then(res => res)
                        .catch(err => err)
                    // dispatch(setLogged(true));
                }
            })
            .catch(err => {
                setError(new Date().getTime());
                return err;
            })
    }

    const handleOnCheckExists = (values) => {
        dispatch(checkExists({ ...values, address: account }))
            .then(res => res)
            .catch(err => err)
    }

    const handleNavigateTermPage = () => {
        window.open(PAGE_TERM_OF_SERVICE_PATH, '_blank');
    };

    return (
        <LandingLayout wrapperClass={styles.wrapperClass}>
            <div className={styles.container}>
                <section
                    className={styles.header}
                    onClick={() => handleOnBack()}
                >
                    <img src={backArrow} alt="" className={styles.backArrow} />
                    <span>Back</span>
                </section>

                <section className={styles.contentBox}>
                    <CreateAccountForm
                        onSubmit={handleOnSubmit}
                        setCaptcha={setCaptcha}
                        captcha={captcha}
                        onCheckExists={debounce(handleOnCheckExists, 500)}
                        isSuccess={isSuccess}
                        isError={isError}
                        isConnect={isConnect}
                        onNavigate={handleNavigateTermPage}
                    />
                </section>

                <GoingApp visible={updateAvatarVisible} onCancel={() => handleUpdateAvatar(false)} />

                {/* <SuccessModal visible={isSuccess} onCancel={() => setSuccess(false)} email={email} /> */}
            </div>
        </LandingLayout>
    );
}

export default CreateAccount;