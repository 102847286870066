import React, { memo } from 'react';

import bannerFooter from '../img/banner-footer.png';

import styles from './BaseBannerFooter.module.scss';

function BaseBannerFooter() {
    return (
        <div className={styles.container}>
            <img src={bannerFooter} alt="banner" className={styles.banner} />
        </div>
    );
}

export default memo(BaseBannerFooter);