import React from 'react';

import completeIcon from '../../assets/img/complete.png';

import styles from './ClaimPopup.module.scss';

function Step3(props) {
    const { onClose } = props;

    return (
        <div className={styles.step3}>
            <div className={styles.loaderContainer}>
                <img src={completeIcon} alt="" className={styles.completeIcon} />
            </div>

            <div className={styles.main}>
                <h2 className={styles.heading}>Token Swap Complete</h2>
            </div>

            <div className={styles.buttonContainer} onClick={onClose}>
                Close
            </div>
        </div>
    );
}

export default Step3;