import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';

import appReducer from './modules/App/reducer';
import userReducer from './modules/User/reducer';
import poolsReducer from './modules/Pools/reducer';
import walletReducer from './modules/Wallet/reducer';
import withDrawReducer from './modules/Withdraw/reducer';
import depositReducer from './modules/Deposit/reducer';
import settingWithdrawReducer from './modules/SettingWithdraw/reducer';
import imxReducer from './modules/Imx/reducer';
import ownershipReducer from './modules/Ownership/reducer';
import lootConffinReducer from './modules/LootConffin/reducer';

const rootReducer = combineReducers({
    app: appReducer,
    user: userReducer,
    wallet: walletReducer,
    imx: imxReducer,
    pools: poolsReducer,
    ownership: ownershipReducer,
    withdraw: withDrawReducer,
    settingWithdraw: settingWithdrawReducer,
    deposit: depositReducer,
    lootConffin: lootConffinReducer,
    router: routerReducer
});

export default rootReducer;