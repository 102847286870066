import React from 'react';

import styles from './Bottom.module.scss';

function Bottom(props) {
    const { isMain = false, item = {} } = props;

    return (
        <div className={styles.container}>
            {isMain ? (
                <div className={styles.mainType}>
                    <img src={item.icon} alt='' className={styles.levelIcon} />
                    <p className={styles.value}>{item.value}</p>
                </div>
            ) : (
                <div className={styles.normalType}>
                    <div className={styles.iconContainer}>
                        <img style={{ width: item.size.width, height: item.size.height }} src={item.icon} alt='' className={styles.icon} />
                    </div>

                    <h2 className={styles.value}>{item.value}</h2>

                    <p className={styles.label}>{item.label}</p>
                </div> 
            )}
        </div>
    );
}

export default Bottom;