import React from 'react';
import { useSelector } from 'react-redux';

import blackLogo from './img/main/black-logo.png';
import zbuxOutline from './img/main/zbux-outline.png';
import zbuxLogo from './img/main/z-logo.png';

import { BG_STACKS } from './constant';

import { formatNumber } from '../../utils/bigNumber';

import styles from './WagyuSwap.module.scss';

function Step1(props) {
    const {
        address = '',
        name = '',
        onNext
    } = props;

    const addressBlock = address && `${address.substring(0, 3)}.........${address && address.substring(address.length - 4)}`;

    const totalZbuxBalance = useSelector(state => state?.withdraw?.userStatisticRes?.result?.totalZBux ?? '0');

    return (
        <div className={styles.step1}>
            <div className={styles.middle}>
                {BG_STACKS.map((e, i) => (
                    <div
                        key={i}
                        style={{
                            background: e.background,
                            top: `-${BG_STACKS.length - (i + 1)}0rem`,
                            width: `calc(100% - ${(BG_STACKS.length - (i + 1)) * 10}%)`
                        }}
                        className={styles.item}
                    >
                        {i === BG_STACKS.length - 1 && (
                            <div className={styles.card}>
                                <section className={styles.row}>
                                    <div className={styles.leftCol}>
                                        <h2 className={styles.name}>{name.toUpperCase()}</h2>
                                    </div>

                                    <div className={styles.rightCol}>
                                        <img src={blackLogo} alt="" className={styles.blackLogo} />
                                    </div>
                                </section>

                                <section className={styles.row}>
                                    <div className={styles.leftCol}>
                                        <h2 className={styles.name}>{addressBlock}</h2>
                                    </div>
                                </section>

                                <section className={styles.row} style={{ marginBottom: 0 }}>
                                    <div className={styles.leftCol}>
                                        <p className={styles.zbuxLabel}>Gold ZBUX Balance</p>
                                        <div className={styles.name}>
                                            <img src={zbuxLogo} alt="" className={styles.zLogo} />
                                            {formatNumber(totalZbuxBalance)}
                                        </div>
                                    </div>

                                    <div className={styles.rightCol}>
                                        <img src={zbuxOutline} alt="" className={styles.zbuxOutline} />
                                    </div>
                                </section>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <div className={styles.buttonContainer} onClick={onNext}>
                SWAP
            </div>
        </div>
    );
}

export default Step1;