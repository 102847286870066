import React from 'react'
import { Link } from 'react-router-dom';
import cn from 'classnames';

import logo from '../../assets/img/logo.png';

import style from './NotFound.module.scss';

export default function NotFound() {
    return (
        <div className={cn(style.container)}>
            <header className={style.header}>
                <Link className={style.logoLink} to={'/'}>
                    <img src={logo} alt={''} className={style.logo}/>
                </Link>
            </header>

            <section className={style.content}>
                <h2>404</h2>
                <h3>NOT FOUND</h3>
                <p>The page you are looking for can&apos;t be found</p>
            </section>
        </div>
    )
}