import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '../Modal';
import ProviderList from './ProviderList';
import { CONNECTION_TYPES, PROVIDER_ITEMS, LOADING_STATUSES } from './constants';

import {
    connectMetaMask,
    connectGameStop,
    connectToWalletConnect
} from '../../store/modules/User/actions';

const ConnectToWalletModal = (props) => {
    const dispatch = useDispatch();

    const { onCancel, visible } = props;

    const [selectedItem, setSelectedItem] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState(null);

    useEffect(() => {
        setSelectedItem(null);
        setLoadingStatus(null);
    }, [visible]);

    const dispatchConnect = () => dispatch(connectMetaMask());
    const dispatchWalletConnect = () => dispatch(connectToWalletConnect());
    const dispatchGameStopConnect = () => dispatch(connectGameStop());

    const onSelect = (item) => {
        setSelectedItem(item);
        window.localStorage.setItem('connectorId', item.connector);

        if (item.connector === CONNECTION_TYPES.metamask) {
            setLoadingStatus(LOADING_STATUSES.LOADING);

            dispatchConnect()
                .then(res => {
                    if (res) {
                        setLoadingStatus(LOADING_STATUSES.LOADED);
                        onCancel();
                    } else {
                        setLoadingStatus(LOADING_STATUSES.ERROR);
                    }
                });
        } else if (item.connector === CONNECTION_TYPES.walletconnect) {
            setLoadingStatus(LOADING_STATUSES.LOADING);

            dispatchWalletConnect()
                .then(res => {
                    if (res) {
                        setLoadingStatus(LOADING_STATUSES.LOADED);
                        onCancel();
                    } else {
                        setLoadingStatus(LOADING_STATUSES.ERROR);
                    }
                });
        } else if (item.connector === CONNECTION_TYPES.gamestop) {
            setLoadingStatus(LOADING_STATUSES.LOADING);

            dispatchGameStopConnect()
                .then(res => {
                    if (res) {
                        setLoadingStatus(LOADING_STATUSES.LOADED);
                        onCancel();
                    } else {
                        setLoadingStatus(LOADING_STATUSES.ERROR);
                    }
                });
        }
        else {
            setLoadingStatus(LOADING_STATUSES.ERROR);
        }
    };

    useEffect(() => {
        if (!selectedItem) {
            setLoadingStatus(null);
        }
    }, [selectedItem]);

  return (
    <Modal
        centered
        title={renderTitle(selectedItem, setSelectedItem)}
        visible={visible}
        onCancel={onCancel}
        width={'350rem'}
        zIndex={2000}
    >
        <ProviderList
            items={PROVIDER_ITEMS}
            selectedItem={selectedItem}
            onSelect={onSelect}
            onRepeat={onSelect}
            requesting={loadingStatus === LOADING_STATUSES.LOADING}
            error={loadingStatus === LOADING_STATUSES.ERROR}
        />
    </Modal>
  )
}

export default React.memo(ConnectToWalletModal);

function renderTitle(selectedItem, setSelectedItem) {
    return selectedItem ? <div style={{ cursor: 'pointer' }} onClick={() => setSelectedItem(null)}>Back</div> : <div>Connect to a Wallet</div>
}
