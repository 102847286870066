import { ethers } from 'ethers';
import { getWalletProvider } from '../store/modules/User/utils';

export const getProviderWeb3 = () => {
    const currentProvider = getWalletProvider(); // get what provider is metamask, gamestop or walletConnect

    const providerWeb3 = new ethers.providers.Web3Provider(currentProvider); // set new provider with ether

    return providerWeb3;
}

export const getContractSigner = () => {
    const providerWeb3 = getProviderWeb3();
    const signer = providerWeb3.getSigner();

    return signer;
}
