import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik, Field } from 'formik';
import * as yup from 'yup';
import cn from 'classnames';

import Hcapcha from '../../components/Hcapcha';
import InputField from '../../components/Form/InputField';
import Spinner from '../../components/Spinner';

import styles from './Form.module.scss';

// const regexUsername = /^\w{6,20}$/; // eslint-disable-next-line

const validationSchema = yup.object().shape({
    account: yup
        .string()
        .email()
        .required('Email is required'),
});

function ForgotPasswordForm(props) {
    const {
        onSubmit,
        captcha,
        setCaptcha,
        isSuccess,
        isError
    } = props;

    const initialValues = {
        account: ''
    };

    const requesting = useSelector(state => state.wallet?.forgotPasswordRes?.requesting);

    return (
        <div className={styles.container}>
            <h2 className={styles.titleForm}>Forgot Password</h2>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(e, cb) => !requesting && onSubmit(e, cb)}
            >
                {formikProps => {
                    // eslint-disable-next-line
                    const { values, errors, touched, handleSubmit } = formikProps;

                    const disabled = !values.account || !captcha;

                    return (
                        <Form>
                            <Field
                                component={InputField}
                                name={'account'}
                                label={'Email'}
                                placeholder={'email...'}
                                type={'email'}
                                required
                            />
            
                            <div className={styles.hcaptcha}>
                                {/* {!captcha && <p className={styles.warning}>Please check the box below to procceed.</p>} */}

                                <Hcapcha
                                    getToken={setCaptcha}
                                    isSuccess={isSuccess}
                                    isError={isError}
                                />
                            </div>
            
                            <div
                                className={cn(styles.sendBtn, {
                                    [styles.notAllowed]: disabled
                                })}
                                onClick={() => {
                                    !disabled && !requesting && handleSubmit()
                                }}
                            >
                                Send Email
                                {requesting && <Spinner alignRight />}
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    );
}

export default ForgotPasswordForm;

