import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchSwapTokens } from './actions';

import { setAllPoolsList, setTokenPoolsList } from '../Pools/actions';

import { getPoolsList } from '../../../components/WagyuSwap/utils';

export function UseSettingWithdrawInit() {
    const dispatch = useDispatch();

    const { loaded } = useSelector(state => state.app?.load);
    const { logged } = useSelector(state => state?.user ?? {});
    const chainId = useSelector(state => state?.user?.chainId ?? 1);

    useEffect(() => {
        if (!loaded) return;
        if (!logged) return;
    
        dispatch(fetchSwapTokens())
            .then(res => {
                res.sort((a, b) => a.chainId - b.chainId)
    
                dispatch(setAllPoolsList(getPoolsList(res)));
                dispatch(setTokenPoolsList(getPoolsList(res).filter(e => !e.isBaseCoin && e.chainId === `0x${Number(chainId).toString(16)}`)));
            })
    }, [dispatch, loaded, logged, chainId]);
}