import BigNumber from 'bignumber.js';
import { formatEther } from '@ethersproject/units';
import prettyNum, { ROUNDING_MODE } from 'pretty-num';
import fromExponential from 'from-exponential';
import { ethers } from 'ethers';

export const BIG_ZERO = new BigNumber(0);
export const BIG_TEN = new BigNumber(10);

export const getDecimalBalance = (totalBalance = '0', decimal = 18) => {
    let balance = 0;

    balance = Number(totalBalance) / Math.pow(10, decimal);

    return balance.toFixed(4);
}

export const bigPow = (val = 18) => (new BigNumber(10)).exponentiatedBy(val);

export const getBalanceOfToken = (totalBalance, fixed = 4) => {
    if (totalBalance === '0' || totalBalance === 0 || totalBalance === undefined) return '0';

    const valueString = parseFloat(formatEther(totalBalance)).toString();

    return valueString?.substring(0,6);
}

export const convertBigNumber = val => BigNumber.isBigNumber(val) ? val : new BigNumber(val || 0);

function normalizeValue(value) {
    if (typeof value === 'string') {
        return parseFloat(value)
    }

    return value
}

export function separateThousands(x, s) {
    const arrSplitValue = x.split('.');

    if (arrSplitValue && arrSplitValue.length < 2) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, s);
    }

    const firstNumber = arrSplitValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, s);

    return `${firstNumber}.${arrSplitValue[1]}`;

}

export const formatNumber = (value, prefix = '', fixed_amount = 2, thousands_separator = ',') => {
    const newValue = normalizeValue(value);

    if (isNaN(value)) {
        return 'N/A';
    }

    const newAmount = prettyNum(fromExponential(newValue || 0), {
        precision: fixed_amount,
        roundingMode: ROUNDING_MODE.DOWN,
    });

    const usePrefix = (new BigNumber(newAmount)).comparedTo(value) < 0;

    return (usePrefix ? prefix : '') + separateThousands(newAmount, thousands_separator);
};

export const toWeiDecimals = (amount, decimals = 18) => {
    return fromExponential(Number(new BigNumber(amount * 10 ** decimals)));
    // return fromExponential((new BigNumber(amount * 10 ** decimals).toString()));
}

// 1e18 => 1
export const fromWeiDecimals = (amount, decimals = 18) => {
    amount = BigNumber.isBigNumber(amount) ? amount.toString() : amount;
    return ethers.utils.formatUnits(prettyNum(fromExponential(amount || 0), {
        precision: decimals,
        roundingMode: ROUNDING_MODE.DOWN
    }), decimals);
};