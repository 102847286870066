import React from 'react';

import Filter from './Filter';

import TitleCard from '../UsedTo/TitleCard';

import Chart from '../../../../../components/Chart';

import styles from './ChartElement.module.scss';

function ChartElement() {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <TitleCard
                    title={'Total Earning'.toUpperCase()}
                    description={'7500 GZBUX'}
                />

                <Filter />
            </div>

            <div className={styles.divider} />

            <div className={styles.chart}>
                <Chart />
            </div>
        </div>
    );
}

export default ChartElement;
