import React from 'react';
import cn from 'classnames';

import styles from './TitleCard.module.scss';

function TitleCard(props) {
    const { title, description, rightDescription } = props;

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>{title}</h2>

            {description && (
                <p
                    className={cn(styles.description, {
                        [styles.rightAlign]: rightDescription
                    })}
                >
                    {description}
                </p>
            )}
        </div>
    );
}

export default TitleCard;