import erc20_ABI_Testnet from '../abi/erc20.json';
import bep20_ABI_Testnet from '../abi/bep20.json';
import polygon_ABI_Testnet from '../abi/polygon.json';

export const getAbiBaseCoin = (chainId) => {
    switch (chainId) {
        case 1:
            return erc20_ABI_Testnet
        case 5:
            return erc20_ABI_Testnet
        case 56:
            return bep20_ABI_Testnet
        case 97:
            return bep20_ABI_Testnet
        case 137:
            return polygon_ABI_Testnet
        case 80001:
            return polygon_ABI_Testnet
        default:
            break;
    }
}

export const getContractAddress = (chainId) => {
    switch (chainId) {
        case 1:
        case '0x1':
            return process.env.REACT_APP_UNDEAD_BRIDGE_ERC20_MAINNET
        case 5:
        case '0x5':
            return process.env.REACT_APP_UNDEAD_BRIDGE_ERC20_TESTNET
        case 56:
        case '0x38':
            return process.env.REACT_APP_UNDEAD_BRIDGE_BEP20_MAINNET
        case 97:
        case '0x61':
            return process.env.REACT_APP_UNDEAD_BRIDGE_BEP20_TESTNET
        case 137:
        case '0x89':
            return process.env.REACT_APP_UNDEAD_BRIDGE_POLYGON_MAINNET
        case 80001:
        case '0x13881':
            return process.env.REACT_APP_UNDEAD_BRIDGE_POLYGON_TESTNET
        default:
            break;
    }
}

export const getUndeadTokenAddress = (chainId) => {
    switch (chainId) {
        case 1:
        case '0x1':
            return process.env.REACT_APP_UNDEAD_TOKEN_ETHEREUM_TESTNET
        case 5:
        case '0x5':
            return process.env.REACT_APP_UNDEAD_TOKEN_ETHEREUM_TESTNET
        case 56:
        case '0x38':
            return process.env.REACT_APP_UNDEAD_TOKEN_BSC_TESTNET
        case 97:
        case '0x61':
            return process.env.REACT_APP_UNDEAD_TOKEN_BSC_TESTNET
        case 137:
        case '0x89':
            return process.env.REACT_APP_UNDEAD_TOKEN_POLYGON_TESTNET
        case 80001:
        case '0x13881':
            return process.env.REACT_APP_UNDEAD_TOKEN_POLYGON_TESTNET
        default:
            break;
    }
}