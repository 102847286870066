import { createAction, createActions } from 'redux-actions';

import { reloadOwnership } from '../Ownership/actions';
import ImxApi from './api';

import {
    setup,
    deposit,
    transfer,
    prepareWithdraw,
    completeWithdraw,
    batchNftTransfer
} from '../../../utils/imxHelpers';

export const loadingNft = createAction('LOADING_NFT');

export const RANDOM_REQUEST = 'RANDOM_REQUEST';
export const RANDOM_SUCCESS = 'RANDOM_SUCCESS';

const { randomRequest, randomSuccess } = createActions({
    [RANDOM_REQUEST]: () => { },
    [RANDOM_SUCCESS]: () => { }
});

export const randomWeapon = () => dispatch => {
    dispatch(randomRequest());

    setTimeout(() => dispatch(randomSuccess()), 8000)
};

export const { connectImxRequest, connectImxSuccess, connectImxFail } = createActions({
    CONNECT_IMX_REQUEST: () => {},
    CONNECT_IMX_SUCCESS: data => (data),
    CONNECT_IMX_FAIL: error => (error),
});

export const connectImx = (connector) => async (dispatch) => {
    dispatch(connectImxRequest());

    return setup(connector)
        .then((data) => {
            dispatch(connectImxSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(connectImxFail(error));
            return error;
        });
}

export const { imxDepositRequest, imxDepositSuccess, imxDepositFail } = createActions({
    IMX_DEPOSIT_REQUEST: () => {},
    IMX_DEPOSIT_SUCCESS: data => (data),
    IMX_DEPOSIT_FAIL: error => (error),
});

export const imxDeposit = (type, tokenId, tokenAddress) => async (dispatch) => {
    dispatch(imxDepositRequest());
    dispatch(loadingNft(true));

    return deposit(type, tokenId, tokenAddress)
        .then((data) => {
            dispatch(imxDepositSuccess(data));
            dispatch(reloadOwnership(Date.now()));
            dispatch(loadingNft(false));
            return data;
        })
        .catch(error => {
            dispatch(imxDepositFail(error));
            dispatch(loadingNft(false));
            return error;
        });
}

export const { imxTransferRequest, imxTransferSuccess, imxTransferFail } = createActions({
    IMX_TRANSFER_REQUEST: () => {},
    IMX_TRANSFER_SUCCESS: data => (data),
    IMX_TRANSFER_FAIL: error => (error),
});

export const imxTransfer = (params) => async (dispatch) => {
    dispatch(imxTransferRequest());
    dispatch(loadingNft(true));

    return transfer(params)
        .then((data) => {
            dispatch(imxTransferSuccess(data));
            dispatch(reloadOwnership(Date.now()));
            dispatch(loadingNft(false));
            return data;
        })
        .catch(error => {
            dispatch(imxTransferFail(error));
            dispatch(loadingNft(false));
            return error;
        });
}

export const { imxBatchNftTransferRequest, imxBatchNftTransferSuccess, imxBatchNftTransferFail } = createActions({
    IMX_BATCH_NFT_TRANSFER_REQUEST: () => {},
    IMX_BATCH_NFT_TRANSFER_SUCCESS: data => (data),
    IMX_BATCH_NFT_TRANSFER_FAIL: error => (error),
});

export const imxBatchNftTransfer = (params) => async (dispatch) => {
    dispatch(imxBatchNftTransferRequest());

    return batchNftTransfer(params)
        .then((data) => {
            dispatch(imxBatchNftTransferSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(imxBatchNftTransferFail(error));
            return error;
        });
}

export const { imxPrepareWithDrawRequest, imxPrepareWithDrawSuccess, imxPrepareWithDrawFail } = createActions({
    IMX_PREPARE_WITH_DRAW_REQUEST: () => {},
    IMX_PREPARE_WITH_DRAW_SUCCESS: data => (data),
    IMX_PREPARE_WITH_DRAW_FAIL: error => (error),
});

export const imxPrepareWithdraw = (type, tokenId, tokenAddress) => async (dispatch) => {
    dispatch(imxPrepareWithDrawRequest());
    dispatch(loadingNft(true));

    return prepareWithdraw(type, tokenId, tokenAddress)
        .then((data) => {
            dispatch(imxPrepareWithDrawSuccess(data));
            dispatch(reloadOwnership(Date.now()));
            dispatch(loadingNft(false));
            return data;
        })
        .catch(error => {
            dispatch(imxPrepareWithDrawFail(error));
            dispatch(loadingNft(false));
            return error;
        });
}

export const { imxCompleteWithDrawRequest, imxCompleteWithDrawSuccess, imxCompleteWithDrawFail } = createActions({
    IMX_COMPLETE_WITH_DRAW_REQUEST: () => {},
    IMX_COMPLETE_WITH_DRAW_SUCCESS: data => (data),
    IMX_COMPLETE_WITH_DRAW_FAIL: error => (error),
});

export const imxCompleteWithdraw = (type, tokenId, tokenAddress) => async (dispatch) => {
    dispatch(imxCompleteWithDrawRequest());
    dispatch(loadingNft(true));

    return completeWithdraw(type, tokenId, tokenAddress)
        .then((data) => {
            dispatch(imxCompleteWithDrawSuccess(data));
            dispatch(reloadOwnership(Date.now()));
            dispatch(loadingNft(false));
            return data;
        })
        .catch(error => {
            dispatch(imxCompleteWithDrawFail(error));
            dispatch(loadingNft(false));
            return error;
        });
}

export const { hasConnectedEverRequest, hasConnectedEverSuccess, hasConnectedEverFail, hasConnectedEverClear } = createActions({
    HAS_CONNECTED_EVER_REQUEST: () => {},
    HAS_CONNECTED_EVER_SUCCESS: data => (data),
    HAS_CONNECTED_EVER_FAIL: error => (error),
    HAS_CONNECTED_EVER_CLEAR: () => {}
});

export const hasConnectedImxEver = (userAddress) => async (dispatch) => {
    dispatch(hasConnectedEverRequest());

    return ImxApi.hasConnectedEver(userAddress)
        .then(({ data }) => {
            dispatch(hasConnectedEverSuccess(data));

            return data;
        })
        .catch(error => {
            const { data } = error;
            dispatch(hasConnectedEverFail(data));

            return error;
        })
}