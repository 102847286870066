import React from 'react';

import recentIcon from '../img/swap/history.png';
import closeIcon from '../../DownloadModal/img/close.png';

import styles from '../WagyuSwap.module.scss';

function ContentWrapper(props) {
    const {
        step,
        name,
        onClose,
        openRecent
    } = props;

    const title = step === 1
        ? 'Wagyu Wallet'
        : 'Swap'

    const description = step === 1
        ? `Welcome back ${name}!`
        : `Swap Gold ZBUX with your favorite crypto`

    return (
        <div className={styles.content}>
            {step === 2 && <img src={recentIcon} alt="" className={styles.recent} onClick={() => openRecent()}/>}
            <img src={closeIcon} alt="" className={styles.close} onClick={() => onClose()}/>

            <header className={styles.header}>
                <h2 className={styles.heading}>{title}</h2>
                <p className={styles.detail}>{description}</p>
            </header>

            {step === 2 && <div className={styles.divide} />}

            {props.children}
        </div>
    );
}

export default ContentWrapper;