import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniqBy } from 'lodash';

import { fetchAllOwnership, reloadOwnershipDetail } from 'store/modules/Ownership/actions';

function useWeaponLoadouts(props) {
    const dispatch = useDispatch();

    const { offset } = props;

    const [ownershipList, setOwnershipList] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [loadFirst, setLoadFirst] = useState(false);
    const [progress, setProgress] = useState(false);

    const loaded = useSelector(state => state?.app?.load?.loaded);
    const { logged } = useSelector(state => state?.user ?? {});
    const mainAddress = useSelector(state => state?.user?.userAccount?.accounts[0] ?? '')?.toLowerCase();
    const tempoAddress = useSelector(state => state?.user?.userInfo?.result?.address ?? '').toLowerCase();
    const ownershipProgressRes = useSelector(state => state?.ownership?.ownershipProgressRes?.result);
    const isReloadOwnership = useSelector(state => state?.ownership?.reloadOwnership);

    const address = mainAddress || tempoAddress;
    const tranferTokenId = window.localStorage.getItem('transferId');

    /* LOAD FIRST */
    useEffect(() => {
        if (!loaded) return;
        if (!logged) return;
        if (offset > 0) return;

        setLoadFirst(true);

        const params = {
            address,
            armoryType: 'LOADOUT',
            limit: 20,
            skip: 0
        }

        dispatch(fetchAllOwnership(params))
            .then(res => {
                // setOwnershipList(res?.data);
                setOwnershipList(res?.data?.filter(item => item.tokenId !== Number(tranferTokenId)));
                setHasMore(res?.data?.length > 9);
                setLoadFirst(false);
            })
            .catch(err => {
                setLoadFirst(false);
                return err;
            })
    }, [loaded, logged, offset, address, ownershipProgressRes, dispatch, tranferTokenId]);

    // RELOAD
    useEffect(() => {
        if (!loaded) return;
        if (!logged) return;
        if (isReloadOwnership === 0) return;

        setProgress(true);

        const params = {
            address,
            armoryType: 'LOADOUT',
            limit: 20,
            skip: 0
        }

        setTimeout(() => {
            dispatch(fetchAllOwnership(params))
                .then(res => {
                    setOwnershipList(res?.data?.filter(item => item.tokenId !== Number(tranferTokenId)));
                    setHasMore(res?.data?.length > 9);
                    dispatch(reloadOwnershipDetail(Date.now()));
                    setProgress(false);
                })
                .catch(err => {
                    setProgress(false);
                    return err;
                })
        }, 4000)
    }, [loaded, logged, address, isReloadOwnership, dispatch, tranferTokenId]);

    useEffect(() => {
        const params = {
            address,
            armoryType: 'LOADOUT',
            limit: 20,
            skip: 0
        }

        const reloadDetail = setInterval(() => {
            dispatch(fetchAllOwnership(params));
        }, [60000]);

        return () => {
            clearInterval(reloadDetail);
        }
    }, [dispatch, address, ownershipList]);

    /* LOAD MORE */
    useEffect(() => {
        if (offset === 0) return;

        const params = {
            address,
            armoryType: 'LOADOUT',
            limit: 20,
            skip: offset
        }

        dispatch(fetchAllOwnership(params))
            .then(res => {
                setOwnershipList(prevState => uniqBy([...prevState, ...res?.data], 'id'));
                setHasMore(res?.data.length > 9);
            })
            .catch(err => {
                return err;
            })
    }, [
        offset,
        address,
        dispatch
    ]);

    return { ownershipList, hasMore, progress, loadFirst, setOwnershipList };
}

export default useWeaponLoadouts;