import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Step1 from './Step1';
import Step2 from './Step2';

import Modal from '../../components/Modal';

import { finishVerify } from '../../store/modules/Wallet/actions';
import { setToast } from '../../store/modules/App/actions';

import styles from './VerifyEmail.module.scss';

function VerifyEmail(props) {
    const dispatch = useDispatch();

    const { isVisible, onCancel } = props;

    const [step, setStep] = useState(1);

    const token = useSelector(state => state?.user?.userAccount?.token ?? '');
    const walletId = useSelector(state => state?.wallet?.verifyEmailRes?.result?.data?.id ?? '');
    const finishing = useSelector(state => state?.wallet?.finishVerifyRes?.requesting);
    const { avatar } = useSelector(state => state?.user?.userInfo?.result ?? {});

    const renderSteps = () => {
        switch (step) {
        case 1:
            return <Step1 onNext={handleOnNext} avatar={avatar} />

        case 2:
            return <Step2 onFinish={handleOnFinish} token={token} />

        default:
            break;
        }
    };

    const handleOnNext = () => {
        avatar ? handleOnCancel() : setStep(step + 1);
    };

    const handleOnCancel = () => {
        onCancel();
        setStep(1);
        window.location.replace('/');
    }

    const handleOnFinish = (avatar) => {
        const body = {
            avatar: avatar?.url
        }

        dispatch(finishVerify(walletId, body))
            .then((res) => {
                if (res?.status >= 500) {
                    handleOnCancel();
                    dispatch(setToast({ title: 'FAILED', detail: 'Something went wrong' }));
                } else if ((res?.status >= 400)) {
                    handleOnCancel();
                    dispatch(setToast({ title: 'FAILED', detail: res?.data?.message }));
                } else {
                    handleOnCancel();
                }
            })
            .catch(err => {
                handleOnCancel();
                dispatch(setToast({ title: 'FAILED', detail: 'Something went wrong' }));

                return err;
            })
    };

    return (
        <div className={styles.container}>
            <Modal
                visible={isVisible}
                closable={false}
                centered
                width={'362rem'}
                maskClosable={!finishing}
            >
                {renderSteps()}
            </Modal>
        </div>
    );
}

export default VerifyEmail;