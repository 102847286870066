import { handleActions } from "redux-actions";

const initialState = {
    swapTokensRes: {
        error: null,
        result: null,
        requesting: false,
    }
};
  
let settingWithDrawReducer = handleActions({
    /** FETCH SWAP TOKENS **/
    FETCH_SWAP_TOKENS_REQUEST: (state) => ({
        ...state,
        swapTokensRes: {
            ...state.swapTokensRes,
            requesting: true
        }
    }),

    FETCH_SWAP_TOKENS_SUCCESS: (state, { payload }) => ({
        ...state,
        swapTokensRes: {
            ...state.swapTokensRes,
            result: payload.data,
            requesting: false,
            error: null
        }
    }),

    FETCH_SWAP_TOKENS_FAIL: (state, { payload }) => ({
        ...state,
        swapTokensRes: {
            ...state.swapTokensRes,
            requesting: false,
            error: payload.error
        }
    })
}, initialState);

export default settingWithDrawReducer;