import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniqBy } from 'lodash';

import { fetchInventories } from '../../../../store/modules/LootConffin/actions';

function useLootCoffin(props) {
    const dispatch = useDispatch();

    const { offset } = props;

    const [inventoriesList, setinventoriesList] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);

    const loaded = useSelector(state => state?.app?.load?.loaded);
    const { logged } = useSelector(state => state?.user ?? {});
    const address = useSelector(state => state?.user?.userAccount?.accounts[0] ?? '')?.toLowerCase();
    const userAddress = useSelector(state => state?.user?.userInfo?.result?.address ?? '');
    const { token } = useSelector(state => state?.user?.userAccount ?? '');

    /* LOAD FIRST */
    useEffect(() => {
        if (!loaded) return;
        if (!logged) return;
        if (!address || !userAddress) return;
        if (offset > 0) return;

        setFirstLoading(true);

        dispatch(fetchInventories((address || userAddress), 20, 0))
            .then(res => {
                if (res?.status >= 500) {
                    setFirstLoading(false);
                } else if ((res?.status >= 400)) {
                    setFirstLoading(false);
                } else {
                    setinventoriesList(res);
                    setHasMore(res?.length > 9);
                    setFirstLoading(false);
                }
            })
            .catch(err => {
                setFirstLoading(false);
                return err;
            })
    }, [
        loaded,
        logged,
        offset,
        address,
        token,
        userAddress,
        dispatch
    ]);

    /* LOAD MORE */
    useEffect(() => {
        if (!address || !userAddress) return;
        if (offset === 0) return;

        dispatch(fetchInventories((address || userAddress), 20, offset))
            .then(res => {
                if (res?.status >= 500) return;
                if ((res?.status >= 400)) return;

                setinventoriesList(prevState => uniqBy([...prevState, ...res], 'id'));
                setHasMore(res?.length > 9);
            })
            .catch(err => {
                return err;
            })
    }, [
        offset,
        address,
        token,
        userAddress,
        dispatch
    ]);

    return { inventoriesList, hasMore, firstLoading };
}

export default useLootCoffin;