import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ethers } from 'ethers';

import DashboardLayout from './Layout';
import Router from './Router';

import GoingApp from 'components/GoingAppModal';
import { setUpdateAvatarVisible } from 'store/modules/App/actions';
import { disconnect } from 'store/modules/User/actions';
import { hasConnectedImxEver, hasConnectedEverClear } from 'store/modules/Imx/actions';

function Dashboard(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const { loaded } = useSelector(state => state?.app?.load ?? {});
    const { logged } = useSelector(state => state?.user ?? {});
    const { token } = useSelector(state => state?.user?.userAccount ?? '');
    const { isConnect } = useSelector(state => state?.user?.connectWallet ?? {});
    const address = useSelector(state => state?.user?.userAccount?.accounts[0] ?? '')?.toLowerCase();
    // const userAddress = useSelector(state => state?.user?.userInfo?.result?.address ?? '');

    const { updateAvatarVisible } = useSelector(state => state?.app ?? {});

    const isRegisterImx = useSelector(state => state?.imx?.hasConnectedEverRes?.result?.accounts[0] ?? '');
    const isConnectImx = useSelector(state => state?.imx?.userImxInfo?.result ?? null);

    // UN_AUTHENTICATION
    useEffect(() => {
        if (!loaded) return;

        if (!logged || !token) {
            dispatch(disconnect());
            history.push('/');
            dispatch(hasConnectedEverClear());
        }

        if (ethers.utils.isAddress(address) && !isConnect) {
            dispatch(disconnect());
            history.push('/');
            dispatch(hasConnectedEverClear());
        }
        // eslint-disable-next-line 
    }, [
        dispatch,
        loaded,
        logged,
        token,
        address,
        isConnect
    ]);

    // CHECK ACCOUNT REGISTER IMX YET
    useEffect(() => {
        if (!address) return;
        if (isRegisterImx) return;

        dispatch(hasConnectedImxEver(address)); // check this address has already register to IMX ever
    }, [
        dispatch,
        address,
        isConnectImx,
        isRegisterImx
    ]);

    const handleSetUpdateAvatarVisible = useCallback(() => {
        dispatch(setUpdateAvatarVisible(false));
    }, [dispatch]);

    return (
        <DashboardLayout {...props}>
            <Router {...props} />

            <GoingApp visible={updateAvatarVisible} onCancel={handleSetUpdateAvatarVisible} />
        </DashboardLayout>
    );
}

export default React.memo(Dashboard);