import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Modal from '../Modal';

import styles from './ClaimPopup.module.scss';

function ClaimPopup(props) {
    const {
        isVisible,
        transactionHash,
        claimInfo,
        onCancel
    } = props;

    const [step, setStep] = useState(1);

    const { isMobile } = useSelector(state => state?.app);

    const claimLoading = useSelector(state => state?.withdraw?.swapOutRes?.requesting);

    const handleCancel = () => {
        if (claimLoading) return;

        setStep(1);
        onCancel();
    };

    const renderContent = () => {
        switch (step) {
            case 1:
                return <Step1 onClose={handleCancel} />

            case 2:
                return <Step2 transactionHash={transactionHash} />

            case 3:
                return <Step3 onClose={handleCancel} />
        
            default:
                break;
        }
    }

    useEffect(() => {
        if (transactionHash) {
            setStep(2);
        }

        if (claimInfo) {
            setStep(3);
        }
    }, [transactionHash, claimInfo]);

    return (
        <Modal
            visible={isVisible}
            closable={false}
            centered
            onCancel={handleCancel}
            width={isMobile ? '340rem' : '397rem'}
            wrapperClass={styles.container}
            wrapperBody={styles.wrapperBody}
            custom={true}
        >
            <div className={styles.content}>
                {renderContent()}
            </div>
        </Modal>
    );
}

export default React.memo(ClaimPopup);