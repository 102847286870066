import React, { useState, useRef, useCallback } from 'react';
import { isEmpty } from 'lodash';
import cn from 'classnames';

import useCharacterSkin from './useCharacterSkin';

import PageSlug from 'components/PageSlug';
import EmptyCoffin from 'components/EmptyCoffin';

import styles from './Skins.module.scss';

function MyCharacterSkin() {
    const [offset, setOffset] = useState(0);

    const { characterList, hasMore, firstLoading } = useCharacterSkin({ offset });

    const observer = useRef();
    const lastElement = useCallback(node => {
        if (firstLoading) return;

        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && hasMore) {
                    setOffset(prevState => prevState + 10);
                }
            });
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        });

        if (node) observer.current.observe(node);
    }, [firstLoading, hasMore, setOffset]);

    return (
        <div className={styles.container}>
            <PageSlug
                slug={'My Character Skins'}
                description={''}
                hasDevide
            />

            <div className={cn(styles.content, {
                [styles.emptyData]: isEmpty(characterList)
            })}>
                {!isEmpty(characterList) ? characterList.map((item, index) => (
                    <div
                        key={item.id}
                        className={styles.item}
                        style={{ background: `url(${item.thumbnail}) no-repeat center / 100%` }}
                        ref={characterList.length === index + 1 ? lastElement : null}
                    >
                        {/* <p className={styles.name}>{item.name}</p> */}
                    </div>
                )) : (
                    <EmptyCoffin
                        label={'No Character Skins currently held in this wallet, find Character Skins in Loot Coffins here:'}
                    />
                )}
            </div>
        </div>
    )
}

export default React.memo(MyCharacterSkin);