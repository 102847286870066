import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import devideIcon from '../Inventory/img/devide.png';
import standardZbux from './img/standard.png';
import goldZbux from './img/gold.png';
import lootBox from './img/lootbox.png';
import swap from './img/swap.png';
import eth from './img/eth.png';

import PageSlug from 'components/PageSlug';
import { formatNumber } from 'utils/bigNumber'
import { PAGE_BUY_LOOT_PATH } from '../../Router/constants';

import { setSwapModalVisible, setConnectModalVisible } from 'store/modules/App/actions';

import styles from './Wallet.module.scss';

function Wallet() {
    const dispatch = useDispatch();

    const totalZbuxBalance = useSelector(state => state?.withdraw?.userStatisticRes?.result?.totalZBux ?? '0');
    const totalStandardZBux = useSelector(state => state?.withdraw?.userStatisticRes?.result?.totalStandardZBux ?? '0');
    const { isConnect } = useSelector(state => state?.user?.connectWallet ?? {});

    const handleClickGoldLoot = (e) => {
        e.stopPropagation();

        if (!isConnect) return dispatch(setConnectModalVisible(true));

        return window.localStorage.setItem('isStandard', 'false');
    }

    const handleClickStandardLoot = (e) => {
        e.stopPropagation();

        if (!isConnect) return dispatch(setConnectModalVisible(true));

        return window.localStorage.setItem('isStandard', 'true');
    }

    const handleOnSwap = (e) => {
        e.stopPropagation();

        if (!isConnect) return dispatch(setConnectModalVisible(true));

        return dispatch(setSwapModalVisible(true));
    }

    return (
        <div className={styles.container}>
            <PageSlug
                slug={'WAGYU WALLET'}
                description={'Buy Gold ZBUX, or redeem ZBUX for Rewards'}
                hasDevide
                normal
            />

            <div className={styles.content}>
                <div className={styles.box}>
                    <section className={styles.standardCard} onClick={() => {}}>
                        <p className={styles.titleCard}>Standard Zbux</p>

                        <section className={styles.priceContainer}>
                            <p className={styles.price}>{formatNumber(totalStandardZBux)}</p>
                            <img src={standardZbux} alt="" className={styles.icon} />
                        </section>

                        <section className={styles.methodInfo}>Redeem Standard Zbux</section>

                        {isConnect ? (
                            <Link
                                to={PAGE_BUY_LOOT_PATH}
                                className={styles.lootInfo}
                                onClick={(e) => handleClickStandardLoot(e)}
                            >
                                <img src={lootBox} alt="" className={styles.lootBox} />

                                <p className={styles.boxInfo}>Redeem for Loot Coffins</p>
                            </Link>
                        ) : (
                            <div
                                className={styles.lootInfo}
                                onClick={(e) => handleClickStandardLoot(e)}
                            >
                                <img src={lootBox} alt="" className={styles.lootBox} />

                                <p className={styles.boxInfo}>Redeem for Loot Coffins</p>
                            </div>
                        )}
                    </section>

                    <img src={devideIcon} alt="" className={styles.divide} />

                    <section className={styles.goldCard} onClick={() => {}}>
                        <p className={styles.titleCard}>Gold ZBUX</p>

                        <section className={styles.priceContainer}>
                            <p className={styles.price}>{formatNumber(totalZbuxBalance)}</p>
                            <img src={goldZbux} alt="" className={styles.icon} />
                        </section>

                        <section className={styles.methodInfo}>Redeem Gold Zbux</section>

                        <section className={styles.goldMethods}>
                            {isConnect ? (
                                <Link
                                    to={PAGE_BUY_LOOT_PATH}
                                    className={styles.goldBox}
                                    onClick={(e) => handleClickGoldLoot(e)}
                                >
                                    <img src={lootBox} alt="" className={styles.goldLootIcon} />

                                    <p className={styles.label}>Redeem for Loot Coffins</p>
                                </Link>
                            ) : (
                                <div
                                    className={styles.goldBox}
                                    onClick={(e) => handleClickGoldLoot(e)}
                                >
                                    <img src={lootBox} alt="" className={styles.goldLootIcon} />

                                    <p className={styles.label}>Redeem for Loot Coffins</p>
                                </div>
                            )}

                            <div className={styles.goldBox} onClick={(e) => handleOnSwap(e)}>
                                <section className={styles.swapRow}>
                                    <img src={goldZbux} alt="" className={styles.left} />
                                    <img src={swap} alt="" className={styles.middle} />
                                    <img src={eth} alt="" className={styles.right} />
                                </section>

                                <p className={styles.label}>Swap</p>
                            </div>
                        </section>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Wallet);