import React from 'react';

import Modal from '../../../components/Modal';

import logo from '../../../assets/landingLayout/logo.png';

import styles from './SuccessModal.module.scss';

function SuccessModal(props) {
    const { visible, onCancel } = props;

    return (
        <div className={styles.container}>
            <Modal
                centered
                visible={visible}
                onCancel={onCancel}
                width={'362rem'}
                closeIcon={null}
                closable={false}
                maskClosable={false}
            >
                <div className={styles.content}>
                    <img src={logo} alt="" />

                    <h2>Forgot Password</h2>
                    <p>An email has been sent to reset your password.</p>
                </div>

                <div className={styles.footer}>
                    <div
                        className={styles.closeBtn}
                        onClick={() => onCancel()}
                    >
                        Back to Login
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default SuccessModal;