import React, { useState, useCallback } from 'react';
import { Dropdown } from 'antd';
import cn from 'classnames';

import downArrow from './img/down-arrow.png';
import downArrowBlue from './img/down-arrow-blue.png';

import ContextMenu from '../../ContextMenu';

import styles from './Dropdown.module.scss';

function DropdownContainer(props) {
    const {
        items = [],
        itemSelected,
        onChange,
        isBlueIcon,
        customLabelClass,
        customDropdownClass
    } = props;

    const [visible, setVisible] = useState(false);

    const handleOnVisible = useCallback(() => {
        setVisible(!visible);
    }, [visible]);

    return (
        <div className={styles.container}>
            <Dropdown
                overlay={(
                    <ContextMenu
                        items={items}
                        itemSelected={itemSelected}
                        onChange={onChange}
                        onVisible={handleOnVisible}
                    />
                )}
                trigger={['click']}
                visible={visible}
                onVisibleChange={() => handleOnVisible()}
            >
                <div className={cn(styles.dropDown, customDropdownClass)}>
                    {itemSelected?.icon && <img src={itemSelected?.icon} alt="" className={styles.tokenIcon} />}
                    <p className={cn(styles.label, customLabelClass)}>{itemSelected?.label}</p>
                    <img src={isBlueIcon ? downArrowBlue : downArrow} alt="" className={styles.downIcon} />
                </div>
            </Dropdown>
        </div>
    );
}

export default DropdownContainer;