import React from 'react';
import { useSelector } from 'react-redux';

import Modal from '../Modal';
import WagyuLoader from '../WagyuLoader';

import styles from './WrongNetworkPopup.module.scss';

function WrongNetworkPopup(props) {
    const { isVisible, onCancel } = props;

    const { isMobile } = useSelector(state => state?.app ?? {});

    return (
        <Modal
            visible={isVisible}
            closable={false}
            centered
            onCancel={onCancel}
            width={isMobile ? '90%' : '390rem'}
            wrapperClass={styles.container}
            wrapperBody={styles.wrapperBody}
        >
            <div className={styles.content}>
                <h2 className={styles.title}>Wrong Network</h2>
                <WagyuLoader
                    customStyle={styles.loadMore}
                />
                <p className={styles.description}>Please change your network to Mainnet.</p>
            </div>
        </Modal>
    );
}

export default WrongNetworkPopup;