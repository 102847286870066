import React from 'react';

import styles from './Left.module.scss';

function Left(props) {
    const { value } = props;

    return (
        <div className={styles.container}>
            <p className={styles.value}>{value}</p>
        </div>
    );
}

export default Left;