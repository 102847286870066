import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import accountIcon from '../../../assets/landingLayout/account.png';

import {
    PAGE_CREATE_ACCOUNT_PATH,
    PAGE_FORGOT_PASSWORD_PATH
} from '../../../router/constants';
import { setConnectModalVisible } from '../../../store/modules/App/actions';
import Spinner from '../../../components/Spinner';

import styles from './Form.module.scss';

function WalletForm(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const {
        isGoApp,
        goAppDelay,
        onGoToApp,
        onLoginWithAccount
    } = props;

    const { isConnect } = useSelector(state => state?.user?.connectWallet);
    const account = useSelector(state => state?.user?.userAccount?.accounts[0] ?? '');
    const { loadingConnectAccount } = useSelector(state => state?.user);

    const myAddress = isConnect
        ? `${account.substring(0, 5)}...${account.substring(account.length - 4)}`
        : ''

    return (
        <div className={styles.container}>
            <h2 className={styles.titleForm}>Login Wagyu Games</h2>

            <div className={cn(styles.metamask)}>
                <section className={styles.left}>
                    <p
                        className={cn(styles.connectText, {
                            [styles.noConnect]: !isConnect
                        })}
                    >
                        {isConnect ? 'Connect with Metamask' : 'Connect Wallet'}
                    </p>

                    <div className={styles.account}>
                        {isConnect && <img src={accountIcon} alt="" className={styles.accountIcon} />}

                        <span className={styles.accountAddress}>
                            {myAddress}
                        </span>
                    </div>
                </section>

                <section className={styles.right}>
                    <div
                        className={cn(styles.changeBtn)}
                        onClick={() => !loadingConnectAccount && dispatch(setConnectModalVisible(true))}
                    >
                        {loadingConnectAccount
                            ? <Spinner alignRight />
                            : (isConnect ? 'Change' : 'Connect')
                        }
                    </div>
                </section>
            </div>

            <div className={styles.withEmailPassword} onClick={onLoginWithAccount}>
                Login with Username or Email Address
            </div>

            <div
                className={cn(styles.LoginBtn, {
                    [styles.notAllowed]: !isGoApp
                })}
                onClick={() => {
                    isGoApp && onGoToApp()
                }}
            >
                Login
                {goAppDelay && <Spinner alignRight />}
            </div>

            <div
                className={styles.registerBtn}
                onClick={() => history.push(PAGE_CREATE_ACCOUNT_PATH, { fromLogin: true })}
            >
                Create Account
            </div>

            <div className={styles.forgot} onClick={() => history.push(PAGE_FORGOT_PASSWORD_PATH)}>
                Forgot your password?
            </div>
        </div>
    );
}

export default WalletForm;

