import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, Field } from 'formik';
import * as yup from 'yup';
import cn from 'classnames';
import { debounce } from 'lodash';
import { Input } from 'antd';

import Modal from '../Modal';
import InputField from '../Form/InputField';
import UploadMedia from '../Form/UploadMedia';
import Spinner from '../Spinner';
import SwitchButton from '../SwitchButton';

import { setToast, setEditProfileModalVisible } from '../../store/modules/App/actions';
import { finishVerify, checkExists, twitchValidate } from '../../store/modules/Wallet/actions';
import { disconnect } from '../../store/modules/User/actions';

import closeIcon from '../DownloadModal/img/close.png';

import styles from './EditProfileModal.module.scss';

// eslint-disable-next-line
const regexUsername = /^\w{6,20}$/;
const regexGuildTag = /^\w{1,5}$/;

const validationSchema = yup.object().shape({
    username: yup
        .string()
        .matches(regexUsername, 'Username only letters, numbers and at least 6 characters')
        .min(6)
        .max(20)
        .required('Username is required'),
    guildTag: yup
        .string()
        .matches(regexGuildTag, 'Maximum 5 letters')
        .min(1)
        .max(5)
});

function EditProfileModal(props) {
    const dispatch = useDispatch();

    const { isVisible, onCancel } = props;

    // eslint-disable-next-line
    const [avatarValue, setAvatarValue] = useState(null);
    const [upload, setUpload] = useState(false);
    const [twitchAccount, setTwitchAccount] = useState('');

    const { isMobile } = useSelector(state => state?.app);
    const account = useSelector(state => state?.user?.userAccount?.accounts[0] ?? '');
    const existsResult = useSelector(state => state?.wallet?.existsRes?.result ?? {});
    const { requesting } = useSelector(state => state?.wallet?.finishVerifyRes);
    const twitchValidating = useSelector(state => state?.wallet?.twitchValidateRes?.requesting);
    const { id, email, avatar, guild, username, twitchUsername } = useSelector(state => state?.user?.userInfo?.result ?? {});

    const [isTwitch, setTwitch] = useState(twitchUsername);

    const initialValues = {
        username: username,
        guildTag: guild || ''
    };

    const handleOnChangeAvatar = (err) => (value) => {
        !err ? setAvatarValue(value) : setAvatarValue(null)
    }

    const onCheckExists = debounce((values) => {
        if (values?.username?.toLowerCase() !== username?.toLowerCase()) {
            dispatch(checkExists({ ...values, address: account }))
                .then(res => res)
                .catch(err => err)
        }
    }, 200);

    const handleOnChecked = (value) => {
        setTwitch(value);
    };

    const onSubmit = (values, cb) => {
        const body = process.env.REACT_APP_TWITCH_DISABELD === 'false' 
            ? {
                avatar: avatarValue?.url,
                guild: values?.guildTag,
                twitchUsername: isTwitch ? twitchAccount : '',
                email: email
            } : {
                avatar: avatarValue?.url,
                guild: values?.guildTag,
                email: email
            }

        dispatch(finishVerify(id, body))
            .then((res) => {
                if (res?.status >= 500) {
                    dispatch(setToast({ title: 'FAILED', detail: 'Something went wrong' }));
                } else if (res?.status === 401) {
                    dispatch(setToast({ title: 'TOKEN IS EXPIRED', detail: '' }));
                    dispatch(setEditProfileModalVisible(false));
                    dispatch(disconnect());
                } else if (res?.status >= 400) {
                    dispatch(setToast({ title: 'FAILED', detail: res?.data?.message }));
                } else {
                    cb.resetForm();
                    dispatch(setEditProfileModalVisible(false));
                }
            })
            .catch(err => {
                dispatch(setToast({ title: 'FAILED', detail: 'Something went wrong' }));
                return err;
            })
    }

    const handleOnTwitchLink = () => {
        const clientId = process.env.REACT_APP_TWITCH_CLIENT_ID;
        const redirectUri = process.env.REACT_APP_TWITCH_REDIRECT_URI;

        const windowFeatures = "left=100,top=100,width=320,height=320";
        const url = `https://id.twitch.tv/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=user_read`;
        const twitchWindow = window.open(url, 'twitchAuth', windowFeatures);

        if (twitchWindow.closed) return;

        return setTimeout(() => {
            const search = twitchWindow.location.search;

            if (search) {
                const searchSplit = search.split('=');
                const code = searchSplit[1].split('&scope');

                window.localStorage.setItem('twitchCode', code[0]);
            }

            twitchWindow.close();

            const twitchCode = window.localStorage.getItem('twitchCode');

            dispatch(twitchValidate(twitchCode))
                .then((res) => {
                    setTwitchAccount(res?.data?.login);
                    window.localStorage.removeItem('twitchCode');
                })
                .catch(() => {
                    window.localStorage.removeItem('twitchCode');
                })
        }, 2500);
    };

    return (
        <Modal
            visible={isVisible}
            closable={false}
            centered
            onCancel={onCancel}
            width={isMobile ? '86%' : '500rem'}
            wrapperClass={styles.container}
        >
            <div className={styles.content}>
                <img src={closeIcon} alt="" className={styles.close} onClick={() => onCancel()}/>

                <UploadMedia
                    onChange={handleOnChangeAvatar}
                    uploadUrl='/media/user-upload'
                    token={''}
                    setUpload={setUpload}
                    wrapperUpload={true}
                    url={avatar}
                    onClose={() => dispatch(setEditProfileModalVisible(false))}
                />

                <header className={styles.header}>
                    <h2 className={styles.heading}>Edit Profile</h2>
                    <p className={styles.detail}>Choose the Avatar, Username and Guild Tag you wish to display on your account in-game</p>
                </header>

                <section className={styles.form}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(e, cb) => onSubmit(e, cb)}
                    >
                        {formikProps => {
                            // eslint-disable-next-line
                            const { values, errors, handleSubmit } = formikProps;

                            const isExists = values?.username?.toLowerCase() !== username?.toLowerCase()
                                && (existsResult?.username || existsResult?.isBlacklist);

                            const disabled = process.env.REACT_APP_TWITCH_DISABELD === 'false'
                                ? (
                                    !values.username
                                    || errors?.username
                                    || errors?.guildTag
                                    || (isTwitch && !twitchAccount)
                                    || isExists
                                ) : ( 
                                    !values.username
                                    || errors?.username
                                    || errors?.guildTag
                                    || isExists
                                )

                            return (
                                <Form>
                                    <Field
                                        component={InputField}
                                        name={'username'}
                                        label={'Username'}
                                        placeholder={'username...'}
                                        type={'text'}
                                        required
                                        onCheckExists={onCheckExists}
                                        exists={isExists}
                                        disabled
                                    />

                                    <Field
                                        component={InputField}
                                        name={'guildTag'}
                                        label={'Guild Tag'}
                                        placeholder={'Guild tag...'}
                                        type={'text'}
                                    />

                                    {process.env.REACT_APP_TWITCH_DISABELD === 'false' && (
                                        <SwitchButton
                                            label='Survivor Stream'
                                            onCheck={handleOnChecked}
                                            isChecked={isTwitch}
                                        />
                                    )}

                                    {process.env.REACT_APP_TWITCH_DISABELD === 'false' && (
                                        isTwitch && (
                                            <>
                                                <h2 className={styles.label}>Twitch Username</h2>
                                                <Input
                                                    value={twitchUsername || twitchAccount}
                                                    className={styles.twitchInput}
                                                    placeholder={'Twitch Username...'}
                                                    type={'text'}
                                                    suffix={
                                                        (twitchValidating)
                                                            ? <Spinner />
                                                            : <span className={styles.twitchLink} onClick={handleOnTwitchLink}>Link</span>
                                                    }
                                                    disabled
                                                />
                                            </>
                                        )
                                    )}

                                    <div className={styles.btnContainer}>
                                        <div
                                            className={cn(styles.changeBtn, {
                                                [styles.notAllowed]: disabled
                                            })}
                                            onClick={() => {
                                                !disabled && !requesting && !upload && handleSubmit()
                                            }}
                                        >
                                            Save Changes
                                            {requesting && <Spinner alignRight />}
                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </section>
            </div>
        </Modal>
    );
}

export default React.memo(EditProfileModal);