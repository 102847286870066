import React, { useEffect, useCallback } from 'react';
import Router from './router';
import Logger from 'js-logger';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { UseAppInit } from './store/modules/App/hooks';
import { UseUserInit } from './store/modules/User/hooks';
import { UseWithdrawInit } from './store/modules/Withdraw/hooks';
import { UseSettingWithdrawInit } from './store/modules/SettingWithdraw/hooks';
import { LoaderContainer } from './components/Loader';

import {
    setConnectModalVisible,
    setDownloadModalVisible,
    setLogoutModalVisible,
    setChangePasswordModalVisible,
    setEditProfileModalVisible,
    setSwapModalVisible,
    setRecentModalVisible,
    setWrongNetworkVisible,
    turnOffModal
} from './store/modules/App/actions';

import { disconnect } from './store/modules/User/actions';

import ConnectToWalletModal from './components/ConnectToWalletModal';
import WagyuToast from './components/WagyuToast';
import DownloadModal from './components/DownloadModal';
import LogoutModal from './components/LogoutModal';
import ChangePasswordModal from './components/ChangePasswordModal';
import EditProfileModal from './components/EditProfileModal';
import WagyuSwapModal from './components/WagyuSwap';
import WagyuHistoryModal from './components/WagyuHistory';
import WrongNetworkPopup from './components/WrongNetworkPopup';

import http from './services/http';

function App() {
    /*
        Any global level component or task should be defined here
    */
    const dispatch = useDispatch();
    const history = useHistory();

    const token = useSelector(state => state?.user?.userAccount?.token ?? '');
    const { logged } = useSelector(state => state?.user ?? {});
    const { isConnect } = useSelector(state => state?.user?.connectWallet ?? {});

    const {
        connectModalVisible,
        downloadModalVisible,
        logoutModalVisible,
        changePasswordModalVisible,
        editProfileModalVisible,
        swapModalVisible,
        wrongNetworkVisible,
        // recentModalVisible,
    } = useSelector(state => state?.app);

    
    if (token) {
        http.setAuthorizationHeader(token);
    }
    
    useEffect(() => {
        if (!isConnect && !token && !logged) {
            return dispatch(turnOffModal());
        }

        return;
    }, [dispatch, token, logged, isConnect]);

    useEffect(() => {
        if (!logged) return;

        const dateNow = window.localStorage.getItem('dateNow');
        const tomorrow = window.localStorage.getItem('tomorrow');

        if (dateNow === Number(tomorrow)) {
            window.localStorage.removeItem('dateNow');
            window.localStorage.removeItem('tomorrow');

            dispatch(disconnect());
            history.push('/');
        } else {
            window.localStorage.setItem('tomorrow', moment(Number(dateNow)).add(1,'days').endOf().valueOf());
        }
    }, [dispatch, logged, history])

    useEffect(() => {
        let script = document.createElement('script');
        script.src = process.env.PUBLIC_URL + '/js/slick.js';
        script.async = true;
        document.body.appendChild(script);
    }, [dispatch]);

    Logger.useDefaults();

    // Initiate state
    UseAppInit();
    UseUserInit();
    UseWithdrawInit();
    UseSettingWithdrawInit();

    const handleSetConnectModalVisible = useCallback(() => {
        dispatch(setConnectModalVisible(false));
    }, [dispatch]);

    const handleSetDownloadModalVisible = useCallback(() => {
        dispatch(setDownloadModalVisible(false));
    }, [dispatch]);

    const handleSetLogoutModalVisible = useCallback(() => {
        dispatch(setLogoutModalVisible(false));
    }, [dispatch]);

    const handleSetChangePasswordModalVisible = useCallback(() => {
        dispatch(setChangePasswordModalVisible(false));
    }, [dispatch]);

    const handleSetEditProfileModalVisible = useCallback(() => {
        dispatch(setEditProfileModalVisible(false));
    }, [dispatch]);

    const handleSetSwapModalVisible = useCallback(() => {
        dispatch(setSwapModalVisible(false));
    }, [dispatch]);

    const handleSetRecentModalVisible = useCallback(() => {
        dispatch(setRecentModalVisible(false));
    }, [dispatch]);

    const handleSetWrongNetworkVisible = useCallback(() => {
        dispatch(setWrongNetworkVisible(false));
    }, [dispatch]);

    return (
        <>
            <Router/>
            <LoaderContainer/>
            <ConnectToWalletModal
                visible={connectModalVisible}
                onCancel={handleSetConnectModalVisible}
            />
            <DownloadModal
                isVisible={downloadModalVisible}
                onCancel={handleSetDownloadModalVisible}
            />
            <LogoutModal
                isVisible={logoutModalVisible}
                onCancel={handleSetLogoutModalVisible}
            />

            {changePasswordModalVisible && (
                <ChangePasswordModal
                    isVisible={changePasswordModalVisible}
                    onCancel={handleSetChangePasswordModalVisible}
                />
            )}

            {editProfileModalVisible && (
                <EditProfileModal
                    isVisible={editProfileModalVisible}
                    onCancel={handleSetEditProfileModalVisible}
                />
            )}

            <WagyuSwapModal
                isVisible={swapModalVisible}
                onCancel={handleSetSwapModalVisible}
            />

            <WagyuHistoryModal
                // isVisible={recentModalVisible}
                onCancel={handleSetRecentModalVisible}
            />

            <WrongNetworkPopup
                isVisible={wrongNetworkVisible}
                onCancel={handleSetWrongNetworkVisible}
            />

            <WagyuToast />
        </>
    );
}

export default App;
