import React, { memo } from 'react';

import character from '../img/characters.png';

import styles from './BaseBackground.module.scss';

function BaseBackground() {
    return (
        <div className={styles.container}>
            <img src={character} alt="character-1" className={styles.character1} />
            <img src={character} alt="character-2" className={styles.character2} />
            <img src={character} alt="character-3" className={styles.character3} />
        </div>
    );
}

export default memo(BaseBackground);