import React from 'react';
import cn from 'classnames';

import close from './close.png';

import style  from './MobileMenu.module.scss';

export default function MobileMenu(props) {
    const {isOpen, items, onItemClick, onClose} = props;

    return (
        <nav className={cn(style.container, {
            [style.isOpen]: isOpen
        })}>
            <div className={style.fade} onClick={onClose}/>
            <div className={style.menuWrapper}>
                <div className={style.alignEnd}>
                    <div
                        onClick={() => onClose()}
                        className={style.containerHamburger}
                    >
                        <div id="bottom" className={style.bottomLayerMobile} />
                        <div id="top" className={style.topLayerMobile}>
                            <img src={close} alt="icon" />
                        </div>
                    </div>
                </div>

                <ul className={style.menu}>
                    {items && items.map((item, i) => (
                        <li className={style.menuItem} key={i}>
                            <button
                                className={style.menuLink}
                                onClick={() => onItemClick(item)}
                            >
                                {item.name}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </nav>
    )
}