import AKM from './img/AKM_BasicBlack_Common.png';
import BASEBALL_BAT from './img/BaseballBat_BasicBlack_Rare.png';
import COMBAT_KNIFE from './img/CombatKnife_BasicBlack_Rare.png';
import CROSSBOW from './img/Crossbow_EagleEye_Epic.png';
import DOUBLE_BAREL from './img/DoubleBarrel_Anodized_Rare.png';
import F1 from './img/F1_Alert_Epic.png';
import FIRE_AXE from './img/FireAxe_BasicBlack_Rare.png';
import HUNTING_RIFLE from './img/HuntingRifle_BasicBlack_Rare.png';
import M1A from './img/M1A_BasicBlack_Common.png';
import M1911 from './img/M1911_BasicBlack_Common.png';
import MP5 from './img/MP5_Alert_Rare.png';
import R870 from './img/R870_Anodized_Rare.png';
import REVOLVER from './img/Revolver_Anodized_Rare.png';
import RPG from './img/RPG_Desert_Legendary.png';

export const WEAPON_LIST = [
    { id: '0', rarityType: 'Common', img: AKM },
    { id: '1', rarityType: 'Rare', img: BASEBALL_BAT },
    { id: '2', rarityType: 'Rare', img: COMBAT_KNIFE },
    { id: '3', rarityType: 'Epic', img: CROSSBOW },
    { id: '4', rarityType: 'Rare', img: DOUBLE_BAREL },
    { id: '5', rarityType: 'Epic', img: F1 },
    { id: '6', rarityType: 'Rare', img: FIRE_AXE },
    { id: '7', rarityType: 'Rare', img: HUNTING_RIFLE },
    { id: '8', rarityType: 'Common', img: M1A },
    { id: '9', rarityType: 'Common', img: M1911 },
    { id: '10', rarityType: 'Rare', img: MP5 },
    { id: '11', rarityType: 'Rare', img: R870 },
    { id: '12', rarityType: 'Rare', img: REVOLVER },
    { id: '13', rarityType: 'Legendary', img: RPG },
    { id: '14', rarityType: 'Common', img: AKM },
    { id: '15', rarityType: 'Rare', img: BASEBALL_BAT },
    { id: '16', rarityType: 'Rare', img: COMBAT_KNIFE },
    { id: '17', rarityType: 'Epic', img: CROSSBOW },
    { id: '18', rarityType: 'Rare', img: DOUBLE_BAREL },
    { id: '19', rarityType: 'Epic', img: F1 },
    { id: '20', rarityType: 'Rare', img: FIRE_AXE },
    { id: '21', rarityType: 'Rare', img: HUNTING_RIFLE },
    { id: '22', rarityType: 'Common', img: M1A },
    { id: '23', rarityType: 'Common', img: M1911 },
    { id: '24', rarityType: 'Rare', img: MP5 },
    { id: '25', rarityType: 'Rare', img: R870 },
    { id: '26', rarityType: 'Rare', img: REVOLVER },
    { id: '27', rarityType: 'Legendary', img: RPG },
    { id: '28', rarityType: 'Common', img: AKM },
    { id: '29', rarityType: 'Rare', img: BASEBALL_BAT },
    { id: '30', rarityType: 'Rare', img: COMBAT_KNIFE },
    { id: '31', rarityType: 'Epic', img: CROSSBOW },
    { id: '32', rarityType: 'Rare', img: DOUBLE_BAREL },
    { id: '33', rarityType: 'Epic', img: F1 },
    { id: '34', rarityType: 'Rare', img: FIRE_AXE },
    { id: '35', rarityType: 'Rare', img: HUNTING_RIFLE },
    { id: '36', rarityType: 'Common', img: M1A },
    { id: '37', rarityType: 'Common', img: M1911 },
    { id: '38', rarityType: 'Rare', img: MP5 },
    { id: '39', rarityType: 'Rare', img: R870 },
    { id: '40', rarityType: 'Rare', img: REVOLVER },
    { id: '41', rarityType: 'Legendary', img: RPG }
];

export const randomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min)) + min;
}