import React from 'react';
import { useSelector } from 'react-redux';

import WagyuLoader from '../WagyuLoader';
import { getTransactionUrl } from '../../utils/common';

import whiteViewIcon from '../../assets/img/white-view.png';

import styles from './ClaimPopup.module.scss';

function Step2(props) {
    const { transactionHash = '' } = props;

    const chainId = useSelector(state => state?.user?.chainId ?? 1);

    const txLink = getTransactionUrl(chainId);

    return (
        <div className={styles.step2}>
            <div className={styles.loaderContainer}>
                <WagyuLoader />
            </div>

            <div className={styles.main}>
                <h2 className={styles.heading}>Swapping Tokens</h2>
                <p className={styles.description}>
                    Please wait while we swap cryptocurrencies. This process can take a couple minutes.
                </p>
            </div>

            <div
                className={styles.buttonContainer}
                onClick={() => window.open(`${txLink}/${transactionHash}`, '_blank')}
            >
                View Transaction
                <img src={whiteViewIcon} alt="" className={styles.whiteViewIcon} />
            </div>
        </div>
    );
}

export default Step2;