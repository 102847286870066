import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { HEADER_NAVIGATION_ITEMS, HEADER_NAVIGATION_LABELS } from './constants';
import { PAGE_CREATE_ACCOUNT_PATH } from '../../router/constants';

import LandingHeaderCustom from '../../components/LandingHeaderCustom';
import { setMenuOpen } from '../../store/modules/App/actions';

export default function LandingHeaderCustomContainer(props) {
    const history = useHistory();
    const dispatch = useDispatch();

    const navItems = HEADER_NAVIGATION_ITEMS;

    const {isMobile, isMenuOpen} = useSelector(state => state.app);

    const onNavItemClick = (item) => {
        if (isMobile) {
            dispatch(setMenuOpen(false))
        }

        if (item && item.path) {
            if (HEADER_NAVIGATION_LABELS.includes(item.name)) {
                window.open(item.path,'_blank');
            } else {
                history.push(item.path);
            }
        }
    };

    const onButtonClick = () => history.push(PAGE_CREATE_ACCOUNT_PATH);
    const onMenuClose = () => dispatch(setMenuOpen(false));
    const onMenuOpen = () => dispatch(setMenuOpen(true));

    return (
        <LandingHeaderCustom
            {...{
                navItems,
                isMobile,
                isMenuOpen,
                onButtonClick,
                onNavItemClick,
                onMenuOpen,
                onMenuClose
            }}
            {...props}
        />
    )
}
