import React from 'react';
import style from './Modal.module.scss';
import cn from 'classnames';
import { Modal as AntdModal } from 'antd';
import CloseIcon from './CloseIcon';

export default function Modal(props) {
    const {wrapperClass, wrapperBody, title, custom, children, ...restProps} = props;

    return (
        <AntdModal
            wrapClassName={cn(style.container, wrapperClass)}
            footer={null}
            closeIcon={<CloseIcon/>}
            {...restProps}
        >
            {title && (
                <div className={style.head}>
                    <h3 className={style.title}>{title}</h3>
                </div>
            )}
            <div
                className={cn(style.body, wrapperBody, {
                    [style.noTitle]: !title,
                    [style.custom]: custom
                })}
            >
                {children}
            </div>
        </AntdModal>
    )
}