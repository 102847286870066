import React from 'react';

import StatisticList from './StatisticList';

import TitleCard from '../UsedTo/TitleCard';
import LeftGradientCard from '../UsedTo/GradientCard/Left';
import BottomGradientCard from '../UsedTo/GradientCard/Bottom';

import levelImg from '../img/level.png';
import manImg from '../img/man.png';

import styles from './AccountSummary.module.scss';

function AccountSummary(props) {
    const { userInfo = {} } = props;

    const avatar = userInfo?.avatar ?? '';
    const username = userInfo?.username ?? '';

    const item = {
        icon: levelImg,
        value: 'Prestige Level 13'
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <TitleCard
                    title={'My Account Summary'.toUpperCase()}
                />

                <section className={styles.userInfo}>
                    <img src={avatar} alt='avatar' className={styles.avatar} />
                    <p className={styles.username}>{username.toUpperCase()}</p>
                </section>

                <section className={styles.accountInfo}>
                    <BottomGradientCard isMain item={item} />

                    <div className={styles.experience}>
                        <LeftGradientCard value={'140000 XP Earned'.toUpperCase()} />
                        <LeftGradientCard value={'120000 Xp Spent'.toUpperCase()} />
                    </div>
                </section>

                <img src={manImg} alt='man' className={styles.manImg} />

                <section className={styles.divider} />

                <StatisticList />
            </div>
        </div>
    );
}

export default AccountSummary;