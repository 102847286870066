import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import lottie from 'lottie-web';

import { CARD_ITEMS } from './constants';

import part1 from './lotties/part1';
import part2 from './lotties/part2';
import part3 from './lotties/part3';
import part4 from './lotties/part4';

import rightArrow from '../../../../../assets/dashboardLayout/dashboard/right-arrow.png';

import { setConnectModalVisible } from '../../../../../store/modules/App/actions';

import styles from './CardList.module.scss';

const animations = [part1, part2, part3, part4];

function CardList() {
    const dispatch = useDispatch();
    const history = useHistory();

    const bgId = 'part1';

    const [index, setIndex] = useState('');
    const [random, setRandom] = useState('');

    const { isConnect } = useSelector(state => state?.user?.connectWallet);

    const cardItems = useMemo(() => {
        return CARD_ITEMS(isConnect)
    }, [isConnect]);

    useEffect(() => {
        let bgAnim = lottie.loadAnimation({
            container: document.getElementById(bgId),
            animationData: animations[random - 1],
            renderer: 'svg',
            loop: true,
            autoplay: true,
            name: 'bg-animation',
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        });

        bgAnim.play();

        return () => {
            bgAnim.destroy();
        }
    }, [index, random]);

    const randomMinMax = (min, max) => {
        setRandom(Math.floor(Math.random() * (max - min)) + min);
    }

    return (
        <div className={styles.container}>
            {cardItems && cardItems.map((e, i)=> (
                <div
                    key={e.key}
                    className={styles.content}
                    onMouseEnter={() => {
                        randomMinMax(1, 5);
                        setIndex(i);
                    }}
                    onMouseLeave={() => {
                        setRandom('');
                        setIndex('');
                    }}
                    onClick={() => {
                        if (e.requireConnected) {
                            if (!isConnect) return dispatch(setConnectModalVisible(true));

                            if (typeof e.action === 'string') return history.push(e.action)

                            dispatch(e.action(true));
                        }

                        if (e.action) {
                            if (typeof e.action === 'string') return history.push(e.action)
                            
                            return dispatch(e.action(true))
                        }
                    }}
                >
                    <section className={styles.cardItem}>
                        <section className={styles.cardBanner}>
                            <img
                                src={e.icon}
                                alt=""
                                className={styles.banner}
                                style={{ width: e.styling.width, height: e.styling.height, marginTop: e.styling.marginTop }}
                            />

                            {i === index && <div id={bgId} className={styles.bgAnimation} />}
                        </section>

                        <section className={styles.cardInfo}>
                            <h2 className={styles.title}>{e.title}</h2>
                            <div className={styles.detailView}>
                                <p className={styles.detail}>{e.detail}</p>
                            </div>
                        </section>

                        <section className={styles.cardFooter}>
                            <p className={styles.coming}>{e.directionLabel}</p>
                            <img src={rightArrow} alt="" className={styles.rightArrow} />
                        </section>
                    </section>
                </div>
            ))}
        </div>
    );
}

export default CardList;