import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { ethers } from 'ethers';
import { groupBy, isEmpty } from 'lodash';

import itemLoot from '../img/loot-item.png';
import devideIcon from '../img/devide.png';

import PopupConfirm from './PopupConfirm';
import { LIST } from '../constants';

import PageSlug from 'components/PageSlug';
// import ImxProvider from 'components/ImxProvider';

import { fetchStore, buyCoffin, getInventorySignature } from 'store/modules/LootConffin/actions';
import { getUserStatistic } from 'store/modules/Withdraw/actions';
import { setToast, setConnectModalVisible } from 'store/modules/App/actions';
import { handleBuyPackage, getSessionInfo } from 'store/modules/LootConffin/actions';
import { connectImx } from 'store/modules/Imx/actions';
import { switchNetwork } from 'store/modules/User/actions';
import { freezeWarning } from 'constants/common';

import { getAllowance, approve } from 'utils/callHelpers';
import { formatNumber } from 'utils/bigNumber';
import { getUndeadTokenAddress } from 'utils/contractHelpers';

import undeadInventoryAbi from 'abi/undeadInventory.json';
import undeadTokenAbi from 'abi/undeadToken.json';

import styles from './BuyLoot.module.scss';

function BuyLoot() {
    const dispatch = useDispatch();

    const [listStore, setListStore] = useState([]);
    const [isConfirm, setConfirm] = useState(false);
    const [itemSelected, setItemSelected] = useState({});
    const [isUndead, setUndead] = useState(false);
    const [isUndeadProgress, setUndeadProgress] = useState(false);
    const [isRefetchSession, setRefetchSession] = useState(null);
    // eslint-disable-next-line
    const [transactionHash, setTransactionHash] = useState('');

    const loadingBuy = useSelector(state => state?.lootConffin?.buyCoffinRes?.requesting);
    const loadingStoreList = useSelector(state => state?.lootConffin?.storeRes?.requesting);
    const totalZbuxBalance = useSelector(state => state?.withdraw?.userStatisticRes?.result?.totalZBux ?? '0');
    const totalStandardZBux = useSelector(state => state?.withdraw?.userStatisticRes?.result?.totalStandardZBux ?? '0');
    const totalUndeadBalance = useSelector(state => state?.user?.userAccount?.undeadBalance);
    const address = useSelector(state => state?.user?.userAccount?.accounts[0] ?? '')?.toLowerCase();
    const isFreeze = useSelector(state => state?.withdraw?.userStatisticRes?.result?.isFreeze);
    const chainId = useSelector(state => state?.user?.chainId);
    const rewardsType = useSelector(state => state?.lootConffin?.rewardsType);

    const connectImxRequesting = useSelector(state => state?.imx?.userImxInfo?.requesting);
    const hasRegisterImxRequesting = useSelector(state => state?.imx?.hasConnectedEverRes?.requesting);
    const isConnectImx = useSelector(state => state?.imx?.userImxInfo?.result?.address ?? '');
    const isConnectWallet = useSelector(state => state?.user?.connectWallet?.isConnect);

    const isRegisterImx = useSelector(state => state?.imx?.hasConnectedEverRes?.result?.accounts[0] ?? '');
    const sessionInfo = useSelector(state => state?.lootConffin?.sessionInfoRes?.result?.data);

    const connectorId = window.localStorage.getItem('connectorId');
    const isStandard = window.localStorage.getItem('isStandard') === 'true';

    const isAllowBuyUndead = process.env.REACT_APP_DEV === '1'
        ? (chainId === 1 || chainId === 5)
        : chainId === 1;
    
    const buyCoffinByUndeadDisabled = process.env.REACT_APP_BUY_COFFIN_BY_UNDEAD_DISABLED === 'true';

    const NFT_LIST = useMemo(() => {
        if (!rewardsType) return;

        const { commonRewards, epicRewards, rareRewards, legendaryRewards } = rewardsType;

        const typeList = [...commonRewards, ...epicRewards, ...rareRewards, ...legendaryRewards];
        const weaponList = groupBy(typeList, 'weapon');

        let newList = [];

        for (const key in weaponList) {
            if (Object.hasOwnProperty.call(weaponList, key)) {
                const element = weaponList[key];

                newList.push({
                    type: key,
                    children: element
                });
            }
        }

        newList.sort((a, b) => {
            if (a.type < b.type) {
                return -1;
            }

            if (a.type > b.type) {
                return 1;
            }

            return 0;
        })

        return newList;
    }, [rewardsType]);

    const confirmButtonLabel = useMemo(() => {
        if (!isUndead) {
            if (!isConnectWallet) return 'Connect Wallet';
            if (!isRegisterImx && !isConnectImx) return 'Connect IMX';

            return 'Confirm';
        }

        if (!isConnectWallet) return 'Connect Wallet';
        if (!isRegisterImx && !isConnectImx) return 'Connect IMX';
        if (!isAllowBuyUndead) return 'Switch Network';

        return 'Confirm';
    }, [
        isUndead,
        isConnectImx,
        isRegisterImx,
        isConnectWallet,
        isAllowBuyUndead
    ]);

    const loadingButton = useMemo(() => {
        return isUndeadProgress || loadingBuy || connectImxRequesting || hasRegisterImxRequesting;
    }, [isUndeadProgress, loadingBuy, connectImxRequesting, hasRegisterImxRequesting]);

    useEffect(() => {
        dispatch(getSessionInfo());
    }, [dispatch, isRefetchSession]);

    useEffect(() => {
        dispatch(fetchStore())
            .then(res => {
                const customData = LIST.map((e, i) => ({
                    ...e,
                    zbuxPrice: isStandard ? res[i].priceInStandardZBux : res[i].priceInZBux,
                    undeadPrice: res[i]?.priceInUndead,
                    value: res[i].box,
                    disabled: res[i].disabled
                }));

                setListStore(customData);
            })
            .catch(err => err)
    }, [dispatch, isStandard]);

    const handleOnConfirm = (item) => {
        setItemSelected(item);
        setConfirm(true);
    };

    const handleOnReceipt = (receipt, error = null) => {
        setTransactionHash(receipt?.transactionHash);
    }

    // main Buy action
    const buyCoffinAction = async (amount, type, hash) => {
        const { zbuxPrice } = itemSelected;

        if (!isConnectWallet) return dispatch(setConnectModalVisible(true));

        if (!isRegisterImx) {
            return dispatch(connectImx(connectorId))
                .then(res => {
                    if (res?.message) return dispatch(setToast({ title: 'FAILED', detail: 'Unlock Immutable X' }));
                })
                .catch(err => err)
        }

        if (isFreeze) return dispatch(setToast({ title: 'FAILED', detail: freezeWarning }));

        if (isStandard) {
            if (totalStandardZBux < zbuxPrice) return dispatch(setToast({ title: 'BUY COFFIN', detail: 'Your standard zbux balance is not enough' }));
        }

        if (!isStandard) {
            if (totalZbuxBalance < zbuxPrice) return dispatch(setToast({ title: 'BUY COFFIN', detail: 'Your gold zbux balance is not enough' }));
        }

        dispatch(buyCoffin({ box: amount, useZBuxType: type, hash }))
            .then((res) => {
                if (res?.status >= 500) {
                    dispatch(setToast({ title: 'BUY COFFIN', detail: 'Something went wrong.' }));
                    setConfirm(false);
                } else if ((res?.status >= 400)) {
                    dispatch(setToast({ title: 'BUY COFFIN', detail: res?.data?.message || 'Something went wrong.' }));
                    setConfirm(false);
                } else {
                    dispatch(setToast({ title: 'BUY COFFIN', detail: `You bought ${amount}X success.` }));
                    setConfirm(false);
                    dispatch(getUserStatistic(address));
                    setRefetchSession(new Date().getTime());
                }
            })
            .catch((error) => {
                dispatch(setToast({ title: 'BUY COFFIN', detail: error?.data?.message || 'Something went wrong.' }));
                setConfirm(false);
            })
    };

    // for Standard Zbux or Gold Zbux
    const handleBuyCoffinByZbux = () => {
        const { value } = itemSelected;

        if (isStandard) return buyCoffinAction(value, 'STANDARD_Z_BUX');

        return buyCoffinAction(value, 'GOLD_Z_BUX');
    };

    // for Undead Token
    const handleBuyCoffinByUndead = async () => {
        const { value, undeadPrice } = itemSelected;

        if (!isConnectWallet) return dispatch(setConnectModalVisible(true));

        // just allow ethereum now
        if (!isAllowBuyUndead) {
            const chainId = process.env.REACT_APP_DEV === '1'
                ? '0x5'
                : '0x1';

            const network = 'eth';

            return dispatch(switchNetwork(chainId, network));
        }

        if (!isRegisterImx) {
            return dispatch(connectImx(connectorId))
                .then(res => {
                    if (res?.message) return dispatch(setToast({ title: 'FAILED', detail: 'Unlock Immutable X' }));
                })
                .catch(err => err)
        }

        if (isFreeze) return dispatch(setToast({ title: 'FAILED', detail: freezeWarning }));

        if (totalUndeadBalance < undeadPrice) return dispatch(setToast({ title: 'BUY COFFIN', detail: 'Your undead balance is not enough' }));

        const inventoryContractAddress = process.env.REACT_APP_DEV === '1'
            ? process.env.REACT_APP_UNDEAD_INVENTORY_TESTNET
            : process.env.REACT_APP_UNDEAD_INVENTORY_MAINNET

        try {
            setUndeadProgress(true);

            const undeadTokenAddress = getUndeadTokenAddress(chainId);
            const undeadContract = new window.web3.eth.Contract(undeadTokenAbi, undeadTokenAddress);

            // getAllowance with user address
            const hasApprove = await getAllowance(undeadContract, address, inventoryContractAddress);

            // checking approve or not yet, if Allowance is more than 0 that is approved
            if (Number(hasApprove) < 1) {
                await approve(undeadContract, inventoryContractAddress, address, ethers.constants.MaxUint256.toString());
            }

            // get params info to call buyPackage contract
            dispatch(getInventorySignature({ box: value }))
                .then(res => {
                    const { pkgId, pkgAmount, price, sigTime, signature } = res;

                    const params = {
                        pkgId,
                        pkgAmount,
                        price,
                        sigTime,
                        signature
                    }

                    const callbacks = {
                        onReceipt: handleOnReceipt
                    }

                    try {
                        const inventoryContract = new window.web3.eth.Contract(undeadInventoryAbi, inventoryContractAddress);

                        dispatch(handleBuyPackage(inventoryContract, address, params, callbacks))
                            .then(res => {
                                if (res?.message) {
                                    setUndeadProgress(false);
                                    dispatch(setToast({ title: 'BUY COFFIN', detail: res?.message }));
                                    return
                                }

                                dispatch(buyCoffin({ box: value, useZBuxType: 'UNDEAD', hash: res?.transactionHash }))
                                    .then((res) => {
                                        if (res?.status >= 500) {
                                            dispatch(setToast({ title: 'BUY COFFIN', detail: 'Something went wrong.' }));
                                            setConfirm(false);
                                        } else if ((res?.status >= 400)) {
                                            dispatch(setToast({ title: 'BUY COFFIN', detail: res?.data?.message }));
                                            setConfirm(false);
                                        } else {
                                            dispatch(setToast({ title: 'BUY COFFIN', detail: `You bought ${value}X success.` }));
                                            setConfirm(false);
                                            dispatch(getUserStatistic(address));
                                            setUndeadProgress(false);
                                            setRefetchSession(new Date().getTime());
                                        }
                                    })
                                    .catch((error) => {
                                        dispatch(setToast({ title: 'BUY COFFIN', detail: error?.data?.message || 'Something went wrong.' }));
                                        setConfirm(false);
                                    })
                            })
                            .catch((error) => {
                                setUndeadProgress(false);
                                dispatch(setToast({ title: 'BUY COFFIN', detail: error?.data?.message || 'Something went wrong.' }));
                            })
                    } catch (error) {
                        setUndeadProgress(false);
                        dispatch(setToast({ title: 'BUY COFFIN', detail: error?.data?.message || 'Something went wrong.' }));
                    }
                })
                .catch((error) => {
                    setUndeadProgress(false);
                    dispatch(setToast({ title: 'BUY COFFIN', detail: error?.data?.message || 'Something went wrong.' }));
                })
        } catch (error) {
            setUndeadProgress(false);
            dispatch(setToast({ title: 'BUY COFFIN', detail: error?.data?.message || 'Something went wrong.' }));
        }
    }

    return (
        <div className={styles.container}>
            <PageSlug
                slug={'Undead Blocks Loot Coffin'}
                description={''}
            />

            <div className={styles.content}>
                <section className={styles.left}>
                    <img src={itemLoot} alt="" className={styles.itemLoot} />

                    <div className={styles.lootInfo}>
                        <p className={styles.title}>SEASON 1 LOOT COFFIN</p>
                        <p className={styles.detail}>Season 1 Loot Coffins Purchased: {formatNumber(sessionInfo?.total)} / {formatNumber(sessionInfo?.limit)}</p>
                    </div>

                    <div className={styles.priceContainer}>
                        {loadingStoreList ? (
                            Array(3).fill({}).map((e, index) => (
                                <div key={index} className={styles.priceItemLoading} />
                            ))
                        ) : (
                            listStore.map((item) => (
                                <div
                                    key={item.id}
                                    className={cn(styles.priceItem)}
                                >
                                    <section className={styles.top}>
                                        <img
                                            src={item.icon}
                                            alt=""
                                            className={cn(styles.itemImg, {
                                                [styles[item.flat]]: item.flat
                                            })}
                                        />

                                        <p className={styles.value}>{item.value}X</p>
                                        <p className={styles.label}>Loot Coffin</p>
                                    </section>

                                    <section className={styles.bottom}>
                                        <div
                                            className={cn(styles.zbuxButton, {
                                                [styles.isDisabled]: item.disabled,
                                                [styles.isStandard]: isStandard
                                            })}
                                            onClick={() => {
                                                setUndead(false);
                                                handleOnConfirm(item);
                                            }}
                                        >
                                            <p
                                                className={cn(styles.zbuxPrice, {
                                                    [styles.isStandard]: isStandard
                                                })}
                                            >
                                                {formatNumber(item.zbuxPrice)} {isStandard ? 'Standard' : 'Gold'} Zbux
                                            </p>
                                            {
                                                !isStandard &&
                                                <p className={styles.zbuxToDollar}>(${(item.zbuxPrice * 0.1 / item.value).toFixed(2)} each)</p>
                                            }
                                        </div>

                                        {isStandard ? (
                                            <p className={styles.orDevide}>OR</p>
                                        ) : (
                                            !buyCoffinByUndeadDisabled ? <p className={styles.orDevide}>OR</p> : null
                                        )}

                                        {isStandard ? (
                                            <div
                                                className={cn(styles.buyByGZbuxLink, {
                                                })}
                                                onClick={() => {
                                                    window.localStorage.setItem('isStandard', 'false');
                                                    window.location.reload();
                                                }}
                                            >
                                                <p className={styles.undeadPrice}>
                                                    Buy with Gold ZBUX / $UNDEAD
                                                </p>
                                            </div>
                                        ) : (
                                            null
                                        )}

                                        {(!isStandard && !buyCoffinByUndeadDisabled) ? (
                                            <div
                                                className={cn(styles.undeadButton, {
                                                })}
                                                onClick={() => {
                                                    setUndead(true);
                                                    handleOnConfirm(item);
                                                }}
                                            >
                                                <p className={styles.undeadPrice}>{item.undeadPrice}$ UNDEAD Tokens</p>
                                                {/* <p className={styles.undeadToDollar}>($9.99 each)</p> */}
                                            </div>
                                        ) : (
                                            null
                                        )}
                                    </section>
                                </div>
                            ))
                        )}
                    </div>
                </section>

                <img src={devideIcon} alt="" className={styles.divide} />

                <section className={styles.right}>
                    <header className={styles.rightheader}>
                        <p className={styles.description}>
                            Rewards Included in Season 1 Loot Coffins:
                        </p>

                        <div className={styles.devide} />
                    </header>

                    <section className={styles.weaponList}>
                        {!isEmpty(NFT_LIST) && NFT_LIST.map((nftItem, index) => {
                            const weaponList = nftItem.children ?? [];

                            return (
                                <div key={index} className={styles.weaponItem}>
                                    <p className={styles.type}>{nftItem.type}</p>

                                    {weaponList.map((weaponItem, index) => (
                                        <p key={index} className={styles.item}>{nftItem?.type}: {weaponItem?.skin}</p>
                                    ))}
                                </div>
                            )
                        })}
                    </section>
                </section>
            </div>

            <PopupConfirm
                loading={loadingButton}
                isUndead={isUndead}
                isVisible={isConfirm}
                isStandard={isStandard}
                itemSelected={itemSelected}
                confirmButtonLabel={confirmButtonLabel}
                onCancel={() => !loadingButton && setConfirm(false)}
                onConfirm={isUndead ? handleBuyCoffinByUndead : handleBuyCoffinByZbux}
            />
        </div>
    )
}

export default React.memo(BuyLoot);