import { handleActions } from "redux-actions";

const initialState = {
    allPoolsListRes: null,
    tokenPoolsListRes: null
};
  
let poolsListReducer = handleActions({
    /** SET POOLS **/
    SET_ALL_POOLS_LIST: (state, { payload }) => ({
        ...state,
        allPoolsListRes: payload
    }),

    /** SET TOKEN POOLS **/
    SET_TOKEN_POOLS_LIST: (state, { payload }) => ({
        ...state,
        tokenPoolsListRes: payload
    }),

}, initialState);

export default poolsListReducer;