import { createActions } from 'redux-actions';

import DepositApi from './api';

const { depositRequest, depositSuccess, depositFail } = createActions({
    DEPOSIT_REQUEST: () => {},
    DEPOSIT_SUCCESS: data => ({ data }),
    DEPOSIT_FAIL: error => ({ error }),
});

export const deposit = (body) => (dispatch) => {
    dispatch(depositRequest());

    return DepositApi.deposit(body)
        .then((data) => {
            dispatch(depositSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(depositFail(error));
            return error;
        });
}

