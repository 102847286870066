import oneX from './img/1x.png';
import twoX from './img/10x.png';
import threeX from './img/100x.png';

import common from './img/common.png';
import rare from './img/rare.png';
import epic from './img/epic.png';
import legendary from './img/legendary.png';

export const LOOT_CONFFINS = [
    { id: '0', flat: 'common', label: 'Common', icon: common },
    { id: '1', flat: 'rare', label: 'Rare', icon: rare },
    { id: '2', flat: 'epic', label: 'Epic', icon: epic },
    { id: '3', flat: 'legendary', label: 'Legendary', icon: legendary }
];

export const LIST = [
    {
        id: '0', flat: 'oneX', value: '1', zbuxPrice: 110, undeadPrice: 100, icon: oneX
    },
    {
        id: '1', flat: 'twoX', value: '10', zbuxPrice: 110, undeadPrice: 100, icon: twoX
    },
    {
        id: '2', flat: 'threeX', value: '100', zbuxPrice: 110, undeadPrice: 100, icon: threeX
    },
];

export const NFT_LIST = [
    {
        id: '0',
        type: 'M1911',
        weaponList: [
            'Basic - Red',
            'Basic - Red',
            'Basic - Blue',
            'Basic - Gray',
            'Basic - Green',
            'Camo - Forest',
            'Camo - Desert',
            'Camo - Ocean',
            'Camo - Clay'
        ]
    },
    {
        id: '1',
        type: 'Revolver',
        weaponList: [
            'Basic - Red',
            'Basic - Red',
            'Basic - Blue',
            'Basic - Gray',
            'Basic - Green',
            'Camo - Forest',
            'Camo - Desert',
            'Camo - Ocean',
            'Camo - Clay'
        ]
    },
    {
        id: '2',
        type: 'MP5',
        weaponList: [
            'Basic - Red',
            'Basic - Red',
            'Basic - Blue',
            'Basic - Gray',
            'Basic - Green',
            'Camo - Forest',
            'Camo - Desert',
            'Camo - Ocean',
            'Camo - Clay'
        ]
    }
]