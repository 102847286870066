import React from 'react';

import BottomGradientCard from '../../UsedTo/GradientCard/Bottom';

import { STATISTIC_LIST } from '../../constant';

import styles from './StatisticList.module.scss';

function StatisticList() {
    return (
        <div className={styles.container}>
            {STATISTIC_LIST.map(item => {
                return (
                    <BottomGradientCard
                        key={item.id}
                        item={item}
                        isMain={false}
                    />
                )
            })}
        </div>
    );
}

export default StatisticList;