import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, Field } from 'formik';
import * as yup from 'yup';
import cn from 'classnames';

import Modal from '../Modal';
import PasswordField from '../Form/PasswordField';
import Spinner from '../Spinner';

import { setToast, setChangePasswordModalVisible } from '../../store/modules/App/actions';
import { changePassword } from '../../store/modules/Wallet/actions';
import { disconnect } from '../../store/modules/User/actions';

import closeIcon from '../DownloadModal/img/close.png';

import styles from './ChangePasswordModal.module.scss';

// eslint-disable-next-line
const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/;

const validationSchema = yup.object().shape({
    // oldPassword: yup
    //     .string()
    //     .matches(regexPassword, 'OldPassword must contain lowercase, uppercase letters, special characters and at least 8 characters')
    //     .min(8)
    //     .required('Current Password is required'),
    newPassword: yup
        .string()
        .matches(regexPassword, 'Password must contain lowercase, uppercase letters and at least 8 characters')
        .min(8)
        .required('New Password is required'),
    rePassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), null], 'Re-Password must match New Password')
        .required('Re-Password is required')
});

function ChangePasswordModal(props) {
    const dispatch = useDispatch();

    const { isVisible, onCancel } = props;

    const initialValues = {
        // oldPassword: '',
        newPassword: '',
        rePassword: ''
    };

    const { isMobile } = useSelector(state => state?.app);
    const { requesting } = useSelector(state => state?.wallet?.changePasswordRes);

    const onSubmit = (values, cb) => {
        const body = {
            // oldPassword: values?.oldPassword,
            newPassword: values?.newPassword
        };

        dispatch(changePassword(body))
            .then((res) => {
                if (res?.status >= 500) {
                    dispatch(setToast({ title: 'FAILED', detail: 'Something went wrong' }))
                } else if (res?.status === 401) {
                    dispatch(setToast({ title: 'TOKEN IS EXPIRED', detail: '' }));
                    dispatch(setChangePasswordModalVisible(false));
                    dispatch(disconnect());
                } else if ((res?.status >= 400)) {
                    dispatch(setToast({ title: 'FAILED', detail: res?.data?.message }));
                } else {
                    cb.resetForm();
                    dispatch(setChangePasswordModalVisible(false));
                }
            })
            .catch(err => {
                dispatch(setToast({ title: 'FAILED', detail: 'Something went wrong' }))
                return err;
            })
    }

    return (
        <Modal
            visible={isVisible}
            closable={false}
            centered
            onCancel={onCancel}
            width={isMobile ? '86%' : '500rem'}
            wrapperClass={styles.container}
            wrapperBody={styles.wrapperBody}
        >
            <div className={styles.content}>
                <img src={closeIcon} alt="" className={styles.close} onClick={() => onCancel()}/>

                <header className={styles.header}>
                    <h2 className={styles.heading}>Change Password</h2>
                    <p className={styles.detail}>This password will be used in-game to log in to your Wagyu Account and connect to the game</p>
                </header>

                <section className={styles.form}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(e, cb) => onSubmit(e, cb)}
                    >
                        {formikProps => {
                            // eslint-disable-next-line
                            const { values, errors, touched, handleSubmit } = formikProps;

                            const disabled = !values.newPassword || !values.rePassword || errors?.newPassword || errors?.rePassword

                            return (
                                <Form>
                                    {/* <Field
                                        component={PasswordField}
                                        name={'oldPassword'}
                                        label={'Current Password'}
                                        placeholder={'Current password...'}
                                        required
                                    /> */}

                                    <Field
                                        component={PasswordField}
                                        name={'newPassword'}
                                        label={'New Password'}
                                        placeholder={'New password...'}
                                        required
                                    />

                                    <Field
                                        component={PasswordField}
                                        name={'rePassword'}
                                        label={'Confirm New Password'}
                                        placeholder={'Confirm new password...'}
                                        required
                                    />

                                    <div className={styles.btnContainer}>
                                        <div
                                            className={cn(styles.changeBtn, {
                                                [styles.notAllowed]: disabled
                                            })}
                                            onClick={() => {
                                                !disabled && !requesting && handleSubmit()
                                            }}
                                        >
                                            Change Password
                                            {requesting && <Spinner alignRight />}
                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </section>
            </div>
        </Modal>
    );
}

export default React.memo(ChangePasswordModal);