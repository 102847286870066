import prestige1 from '../../../../assets/dashboardLayout/prestige/1.png';
import prestige2 from '../../../../assets/dashboardLayout/prestige/2.png';
import prestige3 from '../../../../assets/dashboardLayout/prestige/3.png';
import prestige4 from '../../../../assets/dashboardLayout/prestige/4.png';
import prestige5 from '../../../../assets/dashboardLayout/prestige/5.png';

import prestige6 from '../../../../assets/dashboardLayout/prestige/6.png';
import prestige7 from '../../../../assets/dashboardLayout/prestige/7.png';
import prestige8 from '../../../../assets/dashboardLayout/prestige/8.png';
import prestige9 from '../../../../assets/dashboardLayout/prestige/9.png';
import prestige10 from '../../../../assets/dashboardLayout/prestige/10.png';

import prestige11 from '../../../../assets/dashboardLayout/prestige/11.png';
import prestige12 from '../../../../assets/dashboardLayout/prestige/12.png';
import prestige13 from '../../../../assets/dashboardLayout/prestige/13.png';
import prestige14 from '../../../../assets/dashboardLayout/prestige/14.png';
import prestige15 from '../../../../assets/dashboardLayout/prestige/15.png';

export const PRESTIGE_LIST = [
    prestige1,
    prestige2,
    prestige3,
    prestige4,
    prestige5,

    prestige6,
    prestige7,
    prestige8,
    prestige9,
    prestige10,

    prestige11,
    prestige12,
    prestige13,
    prestige14,
    prestige15
];

export const prestigeIcon = (prestige) => {
    return PRESTIGE_LIST[prestige];
}