import React from 'react';
import cn from 'classnames';

import styles from './PageSlug.module.scss';

function PageSlug(props) {
    const {
        slug,
        description,
        slugStyling,
        hasDevide,
        hasElement,
        wrappedClass,
        normal
    } = props;

    return (
        <div className={cn(styles.container, wrappedClass)}>
            <h2 className={cn(styles.slug, slugStyling)}>{slug}</h2>
            <p className={styles.description}>{description}</p>

            {hasElement ? (
                <div className={styles.elementContainer}>
                    {hasElement}
                </div>
            ) : (
                null
            )}

            {hasDevide ? (
                <div
                    className={cn(styles.devide, {
                        [styles.normal]: normal
                    })}
                />
            ) : (
                null
            )}
        </div>
    );
}

export default React.memo(PageSlug);