import download from 'assets/dashboardLayout/dashboard/download.png';
// import player from 'assets/dashboardLayout/dashboard/player.png';
import wallet from 'assets/dashboardLayout/dashboard/wallet.png';
// import weapon from '../../../../../assets/dashboardLayout/dashboard/weapon.png';
// import leaderBoards from '../../../../../assets/dashboardLayout/dashboard/leaderboards.png';

import {
    setDownloadModalVisible,
    // setSwapModalVisible
} from 'store/modules/App/actions';

import lootCoffin from 'pages/Dashboard/Pages/PurcharseLoot/img/loot-item.png';
import camoflagePistol from 'pages/Dashboard/Pages/Inventory/img/armory.png';

export const CARD_ITEMS = (isConnected) => {
    return [
        {
            key: 'download',
            icon: download,
            title: 'Download Undead Blocks',
            detail: 'Download Undead Blocks - available on PC and Mac!',
            directionLabel: 'Download Now',
            styling: { width: '142.5rem', height: '155rem', marginTop: '0rem' },
            action: setDownloadModalVisible,
            requireConnected: false
        },
        // {
        //     key: 'weapon',
        //     icon: weapon,
        //     title: 'WEAPON LENDING',
        //     detail: 'Weapon holders have the ability to lend their weapons to players all around the globe.',
        //     directionLabel: 'Coming Soon',
        //     styling: { width: '190rem', height: '105.5rem', marginTop: '23.5rem' },
        //     action: null,
        //     requireConnected: false
        // },
        // {
        //     key: 'leaderBoards',
        //     icon: leaderBoards,
        //     title: 'LEADERBOARDS',
        //     detail: 'View the latest leaderboard from Undead Blocks',
        //     directionLabel: 'View Now',
        //     styling: { width: '120.5rem', height: '124.5rem', marginTop: '13.5rem' },
        //     action: '/leaderboards',
        //     requireConnected: false
        // },
        {
            key: 'lootCoffins',
            icon: lootCoffin,
            title: 'Loot Coffins',
            detail: 'Use your Standard and Gold ZBUX to buy Loot Coffins - open them to reveal a Skin NFT that can be used in game!',
            directionLabel: 'Buy Now',
            styling: { width: '170rem', height: '80rem', marginTop: '30rem' },
            action: '/buy-loot-conffins',
            requireConnected: false
        },
        {
            key: 'wallet',
            icon: wallet,
            title: 'Wagyu Wallet',
            detail: 'Your secure location for all Wagyu finances.  Deposit or Withdraw Gold ZBUX and other crypto.',
            directionLabel: !isConnected ? 'Connect Wallet' : 'Open Wallet',
            styling: { width: '156rem', height: '122.5rem', marginTop: '20rem' },
            action: '/wallet',
            requireConnected: true
        },
        {
            key: 'myInventory',
            icon: camoflagePistol,
            title: 'My Inventory',
            detail: 'View your Loot Coffins, Weapon Skins, and Loadout NFTs all in one place',
            directionLabel: 'View Inventory',
            styling: { width: 'auto', height: '110rem', marginTop: '25rem' },
            action: '/my-inventory',
            requireConnected: false
        }
        // {
        //     key: 'player',
        //     icon: player,
        //     title: 'PLAYER STATS',
        //     detail: 'Historical statistics for all Wagyu Games, compare your stats to players around the world.',
        //     directionLabel: 'Coming Soon',
        //     styling: { width: '165rem', height: '125.5rem', marginTop: '13.5rem' },
        //     action: null,
        //     requireConnected: false
        // }
    ]
}
