import React, { memo } from 'react';

import banner from '../img/banner.png';

import styles from './BaseBanner.module.scss';

function BaseBanner() {
    return (
        <div className={styles.container}>
            <img src={banner} alt="banner" className={styles.banner} />
        </div>
    );
}

export default memo(BaseBanner);