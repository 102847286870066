import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniqBy } from 'lodash';

import { fetchAllOwnership } from 'store/modules/Ownership/actions';

function useWeaponSkins(props) {
    const dispatch = useDispatch();

    const { offset } = props;

    const [weaponSkinList, setWeaponSkinList] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);

    const loaded = useSelector(state => state?.app?.load?.loaded);
    const { logged } = useSelector(state => state?.user ?? {});
    const address = useSelector(state => state?.user?.userAccount?.accounts[0] ?? '')?.toLowerCase();

    useEffect(() => {
        if (!loaded) return;
        if (!logged) return;
        if (offset > 0) return;

        setFirstLoading(true);

        const params = {
            address,
            armoryType: 'WEAPON_SKIN',
            limit: 20,
            skip: 0
        }

        dispatch(fetchAllOwnership(params))
            .then(res => {
                setWeaponSkinList(res?.data);
                setHasMore(res?.data?.length > 9);
                setFirstLoading(false);
            })
            .catch(err => {
                setFirstLoading(false);
                return err;
            })
    }, [loaded, logged, offset, address, dispatch]);

    /* LOAD MORE */
    useEffect(() => {
        if (offset === 0) return;

        const params = {
            address,
            armoryType: 'WEAPON_SKIN',
            limit: 20,
            skip: offset
        }

        dispatch(fetchAllOwnership(params))
            .then(res => {
                setWeaponSkinList(prevState => uniqBy([...prevState, ...res?.data], 'id'));
                setHasMore(res?.data?.length > 9);
            })
            .catch(err => {
                return err;
            })
    }, [
        offset,
        address,
        dispatch
    ]);

    return { weaponSkinList, hasMore, firstLoading };
}

export default useWeaponSkins;