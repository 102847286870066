import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import Modal from 'components/Modal';

import styles from './HowItWork.module.scss';

function HowItWork(props) {
    const { isVisible, onCancel } = props;

    const { isMobile } = useSelector(state => state?.app);

    return (
        <Modal
            visible={isVisible}
            closable={false}
            centered 
            onCancel={onCancel}
            width={isMobile ? '86%' : '640rem'}
            wrapperClass={styles.container}
            wrapperBody={styles.wrapperBody}
        >
            <div className={styles.content}>
                <header className={styles.header}>
                    <h2 className={styles.heading}>HOW IT WORKS:</h2>
                </header>

                <div className={styles.box}>
                    <p className={styles.sectionRow}>
                        1. Select the skins you would like to Burn.
                    </p>

                    <p className={styles.sectionRow}>
                        2. Click "BURN" and follow the Metamask/ImmutableX prompts and signatures to complete the burning of the skins
                    </p>

                    <p className={styles.sectionRow}>
                        3. Once the skins are burned, they will add to the amount of Burned Skins Needed (example: from 0/12 to 1/12)
                    </p>

                    <p className={styles.sectionRow}>
                        4. Once you have reached the amount of Burned Skins Needed (example: 12/12 or more), you can click the "BOOST" button to unlock your Free
                        Rarity Booster Loot Coffin.  
                    </p>

                    <p className={styles.noteRow}>
                        **The Rarity Booster Loot Coffin is guaranteed to contain a weapon or character skin of the next highest rarity (example: Burn 9 Rare
                        Skins, and get an Epic Rarity Booster Coffin which guarantees an Epic level Weapon or Character Skin)"
                    </p>
                </div>

                <div
                    className={cn(styles.buttonContainer)}
                >
                    <section className={styles.cancel} onClick={() => onCancel()}>
                        Close
                    </section>
                </div>
            </div>
        </Modal>
    );
}

export default React.memo(HowItWork);