import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ethers } from 'ethers';

import backArrow from '../../assets/landingLayout/back-arrow.png';

import WalletForm from './WalletForm/Form';
import AccountForm from './AccountForm/AccountForm';

import { goToApp } from '../../utils/common';
import LandingLayout from '../../layouts/LandingLayout';
import { PAGE_DASHBOARD_PATH } from '../../router/constants';
import { loginAccount } from '../../store/modules/Wallet/actions';
import {
    disconnect,
    setLogged,
    setUserNonce,
    // setUserAccounts,
    fetchUserInfoSuccess,
    // fetchUserInfo
} from '../../store/modules/User/actions';
import { setToast } from '../../store/modules/App/actions';


import styles from './LoginAccount.module.scss';

function LoginAccount() {
    const dispatch = useDispatch();
    const history = useHistory();

    const [goAppDelay, setGoAppDelay] = useState(false);
    const [captcha, setCaptcha] = useState('');
    const [isSuccess, setSuccess] = useState(false); // eslint-disable-next-line
    const [isError, setError] = useState(''); // eslint-disable-next-line

    const [isLoginWallet, setLoginWallet] = useState(true);

    // const { isConnect } = useSelector(state => state?.user?.connectWallet);
    const userToken = useSelector(state => state?.user?.userAccount?.token ?? '');
    const { username } = useSelector(state => state?.user?.userInfo?.result ?? {});

    const isGoApp = goToApp(userToken, username);

    useEffect(() => {
        dispatch(disconnect());
    }, [dispatch]);

    const handleOnBack = () => {
        if (isLoginWallet) return history.push('/');

        return setLoginWallet(true);
    };

    const handleGoToApp = () => {
        window.localStorage.setItem('dateNow', Date.now());
        setGoAppDelay(true);
        dispatch(setLogged(true));

        setTimeout(() => {
            setGoAppDelay(false);

            history.push(PAGE_DASHBOARD_PATH);
        }, 1500)
    };

    const handleOnLoginWithAccount = () => {
        setLoginWallet(false);
    };

    const handleOnSubmitWithAccount = (value, cb) => {
        const body = {
            account: value?.account?.trim(),
            password: value?.password,
            captcha
        }

        dispatch(loginAccount(body))
            .then(res => {
                if (res?.status >= 500) {
                    dispatch(setToast({ title: 'FAILED', detail: 'Something went wrong' }))
                    setError(new Date().getTime());
                } else if ((res?.status >= 400)) {
                    setError(new Date().getTime());
                    dispatch(setToast({ title: 'FAILED', detail: res?.data?.message }));
                } else {
                    setSuccess(true);
                    cb.resetForm();

                    if (ethers.utils.isAddress(res?.data?.wallet?.address)) {
                        setLoginWallet(true);
                        dispatch(setToast({ title: 'LOGIN FAILED', detail: 'Account connected wallet, please use login with wallet.' }));
                        dispatch(disconnect());
                    } else {
                        window.localStorage.setItem('tempoAddress', res?.data?.wallet?.address);
                        window.localStorage.setItem('nonce', res?.data?.wallet?.nonce);
                        window.localStorage.setItem('dateNow', Date.now());
                        
                        dispatch(setUserNonce({ nonce: res?.data?.wallet?.nonce }));
                        dispatch(fetchUserInfoSuccess({ ...res?.data?.wallet }));
                        dispatch(setLogged(true));

                        history.push(PAGE_DASHBOARD_PATH);
                    }
                }
            })
            .catch(err => err)
    }

    return (
        <LandingLayout wrapperClass={styles.wrapperClass}>
            <div className={styles.container}>
                <section
                    className={styles.header}
                    onClick={() => handleOnBack()}
                >
                    <img src={backArrow} alt="" className={styles.backArrow} />
                    <span>Back</span>
                </section>

                <section className={styles.contentBox}>
                    {isLoginWallet ? (
                        <WalletForm
                            isGoApp={isGoApp}
                            goAppDelay={goAppDelay}
                            onGoToApp={handleGoToApp}
                            onLoginWithAccount={handleOnLoginWithAccount}
                        />
                    ) : (
                        <AccountForm
                            isGoApp={isGoApp}
                            isSuccess={isSuccess}
                            isError={isError}
                            captcha={captcha}

                            onSubmit={handleOnSubmitWithAccount}
                            setCaptcha={setCaptcha}
                        />
                    )}
                </section>
            </div>
        </LandingLayout>
    );
}

export default LoginAccount;