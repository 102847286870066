import { handleActions } from "redux-actions";

const initialState = {
    inventoriesRes: {
        error: null,
        result: null,
        requesting: false
    },
    storeRes: {
        error: null,
        result: null,
        requesting: false
    },
    buyCoffinRes: {
        error: null,
        result: null,
        requesting: false
    },
    unlockCoffinRes: {
        error: null,
        result: null,
        requesting: false
    },
    rewardsRes: {
        error: null,
        result: null,
        requesting: false
    },
    rewardsType: {
        commonRewards: [],
        rareRewards: [],
        epicRewards: [],
        legendaryRewards: []
    },
    inventorySignatureRes: {
        error: null,
        result: null,
        requesting: false
    },
    buyPackageRes: {
        error: null,
        result: null,
        requesting: false,
    },
    sessionInfoRes: {
        error: null,
        result: null,
        requesting: false,
    },
};

let lootConffinReducer = handleActions({
    /** INVENTORIES **/
    FETCH_INVENTORIES_REQUEST: (state) => ({
        ...state,
        inventoriesRes: {
            ...state.inventoriesRes,
            requesting: true
        },
    }),
    FETCH_INVENTORIES_SUCCESS: (state, { payload }) => ({
        ...state,
        inventoriesRes: {
            ...state.inventoriesRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    FETCH_INVENTORIES_FAIL: (state, { payload }) => ({
        ...state,
        inventoriesRes: {
            ...state.inventoriesRes,
            requesting: false,
            error: payload.error
        }
    }),

    /** STORE **/
    FETCH_STORE_REQUEST: (state) => ({
        ...state,
        storeRes: {
            ...state.storeRes,
            requesting: true
        },
    }),
    FETCH_STORE_SUCCESS: (state, { payload }) => ({
        ...state,
        storeRes: {
            ...state.storeRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    FETCH_STORE_FAIL: (state, { payload }) => ({
        ...state,
        storeRes: {
            ...state.storeRes,
            requesting: false,
            error: payload.error
        }
    }),

    /** BUY COFFIN **/
    BUY_COFFIN_REQUEST: (state) => ({
        ...state,
        buyCoffinRes: {
            ...state.buyCoffinRes,
            requesting: true
        },
    }),
    BUY_COFFIN_SUCCESS: (state, { payload }) => ({
        ...state,
        buyCoffinRes: {
            ...state.buyCoffinRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    BUY_COFFIN_FAIL: (state, { payload }) => ({
        ...state,
        buyCoffinRes: {
            ...state.buyCoffinRes,
            requesting: false,
            error: payload.error
        }
    }),

    /** UNLOCK COFFIN **/
    UNLOCK_COFFIN_REQUEST: (state) => ({
        ...state,
        unlockCoffinRes: {
            ...state.unlockCoffinRes,
            requesting: true
        },
    }),
    UNLOCK_COFFIN_SUCCESS: (state, { payload }) => ({
        ...state,
        unlockCoffinRes: {
            ...state.unlockCoffinRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    UNLOCK_COFFIN_FAIL: (state, { payload }) => ({
        ...state,
        unlockCoffinRes: {
            ...state.unlockCoffinRes,
            requesting: false,
            error: payload.error
        }
    }),

    /** FETCH REWARDS **/
    FETCH_REWARDS_REQUEST: (state) => ({
        ...state,
        rewardsRes: {
            ...state.rewardsRes,
            requesting: true
        },
    }),
    FETCH_REWARDS_SUCCESS: (state, { payload }) => ({
        ...state,
        rewardsRes: {
            ...state.rewardsRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    FETCH_REWARDS_FAIL: (state, { payload }) => ({
        ...state,
        rewardsRes: {
            ...state.rewardsRes,
            requesting: false,
            error: payload.error
        }
    }),

    /** REWARDS TYPE **/
    GET_COMMON_REWARDS: (state, { payload }) => ({
        ...state,
        rewardsType: {
            ...state.rewardsType,
            commonRewards: payload
        }
    }),

    GET_RARE_REWARDS: (state, { payload }) => ({
        ...state,
        rewardsType: {
            ...state.rewardsType,
            rareRewards: payload
        }
    }),

    GET_EPIC_REWARDS: (state, { payload }) => ({
        ...state,
        rewardsType: {
            ...state.rewardsType,
            epicRewards: payload
        }
    }),

    GET_LEGENDARY_REWARDS: (state, { payload }) => ({
        ...state,
        rewardsType: {
            ...state.rewardsType,
            legendaryRewards: payload
        }
    }),

    /** GET INVENTORY SIGNATURE **/
    GET_INVENTORY_SIGNATURE_REQUEST: (state) => ({
        ...state,
        inventorySignatureRes: {
            ...state.inventorySignatureRes,
            requesting: true
        },
    }),
    GET_INVENTORY_SIGNATURE_SUCCESS: (state, { payload }) => ({
        ...state,
        inventorySignatureRes: {
            ...state.inventorySignatureRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    GET_INVENTORY_SIGNATURE_FAIL: (state, { payload }) => ({
        ...state,
        inventorySignatureRes: {
            ...state.inventorySignatureRes,
            requesting: false,
            error: payload.error
        }
    }),

    /** BUY PACKAGE **/
    BUY_PACKAGE_REQUEST: (state) => ({
        ...state,
        buyPackageRes: {
            ...state.buyPackageRes,
            requesting: true
        }
    }),

    BUY_PACKAGE_SUCCESS: (state, { payload }) => ({
        ...state,
        buyPackageRes: {
            ...state.buyPackageRes,
            result: payload,
            requesting: false,
            error: null
        }
    }),

    BUY_PACKAGE_FAIL: (state, { payload }) => ({
        ...state,
        buyPackageRes: {
            ...state.buyPackageRes,
            requesting: false,
            error: payload
        }
    }),

    /** GET SESSION INFO **/
    GET_SESSION_INFO_REQUEST: (state) => ({
        ...state,
        sessionInfoRes: {
            ...state.sessionInfoRes,
            requesting: true
        }
    }),

    GET_SESSION_INFO_SUCCESS: (state, { payload }) => ({
        ...state,
        sessionInfoRes: {
            ...state.sessionInfoRes,
            result: payload,
            requesting: false,
            error: null
        }
    }),

    GET_SESSION_INFO_FAIL: (state, { payload }) => ({
        ...state,
        sessionInfoRes: {
            ...state.sessionInfoRes,
            requesting: false,
            error: payload
        }
    }),
}, initialState);

export default lootConffinReducer;