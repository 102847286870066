import React from 'react';

import closeIcon from '../DownloadModal/img/close.png';

import Spinner from '../Spinner';

import styles from './ClaimPopup.module.scss';

function Step1(props) {
    const { onClose } = props;

    return (
        <div className={styles.step1}>
            <img src={closeIcon} alt="" className={styles.close} onClick={onClose}/>

            <div className={styles.main}>
                <h2 className={styles.heading}>Receiving Transaction</h2>
                <p className={styles.description}>Approve the transaction in your wallet to swap cryptocurrency.</p>
            </div>

            <div className={styles.buttonContainer}>
                <Spinner width={27} />
            </div>
        </div>
    );
}

export default Step1;