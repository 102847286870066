import {
    PAGE_DASHBOARD_PATH,
    PAGE_PLAYERS_STATS_PATH,
    PAGE_LEADER_BOARD_PATH,
    // PAGE_MY_WEAPONS_PATH,
    PAGE_MY_INVENTORY_PATH,
    PAGE_WALLET
} from '../../Router/constants';

import dashboardActive from 'assets/dashboardLayout/navigation/home-active.png';
import walletActive from 'assets/dashboardLayout/navigation/wallet-active.png';
import downloadActive from 'assets/dashboardLayout/navigation/download-active.png';
import statsActive from 'assets/dashboardLayout/navigation/stats-active.png';
// import weaponActive from 'assets/dashboardLayout/navigation/weapon-active.png';
import leaderBoardActive from 'assets/dashboardLayout/navigation/leader-board-active.png';
import inventoryActive from 'assets/dashboardLayout/navigation/inventory-active.png';

import dashboardDeactive from 'assets/dashboardLayout/navigation/home-deactive.png';
import walletDeactive from 'assets/dashboardLayout/navigation/wallet-deactive.png';
import downloadDeactive from 'assets/dashboardLayout/navigation/download-deactive.png';
import statsDeactive from 'assets/dashboardLayout/navigation/stats-deactive.png';
// import weaponDeactive from 'assets/dashboardLayout/navigation/weapon-deactive.png';
import leaderBoardDeActive from 'assets/dashboardLayout/navigation/leader-board-deactive.png';
import inventoryDeactive from 'assets/dashboardLayout/navigation/inventory-deactive.png';

import {
    setLogoutModalVisible,
    setDownloadModalVisible,
    setChangePasswordModalVisible,
    setEditProfileModalVisible,
    // setSwapModalVisible
} from 'store/modules/App/actions';

import { isPlayerStatsDisabled } from 'utils/common';

export const NAVIGATION_ITEMS = [
    {
        key: '/dashboard',
        label: 'Dashboard',
        path: PAGE_DASHBOARD_PATH,
        iconActive: dashboardActive,
        iconDeactive: dashboardDeactive,
        styling: { width: '16.5rem', height: '14.5rem', marginRight: '17rem' },
        disabled: false,
        action: null,
        requireConnected: false,
    },
    {
        key: '/wallet',
        label: 'Wagyu Wallet',
        path: PAGE_WALLET,
        iconActive: walletActive,
        iconDeactive: walletDeactive,
        styling: { width: '15r.5em', height: '14rem', marginRight: '17rem' },
        disabled: false,
        action: null,
        requireConnected: true,
    },
    {
        key: '/download-beta',
        label: 'Download Undead Blocks',
        path: '',
        iconActive: downloadActive,
        iconDeactive: downloadDeactive,
        styling: { width: '14rem', height: '15rem', marginRight: '17rem' },
        disabled: false,
        action: setDownloadModalVisible,
        requireConnected: false,
    },
    {
        key: '/player-stats',
        label: 'Player Stats',
        path: PAGE_PLAYERS_STATS_PATH,
        iconActive: statsActive,
        iconDeactive: statsDeactive,
        styling: { width: '15.5rem', height: '15.5rem', marginRight: '17rem' },
        disabled: isPlayerStatsDisabled,
        action: null,
        requireConnected: false,
    },
    // {
    //     key: '/my-weapons',
    //     label: 'My Weapon Loadouts',
    //     path: PAGE_MY_WEAPONS_PATH,
    //     iconActive: weaponActive,
    //     iconDeactive: weaponDeactive,
    //     styling: { width: '19.5rem', height: '20.5rem', marginRight: '12rem' },
    //     disabled: false,
    //     action: null,
    //     requireConnected: false,
    // },
    {
        key: '/my-inventory',
        label: 'My Inventory',
        path: PAGE_MY_INVENTORY_PATH,
        iconActive: inventoryActive,
        iconDeactive: inventoryDeactive,
        styling: { width: '19.5rem', height: '20.5rem', marginRight: '12rem' },
        disabled: false,
        action: null,
        requireConnected: false,
    },
    {
        key: '/leaderboards',
        label: 'Leaderboards',
        path: PAGE_LEADER_BOARD_PATH,
        iconActive: leaderBoardActive,
        iconDeactive: leaderBoardDeActive,
        styling: { width: '22rem', height: '17.5rem', marginRight: '10rem' },
        disabled: false,
        action: null,
        requireConnected: false,
    },
]

export const NAVIGATION_OPTIONS = [
    { key: '0', label: 'Edit Profile', action: setEditProfileModalVisible },
    { key: '1', label: 'Change Password', action: setChangePasswordModalVisible },
    { key: '2', label: 'Logout', action: setLogoutModalVisible }
]