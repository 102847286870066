import React, { useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Tooltip } from 'antd';

import {
    PENDING,
    PROCESSING,
    SUCCESS,
    CANCEL,
    SIGNED,
    DEPOSITED,
    EXPIRED
} from '../constants';

import Spinner from '../../Spinner';
import WagyuLoader from '../../WagyuLoader';

import blueViewIcon from '../img/blue-view.png';
import successIcon from '../img/success.png';
import failedIcon from '../img/failed.png';

import { getTransactionUrl } from '../../../utils/common';
import { formatNumber } from '../../../utils/bigNumber';

import { setToast } from 'store/modules/App/actions';
import { freezeWarning } from 'constants/common';

import styles from './TransactionList.module.scss';

function TransactionList(props) {
    const dispatch = useDispatch();

    const {
        list = [],
        onClaim,
        loading,
        hasMore,
        setOffset
    } = props;

    const swapInfoLoading = useSelector(state => state?.withdraw?.swapInfoRes?.requesting);
    const claimLoading = useSelector(state => state?.withdraw?.swapOutRes?.requesting);
    const swapOutRes = useSelector(state => state?.withdraw?.swapOutRes?.result ?? null);
    const withDrawnFinishLoading = useSelector(state => state?.withdraw?.withDrawnFinishRes?.requesting);
    const isFreeze = useSelector(state => state?.withdraw?.userStatisticRes?.result?.isFreeze);

    // LOAD MORE
    const observer = useRef();
    const lastElement = useCallback(node => {
        if (loading) return;

        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && hasMore) {
                    setOffset(prevState => prevState + 10);
                }
            });
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        });

        if (node) observer.current.observe(node);
    }, [loading, hasMore, setOffset]);

    return (
        <div className={styles.container}>
            {list && Array.isArray(list) && list.map((e, i) => {
                const viewTxIcon = (e.status === SUCCESS || e.status === DEPOSITED || swapOutRes);
                const txLink = getTransactionUrl(e?.chainId);

                const amount = e.amount < 0.0001 ? formatNumber(e.amount, '', 8) : formatNumber(e.amount, '', 4);
                const swapAmount = e.swapAmount < 0.0001 ? formatNumber(e.swapAmount, '', 8) : formatNumber(e.swapAmount, '', 4);
                const txInfo = e.isDeposit 
                    ? `Swap ${swapAmount} ${e.swapToken} for ${amount} Gold ZBUX`
                    : `Swap ${amount} Gold  for ${swapAmount} ${e.swapToken}`;
                const reasonMess = e.status === CANCEL ? e?.cancelReason : (e.status === EXPIRED && EXPIRED);

                return (
                    <div
                        key={e.id}
                        className={styles.item}
                        ref={list.length === i + 1 ? lastElement : null}
                    >
                        {e.status === EXPIRED || e.status === CANCEL ? (
                            <Tooltip title={reasonMess} color={'#3075ff'}>
                                <section className={styles.left}>
                                    <p className={styles.transactionInfo}>
                                        {txInfo}
                                    </p>
        
                                    <span className={styles.waitingStatus}>{(e.status === PROCESSING || e.status === PENDING) && '(Validating...)'}</span>
        
                                    {viewTxIcon && (
                                        <img
                                            src={blueViewIcon}
                                            alt=""
                                            className={styles.blueViewIcon}
                                            onClick={() => window.open(`${txLink}/${e.transactionHash}`, '_blank')}
                                        />
                                    )}
                                </section>
                            </Tooltip>
                        ) : (
                            <section className={styles.left}>
                                <p className={styles.transactionInfo}>
                                    {txInfo}
                                </p>
    
                                <span className={styles.waitingStatus}>{(e.status === PROCESSING || e.status === PENDING) && '(Validating...)'}</span>
    
                                {viewTxIcon && e?.transactionHash && (
                                    <img
                                        src={blueViewIcon}
                                        alt=""
                                        className={styles.blueViewIcon}
                                        onClick={() => window.open(`${txLink}/${e.transactionHash}`, '_blank')}
                                    />
                                )}
                            </section>
                        )}

                        <section className={styles.right}>
                            <div className={styles.actions}>
                                {(e.status === PROCESSING || e.status === PENDING) && <Spinner custom />}
                                {(e.status === SUCCESS || e.status === DEPOSITED) && <img src={successIcon} alt="" className={styles.successIcon} />}
                                {(e.status === CANCEL || e.status === EXPIRED) && (
                                    <Tooltip title={reasonMess} color={'#3075ff'}>
                                        <img src={failedIcon} alt="" className={styles.failedIcon} />
                                    </Tooltip>
                                )}
                            </div>

                            {e.status === SIGNED && (
                                <div
                                    className={cn(styles.claimBtn)}
                                    onClick={() => {
                                        if (isFreeze) return dispatch(setToast({ title: 'FAILED', detail: freezeWarning }));
                                        if (swapInfoLoading || claimLoading || withDrawnFinishLoading || loading) return;

                                        onClaim(e);
                                    }}
                                >
                                    {loading ? <Spinner custom /> : 'Claim'}
                                </div>
                            )}
                        </section>
                    </div>
                )
            })}

            {loading && (
                <div className={styles.loadMoreContainer}>
                    <WagyuLoader customStyle={styles.loadMore} />
                </div>
            )}
        </div>
    );
}

export default TransactionList;