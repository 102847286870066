import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';

import styles from './TournamentCountdown.module.scss';

function TournamentCountdown(props) {
    const { conditional, startTime, endTime } = props;

    const [timer, setTimer] = useState(null);

    const { loaded } = useSelector(state => state?.app?.load ?? {});

    const countDownDate = useMemo(() => {
        if (!loaded) return;

        return moment(startTime).valueOf() > Date.now() && moment(startTime).valueOf() < moment(endTime).valueOf()
            ? new Date(startTime).getTime()
            : new Date(endTime).getTime();
    }, [loaded, startTime, endTime]);

    useEffect(() => {
        if (!loaded) return;
        // if (!data) return;

        if (countDownDate < Date.now()) return setTimer({ days: '00', hours: '00', minutes: '00', seconds: '00' });

        const interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = countDownDate - now;

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            setTimer({
                days: days < 10 ? `0${days}` : days,
                hours: hours < 10 ? `0${hours}` : hours,
                minutes: minutes < 10 ? `0${minutes}` : minutes,
                seconds: seconds < 10 ? `0${seconds}` : seconds
            });

            if (distance < 0) {
                clearInterval(interval);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, [loaded, countDownDate]);

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>
                {conditional().sub}
            </h2>

            {timer && (
                <p className={styles.value}>
                    {
                        !countDownDate
                            ? `${moment(countDownDate).startOf('day').fromNow()}`
                            : `${timer?.days}:${timer?.hours}:${timer?.minutes}:${timer?.seconds}`
                    }
                </p>
            )}
        </div>
    );
}

export default React.memo(TournamentCountdown);