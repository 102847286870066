import usdtAbi from '../abi/usdt_ether.json';
// import usdtBscAbi from '../abi/usdt_bsc.json';
// import usdtPolygonAbi from '../abi/usdt_polygon.json';
import usdcAbi from '../abi/usdc.json';
import busdAbi from '../abi/busd.json';
import undeadAbi from '../abi/undead.json';

export const goToApp = (userToken = '', username = '') => {
  if (!userToken || !username) return false;

  return true;
}

export const BSC = 'bsc';
export const ETH = 'ether';
export const POLYGON = 'polygon';

export const BSC_CHAIN_ID = process.env.REACT_APP_DEV === '1' ? '0x61' : '0x38';
export const ETH_CHAIN_ID = process.env.REACT_APP_DEV === '1' ? '0x5' : '0x1';
export const POLYGON_CHAIN_ID = process.env.REACT_APP_DEV === '1' ? '0x13881' : '0x89';

export const BSC_RPC_MAINNET = [
  'https://bsc-dataseed1.binance.org',
  'https://bsc-dataseed2.binance.org',
  'https://bsc-dataseed3.binance.org',
  'https://bsc-dataseed4.binance.org',
  'https://bsc-dataseed1.defibit.io',
  'https://bsc-dataseed2.defibit.io',
  'https://bsc-dataseed3.defibit.io',
  'https://bsc-dataseed4.defibit.io',
  'https://bsc-dataseed1.ninicoin.io',
  'https://bsc-dataseed2.ninicoin.io',
  'https://bsc-dataseed3.ninicoin.io',
  'https://bsc-dataseed4.ninicoin.io',
  'wss://bsc-ws-node.nariox.org'
]

export const networks = {
  ether: {
    chainId: process.env.REACT_APP_DEV === '1' ? `0x${Number(5).toString(16)}` : `0x${Number(1).toString(16)}`,
    chainName: process.env.REACT_APP_DEV === '1' ? 'Goerli Testnet' : 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: [
      process.env.REACT_APP_DEV === '1'
        ? process.env.REACT_APP_ETHER_WEB3_PROVIDER_TESTNET_HTTPS
        : process.env.REACT_APP_ETHER_WEB3_PROVIDER_MAINNET_HTTPS
    ],
    blockExplorerUrls: [
      process.env.REACT_APP_DEV === '1' ? 'https://goerli.etherscan.io' : 'https://etherscan.io/'
    ]
  },

  ropsten: {
    chainId: `0x${Number(3).toString(16)}`,
    chainName: 'Ethereum Ropsten Testnet',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: ['https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: ['https://ropsten.etherscan.io']
  },

  polygon: {
    chainId: process.env.REACT_APP_DEV === '1' ? `0x${Number(80001).toString(16)}` : `0x${Number(137).toString(16)}`,
    chainName: process.env.REACT_APP_DEV === '1' ? 'Matic Mumbai Testnet' : 'Polygon Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
    },
    rpcUrls: [
      process.env.REACT_APP_DEV === '1'
        ? process.env.REACT_APP_POLYGON_WEB3_PROVIDER_TESTNET_HTTPS
        : process.env.REACT_APP_POLYGON_WEB3_PROVIDER_MAINNET_HTTPS,
    ],
    blockExplorerUrls: [
      process.env.REACT_APP_DEV === '1' ? 'https://mumbai.polygonscan.com/' : 'https://polygonscan.com/'
    ]
  },

  bsc: {
    chainId: process.env.REACT_APP_DEV === '1' ? `0x${Number(97).toString(16)}` : `0x${Number(56).toString(16)}`,
    chainName: process.env.REACT_APP_DEV === '1' ? 'Binance Smart Chain Testnet' : 'Binance Smart Chain Mainnet',
    nativeCurrency: {
      name: 'Binance Chain Native Token',
      symbol: 'BNB',
      decimals: 18
    },
    rpcUrls: [
      process.env.REACT_APP_DEV === '1'
        ? process.env.REACT_APP_BSC_WEB3_PROVIDER_TESTNET_HTTPS
        : process.env.REACT_APP_BSC_WEB3_PROVIDER_MAINNET_HTTPS,
    ],
    blockExplorerUrls: [
      process.env.REACT_APP_DEV === '1' ? 'https://testnet.bscscan.com' : 'https://bscscan.com'
    ]
  }
};

export const getTransactionUrl = (chainId) => {
  switch (chainId.toString()) {
    case '5':
    case '0x5':
      return 'https://goerli.etherscan.io/tx'

    case '1':
    case '0x1':
      return 'https://etherscan.io/tx'

    case '97':
    case '0x61':
      return 'https://testnet.bscscan.com/tx'

    case '56':
    case '0x38':
      return 'https://bscscan.com/tx'

    case '80001':
    case '0x13881':
      return 'https://mumbai.polygonscan.com/tx'

    case '137':
    case '0x89':
      return 'https://polygonscan.com/tx'
  
    default:
      return 'https://goerli.etherscan.io/tx';
  }
}

export const geTokenAbiOfBlockchain = (token) => {
  switch (token) {
    case 'USDT':
      return usdtAbi;
    case 'USDC':
      return usdcAbi;
    case 'BUSD':
      return busdAbi;
    case 'UNDEAD':
      return undeadAbi;
    default:
      return usdtAbi;
  }
}

export const getBlockchainName = (chainId) => {
  switch (chainId) {
    case '0x5':
    case '0x1':
      return ETH;
    case '0x61':
    case '0x38':
      return BSC;
    case '0x89':
    case '0x13881':
      return POLYGON;
    default:
      return ETH;
  }
}

export const isPlayerStatsDisabled = process.env.REACT_APP_PLAYER_STATS_DISABLED === 'true';