import { handleActions } from "redux-actions";
import { persistReducer } from 'redux-persist';
import localForage from 'localforage';

const initialState = {
    userImxInfo: {
        error: null,
        result: null,
        requesting: false
    },
    depositRes: {
        error: null,
        result: null,
        requesting: false
    },
    transferRes: {
        error: null,
        result: null,
        requesting: false
    },
    batchNftTransferRes: {
        error: null,
        result: null,
        requesting: false
    },
    withDrawPrepareRes: {
        error: null,
        result: null,
        requesting: false
    },
    withDrawCompleteRes: {
        error: null,
        result: null,
        requesting: false
    },
    isLoadingNft: false,
    randomWeponRes: {
        requesting: false,
        done: false
    },
    hasConnectedEverRes: {
        error: null,
        result: null,
        requesting: false
    }
};
  
let imxReducer = handleActions({
    LOADING_NFT: (state, { payload }) => ({
        ...state,
        isLoadingNft: payload
    }),
    LOADING_REQUEST: (state) => ({
        ...state,
        randomWeponRes: {
            ...state.randomWeponRes,
            requesting: true
        }
    }),
    LOADING_SUCCESS: (state) => ({
        ...state,
        randomWeponRes: {
            ...state.randomWeponRes,
            requesting: false,
            done: true
        }
    }),

    /** USER IMX INFO **/
    CONNECT_IMX_REQUEST: (state) => ({
        ...state,
        userImxInfo: {
            ...state.userImxInfo,
            requesting: true
        },
    }),
    CONNECT_IMX_SUCCESS: (state, { payload }) => ({
        ...state,
        userImxInfo: {
            ...state.userImxInfo,
            result: payload,
            requesting: false,
            error: null
        },
    }),
    CONNECT_IMX_FAIL: (state, { payload }) => ({
        ...state,
        userImxInfo: {
            ...state.userImxInfo,
            requesting: false,
            error: payload
        }
    }),

    /** DEPOSIT **/
    IMX_DEPOSIT_REQUEST: (state) => ({
        ...state,
        depositRes: {
            ...state.depositRes,
            requesting: true
        },
    }),
    IMX_DEPOSIT_SUCCESS: (state, { payload }) => ({
        ...state,
        depositRes: {
            ...state.depositRes,
            result: payload,
            requesting: false,
            error: null
        },
    }),
    IMX_DEPOSIT_FAIL: (state, { payload }) => ({
        ...state,
        depositRes: {
            ...state.depositRes,
            requesting: false,
            error: payload
        }
    }),

    /** TRANSFER **/
    IMX_TRANSFER_REQUEST: (state) => ({
        ...state,
        transferRes: {
            ...state.transferRes,
            requesting: true
        },
    }),
    IMX_TRANSFER_SUCCESS: (state, { payload }) => ({
        ...state,
        transferRes: {
            ...state.transferRes,
            result: payload,
            requesting: false,
            error: null
        },
    }),
    IMX_TRANSFER_FAIL: (state, { payload }) => ({
        ...state,
        transferRes: {
            ...state.transferRes,
            requesting: false,
            error: payload
        }
    }),

    /** BATCH NFT TRANSFER **/
    IMX_BATCH_NFT_TRANSFER_REQUEST: (state) => ({
        ...state,
        batchNftTransferRes: {
            ...state.batchNftTransferRes,
            requesting: true
        },
    }),
    IMX_BATCH_NFT_TRANSFER_SUCCESS: (state, { payload }) => ({
        ...state,
        batchNftTransferRes: {
            ...state.batchNftTransferRes,
            result: payload,
            requesting: false,
            error: null
        },
    }),
    IMX_BATCH_NFT_TRANSFER_FAIL: (state, { payload }) => ({
        ...state,
        batchNftTransferRes: {
            ...state.batchNftTransferRes,
            requesting: false,
            error: payload
        }
    }),

    /** PREPARE WITHDRAW **/
    IMX_PREPARE_WITH_DRAW_REQUEST: (state) => ({
        ...state,
        withDrawPrepareRes: {
            ...state.withDrawPrepareRes,
            requesting: true
        },
    }),
    IMX_PREPARE_WITH_DRAW_SUCCESS: (state, { payload }) => ({
        ...state,
        withDrawPrepareRes: {
            ...state.withDrawPrepareRes,
            result: payload,
            requesting: false,
            error: null
        },
    }),
    IMX_PREPARE_WITH_DRAW_FAIL: (state, { payload }) => ({
        ...state,
        withDrawPrepareRes: {
            ...state.withDrawPrepareRes,
            requesting: false,
            error: payload
        }
    }),

    /** COMPLETE WITHDRAW **/
    IMX_COMPLETE_WITH_DRAW_REQUEST: (state) => ({
        ...state,
        withDrawCompleteRes: {
            ...state.withDrawCompleteRes,
            requesting: true
        },
    }),
    IMX_COMPLETE_WITH_DRAW_SUCCESS: (state, { payload }) => ({
        ...state,
        withDrawCompleteRes: {
            ...state.withDrawCompleteRes,
            result: payload,
            requesting: false,
            error: null
        },
    }),
    IMX_COMPLETE_WITH_DRAW_FAIL: (state, { payload }) => ({
        ...state,
        withDrawCompleteRes: {
            ...state.withDrawCompleteRes,
            requesting: false,
            error: payload
        }
    }),

    /** HAS CONNECTED EVER **/
    HAS_CONNECTED_EVER_REQUEST: (state) => ({
        ...state,
        hasConnectedEverRes: {
            ...state.hasConnectedEverRes,
            requesting: true
        },
    }),
    HAS_CONNECTED_EVER_SUCCESS: (state, { payload }) => ({
        ...state,
        hasConnectedEverRes: {
            ...state.hasConnectedEverRes,
            result: payload,
            requesting: false,
            error: null
        },
    }),
    HAS_CONNECTED_EVER_FAIL: (state, { payload }) => ({
        ...state,
        hasConnectedEverRes: {
            ...state.hasConnectedEverRes,
            requesting: false,
            error: payload
        }
    }),
    HAS_CONNECTED_EVER_CLEAR: (state) => ({
        ...state,
        hasConnectedEverRes: {
            ...state.hasConnectedEverRes,
            error: null,
            result: null,
            requesting: false
        }
    })
}, initialState);

imxReducer = persistReducer({
    key: 'imx',
    storage: localForage,
    whitelist: ['userImxInfo']
}, imxReducer);

export default imxReducer;