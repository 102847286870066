import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import swapIcon from './img/swap/swap-icon.png';
import zBuxIcon from './img/swap/gold-zbux.png';

import { getDropdownItem } from './utils';

import Dropdown from '../Form/Dropdown';
import NumberField from '../Form/NumberField';
import Spinner from '../Spinner';

import { formatNumber, fromWeiDecimals, convertBigNumber } from '../../utils/bigNumber';
import useRefresh from '../../hooks/useRefresh';
import { setToast } from 'store/modules/App/actions';
import { freezeWarning } from 'constants/common';

import styles from './WagyuSwap.module.scss';

function Step2(props) {
    const dispatch = useDispatch();

    const {
        list,
        onChange,
        onSubmit,
        onApprove,
        onSwitchNetwork,
        disableWithdraw
    } = props;

    const [firstItem, setFirstItem] = useState(list[0]);
    const [balance, setBalance] = useState('');
    const [zbuxBalance, setZbuxBalance] = useState('');
    const [keyDropdown, setKeyDropdown] = useState('');
    const [isSwap, setSwap] = useState(false);
    const [isNotApprove, setNotApprove] = useState(false);
    const [coinInputFocus, setCoinInputFocus] = useState(false);
    const [zBuxInputFocus, setZbuxInputFocus] = useState(false);

    const chainId = useSelector(state => state?.user?.chainId ?? 1);
    const totalBalance = useSelector(state => state?.user?.userAccount?.balance ?? '0');
    const totalZbuxBalance = useSelector(state => state?.withdraw?.userStatisticRes?.result?.totalZBux ?? '0');
    const { tokensInfo } = useSelector(state => state?.user?.userAccount ?? {});

    const { swapModalVisible } = useSelector(state => state?.app);

    // LOADING BLOCK
    const swapInLoading = useSelector(state => state?.withdraw?.swapInRes?.requesting);
    const withdrawLoading = useSelector(state => state?.withdraw?.withDrawnRes?.requesting);
    const checkRateLoading = useSelector(state => state?.withdraw?.checkRateRes?.requesting);
    const approveLoading = useSelector(state => state?.user?.approveRes?.requesting);
    const switchNetworkLoading = useSelector(state => state?.user?.switchNetwork?.requesting);
    const isFreeze = useSelector(state => state?.withdraw?.userStatisticRes?.result?.isFreeze);

    const tokenItem = !firstItem?.isBaseCoin && tokensInfo[firstItem?.networkName?.toLowerCase()]?.[firstItem?.blockchain];

    const { slowRefresh } = useRefresh();

    // const tokenBalanceLoading = useMemo(() => {
    //     return !isSwap && checkRateLoading;
    // }, [isSwap, checkRateLoading]);
    // const zbuxBalanceLoading = useMemo(() => {
    //     return isSwap && checkRateLoading;
    // }, [isSwap, checkRateLoading]);

    // Balance just to display
    const balanceDisplay = firstItem?.isBaseCoin
        ? formatNumber(fromWeiDecimals(totalBalance, firstItem?.decimals), '~', 4)
        : formatNumber(fromWeiDecimals(tokenItem?.balance, firstItem?.decimals), '~', 4);
    const zbuxBalanceDisplay = formatNumber(totalZbuxBalance, '~', 4);

    // Input value not must includes thousands_separator, this is the value that send to request
    const balanceInput = firstItem?.isBaseCoin
        ? formatNumber(fromWeiDecimals(totalBalance), '', 8, '')
        : formatNumber(fromWeiDecimals(tokenItem?.balance, firstItem?.decimals), '~', 8, '');
    const zbuxBalanceInput = formatNumber(convertBigNumber(totalZbuxBalance), '', 8, '');

    // Set itemDropdown when dropdown is selected
    useEffect(() => {
        if (!keyDropdown) return;

        return setFirstItem(list.find(e => e.label === keyDropdown));
    }, [keyDropdown, list]);

    // Set itemDropdown default with chainId when dropdown is not selected
    useEffect(() => {
        if (keyDropdown) return;

        getDropdownItem(list, chainId, setFirstItem);
    }, [chainId, keyDropdown, list]);

    /* The tokens is not base coin that must check isApprove first */
    useEffect(() => {
        if (firstItem?.isBaseCoin) return setNotApprove(false);

        if (Number(tokenItem?.isApprove) > 0) {
            setNotApprove(false);
        } else {
            setNotApprove(true);
        }
    }, [firstItem, tokenItem]);

    /*
        Recall api check rate after each 30 seconds to fetch newest rate
        Listening slowRefresh variable
    */
    useEffect(() => {
        if (!swapModalVisible) return;
        if (!Number(balance) || !Number(zbuxBalance)) return;

        if (isSwap) {
            const params = {
                amount: Number(zbuxBalance),
                token: firstItem?.value,
                fromZbux: true
            }

            return onChange(params, setBalance);
        }

        const params = {
            amount: Number(balance),
            token: firstItem?.value,
            fromZbux: false
        }

        return onChange(params, setZbuxBalance); // eslint-disable-next-line
    }, [
        swapModalVisible,
        slowRefresh
    ]);

    const isDisabled = useMemo(() => {
        if (swapInLoading || withdrawLoading || checkRateLoading || approveLoading || switchNetworkLoading) return true;

        if (isNotApprove) return false;

        if (!Number(balance) || !Number(zbuxBalance)) return true;
        
        // if (firstItem?.isBaseCoin || (!firstItem?.isBaseCoin && !isNotApprove) || !firstItem) {
        //     return checkRateLoading || swapInLoading || withdrawLoading;
        // }

        if (!isSwap) {
            if (disableWithdraw) return true;

            return Number(balance) > Number(balanceInput);
        }

        return Number(zbuxBalance) > Number(zbuxBalanceInput);
    }, [
        isSwap,
        balance,
        zbuxBalance,
        balanceInput,
        zbuxBalanceInput,
        disableWithdraw,
        swapInLoading,
        withdrawLoading,
        checkRateLoading,
        approveLoading,
        switchNetworkLoading,
        // firstItem,
        isNotApprove
    ]);

    const handleOnChangeFirstItem = (value) => {
        setBalance('');
        setZbuxBalance('');

        onSwitchNetwork(value, {
            setFirstItem,
            setKeyDropdown
        });
    };

    const handleOnChangeBalance = (e) => {
        if (coinInputFocus) {
            setBalance(e.formattedValue);

            const params = {
                amount: Number(e.formattedValue),
                token: firstItem?.value,
                fromZbux: false
            }

            onChange(params, setZbuxBalance);
        } else {
            setBalance(e.formattedValue);
        }
    };

    const handleOnChangeZbuxBalance = (e) => {
        if (zBuxInputFocus) {
            setZbuxBalance(e.formattedValue);

            const params = {
                amount: Number(e.formattedValue),
                token: firstItem?.value,
                fromZbux: true
            }

            onChange(params, setBalance);
        } else {
            setZbuxBalance(e.formattedValue);
        }
    };

    const handleOnMax = () => {
        if (isSwap) {
            setZbuxBalance(zbuxBalanceInput)

            const params = {
                amount: Number(zbuxBalanceInput),
                token: firstItem?.value,
                fromZbux: true
            }

            return onChange(params, setBalance);
        }

        setBalance(balanceInput);

        const params = {
            amount: Number(balanceInput),
            token: firstItem?.value,
            fromZbux: false
        }

        return onChange(params, setZbuxBalance);
    };

    const handleSwap = () => {
        setSwap(!isSwap);
    };

    // const disable = () => {
    //     if (!isSwap && disableWithdraw) {
    //         return true;
    //     }

    //     if (firstItem?.isBaseCoin || (!firstItem?.isBaseCoin && !isNotApprove) || !firstItem) {
    //         return isDisabled || checkRateLoading || swapInLoading || withdrawLoading;
    //     }

    //     return approveLoading
    // }

    const freezeToast = () => {
        dispatch(setToast({ title: 'FAILED', detail: freezeWarning }));
    };

    return (
        <div className={styles.step2}>
            <div
                className={cn(styles.middle, {
                    [styles.isSwap]: isSwap
                })}
            >
                <section className={styles.row}>
                    <div className={styles.rowHeader}>
                        <Dropdown
                            items={list}
                            itemSelected={firstItem}
                            onChange={handleOnChangeFirstItem}
                        />

                        <div className={styles.balanceContainer}>
                            Balance: {balanceDisplay}
                        </div>
                    </div>

                    <div className={cn(styles.rowNumberInput, {
                        // [styles.loading]: (!isSwap && coinInputFocus) && zbuxBalanceLoading
                    })}>
                        <NumberField
                            value={balance}
                            onChange={handleOnChangeBalance}
                            // loading={(!isSwap && coinInputFocus) && zbuxBalanceLoading}
                            onFocus={setCoinInputFocus}
                        />

                        {!isSwap && (
                            <div className={styles.maxBtn} onClick={() => handleOnMax()}>
                                MAX
                            </div>
                        )}
                    </div>
                </section>

                <section className={styles.swapContainer}>
                    <img src={swapIcon} alt="" className={styles.swapIcon} onClick={() => handleSwap()} />
                </section>

                <section className={styles.row}>
                    <div className={styles.rowHeader}>
                        <div className={styles.zBuxHeader}>
                            <img src={zBuxIcon} alt="" className={styles.zBuxIcon} />
                            <p className={styles.zBuxLabel}>Gold ZBUX</p>
                        </div>

                        <div className={styles.balanceContainer}>
                            Balance: {zbuxBalanceDisplay}
                        </div>
                    </div>

                    <div className={cn(styles.rowNumberInput, styles.zbuxRow, {
                        // [styles.loading]: (isSwap && zBuxInputFocus) && tokenBalanceLoading
                    })}>
                        <NumberField
                            value={zbuxBalance}
                            onChange={handleOnChangeZbuxBalance}
                            // loading={(isSwap && zBuxInputFocus) && tokenBalanceLoading}
                            onFocus={setZbuxInputFocus}
                        />

                        {isSwap && (
                            <div className={styles.maxBtn} onClick={() => handleOnMax()}>
                                MAX
                            </div>
                        )}
                    </div>
                </section>
            </div>

            <div
                className={cn(styles.buttonContainer, {
                    [styles.isDisabled]: isDisabled
                })}
                onClick={() => {
                    if (firstItem?.isBaseCoin || (!firstItem?.isBaseCoin && !isNotApprove) || !firstItem) {
                        if (!isSwap && disableWithdraw) return;
                        if (isFreeze) return freezeToast();
                        if (isDisabled) return;

                        return onSubmit(isSwap, firstItem, balance, zbuxBalance);
                    }

                    if (approveLoading || switchNetworkLoading) return;

                    return onApprove(firstItem);
                }}
            >
                {(swapInLoading || withdrawLoading || approveLoading || switchNetworkLoading) ? (
                    <Spinner />
                ) : (
                    !firstItem?.isBaseCoin && isNotApprove
                        ? 'APPROVE'
                        : 'SWAP'
                )}
            </div>
        </div>
    );
}

export default React.memo(Step2);