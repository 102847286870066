import React, { useState } from 'react';
import cn from 'classnames';

import { FILTER } from '../constants';

import styles from './Filter.module.scss';

function Filter() {
    const [filter, setFilter] = useState(FILTER[0]);

    const handleOnFilter = (value) => {
        setFilter(value);
    };

    return (
        <div className={styles.container}>
            {FILTER.map((item) => {
                return (
                    <div
                        key={item.label}
                        className={cn(styles.filterItem, {
                            [styles.active]: filter.value === item.value
                        })}
                        onClick={() => handleOnFilter(item)}
                    >
                        <p className={styles.value}>{item.label}</p>
                    </div>
                )
            })}
        </div>
    );
}

export default Filter;