import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import blockIcon from './img/block.png';
// import cancelIcon from './img/cancel.svg';
import close from '../MobileMenu/close.png';

import { setToast } from '../../store/modules/App/actions';

import styles from './WagyuToast.module.scss';

function WagyuToast() {
    const dispatch = useDispatch();

    const { hasToast = {}, isMobile } = useSelector(state => state.app);

    useEffect(() => {
        if (!hasToast) return;
    
        toast(
            <div className={styles.toastContent}>
                <section className={styles.toastLeftContent}>
                    <img src={blockIcon} alt="block" className={styles.block} />
                </section>

                <section className={styles.toastRightContent}>
                    <h2>{hasToast?.title}</h2>
                    <p>{hasToast?.detail}</p>
                </section>

                <section className={styles.closeContainer}>
                    <img
                        src={close}
                        alt="close"
                        className={styles.close}
                        onClick={() => dispatch(setToast(null))}
                    />
                </section>
            </div>
        )
    }, [dispatch, hasToast]);

    return (
        <div className={styles.container}>
            <ToastContainer
                position={isMobile ? 'top-center' : 'top-right'}
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={() => {}}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={1}
                toastClassName={styles.toastContainer}
                closeButton={null}
                onClick={() => dispatch(setToast(null))}
            />
        </div>
    );
}

export default WagyuToast;