import { createActions, createAction } from 'redux-actions';

import WalletApi from './api';

import { setUserToken } from '../User/actions';

export const clearLoginError = createAction('CLEAR_LOGIN_ERROR');

const { registerAccountRequest, registerAccountSuccess, registerAccountFail } = createActions({
    REGISTER_ACCOUNT_REQUEST: () => {},
    REGISTER_ACCOUNT_SUCCESS: data => ({ data }),
    REGISTER_ACCOUNT_FAIL: error => ({ error }),
});

export const registerAccount = (body) => (dispatch) => {
    dispatch(registerAccountRequest());

    return WalletApi.register(body)
        .then((data) => {
            dispatch(registerAccountSuccess(data));
            dispatch(setUserToken(data?.data?.token));
            return data;
        })
        .catch(error => {
            dispatch(registerAccountFail(error));
            return error;
        });
}

const { checkExistsRequest, checkExistsSuccess, checkExistsFail } = createActions({
    CHECK_EXISTS_REQUEST: () => {},
    CHECK_EXISTS_SUCCESS: data => ({ data }),
    CHECK_EXISTS_FAIL: error => ({ error }),
});

export const checkExists = (params) => (dispatch) => {
    dispatch(checkExistsRequest());

    return WalletApi.checkExists(params)
        .then(({ data }) => {
            dispatch(checkExistsSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(checkExistsFail(error));
            return error;
        });
}

const { loginAccountRequest, loginAccountSuccess, loginAccountFail } = createActions({
    LOGIN_ACCOUNT_REQUEST: () => {},
    LOGIN_ACCOUNT_SUCCESS: data => ({ data }),
    LOGIN_ACCOUNT_FAIL: error => ({ error }),
});

export const loginAccount = (body) => (dispatch) => {
    dispatch(loginAccountRequest());

    return WalletApi.login(body)
        .then((data) => {
            dispatch(loginAccountSuccess(data));
            dispatch(setUserToken(data?.data?.token));
            return data;
        })
        .catch(error => {
            dispatch(loginAccountFail(error));
            return error;
        });
}

const { assignAddressRequest, assignAddressSuccess, assignAddressFail } = createActions({
    ASSIGN_ADDRESS_REQUEST: () => {},
    ASSIGN_ADDRESS_SUCCESS: data => ({ data }),
    ASSIGN_ADDRESS_FAIL: error => ({ error }),
});

export const assignAddress = (body) => (dispatch) => {
    dispatch(assignAddressRequest());

    return WalletApi.assignAddress(body)
        .then((data) => {
            dispatch(assignAddressSuccess(data));
            dispatch(setUserToken(data?.data?.token));
            return data;
        })
        .catch(error => {
            dispatch(assignAddressFail(error));
            return error;
        });
}

const { changePasswordRequest, changePasswordSuccess, changePasswordFail } = createActions({
    CHANGE_PASSWORD_REQUEST: () => {},
    CHANGE_PASSWORD_SUCCESS: data => ({ data }),
    CHANGE_PASSWORD_FAIL: error => ({ error }),
});

export const changePassword = (body) => (dispatch) => {
    dispatch(changePasswordRequest());

    return WalletApi.changePassword(body)
        .then((data) => {
            dispatch(changePasswordSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(changePasswordFail(error));
            return error;
        });
}

const { forgotPasswordRequest, forgotPasswordSuccess, forgotPasswordFail } = createActions({
    FORGOT_PASSWORD_REQUEST: () => {},
    FORGOT_PASSWORD_SUCCESS: data => ({ data }),
    FORGOT_PASSWORD_FAIL: error => ({ error }),
});

export const forgotPassword = (body) => (dispatch) => {
    dispatch(forgotPasswordRequest());

    return WalletApi.forgotPassword(body)
        .then((data) => {
            dispatch(forgotPasswordSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(forgotPasswordFail(error));
            return error;
        });
}

const { resetPasswordRequest, resetPasswordSuccess, resetPasswordFail } = createActions({
    RESET_PASSWORD_REQUEST: () => {},
    RESET_PASSWORD_SUCCESS: data => ({ data }),
    RESET_PASSWORD_FAIL: error => ({ error }),
});

export const resetPassword = (body) => (dispatch) => {
    dispatch(resetPasswordRequest());

    return WalletApi.resetPassword(body)
        .then((data) => {
            dispatch(resetPasswordSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(resetPasswordFail(error));
            return error;
        });
}

const { verifyEmailRequest, verifyEmailSuccess, verifyEmailFail } = createActions({
    VERIFY_EMAIL_REQUEST: () => {},
    VERIFY_EMAIL_SUCCESS: data => ({ data }),
    VERIFY_EMAIL_FAIL: error => ({ error }),
});

export const verifyEmail = (params) => (dispatch) => {
    dispatch(verifyEmailRequest());

    return WalletApi.verifyEmail(params)
        .then((data) => {
            dispatch(verifyEmailSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(verifyEmailFail(error));
            return error;
        });
}

const { finishVerifyRequest, finishVerifySuccess, finishVerifyFail } = createActions({
    FINISH_VERIFY_REQUEST: () => {},
    FINISH_VERIFY_SUCCESS: data => ({ data }),
    FINISH_VERIFY_FAIL: error => ({ error }),
});

export const finishVerify = (id, body) => (dispatch) => {
    dispatch(finishVerifyRequest());

    return WalletApi.finishVerify(id, body)
        .then((data) => {
            dispatch(finishVerifySuccess(data));
            // dispatch(setUserInfo(data?.data));
            return data;
        })
        .catch(error => {
            dispatch(finishVerifyFail(error));
            return error;
        });
}

const { fetchLeaderBoardRequest, fetchLeaderBoardSuccess, fetchLeaderBoardFail } = createActions({
    FETCH_LEADER_BOARD_REQUEST: () => {},
    FETCH_LEADER_BOARD_SUCCESS: data => ({ data }),
    FETCH_LEADER_BOARD_FAIL: error => ({ error }),
});

export const fetchLeaderBoard = (tournamentId, page, email) => (dispatch) => {
    dispatch(fetchLeaderBoardRequest());

    return WalletApi.getLeaderBoard({ tournamentId, page, email })
        .then((data) => {
            dispatch(fetchLeaderBoardSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(fetchLeaderBoardFail(error));
            throw error;
        });
}

export const { twitchValidateRequest, twitchValidateSuccess, twitchValidateFail } = createActions({
    TWITCH_VALIDATE_REQUEST: () => {},
    TWITCH_VALIDATE_SUCCESS: data => ({ data }),
    TWITCH_VALIDATE_FAIL: error => ({ error }),
});

export const twitchValidate = (code) => (dispatch) => {
    dispatch(twitchValidateRequest());

    return WalletApi.twitchValidate(code)
        .then((data) => {
            dispatch(twitchValidateSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(twitchValidateFail(error));
            return error;
        });
}