export const BREAKPOINTS = {
    SM_MAX: 767,
    MD_MIN: 768,
    MD_MAX: 1023,
    LG_MIN: 1024
};

export const THEME_TYPES = {
    LIGHT: 'light',
    DARK: 'dark'
};