import http from '../../../services/http';

export default class Withdraw {
    static getUserStatistic(address) {
        return http.get(`/withdraw/user-statistic/${address}`);
    }

    static getUserHistories({
        walletId,
        limit = 20,
        offset = 0
    }) {
        return http.get(`/withdraw/user-histories/${walletId}`, {
            params: {
                walletId,
                limit,
                offset
            }
        });
    }

    static checkRate(params) {
        return http.get('/withdraw/check-rate', {
            params: {
                amount: params.amount,
                token: params.token,
                fromZbux: params.fromZbux
            }
        });
    }

    static withDraw(body) {
        return http.post('/withdraw', body);
    }

    static getSwapInfo(swapId) {
        return http.get(`/withdraw/swap-info/${swapId}`);
    }

    static withDrawFinish(id, body) {
        return http.patch(`/withdraw/success/${id}`, body)
    }

    static getExchangeStatus() {
        return http.get('/setting-withdraws/check-deposit-disabled');
    }
}
