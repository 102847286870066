export const CONTRACT_TIMEOUT = 5;
export const ERROR_CODE_TIME_OUT = 408;
export const MESSAGE_TIMEOUT_WITHDRAW = 'There was a timeout while attempting to withdraw your transaction.';

export const IMX_STATUS = {
    eth: 'eth',
    imx: 'imx',
    success: '"success',
    preparing_withdrawal: 'preparing_withdrawal',
    withdrawable: 'withdrawable',
}
