import React, { useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useHistories from './useHistories';
import TransactionList from './TransactionList';

import Modal from '../Modal';
import ClaimModal from '../ClaimPopup';

import {
    setClaimModalVisible,
    setReloadHistories,
    setToast
} from 'store/modules/App/actions';
import { getSwapInfo, handleSwapOut, withDrawFinish } from 'store/modules/Withdraw/actions';
import { switchNetwork } from 'store/modules/User/actions';
import { getAbiBaseCoin, getContractAddress } from 'utils/contractHelpers';
import { getBlockchainName } from 'utils/common';

import styles from './WagyuHistory.module.scss';
import { isEmpty } from 'lodash';

function WagyuHistory(props) {
    const dispatch = useDispatch();

    const { onCancel } = props;

    const [offset, setOffset] = useState(0);
    const [claimInfo, setClaimInfo] = useState(null);
    const [transactionHash, setTransactionHash] = useState('');

    const { isMobile, claimModalVisible } = useSelector(state => state?.app ?? {});
    const address = useSelector(state => state?.user?.userAccount?.accounts[0] ?? '');
    const chainId = useSelector(state => state?.user?.chainId ?? 1);
    const loadingList = useSelector(state => state?.withdraw?.userHistoriesRes?.requesting);
    const { recentModalVisible } = useSelector(state => state?.app ?? {});

    const { historiesList, hasMore } = useHistories({ offset, isVisible: recentModalVisible, setOffset });

    const onTransactionHash = (hash) => {
        setTransactionHash(hash);
    }

    const onReceipt = (receipt, error = null) => {
        setClaimInfo(receipt);
    }

    const handleOnSwitchNetwork = (value) => {
        dispatch(switchNetwork(value.chainId, getBlockchainName(value.blockchain)))
            .then(res => {
                if (!res) return dispatch(setToast({ title: 'SWITCH NETWORK FAILED', detail: 'User rejected the request.' }));
            })
            .catch(err => {
                dispatch(setToast({ title: 'SWITCH NETWORK FAILED', detail: 'Something went wrong' }));
                return err;
            })
    }

    const handleOnClaim = async (item) => {
        setTransactionHash('');
        setClaimInfo(null);

        if (item.chainId === `0x${Number(chainId).toString(16)}`) {
            dispatch(setClaimModalVisible(true));

            dispatch(getSwapInfo(item.id))
                .then(res => {
                    const abi = getAbiBaseCoin(chainId);
                    const contractAddress = getContractAddress(chainId);

                    try {
                        const contract = new window.web3.eth.Contract(abi, contractAddress);

                        const params = {
                            swapId: res?.swapId,
                            token: res?.token,
                            amount: res?.amount,
                            sigTime: res?.sigTime,
                            signatures: res?.signatures
                        }

                        const callbacks = {
                            onReceipt,
                            onTransactionHash
                        }

                        dispatch(handleSwapOut(contract, address, params, callbacks))
                            .then((res) => {
                                if (res?.code === 4001) {
                                    dispatch(setToast(({ title: 'SWAP FAILED', detail: res?.message })));
                                    dispatch(setClaimModalVisible(false));
                                } else if (res?.transactionHash) {
                                    dispatch(withDrawFinish(item?.id, { transactionHash: res?.transactionHash }))
                                        .then(() => {
                                            dispatch(setReloadHistories(Date.now()))
                                        })
                                        .catch(err => err)
                                } else {
                                    dispatch(setClaimModalVisible(false));
                                    dispatch(setToast(({ title: 'SWAP FAILED', detail: 'Something went wrong'})));
                                }
                            })
                            .catch(err => {
                                dispatch(setToast(({ title: 'SWAP FAILED', detail: 'Something went wrong'})));
                                return err
                            })
                    } catch (error) {
                        return error;
                    }
                })
                .catch(err => {
                    return err
                })
        } else {
            handleOnSwitchNetwork(item);
        }
    }

    return (
        <div>
            <Modal
                visible={recentModalVisible}
                closable={false}
                centered
                onCancel={onCancel}
                width={isMobile ? '95%' : '470rem'}
                wrapperClass={styles.container}
                wrapperBody={styles.wrapperBody}
                custom={true}
            >
                <div className={styles.content}>
                    <header className={styles.header}>
                        <h2 className={styles.heading}>Recent Claims</h2>
                    </header>

                    <div className={styles.divide} />

                    <div className={styles.listContainer}>
                        <p className={styles.title}>Claim History</p>

                        {isEmpty(historiesList) ? (
                            <p className={styles.empty}>You don't have any Claims transactions</p>
                        ) : (
                            <TransactionList
                                list={historiesList}
                                onClaim={handleOnClaim}
                                loading={loadingList}
                                setOffset={setOffset}
                                hasMore={hasMore}
                            />
                        )}
                    </div>

                    <div className={styles.buttonContainer} onClick={onCancel}>
                        Close
                    </div>
                </div>
            </Modal>

            <ClaimModal
                isVisible={claimModalVisible}
                onCancel={() => dispatch(setClaimModalVisible(false))}
                transactionHash={transactionHash}
                claimInfo={claimInfo}
            />
        </div>
    );
}

export default memo(WagyuHistory);
