import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import logo from '../../assets/img/main-logo.png';
import bottomRayMobile from './img/mobile/ray-bottom.png';
import topRayMobile from './img/mobile/ray-top.png';
import bottomRayDesktop from './img/desktop/ray-bottom.png';
import topRayDesktop from './img/desktop/ray-top.png';
import character from './img/characters.png';

import style from './Loader.module.scss';

export default function Loader(props) {
    const {isVisible} = props;

    const { isMobile } = useSelector(state => state.app);

    return (
        <div className={cn(style.container, {
            [style.containerVisible]: isVisible
        })}>
            <div className={style.bgMask}/>
                <div
                    className={style.bg}
                >
                    <div
                        className={style.mainBg}
                    >
                        {isMobile ? (
                            <section className={style.rayMobile}>
                                <img src={topRayMobile} alt="topRayMobile" className={style.topRayMobile} />
                                <img src={bottomRayMobile} alt="bottomRayMobile" className={style.bottomRayMobile} />
                            </section>
                        ) : (
                            <section className={style.rayDesktop}>
                                <img src={topRayDesktop} alt="topRayDesktop" className={style.topRayDesktop} />
                                <img src={bottomRayDesktop} alt="bottomRayDesktop" className={style.bottomRayDesktop} />
                            </section>
                        )}

                        {isMobile ? (
                            <section className={style.characterMobile}>
                                <img src={character} alt="topCharacterMobile" className={style.topCharacterMobile} />
                                <img src={character} alt="bottomCharacterMobile" className={style.bottomCharacterMobile} />
                            </section>
                        ) : (
                            <section className={style.characterDesktop}>
                                <img src={character} alt="topCharacterDesktop" className={style.topCharacterDesktop} />
                                <img src={character} alt="bottomCharacterDesktop" className={style.bottomCharacterDesktop} />
                            </section>
                        )}
                        <img src={logo} alt="logo" className={style.logo} />
                    </div>
                </div>
        </div>
    )
}