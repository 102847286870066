import React, { useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import cn from 'classnames';

import useWeaponSkin from './useWeaponSkins';

import PageSlug from 'components/PageSlug';
import WagyuLoader from 'components/WagyuLoader';
import EmptyCoffin from 'components/EmptyCoffin';

import ViewModal from './ViewModal';

import rarityBoosterIcon from 'assets/dashboardLayout/rarity-booster.png';
import { PAGE_BOOSTER } from '../../Router/constants';

import styles from './WeaponSkins.module.scss';

function MyArmory() {
    const history = useHistory();

    const [offset, setOffset] = useState(0);
    const [item, setItem] = useState(null);

    const { weaponSkinList, hasMore, firstLoading } = useWeaponSkin({ offset });

    const loadingList = useSelector(state => state?.ownership?.ownershipRes?.requesting);

    const observer = useRef();
    const lastElement = useCallback(node => {
        if (loadingList) return;

        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && hasMore) {
                    setOffset(prevState => prevState + 20);
                }
            });
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        });

        if (node) observer.current.observe(node);
    }, [loadingList, hasMore, setOffset]);

    const handleShowItem = useCallback((value) => {
        setItem(value);
    }, []);

    const handleOnClickBooster = () => {
        history.push(PAGE_BOOSTER);
    };

    return (
        <div className={styles.container}>
            <PageSlug
                slug={'My Weapon Skins'}
                description={''}
                hasDevide
                normal
            />

            {firstLoading ? (
                <div className={styles.firstLoad}>
                    <WagyuLoader />
                </div>
            ) : (
                <div className={styles.parent}>
                    <section className={styles.header}>
                        <img src={rarityBoosterIcon} alt="" className={styles.boosterButton} onClick={() => handleOnClickBooster()} />

                        <p className={styles.boosterLabel}>Trade in skins for a new Weapon Skin with a higher Rarity</p>
                    </section>

                    <div className={cn('', {
                        [styles.content]: !isEmpty(weaponSkinList),
                        [styles.emptyData]: isEmpty(weaponSkinList)
                    })}>
                        {!isEmpty(weaponSkinList) ? weaponSkinList.map((item, index) => (
                            <div
                                key={item.id}
                                className={styles.item}
                                style={{ background: `url(${item.thumbnail}) no-repeat center / 100%` }}
                                ref={weaponSkinList.length === index + 1 ? lastElement : null}
                                onClick={() => handleShowItem(item)}
                            >
                                <p className={styles.name}>{item.rewardWeapon} - {item.rewardSkin}</p>
                            </div>
                        )) : (
                            <EmptyCoffin
                                label={'No Weapon Skins currently held in this wallet, find Weapon Skins in Loot Coffins here:'}
                            />
                        )}
                    </div>
                </div>
            )}

            {!firstLoading && loadingList && (
                <div className={styles.loadMoreContainer}>
                    <WagyuLoader customStyle={styles.loadMore} />
                </div>
            )}

            <ViewModal
                item={item}
                onCancel={() => handleShowItem(null)}
            />
        </div>
    )
}

export default React.memo(MyArmory);