import { createActions } from 'redux-actions';

import WithdrawApi from './api';
import { swapIn, swapOut } from '../../../utils/callHelpers';

const { getUserStatisticRequest, getUserStatisticSuccess, getUserStatisticFail } = createActions({
    GET_USER_STATISTIC_REQUEST: () => {},
    GET_USER_STATISTIC_SUCCESS: data => ({ data }),
    GET_USER_STATISTIC_FAIL: error => ({ error }),
});

export const getUserStatistic = (address) => (dispatch) => {
    dispatch(getUserStatisticRequest());

    return WithdrawApi.getUserStatistic(address)
        .then(({ data }) => {
            dispatch(getUserStatisticSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(getUserStatisticFail(error));
            return error;
        });
}

const { getUserHistoriesRequest, getUserHistoriesSuccess, getUserHistoriesFail } = createActions({
    GET_USER_HISTORIES_REQUEST: () => {},
    GET_USER_HISTORIES_SUCCESS: data => ({ data }),
    GET_USER_HISTORIES_FAIL: error => ({ error }),
});

export const getUserHistories = (walletId, limit = 20, offset = 0) => (dispatch) => {
    dispatch(getUserHistoriesRequest());

    return WithdrawApi.getUserHistories({ walletId, limit, offset })
        .then(({ data }) => {
            dispatch(getUserHistoriesSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(getUserHistoriesFail(error));
            return error;
        });
}

const { getSwapInfoRequest, getSwapInfoSuccess, getSwapInfoFail } = createActions({
    GET_SWAP_INFO_REQUEST: () => {},
    GET_SWAP_INFO_SUCCESS: data => ({ data }),
    GET_SWAP_INFO_FAIL: error => ({ error }),
});

export const getSwapInfo = (swapId) => (dispatch) => {
    dispatch(getSwapInfoRequest());

    return WithdrawApi.getSwapInfo(swapId)
        .then(({ data }) => {
            dispatch(getSwapInfoSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(getSwapInfoFail(error));
            return error;
        });
}

const { checkRateRequest, checkRateSuccess, checkRateFail } = createActions({
    CHECK_RATE_REQUEST: () => {},
    CHECK_RATE_SUCCESS: data => ({ data }),
    CHECK_RATE_FAIL: error => ({ error }),
});

export const checkRate = (params) => (dispatch) => {
    dispatch(checkRateRequest());

    return WithdrawApi.checkRate(params)
        .then((data) => {
            dispatch(checkRateSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(checkRateFail(error));
            return error;
        });
}

const { withDrawRequest, withDrawSuccess, withDrawFail } = createActions({
    WITH_DRAW_REQUEST: () => {},
    WITH_DRAW_SUCCESS: data => ({ data }),
    WITH_DRAW_FAIL: error => ({ error }),
});

export const withDraw = (body) => (dispatch) => {
    dispatch(withDrawRequest());

    return WithdrawApi.withDraw(body)
        .then((data) => {
            dispatch(withDrawSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(withDrawFail(error));
            return error;
        });
}

const { withDrawFinishRequest, withDrawFinishSuccess, withDrawFinishFail } = createActions({
    WITH_DRAW_FINISH_REQUEST: () => {},
    WITH_DRAW_FINISH_SUCCESS: data => ({ data }),
    WITH_DRAW_FINISH_FAIL: error => ({ error }),
});

export const withDrawFinish = (id, body) => (dispatch) => {
    dispatch(withDrawFinishRequest());

    return WithdrawApi.withDrawFinish(id, body)
        .then((data) => {
            dispatch(withDrawFinishSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(withDrawFinishFail(error));
            return error;
        });
}

const { swapInRequest, swapInSuccess, swapInFail } = createActions({
    SWAP_IN_REQUEST: () => {},
    SWAP_IN_SUCCESS: data => (data),
    SWAP_IN_FAIL: error => (error),
});

export const handleSwapIn = (presentContract, address, params, callbacks) => (dispatch) => {
    dispatch(swapInRequest());

    return swapIn(presentContract, address, params, callbacks)
        .then((data) => {
            dispatch(swapInSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(swapInFail(error));
            return error;
        });
}

const { swapOutRequest, swapOutSuccess, swapOutFail } = createActions({
    SWAP_OUT_REQUEST: () => {},
    SWAP_OUT_SUCCESS: data => (data),
    SWAP_OUT_FAIL: error => (error),
});

export const handleSwapOut = (presentContract, address, params, callbacks) => (dispatch) => {
    dispatch(swapOutRequest());

    return swapOut(presentContract, address, params, callbacks)
        .then((data) => {
            dispatch(swapOutSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(swapOutFail(error));
            return error;
        });
}

const { getExchangeStatusRequest, getExchangeStatusSuccess, getExchangeStatusFail } = createActions({
    GET_EXCHANGE_STATUS_REQUEST: () => {},
    GET_EXCHANGE_STATUS_SUCCESS: data => ({ data }),
    GET_EXCHANGE_STATUS_FAIL: error => ({ error }),
});

export const getExchangeStatus = (address) => (dispatch) => {
    dispatch(getExchangeStatusRequest());

    return WithdrawApi.getExchangeStatus(address)
        .then(({ data }) => {
            dispatch(getExchangeStatusSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(getExchangeStatusFail(error));
            return error;
        });
}
