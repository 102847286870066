import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { isEmpty } from 'lodash';

import boosterTitleIcon from 'assets/dashboardLayout/booster-label.png';
import infoIcon from 'assets/dashboardLayout/information.png';

import useWeaponSkin from './useWeaponSkins';
import List from './List';
// import NotEnough from './NotEnough';
import Enough from './SelectedEnough';
import HowItWork from './HowItWork';

import WagyuLoader from 'components/WagyuLoader';
import Spinner from 'components/Spinner';

import {
    fetchExclusivesBurned,
    exclusivesBurned,
    exclusivesBoost,
    // reloadOwnership
} from 'store/modules/Ownership/actions';
import { connectImx, imxTransfer } from 'store/modules/Imx/actions';
import { setToast } from 'store/modules/App/actions';
import { ourRequest } from 'services/http';

import styles from './RarityBooster.module.scss';

const typeList = [
    { id: '01', type: 'Common' },
    { id: '02', type: 'Rare' },
    { id: '03', type: 'Epic' },
    { id: '04', type: 'Legendary' }
];

const type = 'ERC721';

function RarityBooster() {
    const dispatch = useDispatch();

    const [offset, setOffset] = useState(0);
    const [isEnough, setEnough] = useState(false);
    const [isHowItWork, setHowItWork] = useState(false);
    const [loadoutList, setLoadoutList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    // const [isNotEnough, setNotEnough] = useState(false);
    const [typeSelected, setTypeSelected] = useState('Common');

    const { isMobile } = useSelector(state => state?.app ?? {});
    const userAddress = useSelector(state => state?.user?.userInfo?.result?.address ?? '');
    const loadingList = useSelector(state => state?.ownership?.ownershipRes?.requesting);
    const isConnectImx = useSelector(state => state?.imx?.userImxInfo?.result?.address ?? '');
    const boosterData = useSelector(state => state?.ownership?.exclusivesBurnedRes?.result?.data ?? {});
    const exclusivesRequesting = useSelector(state => state?.ownership?.exclusivesBurnedRes?.requesting);

    // loading
    const boostRequesting = useSelector(state => state?.ownership?.onBoostExclusivesRes?.requesting);
    const burnRequesting = useSelector(state => state?.ownership?.onBurnedExclusivesRes?.requesting);
    const transferRequesting = useSelector(state => state?.imx?.transferRes?.requesting);
    const imxRequesting = useSelector(state => state?.imx?.userImxInfo?.requesting);

    const boosterInfo = boosterData[typeSelected] ?? {};
    const isHowItWorkEnabled = !isEmpty(loadoutList);

    const { weaponSkinList, hasMore, firstLoading, setWeaponSkinList } = useWeaponSkin({ offset, typeSelected });

    const boosterLabel = useMemo(() => {
        if (!isEmpty(loadoutList)) return 'BURN';

        if (boosterInfo?.boostAvailable > 0) return 'BOOST';

        return 'BURN';
    }, [boosterInfo?.boostAvailable, loadoutList]);

    const conditionBooster = useMemo(() => {
        if (boosterInfo?.boostAvailable > 0) return true;

        if (!isEmpty(loadoutList)) return true;

        return false;
    }, [boosterInfo?.boostAvailable, loadoutList]);

    const loadingButton = useMemo(() => {
        return imxRequesting || transferRequesting || burnRequesting || boostRequesting || exclusivesRequesting;
    }, [
        imxRequesting,
        burnRequesting,
        boostRequesting,
        transferRequesting,
        exclusivesRequesting
    ]);

    useEffect(() => {
        if (!userAddress) return;

        const lowerCaseAddress = userAddress?.toLowerCase();

        dispatch(fetchExclusivesBurned(lowerCaseAddress));

        return () => {
            ourRequest.cancel();
        }
    }, [dispatch, userAddress]);

    const handleOnClearOptions = () => {
        setOffset(0);
        setLoadoutList([]);
        setSelectedList([]);
    };

    const handleOnSelect = (item) => {
        if (loadoutList.includes(item.tokenId)) {
            setLoadoutList(loadoutList.filter(e => e !== item.tokenId));
            setSelectedList(selectedList.filter(e => e !== item));

            return;
        }

        // if (loadoutList.length >= boosterInfo?.needed) return;

        setLoadoutList(prevState => [...prevState, item.tokenId]);
        setSelectedList(prevState => [...prevState, item]);
    };

    const handleOnClickBoost = () => {
        if (boosterInfo.disabled) return;

        if (boosterInfo?.boostAvailable === 0 && isEmpty(loadoutList)) return;

        // if (!isEmpty(loadoutList)) {
        //     if (loadoutList.length < boosterInfo?.needed) return setNotEnough(true);

        //     return setEnough(true);
        // }

        if (!isEmpty(loadoutList)) return setEnough(true);

        if (boosterInfo?.boostAvailable > 0) {
            const bodyBoost = {
                fromRarity: typeSelected
            }

            return dispatch(exclusivesBoost(bodyBoost))
                .then((res) => {
                    if (res?.status >= 500) {
                        return dispatch(setToast({ title: 'RARITY BOOSTER', detail: 'Something went wrong.' }));
                    }
                    if ((res?.status >= 400)) {
                        return dispatch(setToast({ title: 'RARITY BOOSTER', detail: res?.data?.message || 'Something went wrong.' }));
                    }

                    dispatch(setToast({ title: 'RARITY BOOSTER', detail: 'Success! Please check your Loot Coffin Inventory for your Rarity Booster Loot Coffin' }));
                    // dispatch(reloadOwnership(Date.now()));

                    return dispatch(fetchExclusivesBurned(userAddress?.toLowerCase()));
                })
                .catch(error => {
                    dispatch(setToast({ title: 'RARITY BOOSTER', detail: error?.data?.message || 'Something went wrong.' }));
                })
        }
    };

    const handleOnSubmit = async () => {
        if (boosterInfo.disabled) return;

        if (!isConnectImx) {
            const connectorId = window.localStorage.getItem('connectorId');

            return dispatch(connectImx(connectorId));
        }

        // const tokenAddress = process.env.REACT_APP_DEV === '1'
        //     ? process.env.REACT_APP_TOKEN_ADDRESS_TRANSFER_IMX_TESTNET
        //     : process.env.REACT_APP_TOKEN_ADDRESS_TRANSFER_IMX_MAINNET

        const toAddress = process.env.REACT_APP_DEV === '1'
            ? process.env.REACT_APP_EXCLUSIVES_TESTNET
            : process.env.REACT_APP_EXCLUSIVES_MAINNET

        const params = selectedList && selectedList.map((item) => {
            return ({
                type: type,
                tokenId: String(item?.tokenId),
                tokenAddress: item?.contractAddress?.toLowerCase(),
                toAddress: toAddress.toLowerCase()
            })
        });

        try {
            let bodyBurned = [];

            if (isMobile) {
                for (let index = 0; index < params.length; index++) {
                    const param = params[index];

                    const res = await dispatch(imxTransfer([param]));

                    if (res === 'Failed') return;

                    bodyBurned.push(res?.result[0].txId);
                }
            } else {
                const transferRes = await dispatch(imxTransfer(params));

                if (transferRes === 'Failed') return;
    
                bodyBurned = !isEmpty(transferRes) && transferRes?.result?.map(e => e.txId);
            }

            const onBurnedRes = await dispatch(exclusivesBurned({ txIds: bodyBurned }));

            if (onBurnedRes?.status >= 500) {
                return dispatch(setToast({ title: 'BURN', detail: 'Something went wrong.' }));
            }

            if ((onBurnedRes?.status >= 400)) {
                return dispatch(setToast({ title: 'BURN', detail: onBurnedRes?.data?.message || 'Something went wrong.' }));
            }

            setWeaponSkinList(prevState => prevState.filter(e => !loadoutList.includes(e.tokenId)));

            setLoadoutList([]);
            setSelectedList([]);
            setEnough(false);
            // dispatch(reloadOwnership(Date.now()));

            return dispatch(fetchExclusivesBurned(userAddress?.toLowerCase()));
        } catch (error) {
            dispatch(setToast({ title: 'TRANSFER', detail: error?.data?.message || 'Something went wrong.' }));
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <img src={boosterTitleIcon} alt="" className={styles.title} />

                <p className={styles.description}>Trade in skins for a new Weapon Skin with a higher Rarity</p>

                <div className={styles.devide} />
                <section className={styles.typeWeaponContainer}>
                    {typeList.map((item, index) => {
                        const itemHasBorder = (index !== typeList.length - 1) && styles.hasRightBorder;
                        const commonStyling = item.type === 'Common' && styles.isCommon;
                        const rareStyling = item.type === 'Rare' && styles.isRare;

                        return (
                            <div
                                key={item.id}
                                className={cn(styles.item, itemHasBorder, commonStyling, rareStyling, {
                                    [styles.wait]: firstLoading
                                })}
                                onClick={() => {
                                    if (firstLoading) return;

                                    setTypeSelected(item.type)
                                    handleOnClearOptions()
                                }}
                            >
                                <div
                                    className={cn(styles.typeIcon, {
                                        [styles.isSelected]: item.type === typeSelected,
                                        [styles.common]: item.type === 'Common',
                                        [styles.rare]: item.type === 'Rare',
                                        [styles.epic]: item.type === 'Epic',
                                        [styles.legendary]: item.type === 'Legendary'
                                    })}
                                />
                            </div>
                        )
                    })}

                </section>
                <div className={styles.devide} />

                <section className={styles.actionButton}>
                    <div
                        className={cn(styles.infoButton, {
                            [styles.isEnable]: isHowItWorkEnabled
                        })}
                        onClick={() => setHowItWork(true)}
                    >
                        <p className={styles.infoLabel}>How it works</p>
                        <img src={infoIcon} alt="" className={styles.infoIcon} />
                    </div>

                    <div className={styles.neededButton}>
                        <p className={styles.loadoutLabel}>Burned Skins Needed</p>
                        <p className={styles.countLabel}>{boosterInfo?.burnNotUse}/{boosterInfo?.needed}</p>
                    </div>

                    <div
                        className={cn(styles.boostButton, {
                            [styles.isSelected]: conditionBooster,
                            [styles.isDisabled]: boosterInfo.disabled
                        })}
                        onClick={() => !loadingButton && handleOnClickBoost()}
                    >
                        {loadingButton ? <Spinner width={30} /> : boosterLabel}
                    </div>
                </section>
                <div className={styles.devide} />
            </div>

            {firstLoading ? (
                <div className={styles.firstLoad}>
                    <WagyuLoader />
                </div>
            ) : (
                <List
                    items={weaponSkinList}
                    selectedList={loadoutList}
                    onSelect={handleOnSelect}
                    loadingList={loadingList}
                    hasMore={hasMore}
                    setOffset={setOffset}
                />
            )}

            {!firstLoading && loadingList && (
                <div className={styles.loadMoreContainer}>
                    <WagyuLoader customStyle={styles.loadMore} />
                </div>
            )}

            {/* <NotEnough
                isVisible={isNotEnough}
                onCancel={() => setNotEnough(false)}
            /> */}

            <Enough
                isVisible={isEnough}
                list={selectedList}
                loadingButton={loadingButton}
                onSubmit={handleOnSubmit}
                onCancel={() => setEnough(false)}
            />

            <HowItWork
                isVisible={isHowItWork}
                onCancel={() => setHowItWork(false)}
            />
        </div>
    )
}

export default React.memo(RarityBooster);