import React from 'react';
import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons'
import cn from 'classnames';

import styles from './ProviderList.module.scss';

const antIcon = <LoadingOutlined style={{ fontSize: '18rem', marginRight: '5rem' }} spin />

export default function ProviderList(props) {
    const { onSelect, onRepeat, selectedItem, requesting, error, items } = props
    const itemList = selectedItem ? [selectedItem] : items

    const provider = window?.ethereum || window?.web3?.currentProvider;

    const handleAllowClick = (item) => {
        !selectedItem && onSelect && onSelect(item);
    }

    return (
        <div className={styles.container}>
            {error && (
                <div className={styles.errorMessage}>
                    <div className={styles.errorText}>Error Connecting</div>
                    <div className={styles.tryAgain}
                        onClick={() => {
                            onRepeat && onRepeat(selectedItem)
                        }}>
                        Try again
                    </div>
                </div>
            )}

            {requesting && (
                <div className={styles.initialize}>
                    <div>
                        <Spin indicator={antIcon} /> Initializing...
                    </div>
                </div>
            )}

            {itemList && itemList.map((item, i) => {
                let isAllow = true;

                if (item.name === 'MetaMask' && !provider?.isMetaMask) {
                    isAllow = false;
                }

                if (item.name === 'GameStop' && !window?.gamestop?.isGamestop) {
                    isAllow = false;
                }

                if (item.name === 'TrustWallet' && !provider?.isTrust) {
                    isAllow = false;
                }

                return (
                    <div
                        key={i}
                        onClick={() => isAllow && !requesting && handleAllowClick(item)}
                        className={cn(styles.wallet, { [styles.notAllowed]: !isAllow })}
                    >
                        <div className={styles.walletInfo}>
                            <div className={styles.walletName}>{item.name}</div>
                            {selectedItem && item.description && <div className={styles.walletInfo}>{item.description}</div>}
                        </div>
                        <img className={styles.walletIcon} src={item.picture} alt="" />
                    </div>
                )
            })}
        </div>
    )
}
