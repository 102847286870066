import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import useWeaponLoadouts from './useWeaponLoadouts';

import PageSlug from 'components/PageSlug';
import NftList from 'components/NFTList';
import WagyuLoader from 'components/WagyuLoader';

import styles from './WeaponLoadouts.module.scss';

function MyWeaponLoadouts() {
    const [offset, setOffset] = useState(0);

    const loadingList = useSelector(state => state?.ownership?.ownershipRes?.requesting);

    const { ownershipList, hasMore, progress, loadFirst } = useWeaponLoadouts({ offset });

    return (
        <div className={styles.container}>
            <PageSlug
                slug={'My Weapon Loadout NFTS'}
                description={'Move your weapon loadout NFTs between Layer 1 and Layer 2 (IMX)'}
                hasDevide
            />

            {loadFirst ? (
                <div className={styles.firstLoad}>
                    <WagyuLoader />
                </div>
            ) : null}

            <NftList
                list={ownershipList}
                hasMore={hasMore}
                loading={loadingList}
                loadFirst={loadFirst}
                progress={progress}
                setOffset={setOffset}
                wrappedClass={styles.body}
            />
        </div>
    )
}

export default React.memo(MyWeaponLoadouts);