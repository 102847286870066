import React from 'react';
import { useSelector } from 'react-redux';

import bubble from '../../img/bubble.png';
import closeIcon from 'components/DownloadModal/img/close.png';

import Modal from 'components/Modal';
import Spinner from 'components/Spinner';

import styles from './PopupConfirm.module.scss';

function PopupConfirm(props) {
    const {
        loading,
        isUndead,
        isVisible,
        isStandard,
        itemSelected,
        confirmButtonLabel,
        onCancel,
        onConfirm
    } = props;

    const { isMobile } = useSelector(state => state?.app);

    const typeZbux = isStandard ? 'Standard' : 'Gold';
    const lootCoffinLabel = itemSelected?.value === 1 ? 'Loot Coffin Transaction' : 'Loot Coffins Transaction';
    const priceInfo = isUndead ? `${itemSelected?.undeadPrice} UNDEAD` : `${itemSelected?.zbuxPrice} ${typeZbux} Zbux`;

    return (
        <Modal
            visible={isVisible}
            closable={false}
            centered
            onCancel={onCancel}
            width={isMobile ? '86%' : '640rem'}
            wrapperClass={styles.container}
            wrapperBody={styles.wrapperBody}
        >
            <div className={styles.content}>
                <img src={closeIcon} alt="" className={styles.close} onClick={() => onCancel()}/>

                <header className={styles.header}>
                    <h2 className={styles.heading}>
                        Confirm {itemSelected?.value}x {lootCoffinLabel} <br /> {priceInfo}
                    </h2>
                </header>

                <section className={styles.box}>
                    <img src={bubble} alt="" className={styles.leftBubble} />
                    <img src={bubble} alt="" className={styles.rightBubble} />

                    <img src={itemSelected?.icon} alt="" className={styles.coffinIcon} />
                </section>

                <div className={styles.buttonContainer}>
                    <section className={styles.viewArmory} onClick={() => !loading && onConfirm()}>
                        {loading ? (
                            <div style={{ padding: '3rem 0' }}>
                                <Spinner width={20} />
                            </div>
                        ) : (
                            confirmButtonLabel
                        )}
                    </section>

                    <section className={styles.viewLoot} onClick={() => onCancel()}>
                        Cancel
                    </section>
                </div>
            </div>
        </Modal>
    )
}

export default React.memo(PopupConfirm);