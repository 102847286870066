import React from 'react';

import styles from './ItemLoader.module.scss';

function ItemLoader() {
    return (
        <svg
            width={'100%'}
            height={'210rem'}
            viewBox="0 0 0 0"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.container}
        >
            <circle
                cx="9.5"
                cy="9.5"
                r="8.5"
                stroke="white"
                strokeOpacity="0.2"
                strokeWidth="2"
            />
                <path
                strokeWidth="2"
                d="M18 9.5C18 14.1944 14.1944 18 9.5 18C4.80558 18 1 14.1944 1 9.5C1 4.80558 4.80558 1 9.5 1"
            />
        </svg>
    )
}

export default ItemLoader;
