import React from 'react';
import { Switch } from 'antd';

import styles from './SwitchButton.module.scss';

function SwitchButton(props) {
    const { label, onCheck, isChecked } = props;

    const onChange = (checked) => {
        onCheck(checked);
    };

    return (
        <div className={styles.container}>
            <p className={styles.label}>{label}</p>
            <Switch checked={isChecked} onChange={onChange} />
        </div>
    );
}

export default SwitchButton;