import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';

import Ranks from './Ranks';
import HighTop from './HighTop';
import Pagination from './Pagination';
import TournamentCountdown from './TournamentCountdown';

import PageSlug from '../../../../components/PageSlug';

import { setHighTopLeaderBoard } from '../../../../store/modules/User/actions';
import { fetchLeaderBoard } from '../../../../store/modules/Wallet/actions';

import styles from './LeaderBoard.module.scss';

const DESCRIPTION_DEFAULT = 'Check out the leaderboard for the latest tournament.';

function LeaderBoardPage() {
    const dispatch = useDispatch();

    const [leaderBoard, setLeaderBoard] = useState([]);
    const [tournament, setTournament] = useState({});
    const [totalPage, setTotalPage] = useState(0);
    const [youLeaderBoard, setYouLeaderBoard] = useState(null);
    const [tournamentType, setTournamentType] = useState('2');
    const [tournamentScoreValue, setTournamentScoreValue] = useState('Zombies Killed');

    const [currentPage, setCurrentPage] = useState(1);

    const { loaded } = useSelector(state => state?.app?.load ?? {});
    const { isMobile } = useSelector(state => state?.app ?? {});
    const { data } = useSelector(state => state?.app?.allSettings?.result ?? []);
    const email = useSelector(state => state?.user?.userInfo?.result?.email ?? '');

    const descriptionTournament = useMemo(() => {
        if (isEmpty(data) || !data) return '';

        return data.find(e => e.key === 'description').value || '';
    }, [data]);

    const tournamentId = useMemo(() => {
        if (isEmpty(data) || !data) return '';

        return data.find(e => e.key === 'tournamentId').value;
    }, [data]);

    const dataSource = useMemo(() => {
        if (!youLeaderBoard) return leaderBoard;

        return [youLeaderBoard, ...leaderBoard];
    }, [youLeaderBoard, leaderBoard]);

    const dateNow = Date.now();

    const conditional = useCallback(() => {
        if (!tournament.startTime || dateNow < moment(tournament.startTime).valueOf()) {
            return { main: 'TOURNAMENT UPCOMING!', sub: 'Tournament Starts in:' };
        }

        if (moment(tournament.endTime).valueOf() < dateNow) {
            return { main: 'TOURNAMENT HAS ENDED', sub: 'Tournament HAS ENDED:' };
        }

        return { main: 'TOURNAMENT IS LIVE', sub: 'Tournament Ends in:' };
    }, [tournament.startTime, dateNow, tournament.endTime])

    const checkTournamentScoreValue = (type = 0) => {
        setTournamentType(type);
        switch (parseInt(type)) {
            case 2:
                setTournamentScoreValue('Zombies Killed');
                break;
            case 4:
                setTournamentScoreValue('S.ZBUX Gained');
                break;
            default:
                setTournamentScoreValue('XP GAINED');
                break;
        }
    }

    useEffect(() => {
        if (!loaded) return;
        if (!data) return;
        if (!tournamentId) return;

        dispatch(fetchLeaderBoard(tournamentId, currentPage, email))
            .then(res => {
                const response = res?.data;

                if (!response) return;

                response?.tournament?.type && checkTournamentScoreValue(response?.tournament?.type);

                if (!isEmpty(response?.leaderBoard)) {
                    const leaderBoardsList = response?.leaderBoard;

                    if (currentPage === 1) {
                        if (leaderBoardsList.length <= 3) {
                            let highTop = [];

                            for (let index = 0; index < leaderBoardsList.length; index++) {
                                const leaderBoardsItem = leaderBoardsList[index];

                                highTop.push(leaderBoardsItem)
                            }

                            dispatch(setHighTopLeaderBoard(highTop))
                        } else {
                            dispatch(setHighTopLeaderBoard(
                                [
                                    leaderBoardsList[0],
                                    leaderBoardsList[1],
                                    leaderBoardsList[2]
                                ]
                            ))
                        }

                        setLeaderBoard(leaderBoardsList.slice(3));
                    } else {
                        setLeaderBoard(leaderBoardsList);
                    }
                }

                setTournament(response?.tournament);
                setTotalPage(response?.totalPage);
                setYouLeaderBoard(response?.youLeaderBoard);
            })
            .catch(err => err)
    }, [
        dispatch,
        loaded,
        data,
        email,
        currentPage,
        tournamentId,
    ]);

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <section className={styles.headerPage}>
                    <PageSlug
                        slug={conditional().main}
                        description={descriptionTournament || DESCRIPTION_DEFAULT}
                        slugStyling={styles.slugStyling}
                    />

                    {tournament?.sponsor && (
                        <div className={styles.sponsored}>
                            <p className={styles.label}>SPONSORED BY</p>

                            <img src={tournament?.sponsor} alt="" className={styles.sponsoredIcon} />
                        </div>
                    )}

                    {isMobile && (
                        <div>
                            <TournamentCountdown
                                conditional={conditional}
                                startTime={tournament?.startTime || Date.now()}
                                endTime={tournament?.endTime || Date.now()}
                            />

                            <HighTop tournamentType={tournamentType} tournamentScoreValue={tournamentScoreValue} />
                        </div>
                    )}
                </section>

                <section className={styles.ranksContainer}>
                    <Ranks
                        data={dataSource}
                        youLeaderBoard={youLeaderBoard}
                        tournamentType={tournamentType}
                        tournamentScoreValue={tournamentScoreValue}
                    />

                    {totalPage > 0 && (
                        <Pagination
                            totalPage={totalPage}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    )}
                </section>
            </div>

            {!isMobile && (
                <div className={styles.right}>
                    <TournamentCountdown
                        conditional={conditional}
                        startTime={tournament?.startTime || Date.now()}
                        endTime={tournament?.endTime || Date.now()}
                    />

                    <HighTop tournamentType={tournamentType} tournamentScoreValue={tournamentScoreValue} />
                </div>
            )}
        </div>
    );
}

export default React.memo(LeaderBoardPage);