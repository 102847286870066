import { createActions } from 'redux-actions';

import SettingWithdrawApi from './api';

const { fetchSwapTokensRequest, fetchSwapTokensSuccess, fetchSwapTokensFail } = createActions({
    FETCH_SWAP_TOKENS_REQUEST: () => {},
    FETCH_SWAP_TOKENS_SUCCESS: data => ({ data }),
    FETCH_SWAP_TOKENS_FAIL: error => ({ error }),
});

export const fetchSwapTokens = () => (dispatch) => {
    dispatch(fetchSwapTokensRequest());

    return SettingWithdrawApi.fetchSwapTokens()
        .then(({ data }) => {
            dispatch(fetchSwapTokensSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(fetchSwapTokensFail(error));
            return error;
        });
}