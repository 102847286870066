import React from 'react';
import { useSelector } from 'react-redux';

import Sidebar from './Sidebar';
import HeaderMobile from './HeaderMobile';

import styles from './Layout.module.scss';

function DashboardLayout(props) {
    const { isMobile } = useSelector(state => state.app);

    return (
        <div className={styles.container}>
            {isMobile ? <HeaderMobile /> : <Sidebar {...props} />}

            <main className={styles.main}>
                {props.children}
            </main>
        </div>
    );
}

export default React.memo(DashboardLayout);