import React from 'react';
import { useSelector } from 'react-redux';

import { Empty } from 'antd';
import cn from 'classnames';

import Tables from './table';

import styles from './Ranks.module.scss';

function Ranks(props) {
    const {
        data = [],
        youLeaderBoard,
        tournamentType,
        tournamentScoreValue
    } = props;

    const requesting = useSelector(state => state?.wallet?.leaderBoardRes?.requesting);

    return (
        <div className={cn(styles.container, {
            [styles.isActived]: !requesting
        })} data={youLeaderBoard}>
            {data ? (
                <Tables
                    data={data}
                    youLeaderBoard={youLeaderBoard}
                    tournamentType={tournamentType}
                    tournamentScoreValue={tournamentScoreValue}
                />
            ) : (
                <Empty />
            )}
        </div>
    );
}

export default React.memo(Ranks);