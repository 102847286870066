import dead from './img/statistic/dead.png';
import finish from './img/statistic/finish.png';
import controller from './img/statistic/controller.png';
import headShotRatio from './img/statistic/headshot-ratio.png';
import headShotKills from './img/statistic/headshot-kills.png';
import personKilling from './img/statistic/person-killing.png';

export const STATISTIC_LIST = [
    { id: '0', label: 'Headshot Ratio', value: '75%', icon: headShotRatio, size: { width: '31rem', height: '34rem' } },
    { id: '1', label: 'Matches Played', value: '1000', icon: controller, size: { width: '38.5rem', height: '27.5rem' } },
    { id: '2', label: 'Matches Completed', value: '750', icon: finish, size: { width: '34rem', height: '34rem' } },
    { id: '3', label: 'Deaths', value: '250', icon: dead, size: { width: '32rem', height: '32rem' } },
    { id: '4', label: 'Total Kills', value: '1320', icon: personKilling, size: { width: '42rem', height: '32.5rem' } },
    { id: '5', label: 'Headshot Kills', value: '75%', icon: headShotKills, size: { width: '32rem', height: '32rem' } }
];