import { has, isEmpty, get } from 'lodash';
import {persistReducer} from 'redux-persist';
import localForage from 'localforage';
import { handleActions } from "redux-actions";

const initialState = {
    connectWallet: {
        error: null,
        isConnect: false,
        requesting: false,
    },
    userAccount: {
        balance: 0,
        undeadBalance: 0,
        tokensInfo: {
            undead: {
                ether: { balance: 0, isApprove: 0 },
                bsc: { balance: 0, isApprove: 0 },
                polygon: { balance: 0, isApprove: 0 }
            },
            usdt: {
                ether: { balance: 0, isApprove: 0 },
                bsc: { balance: 0, isApprove: 0 },
                polygon: { balance: 0, isApprove: 0 }
            },
            usdc: {
                ether: { balance: 0, isApprove: 0 },
                bsc: { balance: 0, isApprove: 0 },
                polygon: { balance: 0, isApprove: 0 }
            },
            busd: {
                ether: { balance: 0, isApprove: 0 },
                bsc: { balance: 0, isApprove: 0 },
                polygon: { balance: 0, isApprove: 0 }
            }
        },
        accounts: [],
        error: null,
        requesting: false,
    },
    userInfo: {
        error: null,
        result: null,
        requesting: false
    },
    chainId: 1,
    switchNetwork: {
        error: null,
        success: null,
        requesting: false
    },
    loadingConnectAccount: false,
    logged: false,
    hightTopLeaderBoard: [],
    approveRes: {
        error: null,
        result: null,
        requesting: false
    }
};
  
let userReducer = handleActions({
    // LOADING_CONNECT_ACCOUNT
    LOADING_CONNECT_ACCOUNT: (state, { payload }) => ({
        ...state,
        loadingConnectAccount: payload,
    }),

    SET_LOGGED: (state, { payload }) => ({
        ...state,
        logged: payload
    }),

    SET_USER_NONCE: (state, { payload }) => ({
        ...state,
        userAccount: {
          ...state.userAccount,
          nonce: payload
        }
    }),
    
    SET_USER_SIGNKEY: (state, { payload }) => ({
        ...state,
        userAccount: {
            ...state.userAccount,
            signKey: payload
        }
    }),

    SET_TOKENS_INFO: (state, { payload }) => ({
        ...state,
        userAccount: {
            ...state.userAccount,
            tokensInfo: {
                ...get(state, 'userAccount.tokensInfo', {}),
                ...payload
            }
        }
    }),

    /** SET USER ACCOUNTS **/
    SET_USER_ACCOUNTS: (state, { payload }) => ({
        ...state,
        userAccount: {
            ...state.userAccount,
            accounts: has(payload, 'accounts') ? payload.accounts : state.userAccount.accounts,
            balance: has(payload, 'balance') ? payload.balance : state.userAccount.balance
        },
        connectWallet: {
            ...state.connectWallet,
            ...{ isConnect: has(payload, 'accounts') ? !isEmpty(payload.accounts) : !isEmpty(state.userAccount.accounts) },
        },
        chainId: has(payload, 'chainId') ? parseInt(payload.chainId) : parseInt(state.chainId)
    }),

    SET_UNDEAD_BALANCE: (state, { payload }) => ({
        ...state,
        userAccount: {
            ...state.userAccount,
            undeadBalance: payload
        },
    }),

    SET_HIGH_TOP_LEADER_BOARD: (state, { payload }) => ({
        ...state,
        hightTopLeaderBoard: payload
    }),
  
    /** CONNECT WALLET **/
    CONNECT_WALLET_REQUEST: (state) => ({
        ...state,
        connectWallet: {
            ...state.connectWallet,
            requesting: true,
        },
    }),
    CONNECT_WALLET_SUCCESS: (state) => ({
        ...state,
        connectWallet: {
            ...state.connectWallet,
            requesting: false,
            isConnect: true,
            error: null,
        },
    }),
    CONNECT_WALLET_FAIL: (state, { payload }) => ({
        ...state,
        connectWallet: {
            ...state.connectWallet,
            isConnect: false,
            requesting: false,
            error: payload.error,
        },
    }),

    /** SWITCH NETWORK **/
    SET_SWITCH_NETWORK_REQUEST: (state) => ({
        ...state,
        switchNetwork: {
            ...state.switchNetwork,
            requesting: true
        },
    }),
    SET_SWITCH_NETWORK_SUCCESS: (state) => ({
        ...state,
        switchNetwork: {
            ...state.switchNetwork,
            success: true,
            requesting: false,
        },
    }),
    SET_SWITCH_NETWORK_FAILED: (state, { payload }) => ({
        ...state,
        switchNetwork: {
            ...state.switchNetwork,
            requesting: false,
            error: payload.error
        },
    }),

    /** USER TOKEN **/
    SET_USER_TOKEN: (state, { payload }) => ({
        ...state,
        userAccount: {
          ...state.userAccount,
          token: payload
        }
    }),

    /** USER INFO **/
    SET_USER_INFO: (state, { payload }) => ({
        ...state,
        userInfo: {
            ...state.userInfo,
            result: payload
        }
    }),

    /** USER INFO **/
    FETCH_USER_INFO_REQUEST: (state) => ({
        ...state,
        userInfo: {
            ...state.userInfo,
            requesting: true
        },
    }),
    FETCH_USER_INFO_SUCCESS: (state, { payload }) => ({
        ...state,
        userInfo: {
            ...state.userInfo,
            result: {
                ...get(state, 'userInfo.result', {}),
                ...payload.data
            },
            requesting: false,
            error: null
        },
    }),
    FETCH_USER_INFO_FAIL: (state, { payload }) => ({
        ...state,
        userInfo: {
            ...state.userInfo,
            requesting: false,
            error: payload.error
        },
    }),

    /** ON APPROVE **/
    ON_APPROVE_REQUEST: (state) => ({
        ...state,
        approveRes: {
            ...state.approveRes,
            requesting: true
        },
    }),
    ON_APPROVE_SUCCESS: (state, { payload }) => ({
        ...state,
        approveRes: {
            ...state.approveRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    ON_APPROVE_FAIL: (state, { payload }) => ({
        ...state,
        approveRes: {
            ...state.approveRes,
            requesting: false,
            error: payload.error
        },
    }),
}, initialState);

userReducer = persistReducer({
    key: 'user',
    storage: localForage,
    whitelist: ['userAccount', 'userInfo', 'logged']
}, userReducer);
  
export default userReducer;