import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import logo from '../../../../assets/img/logo.png';

import MobileMenu from '../MobileMenu';
import Hamburger from '../../../../components/LandingHamburger';
import { setMenuOpen } from '../../../../store/modules/App/actions';
import { NAVIGATION_ITEMS, NAVIGATION_OPTIONS } from '../Header/constants';

import styles from './HeaderMobile.module.scss';

function HeaderMobile() {
    const dispatch = useDispatch();

    const { isMenuOpen } = useSelector(state => state.app);

    const onMenuClose = () => dispatch(setMenuOpen(false));
    const onMenuOpen = () => dispatch(setMenuOpen(true));

    return (
        <div className={styles.container}>
            <nav className={styles.content}>
                <section className={styles.left}>
                    <Link className={styles.logoLink} to={'/'}>
                        <img src={logo} alt={''} className={styles.logo}/>
                    </Link>
                </section>

                <section className={styles.right}>
                    <Hamburger isOpen={isMenuOpen} onClick={onMenuOpen} />
                </section>

                <MobileMenu
                    items={NAVIGATION_ITEMS}
                    options={NAVIGATION_OPTIONS}
                    onItemClick={onMenuClose}
                    onClose={onMenuClose}
                    isOpen={isMenuOpen}
                />
            </nav>
        </div>
    );
}

export default HeaderMobile;