import React, { useState } from 'react';
import cn from 'classnames';

import LandingHeaderCustomContainer from '../../containers/LandingHeaderCustomContainer';

import style from './PageLayout.module.scss';

export default function PageLayout(props) {
    /*
        Only the layout level components or tasks is allowed here.
        Use an App component to place any global level component or task
    */
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={cn(style.container, 'content')}>
            <div className={cn(style.fade, {[style.fadeActive]: isOpen})} onClick={() => setIsOpen(false)}/>

            <LandingHeaderCustomContainer wrappedClass={style.navWrapped} />

            <main className={style.main}>{props.children}</main>
        </div>
    )
}