import {useDispatch, useSelector} from 'react-redux';
import {useCallback, useEffect} from 'react';
import $ from 'jquery';

import {BREAKPOINTS} from './constants';
import {
    loadApp,
    setIsDesktop,
    setIsMobile,
    // setIsScrolled,
    setIsTablet,
    fetchAllSettings
} from './actions';

import { fetchRewards } from '../LootConffin/actions';

window.$ = $;
window.jQuery = $;

export function UseAppInit() {
    /*
        Basic initialization logic for the module:
        init scripts, event listeners, etc...
    */

    const dispatch = useDispatch();
    const {
        isMobile,
        isTablet,
        isDesktop,
        // theme,
        // isScrolled
    } = useSelector(state => state.app);
    const { loaded } = useSelector(state => state.app?.load);
    const { logged } = useSelector(state => state?.user ?? {});

    // RESIZE LOGIC

    const onResize = useCallback(() => {
        if ((window.innerWidth < BREAKPOINTS.MD_MIN) && !isMobile) {
            dispatch(setIsMobile())
        } else if ((window.innerWidth > BREAKPOINTS.SM_MAX) && (window.innerWidth < BREAKPOINTS.LG_MIN) && !isTablet) {
            dispatch(setIsTablet())
        } else if ((window.innerWidth > BREAKPOINTS.MD_MAX) && !isDesktop) {
            dispatch(setIsDesktop())
        }
    }, [dispatch, isMobile, isTablet, isDesktop]);

    useEffect(() => {
        onResize();
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, [onResize]);

    // LOADER LOGIC

    useEffect(() => {
        dispatch(loadApp());
    }, [dispatch]);

    // FETCH SETTINGS
    useEffect(() => {
        if (!loaded) return;
        if (!logged) return;

        dispatch(fetchAllSettings());
        dispatch(fetchRewards());
    }, [dispatch, loaded, logged]);

    // LOAD JQUERY

    // THEME LOGIC

    // useEffect(() => {
    //     document.body.classList.add(theme);

    //     return ()  => {
    //         document.body.classList.remove(theme);
    //     }
    // }, [dispatch, theme]);

    // SCROLLING LOGIC

    // const handleScroll = useCallback(() => {
    //     if (!isScrolled && window.scrollY > 1) {
    //         dispatch(setIsScrolled(true));
    //     } else if (isScrolled && window.scrollY < 1) {
    //         dispatch(setIsScrolled(false));
    //     }
    // }, [dispatch, isScrolled]);

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // });
}
