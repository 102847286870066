import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import Spinner from '../Spinner';

import { connectImx } from 'store/modules/Imx/actions';
import { METHOD_TYPES } from 'utils/imxHelpers';
import { IMX_STATUS } from 'utils/constants';

import styles from './ImxProvider.module.scss';

function ImxProvider(props) {
    const dispatch = useDispatch();

    const {
        type,
        onSubmit,
        disabled,
        isOwned,
        imxStatus,
        loadingDetail
    } = props;

    const connectImxRequesting = useSelector(state => state?.imx?.userImxInfo?.requesting);
    const isConnectImx = useSelector(state => state?.imx?.userImxInfo?.result?.address ?? '');

    const buttonLbl = useMemo(() => {
        if (!isConnectImx || !isOwned) {
            return 'Connect IMX';
        }

        if (type === METHOD_TYPES.DEPOSIT) {
            return 'DEPOSIT';
        }

        if (type === METHOD_TYPES.TRANSFER) {
            return 'TRANSFER';
        }

        if (type === METHOD_TYPES.WITHDRAW) {
            if (imxStatus === IMX_STATUS.preparing_withdrawal) {
                return 'PREPARING WITHDRAW';
            }

            if (imxStatus === IMX_STATUS.withdrawable) {
                return 'COMPLETE WITHDRAW';
            }

            return 'WITHDRAW';
        }
    }, [isConnectImx, type, imxStatus, isOwned])

    const handleOnClick = () => {
        const connectorId = window.localStorage.getItem('connectorId');

        if (!isConnectImx || !isOwned) {
            return dispatch(connectImx(connectorId));
        }

        onSubmit();
    }

    return (
        <div className={styles.container}>
            {loadingDetail ? (
                <div className={styles.loadingBox}>
                    <Spinner custom width={30} />
                </div>
            ) : (
                <div
                    className={cn(styles.buttonContainer, {
                        [styles.isDisabled]: disabled
                    })}
                    onClick={() => {
                        if (disabled || connectImxRequesting) return;
                        
                        handleOnClick();
                    }}
                >
                    {connectImxRequesting ? (
                        <Spinner />
                    ) : (
                        buttonLbl
                    )}
                </div>
            )}
        </div>
    )
}

export default React.memo(ImxProvider);