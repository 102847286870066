import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import MobileMenu from '../MobileMenu';
import LandingHamburger from '../LandingHamburger';

import { PAGE_LANDING_PATH } from '../../router/constants';

import logo from '../../assets/img/logo.png';

import style from './LandingHeaderCustom.module.scss';

export default function LandingHeaderCustom(props) {
    const {
        wrappedClass,
        isScrolled,
        navItems,
        onNavItemClick,
        onButtonClick,
        isMobile,
        onMenuOpen,
        onMenuClose,
        isMenuOpen
    } = props;

    return (
        <header className={cn(style.container, wrappedClass, {
            [style.containerScrolled]: isScrolled
        })}>
            <div className={style.content}>
                <div className={style.left}>
                    <Link className={style.logoLink} to={PAGE_LANDING_PATH}>
                        <img src={logo} alt={''} className={style.logo}/>
                    </Link>
                    {isMobile ? (
                        <MobileMenu
                            items={navItems}
                            onItemClick={onNavItemClick}
                            onClose={onMenuClose}
                            isOpen={isMenuOpen}
                            onTelegramClick={onButtonClick}
                        />
                    ) : (
                        <nav className={style.menuWrapper}>
                            <ul className={style.menu}>
                                {navItems.map((item, i) => (
                                    <li className={style.menuItem} key={i}>
                                        <button
                                            className={style.menuLink}
                                            onClick={() => onNavItemClick(item)}
                                        >
                                            {item.name}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    )}
                </div>
                
                <div className={style.right}>
                    {isMobile && (
                        <LandingHamburger
                            isOpen={isMenuOpen}
                            onClick={onMenuOpen}
                        />
                    )}
                </div>
            </div>
        </header>
    );
}
