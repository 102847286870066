import { createAction, createActions } from 'redux-actions';
import { isEmpty } from 'lodash';
import Web3 from "web3";
import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';
import WalletConnectProvider from "@walletconnect/web3-provider";
import detectGamestopProvider from "@gamestopnft/detect-gamestop-provider";

import UserApi from './api';
import { getWalletConnectProvider } from './utils';
import WalletApi from '../Wallet/api';
import { assignAddress } from '../Wallet/actions';
import { setToast, setWrongNetworkVisible } from '../App/actions';

import http from '../../../services/http';

import { CONNECTION_TYPES } from '../../../components/ConnectToWalletModal/constants';
import { PAGE_LOGIN_ACCOUNT_PATH, PAGE_DASHBOARD_PATH } from '../../../router/constants';
import { networks } from '../../../utils/common';
import { approve } from '../../../utils/callHelpers';
import { connectImxSuccess } from '../Imx/actions';

import { PrivateRoutes } from '../../../pages/Dashboard/Router/constants';

export const loadingConnectAccount = createAction('LOADING_CONNECT_ACCOUNT');
export const setUserAccounts = createAction('SET_USER_ACCOUNTS');
export const setUserToken = createAction('SET_USER_TOKEN');
export const setUserSignKey = createAction('SET_USER_SIGNKEY');
export const setUserNonce = createAction('SET_USER_NONCE');
export const setUserInfo = createAction('SET_USER_INFO');
export const setTokensInfo = createAction('SET_TOKENS_INFO');
export const setUndeadBalance = createAction('SET_UNDEAD_BALANCE');

export const setLogged = createAction('SET_LOGGED');
export const setHighTopLeaderBoard = createAction('SET_HIGH_TOP_LEADER_BOARD');

const NETWORKS_DEV = ['0x1', '0x01', '0x05', '0x5', '0x38', '0x61', '0x89', '0x13881'];
const NETWORKS_PROD = ['0x1', '0x01', '0x38', '0x89'];

const ALLOW_NETWORK = process.env.REACT_APP_DEV === '1' ? NETWORKS_DEV : NETWORKS_PROD;

// const validateNetwork = (chainId) => (dispatch) => {
//     if (!ALLOW_NETWORK.includes(chainId)) {
//         return dispatch(setWrongNetworkVisible(true));
//     }

//     return dispatch(setWrongNetworkVisible(false));
// }

export const { fetchUserInfoRequest, fetchUserInfoSuccess, fetchUserInfoFail } = createActions({
    FETCH_USER_INFO_REQUEST: () => {},
    FETCH_USER_INFO_SUCCESS: data => ({ data }),
    FETCH_USER_INFO_FAIL: error => ({ error }),
});

export const fetchUserInfo = (address) => (dispatch) => {
    dispatch(fetchUserInfoRequest());

    return UserApi.fetchUserInfo(address)
        .then(({ data }) => {
            dispatch(fetchUserInfoSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(fetchUserInfoFail(error));
            return error;
        });
}

const { onApproveRequest, onApproveSuccess, onApproveFail } = createActions({
    ON_APPROVE_REQUEST: () => {},
    ON_APPROVE_SUCCESS: data => ({ data }),
    ON_APPROVE_FAIL: error => ({ error }),
});

export const onApprove = (contract, spenderAddress, address, amount, callbacks) => (dispatch) => {
    dispatch(onApproveRequest());

    return approve(contract, spenderAddress, address, amount, callbacks)
        .then(({ data }) => {
            dispatch(onApproveSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(onApproveFail(error));
            return error;
        });
}

const { setSwitchNetworkRequest, setSwitchNetworkSuccess, setSwitchNetworkFailed } = createActions({
    SET_SWITCH_NETWORK_REQUEST: () => {},
    SET_SWITCH_NETWORK_SUCCESS: data => ({ data }),
    SET_SWITCH_NETWORK_FAILED: error => ({ error }),
});

/** SWITCH NETWORK **/
export const switchNetwork = (chainId, networkName) => async (dispatch) => {
    const connectorId = window.localStorage.getItem('connectorId');
    const isGameStop = window?.gamestop?.connected;

    dispatch(setSwitchNetworkRequest());

    try {
        if (!window.ethersProvider) throw new Error("No crypto wallet found");

        await window.ethersProvider?.provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: isGameStop ? '0x1' : chainId }],
        })
        .then(() => {
            /*
                this condition means walletConnect cannot listen chainChanged method that calls provider.on("chainChanged")
                so we need handle chainId is updated here to set user info
            */
            if (connectorId === CONNECTION_TYPES.walletconnect) {
                setTimeout(async () => {
                    const defaultAccount = await window.ethersProvider.getSigner().getAddress();
                    const account = defaultAccount.toLowerCase();
                    const balance = await window.ethersProvider.getBalance(account);
                    dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
                }, 1000)
            }
        })

        dispatch(setSwitchNetworkSuccess());
        return true;
    } catch (err) {
        if (err.code === 4902) {
            try {
                await window.ethersProvider?.provider.request({
                    method: "wallet_addEthereumChain",
                    params: [
                        ...networks[networkName]
                    ]
                })
                dispatch(setSwitchNetworkSuccess());
            } catch (error) {
                dispatch(setSwitchNetworkFailed());
                throw error;
            }
        } else {
            dispatch(setSwitchNetworkFailed());
            return false;
        }
    }
};

/** CONNECT TO METAMASK **/
const { connectWalletRequest, connectWalletSuccess, connectWalletFail } = createActions({
    CONNECT_WALLET_REQUEST: () => {},
    CONNECT_WALLET_SUCCESS: data => ({ data }),
    CONNECT_WALLET_FAIL: error => ({ error }),
});

export const connectMetaMask = () => async (dispatch, getState) => {
    dispatch(connectWalletRequest());

    // Check metamask is install or not
    if (window.ethereum) {
        if (window.ethereum.isMetaMask) {
            window.ethereum.autoRefreshOnNetworkChange = false;
        }
        const provider = await detectEthereumProvider();
        // If the provider returned by detectEthereumProvider is not the same as
        // window.ethereum, something is overwriting it, perhaps another wallet.
        if (provider !== window.ethereum) {
            window.web3 = new Web3(provider);
        } else {
            window.web3 = new Web3(window.ethereum);
            window.ethersProvider = new ethers.providers.Web3Provider(window.ethereum, 'any');
        }

        window.ethereum.on('connect', async (connectInfo) => {
        });

        window.ethereum && window.ethereum.on('accountsChanged', async (accounts) => {
            const isConnect = getState().user?.connectWallet?.isConnect;

            if (!isConnect) return;

            window.localStorage.setItem('tempoAddress', '');

            if (window.ethereum.selectedAddress) {
                const chainId = window.ethereum.chainId;
                const account = accounts[0]?.toLowerCase();
                const balance = await window.ethersProvider.getBalance(account);

                if (!ALLOW_NETWORK.includes(chainId)) {
                    return dispatch(setWrongNetworkVisible(true));
                }

                dispatch(setWrongNetworkVisible(false));

                /** Sign **/
                const { data } = await WalletApi.getUserNonceKey(account, chainId);
                dispatch(setUserNonce(data));
                window.localStorage.setItem('nonce', data.nonce);

                const result = await dispatch(web3Auth(account));

                if (result) {
                    dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
                    dispatch(connectWalletSuccess());

                    return true;
                } else {
                    dispatch(connectWalletFail());
                    dispatch(setUserAccounts({ accounts: [] }));

                    return false;
                }
            } else {
                dispatch(loadingConnectAccount(false));
                dispatch(connectWalletFail());
                dispatch(disconnect());
            }
        });

        window.ethereum && window.ethereum.on('chainChanged', async (chainId) => {
            if (!ALLOW_NETWORK.includes(chainId)) {
                return dispatch(setWrongNetworkVisible(true));
            }

            dispatch(setWrongNetworkVisible(false));

            const privatePaths = PrivateRoutes.map(e => e.path);

            if (privatePaths.includes(window?.location?.pathname)) {
                const token = getState().user?.userAccount?.token;
                const defaultAccount = await window.ethersProvider.getSigner().getAddress();
                const account = defaultAccount.toLowerCase();
                const balance = await window.ethersProvider.getBalance(account);

                if (account) {
                    if (token) {
                        dispatch(connectWalletSuccess());
                        dispatch(setUserAccounts({ accounts: [account], balance, chainId }));

                        return true;
                    }

                    const { data } = await WalletApi.getUserNonceKey(account, chainId);

                    dispatch(setUserNonce(data));

                    const result = await dispatch(web3Auth(account));

                    if (result) {
                        dispatch(connectWalletSuccess());
                        dispatch(setUserAccounts({ accounts: [account], balance, chainId }));

                        return true;
                    }

                    dispatch(connectWalletFail());

                    return false;
                }
            } else {
                dispatch(disconnect());
            }
        });

        return window.ethereum.request({ method: 'eth_requestAccounts' })
            .then(async () => {
                const chainId = window.ethereum.chainId;
                const defaultAccount = await window.ethersProvider.getSigner().getAddress();
                const account = defaultAccount.toLowerCase();
                const balance = await window.ethersProvider.getBalance(account);
                const currentAddress = window.localStorage.getItem('tempoAddress');

                if (!ALLOW_NETWORK.includes(chainId)) {
                    return dispatch(setWrongNetworkVisible(true));
                }

                dispatch(setWrongNetworkVisible(false));

                /** Sign **/
                if (!currentAddress) {
                    const { data } = await WalletApi.getUserNonceKey(account, chainId);
                    dispatch(setUserNonce(data));
                    window.localStorage.setItem('nonce', data.nonce);
                }

                const result = await dispatch(web3Auth(account));

                if (result) {
                    dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
                    dispatch(connectWalletSuccess());

                    return true;
                } else {
                    dispatch(connectWalletFail());
                    dispatch(setUserAccounts({ accounts: [] }));

                    return false;
                }
            })
            .catch((error) => {
                dispatch(connectWalletFail(error));
                dispatch(disconnect());

                return false;
            });
    }

    return new Promise((resolve, reject) => {
      const err = 'Please install MetaMask!';

      resolve(err);
      dispatch(disconnect());
      dispatch(setToast({ title: 'Wrong Wallet', detail: err }));

      return dispatch(connectWalletFail(err));
    });
};

export const connectGameStop = () => async (dispatch, getState) => {
    dispatch(connectWalletRequest());

    // Check gamestop is install or not
    if (window.gamestop) {
        if (window?.gamestop?.isGamestop) {
            window.ethereum.autoRefreshOnNetworkChange = false;
        }

        const provider = await detectGamestopProvider();

        if (provider !== window.gamestop) {
            window.web3 = new Web3(provider);
        } else {
            window.web3 = new Web3(window.gamestop);
            window.ethersProvider = new ethers.providers.Web3Provider(window.gamestop, 'any');
        }

        provider && provider.on('accountsChanged', async (accounts) => {
            const isConnect = getState().user?.connectWallet?.isConnect;

            if (!isConnect) return;

            window.localStorage.setItem('tempoAddress', '');

            if (window.gamestop.currentAddress) {
                const chainId = await provider.chainId();
                const account = accounts[0]?.toLowerCase();
                const balance = await window.ethersProvider.getBalance(account);

                if (!ALLOW_NETWORK.includes(chainId)) {
                    return dispatch(setWrongNetworkVisible(true));
                }

                dispatch(setWrongNetworkVisible(false));

                /** Sign **/
                const { data } = await WalletApi.getUserNonceKey(account, chainId);
                dispatch(setUserNonce(data));
                window.localStorage.setItem('nonce', data.nonce);

                const result = await dispatch(web3Auth(account));

                if (result) {
                    dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
                    dispatch(connectWalletSuccess());

                    return true;
                } else {
                    dispatch(connectWalletFail());
                    dispatch(setUserAccounts({ accounts: [] }));

                    return false;
                }
            } else {
                dispatch(loadingConnectAccount(false));
                dispatch(connectWalletFail());
                dispatch(disconnect());
            }
        });

        provider && provider.on('chainChanged', async (chainId) => {
            if (!ALLOW_NETWORK.includes(chainId)) {
                return dispatch(setWrongNetworkVisible(true));
            }

            dispatch(setWrongNetworkVisible(false));

            if (window?.location?.pathname === PAGE_DASHBOARD_PATH) {
                const token = getState().user?.userAccount?.token;
                const defaultAccount = await window.ethersProvider.getSigner().getAddress();
                const account = defaultAccount.toLowerCase();
                const balance = await window.ethersProvider.getBalance(account);

                if (account) {
                    if (token) {
                        dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
                        dispatch(connectWalletSuccess());

                        return true;
                    } else {
                        const { data } = await WalletApi.getUserNonceKey(account, chainId);

                        dispatch(setUserNonce(data));

                        const result = await dispatch(web3Auth(account));
                        if (result) {
                            dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
                            dispatch(connectWalletSuccess());

                          return true;
                        } else {
                            dispatch(connectWalletFail());

                            return false;
                        }
                    }
                }
            } else {
                dispatch(disconnect());
            }
        });

        provider.on('disconnect', async (connectInfo) => {
            dispatch(disconnect());
        });

        return provider.request({ method: 'eth_requestAccounts' })
            .then(async () => {
                const chainId = provider.chainID || await provider.chainId();
                const defaultAccount = await window.ethersProvider.getSigner().getAddress();
                const account = defaultAccount.toLowerCase();
                const balance = await window.ethersProvider.getBalance(account);
                const currentAddress = window.localStorage.getItem('tempoAddress');

                if (!ALLOW_NETWORK.includes(chainId)) {
                    return dispatch(setWrongNetworkVisible(true));
                }

                dispatch(setWrongNetworkVisible(false));

                /** Sign **/
                if (ethers.utils.isAddress(currentAddress || account)) {
                    const { data } = await WalletApi.getUserNonceKey(account, chainId);
                    dispatch(setUserNonce(data));
                    window.localStorage.setItem('nonce', data.nonce);
                }

                const result = await dispatch(web3Auth(account));

                if (result) {
                    dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
                    dispatch(connectWalletSuccess());

                    return true;
                } else {
                    dispatch(setUserAccounts({ accounts: [] }));
                    dispatch(connectWalletFail());

                    return false;
                }
            })
            .catch((error) => {
                dispatch(disconnect());
                dispatch(connectWalletFail(error));

                return false;
            });
    }

    return new Promise((resolve, reject) => {
      const err = 'Please install GameStop!';

      resolve(err);
      dispatch(disconnect());
      dispatch(setToast({ title: 'Wrong Wallet', detail: err }));

      return dispatch(connectWalletFail(err));
    });
};

/** CONNECT TO WALLET CONNECT **/
export const connectToWalletConnect = () => async (dispatch, getState) => {
    try {
        const provider = await getWalletConnectProvider();

        dispatch(connectWalletRequest());

        const defaultAccount = await window.ethersProvider.getSigner().getAddress();
        const account = defaultAccount.toLowerCase();
        const balance = await window.ethersProvider.getBalance(account);
        const chainId = `0x${Number(provider.chainId).toString(16)}`;

        const token = getState().user?.userAccount?.token;
        const isConnected = getState().user?.connectWallet?.isConnect;

        if (!ALLOW_NETWORK.includes(chainId)) {
            return dispatch(setWrongNetworkVisible(true));
        }

        dispatch(setWrongNetworkVisible(false));

        if (token && isConnected) {
            dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
            dispatch(connectWalletSuccess());

            return true;
        } else {
            const currentAddress = window.localStorage.getItem('tempoAddress');

            if (!currentAddress) {
                const { data } = await WalletApi.getUserNonceKey(account, chainId);
                dispatch(setUserNonce(data));
                window.localStorage.setItem('nonce', data.nonce);
            }

            const result = await dispatch(web3Auth(account));

            if (result) {
                dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
                dispatch(connectWalletSuccess());

                return true;
            } else {
                dispatch(setUserAccounts({ accounts: [] }));
                dispatch(connectWalletFail('FAILED'));
            }
        }

        provider.on("connect", async (connectInfo) => {})

        provider.on("accountsChanged", async (accounts) => {
            dispatch(disconnect());
        });

        // Subscribe to chainId change
        provider.on("chainChanged", async (chainId) => {
            const hexChainId = `0x${Number(chainId).toString(16)}`;

            if (!ALLOW_NETWORK.includes(hexChainId)) {
                return dispatch(setWrongNetworkVisible(true));
            }

            dispatch(setWrongNetworkVisible(false));

            if (window.location.pathname === PAGE_DASHBOARD_PATH) {
                const defaultAccount = await window.ethersProvider.getSigner().getAddress();
                const account = defaultAccount.toLowerCase();
                const balance = await window.ethersProvider.getBalance(account);

                if (token) {
                    dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
                    dispatch(connectWalletSuccess());

                    return true;
                } else {
                    const { data } = await WalletApi.getUserNonceKey(account, chainId);
                    dispatch(setUserNonce(data));

                    const result = await dispatch(web3Auth(account));

                    if (result) {
                        dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
                        dispatch(connectWalletSuccess());

                        return true;
                    } else {
                        dispatch(connectWalletFail());

                        return false;
                    }
                }
            } else {
                dispatch(disconnect());
            }
        });

        provider.on('disconnect', () => {
            dispatch(disconnect());
            window.web3 = undefined;
        });

        return true;
    } catch (error) {
        dispatch(connectWalletFail(error));
        dispatch(disconnect());
        return false;
    }
};

const autoConnectMetamask = () => async (dispatch, getState) => {
    if (window.ethereum) {
        if (window.ethereum.isMetaMask) {
            window.ethereum.autoRefreshOnNetworkChange = false;
        }

        const provider = await detectEthereumProvider();
        // If the provider returned by detectEthereumProvider is not the same as
        // window.ethereum, something is overwriting it, perhaps another wallet.
        if (provider !== window.ethereum) {
            window.web3 = new Web3(provider);
        } else {
            window.web3 = new Web3(window.ethereum);
            window.ethersProvider = new ethers.providers.Web3Provider(window.ethereum, 'any');
        }

        window.ethereum.request({ method: 'eth_accounts' }).then(async (accounts) => {
            const chainId = window.ethereum.chainId;
            const account = accounts[0]?.toLowerCase();

            if (!ALLOW_NETWORK.includes(chainId)) {
                return dispatch(setWrongNetworkVisible(true));
            }

            dispatch(setWrongNetworkVisible(false));

            if (isEmpty(accounts)) {
                dispatch(connectWalletFail());
                dispatch(setUserAccounts({ accounts: [] }));
            } else {
                const balance = await window.ethersProvider.getBalance(account);
                const token = getState().user?.userAccount?.token;
                const initAddress = getState().user?.userInfo?.result?.address?.toLowerCase();
                const currentAddress = window.localStorage.getItem('tempoAddress');

                if (initAddress === account) {
                    if (token) {
                        dispatch(connectWalletSuccess());
                        dispatch(setUserAccounts({ accounts: [account], balance, chainId }));

                        return true;
                    } else {
                        if (!currentAddress) {
                            const { data } = await WalletApi.getUserNonceKey(account, chainId);
                            dispatch(setUserNonce(data));
                        }

                        const result = await dispatch(web3Auth(account));

                        if (result) {
                            dispatch(connectWalletSuccess());
                            dispatch(setUserAccounts({ accounts: [account], balance, chainId }));

                            return true;
                        } else {
                            dispatch(connectWalletFail());
                            dispatch(setUserAccounts({ accounts: [] }));

                            return false;
                        }
                    }
                }
            }
        });

        window.ethereum && window.ethereum.on('accountsChanged', async (accounts) => {
            const isConnect = getState().user?.connectWallet?.isConnect;

            window.localStorage.setItem('tempoAddress', '');

            if (!isConnect) return;

            if (window.ethereum.selectedAddress) {
                const chainId = window.ethereum.chainId;
                const account = accounts[0]?.toLowerCase();
                const balance = await window.ethersProvider.getBalance(account);

                if (!ALLOW_NETWORK.includes(chainId)) {
                    return dispatch(setWrongNetworkVisible(true));
                }

                dispatch(setWrongNetworkVisible(false));

                /** Sign **/
                const { data } = await WalletApi.getUserNonceKey(account, chainId);
                dispatch(setUserNonce(data));
                window.localStorage.setItem('nonce', data.nonce);

                const result = await dispatch(web3Auth(account));

                if (result) {
                    dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
                    dispatch(connectWalletSuccess());

                    return true;
                } else {
                    dispatch(connectWalletFail());
                    dispatch(setUserAccounts({ accounts: [] }));

                    return false;
                }
            } else {
                dispatch(loadingConnectAccount(false));
                dispatch(connectWalletFail());
                dispatch(disconnect());
            }
        });

        window.ethereum && window.ethereum.on('chainChanged', async (chainId) => {
            if (!ALLOW_NETWORK.includes(chainId)) {
                return dispatch(setWrongNetworkVisible(true));
            }

            dispatch(setWrongNetworkVisible(false));

            const privatePaths = PrivateRoutes.map(e => e.path);

            if (privatePaths.includes(window?.location?.pathname)) {
                const defaultAccount = await window.ethersProvider.getSigner().getAddress();
                const account = defaultAccount.toLowerCase();

                if (account) {
                    const balance = await window.ethersProvider.getBalance(account);
                    const token = getState().user?.userAccount?.token;
                    // const currentAddress = window.localStorage.getItem('tempoAddress');

                    if (token) {
                        dispatch(connectWalletSuccess());
                        dispatch(setUserAccounts({ accounts: [account], balance, chainId }));

                        return true;
                    } else {
                        if (ethers.utils.isAddress(account)) {
                            const { data } = await WalletApi.getUserNonceKey(account, chainId);
                            dispatch(setUserNonce(data));
                        }

                        const result = await dispatch(web3Auth(account));

                        if (result) {
                            dispatch(connectWalletSuccess());
                            dispatch(setUserAccounts({ accounts: [account], balance }));

                            return true;
                        } else {
                            dispatch(connectWalletFail());
                            dispatch(setUserAccounts({ accounts: [] }));

                            return false;
                        }
                    }
                }
            } else {
                dispatch(disconnect());
            }
        });
    }
};

const autoConnectGameStop = () => async (dispatch, getState) => {
    if (window.gamestop) {
        if (window?.gamestop?.isGamestop) {
            window.ethereum.autoRefreshOnNetworkChange = true;
        }

        const provider = await detectGamestopProvider();

        if (provider !== window.gamestop) {
            window.web3 = new Web3(provider);
        } else {
            window.web3 = new Web3(window.gamestop);
            window.ethersProvider = new ethers.providers.Web3Provider(window.gamestop, 'any');
        }

        provider.request({ method: 'eth_accounts' }).then(async (accounts) => {
            const chainId = await provider.chainId();
            const account = accounts[0]?.toLowerCase();
            const initAddress = getState().user?.userInfo?.result?.address.toLowerCase();

            if (!ALLOW_NETWORK.includes(chainId)) {
                return dispatch(setWrongNetworkVisible(true));
            }

            dispatch(setWrongNetworkVisible(false));

            if (isEmpty(accounts)) {
                dispatch(setUserAccounts({ accounts: [] }));
            } else {
                const balance = await window.ethersProvider.getBalance(account);
                const token = getState().user?.userAccount?.token;

                if (initAddress === account) {
                    if (token) {
                        dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
                        dispatch(connectWalletSuccess());

                        return true;
                    } else {
                        const { data } = await WalletApi.getUserNonceKey(account, chainId);
                        dispatch(setUserNonce(data));

                        const result = await dispatch(web3Auth(account));

                        if (result) {
                            dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
                            dispatch(connectWalletSuccess());

                            return true;
                        } else {
                            dispatch(setUserAccounts({ accounts: [] }));
                            dispatch(connectWalletFail());

                            return false;
                        }
                    }
                }
            }
        });

        provider && provider.on('accountsChanged', async (accounts) => {
            const isConnect = getState().user?.connectWallet?.isConnect;

            if (!isConnect) return;

            window.localStorage.setItem('tempoAddress', '');

            if (window.gamestop.currentAddress) {
                const chainId = await provider.chainId();
                const account = accounts[0]?.toLowerCase();
                const balance = await window.ethersProvider.getBalance(account);

                if (!ALLOW_NETWORK.includes(chainId)) {
                    return dispatch(setWrongNetworkVisible(true));
                }

                dispatch(setWrongNetworkVisible(false));

                /** Sign **/
                const { data } = await WalletApi.getUserNonceKey(account, chainId);
                dispatch(setUserNonce(data));
                window.localStorage.setItem('nonce', data.nonce);

                const result = await dispatch(web3Auth(account));

                if (result) {
                    dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
                    dispatch(connectWalletSuccess());

                    return true;
                } else {
                    dispatch(connectWalletFail());
                    dispatch(setUserAccounts({ accounts: [] }));

                    return false;
                }
            } else {
                console.log('? cham')
                dispatch(loadingConnectAccount(false));
                dispatch(connectWalletFail());
                dispatch(disconnect());
            }
        });

        provider && provider.on('chainChanged', async (chainId) => {
            if (!ALLOW_NETWORK.includes(chainId)) {
                return dispatch(setWrongNetworkVisible(true));
            }

            dispatch(setWrongNetworkVisible(false));

            if (window.location.pathname === PAGE_DASHBOARD_PATH) {
                const defaultAccount = await window.ethersProvider.getSigner().getAddress();
                const account = defaultAccount.toLowerCase();

                if (account) {
                    const balance = await window.ethersProvider.getBalance(account);
                    const token = getState().user?.userAccount?.token;

                    if (token) {
                        dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
                        dispatch(connectWalletSuccess());

                        return true;
                    } else {
                        const { data } = await WalletApi.getUserNonceKey(account, chainId);
                        dispatch(setUserNonce(data));

                        const result = await dispatch(web3Auth(account));

                        if (result) {
                            dispatch(setUserAccounts({ accounts: [account], balance }));
                            dispatch(connectWalletSuccess());

                            return true;
                        } else {
                            dispatch(setUserAccounts({ accounts: [] }));
                            dispatch(connectWalletFail());

                            return false;
                        }
                    }
                }
            } else {
                dispatch(disconnect());
            }
        });

        provider.on('disconnect', async (connectInfo) => {
            dispatch(disconnect());
        });
    }
};

const autoConnectWallet = () => async (dispatch, getState) => {
    try {
        const POLLING_INTERVAL = 12000;
        const provider = new WalletConnectProvider({
            infuraId: process.env.REACT_APP_INFURA_ID,
            rpc: {
                1: process.env.REACT_APP_ETHER_WEB3_PROVIDER_MAINNET_HTTPS,
                5: process.env.REACT_APP_ETHER_WEB3_PROVIDER_TESTNET_HTTPS,
                56: process.env.REACT_APP_BSC_WEB3_PROVIDER_MAINNET_HTTPS,
                97: process.env.REACT_APP_BSC_WEB3_PROVIDER_TESTNET_HTTPS,
                137: process.env.REACT_APP_POLYGON_WEB3_PROVIDER_MAINNET_HTTPS,
                80001: process.env.REACT_APP_POLYGON_WEB3_PROVIDER_TESTNET_HTTPS
            },
            bridge: 'https://bridge.walletconnect.org',
            qrcode: true,
            pollingInterval: POLLING_INTERVAL
        });

        //  Enable session (triggers QR Code modal)
        await provider.enable();

        //  Create Web3
        const web3 = new Web3(provider);
        window.web3 = web3;
        window.web3.eth.transactionPollingTimeout = 2700;
        window.provider = provider;
        window.ethersProvider = new ethers.providers.Web3Provider(provider, 'any');

        dispatch(connectWalletRequest());

        const defaultAccount = await window.ethersProvider.getSigner().getAddress();
        const account = defaultAccount.toLowerCase();
        const balance = await window.ethersProvider.getBalance(account);
        const chainId = `0x${Number(provider.chainId).toString(16)}`;

        const token = getState().user?.userAccount?.token;
        const initAddress = getState().user?.userInfo?.result?.address.toLowerCase();

        if (!ALLOW_NETWORK.includes(chainId)) {
            return dispatch(setWrongNetworkVisible(true));
        }

        dispatch(setWrongNetworkVisible(false));

        const currentAddress = window.localStorage.getItem('tempoAddress');

        if (initAddress === account) {
            if (token) {
                dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
                dispatch(connectWalletSuccess());

                return true;
            } else {
                if (ethers.utils.isAddress(currentAddress)) {
                    const { data } = await WalletApi.getUserNonceKey(account, chainId);
                    dispatch(setUserNonce(data));
                    window.localStorage.setItem('nonce', data.nonce);
                }

                const result = await dispatch(web3Auth(account));

                if (result) {
                    dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
                    dispatch(connectWalletSuccess());

                    return true;
                } else {
                    dispatch(setUserAccounts({ accounts: [] }));
                    dispatch(connectWalletFail());
                    dispatch(disconnect());
                }
            }
        }

        provider.on('connect', async () => {
        });

        provider.on('session_update', async (error, payload) => {
        });

        provider.on("accountsChanged", async (accounts) => {
            dispatch(disconnect());
        });

        // Subscribe to chainId change
        provider.on("chainChanged", async (chainId) => {
            const hexChainId = `0x${Number(chainId).toString(16)}`;

            if (!ALLOW_NETWORK.includes(hexChainId)) {
                return dispatch(setWrongNetworkVisible(true));
            }

            dispatch(setWrongNetworkVisible(false));

            if (window.location.pathname === PAGE_DASHBOARD_PATH) {
                const defaultAccount = await window.ethersProvider.getSigner().getAddress();
                const account = defaultAccount.toLowerCase();
                const balance = await window.ethersProvider.getBalance(account);

                if (token) {
                    dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
                    dispatch(connectWalletSuccess());

                    return true;
                } else {
                    const { data } = await WalletApi.getUserNonceKey(account, chainId);
                    dispatch(setUserNonce(data));

                    const result = await dispatch(web3Auth(account));

                    if (result) {
                        dispatch(setUserAccounts({ accounts: [account], balance, chainId }));
                        dispatch(connectWalletSuccess());

                        return true;
                    } else {
                        dispatch(connectWalletFail());

                        return false;
                    }
                }
            } else {
                dispatch(disconnect());
            }
        });

        provider.on('disconnect', () => {
            dispatch(connectWalletFail());
            dispatch(disconnect());
            window.web3 = undefined;
        });

        return true;
    } catch (error) {
        dispatch(connectWalletFail(error));
        dispatch(disconnect());
        return false;
    }
};

export const web3Auth = (address) => async (dispatch, getState) => {
    const isLoading = getState().user?.loadingConnectAccount;

    if (!isLoading) {
        dispatch(loadingConnectAccount(true));

        const nonce = getState().user?.userAccount?.nonce?.nonce ?? '';
        const currentAddress = window.localStorage.getItem('tempoAddress');

        try {
            const signer = window?.ethersProvider?.getSigner();
            const message = ethers.utils.toUtf8String(nonce);

            const signKey = await signer.signMessage(message);

            // const signKey = await window.web3.eth.personal.sign(nonce, address);

            if (!currentAddress) {
                const { data } = await WalletApi.getToken(address, signKey);

                dispatch(setUserSignKey(signKey));
                dispatch(setUserToken(data.token));
                http.setAuthorizationHeader(data.token);
                dispatch(loadingConnectAccount(false));

                if (data?.wallet?.email) {
                    dispatch(fetchUserInfoSuccess({ ...data?.wallet, twitchUsername : data?.wallet?.twitchUsername }));

                    return true;
                }

                if (window.location.pathname === PAGE_LOGIN_ACCOUNT_PATH || window.location.pathname === PAGE_DASHBOARD_PATH) {
                    dispatch(fetchUserInfoSuccess({}));
                    dispatch(setToast({ title: 'Wrong Wallet', detail: 'The connected wallet is not associated with this Wagyu Games account.' }));
                    dispatch(disconnect());

                    return false;
                }

                return true;
            } else {
                dispatch(setUserSignKey(signKey));

                dispatch(assignAddress({ address, sign: signKey }))
                    .then((res) => {
                        if (res?.status >= 500) {
                            dispatch(setToast({ title: 'FAILED', detail: 'Something went wrong' }));
                            dispatch(connectWalletFail(true));
                        } else if ((res?.status >= 400)) {
                            dispatch(setToast({ title: 'ASSIGN FAILED', detail: res?.data?.message }));
                            dispatch(connectWalletFail(true));
                        } else {
                            window.localStorage.setItem('tempoAddress', '');
                            dispatch(fetchUserInfoSuccess({ ...res?.data }));
                        }
                    })
                    .catch(err => {
                        dispatch(setToast({ title: 'FAILED', detail: 'Something went wrong' }));
                        dispatch(connectWalletFail());

                        return err;
                    })

                dispatch(loadingConnectAccount(false));

                return true;
            }
        } catch (error) {
            dispatch(loadingConnectAccount(false));
            dispatch(connectWalletFail(error));

            if (error?.status === 400) {
                dispatch(setToast({ title: 'Wrong Connect', detail: error?.data?.message }));
            }

            return false;
        }
    }
};

export const autoConnect = () => dispatch => {
    const connectorId = window.localStorage.getItem('connectorId');

    if (connectorId === CONNECTION_TYPES.metamask) {
        dispatch(autoConnectMetamask());
    } else if (connectorId === CONNECTION_TYPES.walletconnect) {
        dispatch(autoConnectWallet());
    } else if (connectorId === CONNECTION_TYPES.gamestop) {
        dispatch(autoConnectGameStop());
    }
};

export const disconnect = () => async (dispatch) => {
    dispatch(setLogged(false));
    dispatch(setUserAccounts({ accounts: [] }));
    dispatch(setUserInfo({}));
    dispatch(fetchUserInfoSuccess({}));
    dispatch(connectImxSuccess({}));
    dispatch(setUserNonce({}));
    dispatch(setUserToken(''));
    dispatch(setUserSignKey(''));
    dispatch(loadingConnectAccount(false));

    window.localStorage.clear();
}
