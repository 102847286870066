import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Step1 from './Step1';
import Step2 from './Step2';
import Modal from '../../components/Modal';

import { resetPassword } from '../../store/modules/Wallet/actions';
import { setToast } from '../../store/modules/App/actions';

import styles from './ResetPassword.module.scss';

function ResetPassword(props) {
    const dispatch = useDispatch();

    const { isVisible, onCancel, token } = props;

    const [step, setStep] = useState(1);

    const { requesting } = useSelector(state => state.wallet?.resetPasswordRes);

    const renderStep = () => {
        switch (step) {
        case 1:
            return <Step1 onSubmit={handleOnSubmit} requesting={requesting} />

        case 2:
            return <Step2 onCancel={onCancel} />

        default:
            break;
        }
    }

    const handleOnSubmit = (values, cb) => {
        const body = {
            token: token,
            newPassword: values?.newPassword.trim(),
        };

        dispatch(resetPassword(body))
            .then((res) => {
                if (res?.status >= 500) {
                    dispatch(setToast({ title: 'FAILED', detail: 'Something went wrong' }))
                } else if ((res?.status >= 400)) {
                    dispatch(setToast({ title: 'FAILED', detail: res?.data?.message }));
                } else {
                    setStep(2);
                    cb.resetForm();
                }
            })
            .catch(err => {
                return err;
            })
    }

    return (
        <div className={styles.container}>
            <Modal
                visible={isVisible}
                closable={false}
                maskClosable={!requesting}
                centered
                width={'362rem'}
                onCancel={onCancel}
            >
                {renderStep()}
            </Modal>
        </div>
    );
}

export default ResetPassword;