import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.compact.min.css';

import App from './App';
import store from './store';
import { RefreshContextProvider } from './context/RefreshContext'

import './styles/index.scss';

ReactDOM.render(
    <Provider store={store}>
        <RefreshContextProvider>
            <Router>
                <App/>
            </Router>
        </RefreshContextProvider>
    </Provider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
