import http from '../../../services/http';

export default class Ownership {
    static fetchOwnership(params, config) {
        return http.get('/ownerships', {
            params: {
                ...params
            }
        }, config);
    }

    static fetchOwnershipDetail({
        tokenId,
        contractAddress
    }) {
        return http.get(`/ownerships/${tokenId}`, {
            params: {
                tokenId,
                contractAddress
            }
        })
    }

    static progressOwnership(body) {
        return http.patch('/ownerships/set-pending', body);
    }

    static fetchExclusiveBurned(address) {
        return http.get(`/ownerships/exclusives-burned/${address}`);
    }

    static addExclusiveBurned(body) {
        return http.post('/ownerships/exclusives-burned', body);
    }

    static addExclusiveBoost(body) {
        return http.post('/ownerships/exclusives-boost', body);
    }
}
