import React from 'react';
import { useHistory } from 'react-router-dom';

import logo from '../../assets/landingLayout/logo.png';

import {
    PAGE_LOGIN_ACCOUNT_PATH
} from '../../router/constants';

import styles from './ResetPassword.module.scss';

function Step2(props) {
    const history = useHistory();

    const { onCancel } = props;

    const handleCancel = () => {
        onCancel();
        history.push(PAGE_LOGIN_ACCOUNT_PATH);
    }

    return (
        <div className={styles.content}>
            <img src={logo} alt="" className={styles.logo} />

            <h2 className={styles.title}>Reset Password Success</h2>

            <p className={styles.description}>Password has been reset.</p>

            <div className={styles.btnContainer} onClick={handleCancel}>
                Back to Login
            </div>
        </div>
    );
}

export default Step2;