import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';

import useLootCoffin from './useLootCoffin';
import ConfinsList from './ConffinsList';
import EmptyCoffin from 'components/EmptyCoffin';

import PageSlug from 'components/PageSlug';
import WagyuLoader from 'components/WagyuLoader';

import { PAGE_BUY_LOOT_PATH } from '../../Router/constants';

import styles from './LootConfins.module.scss';

function LootConfins() {
    const history = useHistory();

    const [offset, setOffset] = useState(0);

    const loadingList = useSelector(state => state?.lootConffin?.inventoriesRes?.requesting);

    const { inventoriesList, hasMore, firstLoading } = useLootCoffin({ offset });

    const handleOnClickBuy = () => {
        history.push(PAGE_BUY_LOOT_PATH);
    }

    return (
        <div className={styles.container}>
            <PageSlug
                slug={'MY LOOT COFFINS'}
                description={''}
                hasDevide
                hasElement={
                    <div
                        className={styles.buyButton}
                        onClick={() => {
                            handleOnClickBuy()
                        }}
                    >
                        Buy Loot Coffins
                    </div>
                }
                normal
            />

            {firstLoading ? (
                <div className={styles.firstLoad}>
                    <WagyuLoader />
                </div>
            ) : (
                isEmpty(inventoriesList) ? (
                    <EmptyCoffin
                        label={'Your Loot Coffin inventory is empty'}
                    />
                ) : (
                    <ConfinsList
                        item={inventoriesList}
                        hasMore={hasMore}
                        firstLoading={firstLoading}
                        loading={loadingList}
                        setOffset={setOffset}
                    />
                )
            )}
        </div>
    )
}

export default React.memo(LootConfins);