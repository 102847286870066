import http from '../../../services/http';

export default class LootCoffin {
    static fetchInventories({
        address,
        limit = 10,
        skip = 0
    }) {
        return http.get(`/loot-coffin/inventory/${address}`, {
            params: {
                limit,
                skip
            }
        });
    }

    static fetchRewards() {
        return http.get('/loot-coffin/rewards');
    }

    static fetchStore() {
        return http.get('/loot-coffin/store');
    }

    static buyCoffin(body) {
        return http.post('/loot-coffin/buy', body);
    }

    static unlockCoffin(body, config) {
        return http.post('/loot-coffin/unlock-loot-coffin', body, config);
    }

    static getInventorySignature(body) {
        return http.post('/loot-coffin/inventory-signature', body);
    }

    static getSessionInfo() {
        return http.get('/loot-coffin/session-info');
    }
}
