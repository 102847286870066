import React, { useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';

// import lootIcon from '../../Inventory/img/loot.png';

import { PAGE_LOOT_PATH } from '../../../Router/constants';
import WagyuLoader from 'components/WagyuLoader';

import styles from './ConffinsList.module.scss';

function ConfinsList(props) {
    const history = useHistory();

    const {
        item = [],
        loading,
        hasMore,
        firstLoading,
        setOffset
    } = props;

    const observer = useRef();
    const lastElement = useCallback(node => {
        if (loading) return;

        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && hasMore) {
                    setOffset(prevState => prevState + 10);
                }
            });
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        });

        if (node) observer.current.observe(node);
    }, [loading, hasMore, setOffset]);

    const handleOnClickItem = (e) => {
        window.localStorage.setItem('coffinItem', JSON.stringify(e));
        history.push(PAGE_LOOT_PATH);
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {!isEmpty(item) && item?.map((e, index) => (
                    <div
                        key={e.id}
                        className={styles.item}
                        style={{ background: `url(${e.thumbnail}) no-repeat center / 100% 100%` }}
                        onClick={() => handleOnClickItem(e)}
                        ref={item.length === index + 1 ? lastElement : null}
                    >
                        {/* <img src={lootIcon} alt="" className={styles.icon} /> */}

                        <p className={styles.name}>LOOT COFFIN #{e.uniqueId}</p>
                    </div>
                ))}
            </div>

            {!firstLoading && loading && (
                <div className={styles.loadMoreContainer}>
                    <WagyuLoader customStyle={styles.loadMore} />
                </div>
            )}
        </div>
    )
}

export default React.memo(ConfinsList);