import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniqBy, isEmpty } from 'lodash';

import { getUserHistories } from '../../store/modules/Withdraw/actions';
import {
    PENDING,
    PROCESSING,
} from './constants';

function useHistories(props) {
    const dispatch = useDispatch();

    const { offset, isVisible, setOffset } = props;

    const [historiesList, setHistoriesList] = useState([]);
    const [hasMore, setHasMore] = useState(false);

    const loaded = useSelector(state => state?.app?.load?.loaded);
    const { logged } = useSelector(state => state?.user ?? {});
    const walletId = useSelector(state => state?.user?.userInfo?.result?.id ?? '');
    const { reloadHistories } = useSelector(state => state?.app ?? null);

    useEffect(() => {
        if (!isVisible) return;
        if (isEmpty(historiesList)) return;

        const hasPending = Array.isArray(historiesList) && historiesList?.filter(e => (e.status === PENDING || e.status === PROCESSING));

        if (isEmpty(hasPending)) return;

        const interval = setInterval(() => {
            dispatch(getUserHistories(walletId, 10, 0))
                .then(res => {
                    setHistoriesList(res);
                    setHasMore(res?.length > 9);
                })
                .catch(err => {
                    return err;
                })
        }, [20000]);

        return (() => {
            clearInterval(interval)
        })
    }, [dispatch, historiesList, walletId, isVisible]);

    // RELOAD LIST
    useEffect(() => {
        if (!isVisible) return;
        if (!reloadHistories) return;

        setOffset(0);

        dispatch(getUserHistories(walletId, 10, 0))
            .then(res => {
                setHistoriesList(res);
                setHasMore(res?.length > 9);
            })
            .catch(err => {
                return err;
            })
    }, [dispatch, reloadHistories, walletId, setOffset, isVisible])

    /* LOAD FIRST */
    useEffect(() => {
        if (!loaded) return;
        if (!logged) return;
        if (!isVisible) return;
        if (reloadHistories) return;
        if (offset > 0) return;

        dispatch(getUserHistories(walletId, 10, 0))
            .then(res => {
                setHistoriesList(res);
                setHasMore(res?.length > 9);
            })
            .catch(err => {
                return err;
            })
    }, [
        isVisible,
        loaded,
        logged,
        offset,
        walletId,
        reloadHistories,
        dispatch
    ]);

    /* LOAD MORE */
    useEffect(() => {
        if (!isVisible) return;
        if (offset === 0) return;

        dispatch(getUserHistories(walletId, 10, offset))
            .then(res => {
                setHistoriesList(prevState => uniqBy([...prevState, ...res], 'id'));
                setHasMore(res?.length > 9);
            })
            .catch(err => {
                return err;
            })
    }, [
        offset,
        walletId,
        isVisible,
        dispatch
    ]);

    return { historiesList, hasMore };
}

export default useHistories;
