import React, { useEffect } from 'react';
import lottie from 'lottie-web';
import cn from 'classnames';

import loaderData from './loader.json';

import styles from './WagyuLoader.module.scss';

function WagyuLoader(props) {
    const loaderId = 'loaderId';

    const { customStyle } = props;

    useEffect(() => {
        let bgAnim = lottie.loadAnimation({
            container: document.getElementById(loaderId),
            animationData: loaderData,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            name: 'bg-animation',
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        });

        bgAnim.play();

        return () => {
            bgAnim.destroy();
        }
    }, []);

    return (
        <div className={styles.container}>
            <div id={loaderId} className={cn(styles.loaderAnim, customStyle)} />
        </div>
    );
}

export default WagyuLoader;