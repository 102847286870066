import http from '../../../services/http';

export default class Wallet {
    static register(body) {
        return http.post('/wallets/wagyu-register', body);
    }

    static checkExists(params) {
        return http.get('/wallets/check-exists', {
            params: {
                username: params.username,
                email: params.email,
                address: params.address
            }
        });
    }

    static getUserNonceKey(address, chainId) {
        return http.get(`/wallets/nonce/${address}/chainId/${chainId}`)
    }

    static getToken(address, signKey) {
        return http.post('/wallets/login', {
            address,
            sign: signKey
        })
    }

    static login(body) {
        return http.post('/wallets/wagyu-login', body);
    }

    static changePassword(body) {
        return http.post('/wallets/wagyu-change-password', body);
    }

    static forgotPassword(body) {
        return http.post('/wallets/wagyu-forgot-password', body);
    }

    static resetPassword(body) {
        return http.post('/wallets/wagyu-reset-password', body);
    }

    static verifyEmail(params) {
        return http.get('/wallets/verify-email', {
            params: {
                token: params.token
            }
        });
    }

    static finishVerify(id, body) {
        return http.patch(`/wallets/${id}`, body);
    }

    static assignAddress(body) {
        return http.post('/wallets/wagyu-assign-address', body);
    }

    static getLeaderBoard({
        tournamentId = '',
        page = 1,
        email = 'ub.testing@gmail.com'
    }) {
        return http.get(`/leaderboards/${tournamentId}`, {
            params: {
                leaderboardId: tournamentId,
                email,
                number: page - 1
            }
        })
    }

    static twitchValidate(code) {
        return http.post(`/wallets/twitch-validate/${code}`);
    }
}
