import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import UploadMedia from '../Form/UploadMedia';
import Spinner from '../Spinner';

import styles from './GoingApp.module.scss';

function Step1(props) {
    const { token, onFinish } = props;

    const [avatar, setAvatar] = useState(null);
    const [upload, setUpload] = useState(false);

    const finishing = useSelector(state => state?.wallet?.finishVerifyRes?.requesting);

    const handleOnChangeAvatar = (err) => (value) => {
        !err ? setAvatar(value) : setAvatar(null)
    }

    return (
        <div className={styles.content}>
            <UploadMedia
                onChange={handleOnChangeAvatar}
                uploadUrl='/media/user-upload'
                token={token}
                setUpload={setUpload}
            />

            <h2 className={styles.title}>Add Profile Picture</h2>

            <p className={styles.description}>Customize your Wagyu Games profile by adding a custom profile picture.</p>

            <div
                className={cn(styles.btnContainer, {
                    [styles.disable]: !avatar
                })}
                onClick={() => {
                    (avatar && !upload) && onFinish(avatar)
                }}
            >
                Finish
                {finishing && <Spinner alignRight />}
            </div>
        </div>
    );
}

export default Step1;