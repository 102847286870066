import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserStatistic, getExchangeStatus } from './actions';

import { setUserAccounts } from '../User/actions';

import { getProviderWeb3 } from '../../../utils/provider';

export function UseWithdrawInit() {
    /*
        Basic initialization logic for the module:
        init scripts, event listeners, etc...
    */

    const dispatch = useDispatch();

    const { loaded } = useSelector(state => state?.app?.load);
    const { logged } = useSelector(state => state?.user ?? {});
    const { isConnect } = useSelector(state => state?.user?.connectWallet ?? {});

    const address = useSelector(state => state?.user?.userAccount?.accounts[0] ?? '')?.toLowerCase();
    const userAddress = useSelector(state => state?.user?.userInfo?.result?.address ?? '');

    const { reloadHistories } = useSelector(state => state?.app ?? null);

    useEffect(() => {
        if (!loaded) return;
        if (!isConnect) return;
        if (!logged) return;

        const refreshUserInfo = async () => {
            const balance = await getProviderWeb3().getBalance(address);

            dispatch(getExchangeStatus(address));
            dispatch(getUserStatistic(address));
            dispatch(setUserAccounts({ accounts: [address], balance }));
        }

        if (userAddress === address) {
            refreshUserInfo();
        }
    }, [
        dispatch,
        loaded,
        logged,
        isConnect,
        address,
        userAddress,
        reloadHistories,
    ]);
}