import twitterIcon from '../../../../assets/dashboardLayout/footer/twitter.png';
import discordIcon from '../../../../assets/dashboardLayout/footer/discord.png';
import telegramIcon from '../../../../assets/dashboardLayout/footer/telegram.png';
import youtubeIcon from '../../../../assets/dashboardLayout/footer/youtube.png';
import undeadIcon from '../../../../assets/dashboardLayout/footer/UB_desktop.png';
import openSeaIcon from '../../../../assets/dashboardLayout/footer/opensea_ desktop.png';

export const FOOTER_ITEMS = [
    {
        label: 'twitter',
        icon: twitterIcon,
        styling: { width: '14.5rem', height: '12.5rem' },
        path: 'https://twitter.com/UndeadBlocks'
    },
    {
        label: 'discord',
        icon: discordIcon,
        styling: { width: '14.5rem', height: '11rem' },
        path: 'https://discord.com/invite/S53qxR5b55'
    },
    {
        label: 'telegram',
        icon: telegramIcon,
        styling: { width: '12rem', height: '10rem' },
        path: 'https://t.me/undeadblocks'
    },
    {
        label: 'youtube',
        icon: youtubeIcon,
        styling: { width: '15.5rem', height: '11rem' },
        path: ' https://www.youtube.com/channel/UCYT36nL1xaqzF36u_sZi-Gg'
    },
    {
        label: 'undeadBlocks',
        icon: undeadIcon,
        styling: { width: '100%', height: '100%' },
        path: 'https://www.undeadblocks.com/'
    },
    {
        label: 'openSea',
        icon: openSeaIcon,
        styling: { width: '100%', height: '100%' },
        path: 'https://opensea.io/wagyu_games?tab=created_collections'
    }
];