import React from 'react';
import cn from 'classnames';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import styles from './Spinner.module.scss';

function Spinner(props) {
    const { loadingWrapper, alignLeft, alignRight, custom, width } = props;

    return (
        <div className={styles.container}>
            <Spin
                indicator={
                    <LoadingOutlined
                        style={{ fontSize: `${width}rem` }}
                        className={cn(styles.loading, loadingWrapper, {
                            [styles.left]: alignLeft,
                            [styles.right]: alignRight,
                            [styles.custom]: custom
                        })}
                        spin
                    />
                }
             />
        </div>
    );
}

export default Spinner;