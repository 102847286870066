import React, { PureComponent } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import {
    PrivateRoutes
} from './constants';

import NotFound from '../../NotFound';

// @withRouter
class DashboardRouter extends PureComponent {
    componentDidUpdate(prevProps) {
        const {location} = this.props;

        if (location.key !== prevProps.location.key) {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    }

    render() {
        return (
            <Switch>
                {PrivateRoutes.map(route => {
                    const Page = route.component;

                    return (
                        <Route exact path={route.path} component={Page} key={route.path} />
                    )
                })}
                <Route component={NotFound} key="*" />
            </Switch>
        )
    }
}

export default withRouter(DashboardRouter);