import { createAction, createActions } from 'redux-actions';

import { groupBy } from 'lodash';

import LootCoffinApi from './api';
import { buyPackage } from 'utils/callHelpers';

export const getCommonRewards = createAction('GET_COMMON_REWARDS');
export const getRareRewards = createAction('GET_RARE_REWARDS');
export const getEpicRewards = createAction('GET_EPIC_REWARDS');
export const getLegendaryRewards = createAction('GET_LEGENDARY_REWARDS');

export const { fetchInventoriesRequest, fetchInventoriesSuccess, fetchInventoriesFail } = createActions({
    FETCH_INVENTORIES_REQUEST: () => {},
    FETCH_INVENTORIES_SUCCESS: data => ({ data }),
    FETCH_INVENTORIES_FAIL: error => ({ error }),
});

export const fetchInventories = (address, limit, skip) => async (dispatch) => {
    dispatch(fetchInventoriesRequest());

    return LootCoffinApi.fetchInventories({ address, limit, skip })
        .then(({ data }) => {
            dispatch(fetchInventoriesSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(fetchInventoriesFail(error));
            return error;
        });
}

export const { fetchStoreRequest, fetchStoreSuccess, fetchStoreFail } = createActions({
    FETCH_STORE_REQUEST: () => {},
    FETCH_STORE_SUCCESS: data => ({ data }),
    FETCH_STORE_FAIL: error => ({ error }),
});

export const fetchStore = () => async (dispatch) => {
    dispatch(fetchStoreRequest());

    return LootCoffinApi.fetchStore()
        .then(({ data }) => {
            dispatch(fetchStoreSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(fetchStoreFail(error));
            return error;
        });
}

export const { buyCoffinRequest, buyCoffinSuccess, buyCoffinFail } = createActions({
    BUY_COFFIN_REQUEST: () => {},
    BUY_COFFIN_SUCCESS: data => ({ data }),
    BUY_COFFIN_FAIL: error => ({ error }),
});

export const buyCoffin = (body) => async (dispatch) => {
    dispatch(buyCoffinRequest());

    return LootCoffinApi.buyCoffin(body)
        .then(({ data }) => {
            dispatch(buyCoffinSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(buyCoffinFail(error));
            return error;
        });
}

export const { unlockCoffinRequest, unlockCoffinSuccess, unlockCoffinFail } = createActions({
    UNLOCK_COFFIN_REQUEST: () => {},
    UNLOCK_COFFIN_SUCCESS: data => ({ data }),
    UNLOCK_COFFIN_FAIL: error => ({ error }),
});

export const unlockCoffin = (body, config) => async (dispatch) => {
    dispatch(unlockCoffinRequest());

    return LootCoffinApi.unlockCoffin(body, config)
        .then(({ data }) => {
            dispatch(unlockCoffinSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(unlockCoffinFail(error));
            return error;
        });
}

export const { fetchRewardsRequest, fetchRewardsSuccess, fetchRewardsFail } = createActions({
    FETCH_REWARDS_REQUEST: () => {},
    FETCH_REWARDS_SUCCESS: data => ({ data }),
    FETCH_REWARDS_FAIL: error => ({ error }),
});

export const fetchRewards = () => async (dispatch) => {
    dispatch(fetchRewardsRequest());

    return LootCoffinApi.fetchRewards()
        .then(({ data }) => {
            dispatch(fetchRewardsSuccess(data));

            const rewardsType = groupBy(data, 'rarityType');

            dispatch(getCommonRewards(rewardsType?.Common));
            dispatch(getRareRewards(rewardsType?.Rare));
            dispatch(getEpicRewards(rewardsType?.Epic));
            dispatch(getLegendaryRewards(rewardsType?.Legendary));

            return data;
        })
        .catch(error => {
            dispatch(fetchRewardsFail(error));
            return error;
        });
}

export const { getInventorySignatureRequest, getInventorySignatureSuccess, getInventorySignatureFail } = createActions({
    GET_INVENTORY_SIGNATURE_REQUEST: () => {},
    GET_INVENTORY_SIGNATURE_SUCCESS: data => ({ data }),
    GET_INVENTORY_SIGNATURE_FAIL: error => ({ error }),
});

export const getInventorySignature = (body) => async (dispatch) => {
    dispatch(getInventorySignatureRequest());

    return LootCoffinApi.getInventorySignature(body)
        .then(({ data }) => {
            dispatch(getInventorySignatureSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(getInventorySignatureFail(error));
            return error;
        });
}

const { buyPackageRequest, buyPackageSuccess, buyPackageFail } = createActions({
    BUY_PACKAGE_REQUEST: () => {},
    BUY_PACKAGE_SUCCESS: data => (data),
    BUY_PACKAGE_FAIL: error => (error),
});

export const handleBuyPackage = (presentContract, address, params, callbacks) => (dispatch) => {
    dispatch(buyPackageRequest());

    return buyPackage(presentContract, address, params, callbacks)
        .then((data) => {
            dispatch(buyPackageSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(buyPackageFail(error));
            return error;
        });
}

const { getSessionInfoRequest, getSessionInfoSuccess, getSessionInfoFail } = createActions({
    GET_SESSION_INFO_REQUEST: () => {},
    GET_SESSION_INFO_SUCCESS: data => (data),
    GET_SESSION_INFO_FAIL: error => (error),
});

export const getSessionInfo = () => (dispatch) => {
    dispatch(getSessionInfoRequest());

    return LootCoffinApi.getSessionInfo()
        .then((data) => {
            dispatch(getSessionInfoSuccess(data));
            return data;
        })
        .catch(error => {
            dispatch(getSessionInfoFail(error));
            return error;
        });
}