import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { PAGE_BUY_LOOT_PATH } from '../../pages/Dashboard/Router/constants';

import styles from './EmptyCoffin.module.scss';

function EmptyCoffin(props) {
    const history = useHistory();

    const { label } = props;

    const handleOnClickItem = useCallback(() => {
        window.localStorage.setItem('isStandard', 'false');
        history.push(PAGE_BUY_LOOT_PATH);
    }, [history]);

    return (
        <div className={styles.container}>
            <p className={styles.title}>{label}</p>

            <div className={styles.buttonContainer} onClick={() => handleOnClickItem()}>
                <p>BUY LOOT COFFINS</p>
            </div>
        </div>
    )
}

export default React.memo(EmptyCoffin);