import React, { useRef, useMemo, useCallback } from 'react';
import cn from 'classnames';

import ItemLoader from '../ItemLoader';
import { IMX_STATUS } from '../../../utils/constants';

import styles from './NftItem.module.scss';

function NftItem(props) {
    const {
        item,
        setOffset,
        hasMore,
        loading,
        index,
        list,
        onViewDetail,
        loadFirst
    } = props;

    const isPending = useMemo(() => {
        return item?.pending;
    }, [item?.pending]);

    const imxStatus = useMemo(() => {
        return item?.imxStatus;
    }, [item?.imxStatus]);

    const statusLbl = () => {
        if (!isPending && (imxStatus === IMX_STATUS.eth || imxStatus === IMX_STATUS.imx)) return '';

        if (imxStatus === IMX_STATUS.withdrawable) {
            return 'Withdrawable';
        }

        return 'In Progress';
    }

    // LOAD MORE
    const observer = useRef();
    const lastElement = useCallback(node => {
        if (loading) return;

        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && hasMore) {
                    setOffset(prevState => prevState + 20);
                }
            });
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        });

        if (node) observer.current.observe(node);
    }, [loading, hasMore, setOffset]);

    const handleOnClick = (item) => {
        onViewDetail(item);
    }

    return (
        <div
            className={cn(styles.container)}
            onClick={() => handleOnClick(item)}
            ref={list.length === index + 1 ? lastElement : null}
        >
            {loadFirst ? (
                <ItemLoader />
            ) : (
                <div>
                    {item?.pending && <div className={styles.pending}>{statusLbl()}</div>}
                    {item?.isImmutable && <div className={styles.sticker}>IMX</div>}
                    <img
                        src={item?.thumbnail}
                        alt=""
                        className={styles.thumbnail}
                    />
                </div>
            )}
        </div>
    )
}

export default NftItem;