import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { groupBy } from 'lodash';
import $ from 'jquery';

import itemLoot from '../img/loot-item.png';
import devideIcon from '../img/devide.png';

import ViewModal from './ViewModal';

import { LOOT_CONFFINS } from '../constants';
import { PAGE_LOOT_CONFINS_PATH, PAGE_BUY_LOOT_PATH } from '../../../Router/constants';
import { setWeaponRandomVisible } from 'store/modules/App/actions';
import { unlockCoffin } from 'store/modules/LootConffin/actions';
import { setToast } from 'store/modules/App/actions';
import { connectImx, hasConnectedImxEver } from 'store/modules/Imx/actions';

import Spinner from 'components/Spinner';
import PageSlug from 'components/PageSlug';

import styles from './OpenLoot.module.scss';

window.$ = $;
window.jQuery = $;

function OpenLoot() {
    const dispatch = useDispatch();
    const history = useHistory();

    const [weaponType, setWeaponType] = useState('common');
    const [selected, setSelected] = useState(false);
    const [isSliderStart, setSliderStart] = useState(false);
    const [endLoading, setEndLoading] = useState(false);

    const loading = useSelector(state => state?.lootConffin?.unlockCoffinRes?.requesting);
    const rewardsType = useSelector(state => state?.lootConffin?.rewardsType ?? {});
    const isConnectImx = useSelector(state => state?.imx?.userImxInfo?.result?.address ?? '');
    const imxRequesting = useSelector(state => state?.imx?.userImxInfo?.requesting);
    const { isConnect } = useSelector(state => state?.user?.connectWallet ?? {});
    const address = useSelector(state => state?.user?.userAccount?.accounts[0] ?? '')?.toLowerCase();

    const coffinItem = JSON.parse(window.localStorage.getItem('coffinItem'));
    const isRegisterImx = window.localStorage.getItem('isRegisterImx');

    const list = useMemo(() => {
        if (!weaponType) return [];

        let weaponList = [];

        const groups = groupBy(rewardsType[`${weaponType}Rewards`], 'weapon');

        for (const key in groups) {
            if (Object.hasOwnProperty.call(groups, key)) {
                const element = groups[key];

                weaponList.push({
                    type: element[0].weapon,
                    typeList: element
                })
            }
        }

        weaponList.sort((a, b) => {
            if (a.type < b.type) {
                return -1;
            }

            if (a.type > b.type) {
                return 1;
            }

            return 0;
        })

        return weaponList;
    }, [weaponType, rewardsType]);

    const description = useMemo(() => {
        if (!weaponType) return '';

        return `${weaponType} LIST`; 
    }, [weaponType]);

    const openLootLabel = useMemo(() => {
        if (isRegisterImx === 'true') return 'Open Loot Coffin';

        if (!isConnectImx) return 'Connect IMX';

        return 'Open Loot Coffin';
    }, [isConnectImx, isRegisterImx]);

    useEffect(() => {
        if (isConnect) return;

        return () => {
            dispatch(setWeaponRandomVisible(false));
            setSliderStart(false);
        }
    }, [dispatch, isConnect])

    useEffect(() => {
        dispatch(hasConnectedImxEver(address))
            .then((res) => {
                if (!res?.accounts) return window.localStorage.setItem('isRegisterImx', 'false');

                return window.localStorage.setItem('isRegisterImx', 'true');
            })
            .catch(err => {
                window.localStorage.setItem('isRegisterImx', 'false');

                return err;
            })
    }, [dispatch, address, isRegisterImx]);

    const handleOnClickItemType = (type) => {
        setWeaponType(type);
    };

    const handleOnClickBuy = () => {
        window.localStorage.setItem('isStandard', 'false');
        history.push(PAGE_BUY_LOOT_PATH);
    }

    const handleOnClickBack = () => {
        const { action } = history;

        if (action === 'PUSH') {
            history.goBack();
        } else {
            history.push(PAGE_LOOT_CONFINS_PATH);
        }
    };

    const handleOnClickOpen = () => {
        if (isRegisterImx === 'false') {
            if (!isConnectImx) {
                const connectorId = window.localStorage.getItem('connectorId');
    
                return dispatch(connectImx(connectorId));
            }
        }

        dispatch(setWeaponRandomVisible(true));

        const body = {
            contractAddress: coffinItem.contractAddress,
            uniqueId: coffinItem.uniqueId,
            tokenId: coffinItem.tokenId || undefined
        }

        dispatch(unlockCoffin(body))
            .then(res => {
                if (res?.data?.message) {
                    dispatch(setToast({ title: 'UNLOCK COFFIN', detail: res?.data?.message }));
                    dispatch(setWeaponRandomVisible(false));
                    setSliderStart(false);
                    handleOnClickBack();

                    return;
                }

                // setDurationSlider(Math.floor(Math.ceil(endTime - startTime) / 1000));

                setTimeout(() => {
                    setSelected(true);
                    setSliderStart(false);
                }, [9500]);
            })
            .catch(() => {
                dispatch(setToast({ title: 'UNLOCK COFFIN', detail: 'Something went wrong.' }));
                dispatch(setWeaponRandomVisible(false));
                setSliderStart(false);
                handleOnClickBack();
            })

        let openingTimeout = setTimeout(() => {
            setSliderStart(true);
            setEndLoading(true);
        }, [4510]);


        return () => {
            clearTimeout(openingTimeout);
        }

        // setTimeout(() => {
        //     const startTime = Date.now();

        //     dispatch(unlockCoffin(body))
        //         .then((res) => {
        //             const endTime = Date.now();
        //             const convertSecond = Math.ceil((endTime - startTime));
    
        //             if (res?.data?.message) {
        //                 dispatch(setToast({ title: 'UNLOCK COFFIN', detail: res?.data?.message }));
        //                 dispatch(setWeaponRandomVisible(false));
        //                 handleOnClickBack();

        //                 return;
        //             }

        //             if (convertSecond > 7500) {
        //                 setTimeout(() => {
        //                     $(".center").slick('slickGoTo', 5).slick('slickPause', 1);
        //                 }, 2000)
    
        //                 setTimeout(() => {
        //                     setSelected(true);
        //                 }, 4000);
    
        //                 return
        //             }
    
        //             setTimeout(() => {
        //                 setTimeout(() => {
        //                     $(".center").slick('slickGoTo', 5).slick('slickPause', 1);
        //                 }, 2000)
    
        //                 setTimeout(() => {
        //                     setSelected(true);
        //                 }, 4000);
        //             }, 7500 - convertSecond);
        //         })
        //         .catch(() => {
        //             dispatch(setToast({ title: 'UNLOCK COFFIN', detail: 'Something went wrong.' }));
        //             dispatch(setWeaponRandomVisible(false));
        //             handleOnClickBack();
        //         })
        // }, 5010);
    };

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <PageSlug
                    slug={'Undead Blocks Loot Coffin'}
                    description={''}
                />

                <div
                    className={styles.buyButton}
                    onClick={() => {
                        !loading && handleOnClickBuy()
                    }}
                >
                    Buy Loot Coffins
                </div>
            </header>

            <div className={styles.content}>
                <section className={styles.left}>
                    <img src={itemLoot} alt="" className={styles.itemLoot} />

                    <div className={styles.lootInfo}>
                        <p className={styles.title}>Unlock Loot Coffin</p>
                    </div>

                    <div className={styles.buttonContainer}>
                        <section className={styles.openButton} onClick={() => (!imxRequesting && !loading) && handleOnClickOpen()}>
                            {imxRequesting || loading ? (
                                <Spinner />
                            ) : (
                                openLootLabel
                            )}
                        </section>

                        <section
                            className={styles.backButton}
                            onClick={() => {
                                !loading && handleOnClickBack()
                            }}
                        >
                            Back to My Loot Coffins
                        </section>
                    </div>

                    <div className={styles.lootConffinInfo}>
                        <p className={styles.itemDetail}>Loot Coffin Reward Levels:</p>

                        <div className={styles.lootList}>
                            {LOOT_CONFFINS.map(e => (
                                <div
                                    key={e.id}
                                    className={cn(styles.lootItem, {
                                        [styles[e.flat]]: e.flat,
                                        [styles[`${e.flat}Active`]]: e.flat === weaponType

                                    })}
                                    onClick={() => handleOnClickItemType(e.flat)}
                                >
                                    <section className={styles.top}>
                                        <img
                                            src={e.icon}
                                            alt=""
                                            className={cn(styles.itemImg, {
                                                [styles[e.flat]]: e.flat
                                            })}
                                        />
                                    </section>

                                    <section className={styles.bottom}>
                                        <p
                                            className={cn(styles.nameItem, {
                                                [styles[e.flat]]: e.flat,
                                             })}
                                        >
                                            {e.label}
                                        </p>
                                        <p className={styles.detail}>Weapon Skin</p>
                                    </section>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <img src={devideIcon} alt="" className={styles.divide} />

                <section className={styles.right}>
                    <header className={styles.rightheader}>
                        <p
                            className={cn(styles.description, {
                                [styles.common]: weaponType === 'common',
                                [styles.rare]: weaponType === 'rare',
                                [styles.epic]: weaponType === 'epic',
                                [styles.legendary]: weaponType === 'legendary'
                            })}
                        >
                            {description}
                        </p>

                        <div className={styles.devide} />
                    </header>

                    <section className={styles.weaponList}>
                        {list && list.map((item, index) => {
                            const typeList = item.typeList;

                            return (
                                <div key={index} className={styles.weaponItem}>
                                    <p className={styles.type}>{item.type}</p>

                                    {typeList.map((typeItem) => (
                                        <p key={typeItem.id} className={styles.item}>{typeItem.weapon}: {typeItem.skin}</p>
                                    ))}
                                </div>
                            )
                        })}
                    </section>
                </section>
            </div>

            <ViewModal
                selected={selected}
                isSliderStart={isSliderStart}
                endLoading={endLoading}
                onCancel={() => {
                    if (selected) {
                        dispatch(setWeaponRandomVisible(false));
                        handleOnClickBack();
                    }
                }}
            />
        </div>
    )
}

export default React.memo(OpenLoot);