import http from '../../../services/http';

export default class App {
    static fetchSettings() {
        return http.get('/settings', {
            params: {
                limit: 30,
                skip: 0
            }
        })
    }
}
