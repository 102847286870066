import { handleActions } from "redux-actions";

const initialState = {
    userStatisticRes: {
        error: null,
        result: null,
        requesting: false,
    },
    userHistoriesRes: {
        error: null,
        result: null,
        requesting: false,
    },
    checkRateRes: {
        error: null,
        result: null,
        requesting: false,
    },
    withDrawnRes: {
        error: null,
        result: null,
        requesting: false,
    },
    swapInfoRes: {
        error: null,
        result: null,
        requesting: false,
    },
    withDrawnFinishRes: {
        error: null,
        result: null,
        requesting: false,
    },
    swapInRes: {
        error: null,
        result: null,
        requesting: false,
    },
    swapOutRes: {
        error: null,
        result: null,
        requesting: false,
    },
    exchangeStatusRes: {
        error: null,
        result: null,
        requesting: false,
    }
};

let withDrawReducer = handleActions({
    /** GET USER STATISTIC **/
    GET_USER_STATISTIC_REQUEST: (state) => ({
        ...state,
        userStatisticRes: {
            ...state.userStatisticRes,
            requesting: true
        }
    }),

    GET_USER_STATISTIC_SUCCESS: (state, { payload }) => ({
        ...state,
        userStatisticRes: {
            ...state.userStatisticRes,
            result: payload.data,
            requesting: false,
            error: null
        }
    }),

    GET_USER_STATISTIC_FAIL: (state, { payload }) => ({
        ...state,
        userStatisticRes: {
            ...state.userStatisticRes,
            requesting: false,
            error: payload.error
        }
    }),

    /** GET USER HISTORIES **/
    GET_USER_HISTORIES_REQUEST: (state) => ({
        ...state,
        userHistoriesRes: {
            ...state.userHistoriesRes,
            requesting: true
        }
    }),

    GET_USER_HISTORIES_SUCCESS: (state, { payload }) => ({
        ...state,
        userHistoriesRes: {
            ...state.userHistoriesRes,
            result: payload.data,
            requesting: false,
            error: null
        }
    }),

    GET_USER_HISTORIES_FAIL: (state, { payload }) => ({
        ...state,
        userHistoriesRes: {
            ...state.userHistoriesRes,
            requesting: false,
            error: payload.error
        }
    }),

    /** GET SWAP INFO **/
    GET_SWAP_INFO_REQUEST: (state) => ({
        ...state,
        swapInfoRes: {
            ...state.swapInfoRes,
            requesting: true
        }
    }),

    GET_SWAP_INFO_SUCCESS: (state, { payload }) => ({
        ...state,
        swapInfoRes: {
            ...state.swapInfoRes,
            result: payload.data,
            requesting: false,
            error: null
        }
    }),

    GET_SWAP_INFO_FAIL: (state, { payload }) => ({
        ...state,
        swapInfoRes: {
            ...state.swapInfoRes,
            requesting: false,
            error: payload.error
        }
    }),

    /** CHECK RATE **/
    CHECK_RATE_REQUEST: (state) => ({
        ...state,
        checkRateRes: {
            ...state.checkRateRes,
            requesting: true
        }
    }),

    CHECK_RATE_SUCCESS: (state, { payload }) => ({
        ...state,
        checkRateRes: {
            ...state.checkRateRes,
            result: payload.data,
            requesting: false,
            error: null
        }
    }),

    CHECK_RATE_FAIL: (state, { payload }) => ({
        ...state,
        checkRateRes: {
            ...state.checkRateRes,
            requesting: false,
            error: payload.error
        }
    }),

    /** WITH DRAWN **/
    WITH_DRAW_REQUEST: (state) => ({
        ...state,
        withDrawnRes: {
            ...state.withDrawnRes,
            requesting: true
        }
    }),

    WITH_DRAW_SUCCESS: (state, { payload }) => ({
        ...state,
        withDrawnRes: {
            ...state.withDrawnRes,
            result: payload.data,
            requesting: false,
            error: null
        }
    }),

    WITH_DRAW_FAIL: (state, { payload }) => ({
        ...state,
        withDrawnRes: {
            ...state.withDrawnRes,
            requesting: false,
            error: payload.error
        }
    }),

    /** WITH DRAWN FINISH **/
    WITH_DRAW_FINISH_REQUEST: (state) => ({
        ...state,
        withDrawnFinishRes: {
            ...state.withDrawnFinishRes,
            requesting: true
        }
    }),

    WITH_DRAW_FINISH_SUCCESS: (state, { payload }) => ({
        ...state,
        withDrawnFinishRes: {
            ...state.withDrawnFinishRes,
            result: payload.data,
            requesting: false,
            error: null
        }
    }),

    WITH_DRAW_FINISH_FAIL: (state, { payload }) => ({
        ...state,
        withDrawnFinishRes: {
            ...state.withDrawnFinishRes,
            requesting: false,
            error: payload.error
        }
    }),

    /** SWAP IN **/
    SWAP_IN_REQUEST: (state) => ({
        ...state,
        swapInRes: {
            ...state.swapInRes,
            requesting: true
        }
    }),

    SWAP_IN_SUCCESS: (state, { payload }) => ({
        ...state,
        swapInRes: {
            ...state.swapInRes,
            result: payload,
            requesting: false,
            error: null
        }
    }),

    SWAP_IN_FAIL: (state, { payload }) => ({
        ...state,
        swapInRes: {
            ...state.swapInRes,
            requesting: false,
            error: payload
        }
    }),

    /** SWAP OUT **/
    SWAP_OUT_REQUEST: (state) => ({
        ...state,
        swapOutRes: {
            ...state.swapOutRes,
            requesting: true
        }
    }),

    SWAP_OUT_SUCCESS: (state, { payload }) => ({
        ...state,
        swapOutRes: {
            ...state.swapOutRes,
            result: payload,
            requesting: false,
            error: null
        }
    }),

    SWAP_OUT_FAIL: (state, { payload }) => ({
        ...state,
        swapOutRes: {
            ...state.swapOutRes,
            requesting: false,
            error: payload
        }
    }),

    /** GET EXCHANGE STATUS **/
    GET_EXCHANGE_STATUS_REQUEST: (state) => ({
        ...state,
        exchangeStatusRes: {
            ...state.exchangeStatusRes,
            requesting: true
        }
    }),

    GET_EXCHANGE_STATUS_SUCCESS: (state, { payload }) => ({
        ...state,
        exchangeStatusRes: {
            ...state.exchangeStatusRes,
            result: payload.data,
            requesting: false,
            error: null
        }
    }),

    GET_EXCHANGE_STATUS_FAIL: (state, { payload }) => ({
        ...state,
        exchangeStatusRes: {
            ...state.exchangeStatusRes,
            requesting: false,
            error: payload.error
        }
    }),
}, initialState);

export default withDrawReducer;