import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ethers } from 'ethers';
import { Input } from 'antd';

import Modal from '../../Modal';
import Dropdown from '../../Form/Dropdown';
import ImxProvider from '../../ImxProvider';
import Spinner from 'components/Spinner';

import { METHOD_TYPES } from 'utils/imxHelpers';
import { IMX_STATUS } from 'utils/constants';
import {
    imxDeposit,
    imxTransfer,
    imxPrepareWithdraw,
    imxCompleteWithdraw
} from 'store/modules/Imx/actions';
import { progressOwnership, fetchOwnershipDetail } from 'store/modules/Ownership/actions';

import closeIcon from '../../DownloadModal/img/close.png';
import divide from '../../DownloadModal/img/divide.png';
import copy from '../img/copy.png';
// import noImage from '../img/image-default.png';

import styles from './NftDetail.module.scss';

const LIST_METHODS_NORMAL = [
    { label: 'DEPOSIT', value: 'deposit' }
];

const LIST_METHODS_IMX = [
    { label: 'TRANSFER', value: 'transfer' },
    { label: 'WITHDRAW', value: 'withDraw' }
];

const ONLY_WITHDRAW = [
    { label: 'WITHDRAW', value: 'withDraw' }
];

const type = 'ERC721';

function NftDetail(props) {
    const dispatch = useDispatch();

    const {
        item = {},
        isVisible,
        progress,
        onCancel,
        onGetTransferId
    } = props;

    const [detail, setDetail] = useState({});
    const [methodItem, setMethodItem] = useState(LIST_METHODS_NORMAL[0]);
    const [recipient, setRecipient] = useState('');
    const [copied, setCopied] = useState(false);

    const { isMobile } = useSelector(state => state?.app);
    const chainId = useSelector(state => state?.user?.chainId ?? 1);
    const address = useSelector(state => state?.user?.userAccount?.accounts[0] ?? '')?.toLowerCase();
    const isConnectImx = useSelector(state => state?.imx?.userImxInfo?.result?.address ?? '')?.toLowerCase();
    const loadingNft = useSelector(state => state?.imx?.isLoadingNft);
    const isReloadOwnershipDetail = useSelector(state => state?.ownership?.reloadOwnershipDetail);
    const loadingDetail = useSelector(state => state?.ownership?.ownershipDetailRes?.requesting);

    const { contractAddress = '' } = detail;
    const isPending = detail?.pending;
    const imxStatus = detail?.imxStatus;
    const tokenId = detail?.tokenId;
    const isOwned = detail?.address?.toLowerCase() === isConnectImx;

    const disabled = useMemo(() => {
        if (!isConnectImx || !isOwned) return false;

        if (!isPending) {
            if (methodItem.value === METHOD_TYPES.TRANSFER) {
                return !ethers.utils.isAddress(recipient)
                    || !ethers.utils.isAddress(contractAddress)
                    || recipient?.toLowerCase() === address;
            }

            return false;
        }

        if (isPending && imxStatus === IMX_STATUS.withdrawable) {
            return false;
        }

        return true;
    }, [
        methodItem,
        recipient,
        contractAddress,
        isConnectImx,
        isPending,
        address,
        imxStatus,
        isOwned
    ]);

    const dropdownData = useMemo(() => {
        if (!detail.isImmutable) return LIST_METHODS_NORMAL;

        if (detail.imxStatus === IMX_STATUS.withdrawable || detail.imxStatus === IMX_STATUS.preparing_withdrawal) {
            return ONLY_WITHDRAW;
        }

        return LIST_METHODS_IMX;
    }, [detail.isImmutable, detail.imxStatus]);

    // GET DATA
    useEffect(() => {
        if (!isVisible) return;

        dispatch(fetchOwnershipDetail(item.tokenId, item.contractAddress))
            .then(res => {
                setDetail(res?.data);
            })
            .catch(err => err)

        return () => {
            setRecipient('');
        }
    }, [dispatch, item, isVisible, isReloadOwnershipDetail]);

    useEffect(() => {
        if (!detail?.id) return;

        if (!detail.isImmutable) return setMethodItem(LIST_METHODS_NORMAL[0]);

        if (detail.imxStatus === IMX_STATUS.withdrawable || detail.imxStatus === IMX_STATUS.preparing_withdrawal) {
            return setMethodItem(ONLY_WITHDRAW[0]);
        }

        return setMethodItem(LIST_METHODS_IMX[0]);
    }, [detail.isImmutable, detail.imxStatus, detail.id]);

    const handleOnChangeMethod = useCallback((e) => {
        setMethodItem(e);
    }, []);

    const handleOnChangeRecipient = useCallback((e) => {
        setRecipient(e.target.value);
    }, []);


    const handleOnProgress = (body) => {
        dispatch(progressOwnership(body));
    };

    const handleOnSubmit = async () => {
        if (methodItem.value === METHOD_TYPES.DEPOSIT) {
            return dispatch(imxDeposit(type, tokenId, contractAddress))
                .then((res) => {
                    if (res === 'Failed') return;

                    handleOnProgress({
                        contractAddress,
                        tokenId,
                        pending: true
                    });

                    onCancel();
                })
                .catch(err => err)
        }

        if (methodItem.value === METHOD_TYPES.TRANSFER) {
            const params = {
                type,
                tokenId: String(tokenId),
                tokenAddress: contractAddress,
                toAddress: recipient
            }

            return dispatch(imxTransfer([params]))
                .then((res) => {
                    if (res === 'Failed') return;

                    handleOnProgress({
                        contractAddress,
                        tokenId,
                        pending: true
                    });

                    setRecipient('');
                    onGetTransferId(item);
                    onCancel();
                })
                .catch(err => {
                    setRecipient('');
                    return err
                })
        }

        if (methodItem.value === METHOD_TYPES.WITHDRAW) {
            if (imxStatus === IMX_STATUS.preparing_withdrawal) return;

            if (imxStatus === IMX_STATUS.withdrawable) {
                return dispatch(imxCompleteWithdraw(type, tokenId, contractAddress))
                    .then((res) => {
                        if (res === 'Failed') return;

                        handleOnProgress({
                            contractAddress,
                            tokenId,
                            pending: false,
                            imxStatus: 'eth'
                        });

                        onCancel();
                    })
                    .catch(err => err)
            }

            return dispatch(imxPrepareWithdraw(type, tokenId, contractAddress))
                .then((res) => {
                    if (res === 'Failed') return;

                    handleOnProgress({
                        contractAddress,
                        tokenId,
                        pending: true
                    });

                    onCancel();
                })
                .catch(err => err)
        }
    };

    const handleClipboard = () => {
        if (!recipient) return;

        setCopied(true);

        navigator.clipboard.writeText(recipient);

        setTimeout(() => {
            setCopied(false);
        }, 1500);
    };

    return (
        <div>
            <Modal
                visible={isVisible}
                closable={false}
                centered
                onCancel={onCancel}
                width={isMobile ? '325rem' : '572rem'}
                wrapperClass={styles.container}
                wrapperBody={styles.wrapperBody}
            >
                <div className={styles.content}>
                    <img src={closeIcon} alt="" className={styles.close} onClick={() => onCancel()}/>

                    <header className={styles.header}>
                        <h2 className={styles.heading}>NFT Detail</h2>
                        <p className={styles.detail}>Make transaction with Layer 2</p>
                    </header>

                    <section className={styles.info}>
                        <div className={styles.left}>
                            <img src={item?.thumbnail} alt="" className={styles.thumbnail} />
                            <div className={styles.tokenId}>{item?.tokenId}</div>
                            {detail?.isImmutable ? <div className={styles.sticker}>IMX</div> : null}
                            {isOwned ? <div className={styles.isOwned}>OWNED</div> : null}
                        </div>

                        <img src={divide} alt="" className={styles.divide} />

                        {loadingDetail ? (
                            <div className={styles.loadingBox}>
                                <Spinner custom width={30} />
                            </div>
                        ) : (
                            <div className={styles.right}>
                                {detail?.isImmutable ? (
                                    <div className={styles.dropdownContainer}>
                                        <p className={styles.dropdownLbl}>Choose a Method</p>
                                        <section className={styles.dropdownBox}>
                                            <Dropdown
                                                items={dropdownData}
                                                itemSelected={methodItem}
                                                onChange={handleOnChangeMethod}
                                                customDropdownClass={styles.dropdownCustom}
                                                customLabelClass={styles.labelCustom}
                                                isBlueIcon={true}
                                            />
                                        </section>
                                    </div>
                                ) : null}

                                {methodItem.value === METHOD_TYPES.TRANSFER ? (
                                    <section className={styles.recipientInput}>
                                        <p className={styles.reciptientLbl}>Recipient</p>

                                        <Input
                                            type={'text'}
                                            value={recipient}
                                            placeholder="Wallet Address"
                                            onChange={handleOnChangeRecipient}
                                            className={styles.recipient}
                                            suffix={<img src={copy} alt="" className={styles.copy} onClick={() => handleClipboard()} />}
                                        />

                                        {copied ? <div className={styles.copied}>Copied</div> : null}
                                    </section>
                                ) : null}

                                <ImxProvider
                                    loadingDetail={progress}
                                    type={methodItem.value}
                                    chainId={chainId}
                                    onSubmit={handleOnSubmit}
                                    disabled={disabled || loadingNft || progress}
                                    isOwned={isOwned}
                                    imxStatus={detail?.imxStatus}
                                />
                            </div>
                        )}
                    </section>
                </div>
            </Modal>
        </div>
    )
}

export default React.memo(NftDetail);