import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../Modal';
import Step1 from './Step1';

import { finishVerify } from '../../store/modules/Wallet/actions';
import { setToast } from '../../store/modules/App/actions';

import styles from './GoingApp.module.scss';

function GoingApp(props) {
    const dispatch = useDispatch();

    const { visible, onCancel } = props;

    const token = useSelector(state => state?.user?.userAccount?.token ?? '');
    const { id } = useSelector(state => state?.user?.userInfo?.result ?? {});
    const idWithAccount = useSelector(state => state?.wallet?.registerRes?.result?.data?.data?.id ?? '');

    const handleOnClose = () => {
        onCancel();
    }

    const handleOnFinish = (avatar) => {
        const body = {
            avatar: avatar?.url
        }

        dispatch(finishVerify((id || idWithAccount), body))
            .then((res) => {
                if (res?.status >= 500) {
                    dispatch(setToast({ title: 'FAILED', detail: 'Something went wrong' }));
                    handleOnClose();
                } else if ((res?.status >= 400)) {
                    dispatch(setToast({ title: 'FAILED', detail: res?.data?.message }));
                    handleOnClose();
                } else {
                    handleOnClose();
                }
            })
            .catch(err => {
                dispatch(setToast({ title: 'FAILED', detail: 'Something went wrong' }));
                handleOnClose();
                return err;
            })
    };

    return (
        <div className={styles.container}>
            <Modal
                centered
                visible={visible}
                closable={false}
                maskClosable={false}
                closeIcon={null}
                width={'350rem'}
            >
                <Step1 token={token} onFinish={handleOnFinish} />
            </Modal>
        </div>
    );
}

export default React.memo(GoingApp);