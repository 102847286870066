import React, {PureComponent} from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';

import {
    PublicRoutes,
    PrivateRoutes
} from './constants';
import NotFound from '../pages/NotFound';
import DashboardLayout from '../pages/Dashboard';

// @withRouter
class Router extends PureComponent {
    componentDidUpdate(prevProps) {
        const {location} = this.props;

        if (location.key !== prevProps.location.key) {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    }

    render() {
        return (
            <Switch>
                {PublicRoutes.map(route => {
                    const Page = route.component;

                    return (
                        <Route exact path={route.path} component={Page} key={route.path}/>
                    )
                })}

                {PrivateRoutes.map(route => {
                    const Page = route.component;

                    return (
                        <DashboardLayout key={route.path}>
                            <Route exact path={route.path} component={Page} />
                        </DashboardLayout>
                    )
                })}

                <Route component={NotFound} key="*" />
            </Switch>
        )
    }
}

export default withRouter(Router);