import dashboard from '../Pages/Dashboard';
import stats from '../Pages/Stats';
import leaderBoard from '../Pages/LeaderBoard';
// import imxPage from '../Pages/Imx';
import myInventory from '../Pages/Inventory';
import lootConfins from '../Pages/LootConfins';
import armory from '../Pages/Armory';
import weaponLoadouts from '../Pages/WeaponLoadouts';
import characterSkin from '../Pages/Skins';
import weaponSkin from '../Pages/WeaponSkins';
import buyLoot from '../Pages/PurcharseLoot/BuyLoot';
import openLoot from '../Pages/PurcharseLoot/OpenLoot';
import wallet from '../Pages/Wallet';
import rarityBooster from '../Pages/RarityBooster';

export const PAGE_LANDING_PATH = '/';
export const PAGE_DASHBOARD_PATH = '/dashboard';
export const PAGE_WAGYU_WALLET_PATH = `/wagyu-wallet`;
export const PAGE_PLAYERS_STATS_PATH = `/player-stats`;
// export const PAGE_MY_WEAPONS_PATH = `/my-weapons`;
export const PAGE_LEADER_BOARD_PATH = `/leaderboards`;
export const PAGE_IMX_PATH = `/imx`;
export const PAGE_MY_INVENTORY_PATH = `/my-inventory`;
export const PAGE_LOOT_CONFINS_PATH = `/my-loot-conffins`;
export const PAGE_ARMORY_PATH = `/my-armory`;
export const PAGE_WEAPON_LOADOUTS_PATH = `/my-weapon-loadouts`;
export const PAGE_CHARACTER_SKIN_PATH = `/my-character-skins`;
export const PAGE_WEAPON_SKIN_PATH = `/my-weapon-skins`;
export const PAGE_BUY_LOOT_PATH = `/buy-loot-conffins`;
export const PAGE_LOOT_PATH = `/loot-conffins`;
export const PAGE_WALLET = `/wallet`;
export const PAGE_BOOSTER = `/booster`;

export const PrivateRoutes = [
    { path: PAGE_DASHBOARD_PATH, component: dashboard },
    { path: PAGE_PLAYERS_STATS_PATH, component: stats },
    { path: PAGE_LEADER_BOARD_PATH, component: leaderBoard },
    // { path: PAGE_MY_WEAPONS_PATH, component: imxPage },
    { path: PAGE_MY_INVENTORY_PATH, component: myInventory },
    { path: PAGE_LOOT_CONFINS_PATH, component: lootConfins },
    { path: PAGE_ARMORY_PATH, component: armory },
    { path: PAGE_WEAPON_LOADOUTS_PATH, component: weaponLoadouts },
    { path: PAGE_CHARACTER_SKIN_PATH, component: characterSkin },
    { path: PAGE_WEAPON_SKIN_PATH, component: weaponSkin },
    { path: PAGE_BUY_LOOT_PATH, component: buyLoot },
    { path: PAGE_LOOT_PATH, component: openLoot },
    { path: PAGE_WALLET, component: wallet },
    { path: PAGE_BOOSTER, component: rarityBooster },
];

export const InventoryChildren = [
    PAGE_LOOT_CONFINS_PATH,
    PAGE_ARMORY_PATH,
    PAGE_WEAPON_LOADOUTS_PATH,
    PAGE_CHARACTER_SKIN_PATH,
    PAGE_WEAPON_SKIN_PATH,
    PAGE_BUY_LOOT_PATH,
    PAGE_LOOT_PATH,
    PAGE_BOOSTER
];