import React from 'react';
import NumberFormat from 'react-number-format';

import styles from './NumberField.module.scss';

function NumberField(props) {
    const {
        value,
        onChange,
        onFocus,
        loading
    } = props;

    return (
        <NumberFormat
            value={value}
            onValueChange={onChange}
            // thousandSeparator={true}
            className={loading ? styles.loading : styles.container}
            placeholder={'0.0'}
            allowNegative={false}
            onFocus={() => onFocus(true)}
            onBlur={() => onFocus(false)}
        />
    );
}

export default NumberField;