import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import cn from 'classnames';
import { Collapse } from 'antd';
import { isEmpty } from 'lodash';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import close from './close.png';
import logo from '../../../../assets/dashboardLayout/logo.png';
import arrowIcon from '../../../../assets/dashboardLayout/navigation/arrow.png';
import defaultAvatar from '../../../../assets/img/logo.png';

import Footer from '../Footer';

import { InventoryChildren } from '../../Router/constants';

import { getAccountSymbol } from '../../../../utils/web3';
import { getBalanceOfToken } from '../../../../utils/bigNumber';
import { setConnectModalVisible } from '../../../../store/modules/App/actions';

import styles from './MobileMenu.module.scss';

const { Panel } = Collapse;

export default function MobileMenu(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const {isOpen, items, options, onItemClick, onClose} = props;

    const [dropdown, setDropdown] = useState([]);
    const [navKey, setNavKey] = useState('');

    const { isConnect } = useSelector(state => state?.user?.connectWallet ?? {});

    const chainId = useSelector(state => state?.user?.chainId ?? 1);
    const account = useSelector(state => state?.user?.userAccount?.accounts[0] ?? '');
    const balance = useSelector(state => state?.user?.userAccount?.balance);

    const { username, avatar } = useSelector(state => state?.user?.userInfo?.result ?? {});
    const loadingUser = useSelector(state => state?.user?.userInfo?.requesting);

    const { pathname } = history?.location;

    const currentPage = useCallback((item) => {
        return pathname === item.key || (item.key === '/my-inventory' && InventoryChildren.includes(pathname));
    }, [pathname]);

    const renderNavItem = useCallback((item) => {
        return (
            item.path ? (
                item.disabled ? (
                    <div
                        key={item.label}
                        onMouseEnter={() => {
                            setNavKey(item.key);
                        }}
                        onMouseLeave={() => {
                            setNavKey('');
                        }}
                        onClick={() => {
                            onItemClick();
                        }}
                    >
                        <li
                            className={cn(styles.navItem, {
                                [styles.current]: currentPage(item)
                            })}
                        >
                            <img
                                src={(currentPage(item) || item.key === navKey) ? item.iconActive : item.iconDeactive}
                                alt={item.label}
                                className={styles.icon}
                                style={{ width: item.styling.width, height: item.styling.height, marginRight: item.styling.marginRight }}
                            />
                            <span className={styles.label}>{item.label}</span>
                        </li>
                    </div>
                ) : (
                    <Link
                        key={item.label}
                        to={item.path}
                        onMouseEnter={() => {
                            setNavKey(item.key);
                        }}
                        onMouseLeave={() => {
                            setNavKey('');
                        }}
                        onClick={() => {
                            if (item.requireConnected) {
                                !isConnect ? dispatch(setConnectModalVisible(true)) : item.action && dispatch(item.action(true))
                            } else {
                                item.action && dispatch(item.action(true));
                            }
                            onItemClick();
                        }}
                    >
                        <li
                            className={cn(styles.navItem, {
                                [styles.current]: currentPage(item),
                                [styles.disabled]: item.disabled
                            })}
                        >
                            <img
                                src={(currentPage(item) || item.key === navKey || item.disabled) ? item.iconActive : item.iconDeactive}
                                alt={item.label}
                                className={styles.icon}
                                style={{ width: item.styling.width, height: item.styling.height, marginRight: item.styling.marginRight }}
                            />
                            <span className={styles.label}>{item.label}</span>
                        </li>
                    </Link>
                )
            ) : (
                <div
                    key={item.label}
                    onMouseEnter={() => {
                        setNavKey(item.key);
                    }}
                    onMouseLeave={() => {
                        setNavKey('');
                    }}
                    onClick={() => {
                        if (item.requireConnected) {
                            !isConnect ? dispatch(setConnectModalVisible(true)) : item.action && dispatch(item.action(true))
                        } else {
                            item.action && dispatch(item.action(true));
                        }
                        onItemClick();
                    }}
                >
                    <li
                        className={cn(styles.navItem, {
                            [styles.current]: currentPage(item)
                        })}
                    >
                        <img
                            src={(currentPage(item) || item.key === navKey) ? item.iconActive : item.iconDeactive}
                            alt={item.label}
                            className={styles.icon}
                            style={{ width: item.styling.width, height: item.styling.height, marginRight: item.styling.marginRight }}
                        />
                        <span className={styles.label}>{item.label}</span>
                    </li>
                </div>
            )
        )
    }, [dispatch, isConnect, navKey, onItemClick, currentPage])

    return (
        <nav className={cn(styles.container, {
            [styles.isOpen]: isOpen
        })}>
            <div className={styles.fade} onClick={onClose}/>
            <div className={styles.menuWrapper}>
                <div style={{ flex: 1 }}>
                    <div className={styles.alignEnd}>
                        <div
                            onClick={() => onClose()}
                            className={styles.containerHamburger}
                        >
                            <div id="bottom" className={styles.bottomLayerMobile} />
                            <div id="top" className={styles.topLayerMobile}>
                                <img src={close} alt="icon" />
                            </div>
                        </div>
                    </div>

                    <nav className={styles.nav}>
                        <Link to={'/dashboard'} className={styles.linkLogo}>
                            <img src={logo} alt="logo" className={styles.logo} />
                        </Link>
    
                        <ul className={styles.navList}>
                            {items.map((e) => (
                                renderNavItem(e)
                            ))}
                        </ul>
                    </nav>

                    <div className={styles.divide} />

                    <nav className={styles.collapse}>
                        {isConnect ? (
                            <section className={styles.walletInfo}>
                                <div className={styles.left}>
                                    {getBalanceOfToken(balance)} {getAccountSymbol(chainId)}
                                </div>

                                <div className={styles.right}>
                                    <p>{account && account.substring(0, 4)}...{account && account.substring(account.length - 3)}</p>
                                    <div className={styles.jazzIcon}>
                                        <Jazzicon
                                            paperStyles={{ maxWidth: '100%', maxHeight: '100%' }}
                                            diameter={28}
                                            seed={jsNumberForAddress(account || '')}
                                        />
                                    </div>
                                </div>
                            </section>
                        ) : (
                            <section className={styles.noWallet} onClick={() => dispatch(setConnectModalVisible(true))}>
                                <div className={styles.connectBtn}>CONNECT WALLET</div>
                            </section>
                        )}

                        <Collapse
                            bordered={false}
                            defaultActiveKey={[]}
                            className={styles.collapseCustom}
                            onChange={e => setDropdown(e)}
                        >
                            <Panel
                                header={
                                    <div className={styles.headerCustom}>
                                        {loadingUser ? (
                                            <div className={styles.loadingAvatar} />
                                        ) : (
                                            avatar ? (
                                                <img
                                                    src={avatar}
                                                    alt=""
                                                    className={cn(styles.avatar)}
                                                />
                                            ) : (
                                                <div className={styles.noAvatar}>
                                                    <img
                                                        src={defaultAvatar}
                                                        alt=""
                                                        className={styles.circle}
                                                    />
                                                </div>
                                            )
                                        )}

                                        {loadingUser ? (
                                            <div className={styles.loadingUsername} />
                                        ) : (
                                            <p className={cn(styles.headerLbl)}>
                                                {username}
                                            </p>
                                        )}

                                        <img
                                            src={arrowIcon}
                                            alt=""
                                            className={cn(styles.arrow, {
                                                [styles.isDropdown]: !isEmpty(dropdown)
                                            })}
                                        />
                                    </div>
                                }
                                key="1"
                            >
                                {options.map(e => (
                                    <section
                                        className={styles.contentCustom}
                                        key={e.key}
                                        onClick={() => {
                                            e.action && dispatch(e.action(true));
                                            onItemClick();
                                        }}
                                    >
                                        <p className={styles.contentLbl}>{e.label}</p>
                                    </section>
                                ))}
                            </Panel>
                        </Collapse>
                    </nav>
                </div>

                <Footer />
            </div>
        </nav>
    )
}