import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty, set } from 'lodash';
import { isJwtExpired } from 'jwt-check-expiration';
import { ethers } from 'ethers';

import { getTokenOfBlockchain } from './utils';
import {
    autoConnect,
    disconnect,
    setTokensInfo,
    fetchUserInfo,
    setUndeadBalance
} from './actions';

import undeadTokenAbi from 'abi/undeadToken.json';

import { setUpdateAvatarVisible } from '../App/actions';

import { getContractAddress } from 'utils/contractHelpers';
import { geTokenAbiOfBlockchain } from 'utils/common';
import { getAllowance, getBalanceOf } from 'utils/callHelpers';
import { getBalanceOfToken } from 'utils/bigNumber';

export function UseUserInit() {
    /*
        Basic initialization logic for the module:
        init scripts, event listeners, etc...
    */
    const dispatch = useDispatch();
    const history = useHistory();
    const { location = {} } = history;

    const { loaded } = useSelector(state => state?.app?.load ?? {});
    const { logged } = useSelector(state => state?.user ?? {});
    const { isConnect } = useSelector(state => state?.user?.connectWallet ?? {});

    const address = useSelector(state => state?.user?.userAccount?.accounts[0] ?? '')?.toLowerCase();
    const userAddress = useSelector(state => state?.user?.userInfo?.result?.address ?? '');

    const token = useSelector(state => state?.user?.userAccount?.token ?? ''); // eslint-disable-next-line
    const { data } = useSelector(state => state?.wallet?.finishVerifyRes?.result ?? {});
    const assignAddressRes = useSelector(state => state?.wallet?.assignAddressRes?.result?.data?.address);

    const tokenPoolsListRes = useSelector(state => state?.pools?.tokenPoolsListRes ?? []);

    const { reloadHistories } = useSelector(state => state?.app ?? null);

    const getTokensInformation = useCallback(async() => {
        if (isEmpty(tokenPoolsListRes)) return;

        let listPromise = [];
        let tokensInfo = {};

        try {
            for (let index = 0; index < tokenPoolsListRes.length; index++) {
                const element = tokenPoolsListRes[index];

                const tokenAbi = await geTokenAbiOfBlockchain(element.value);
                const contract = new window.web3.eth.Contract(tokenAbi, element.tokenAddress);
                const spenderAddress = getContractAddress(element.chainId);

                listPromise.push(
                    Promise.resolve(element.networkName.toLowerCase()),
                    Promise.resolve(getTokenOfBlockchain(element.chainId)),
                    getBalanceOf(contract, address),
                    getAllowance(contract, address, spenderAddress)
                );
            }

            const res = await Promise.all(listPromise);

            for (let i = 0; i < res.length; i = i + 4) {
                const tokenName = res[i];
                const networkName = res[(i + 1)];
                const balance = res[(i + 2)];
                const isApprove = res[(i + 3)];

                set(tokensInfo, `${tokenName}`, {
                    [`${networkName}`]: {
                        balance, isApprove
                    }
                })
            }

            return tokensInfo;
        } catch (error) {
            return error;
        }
    }, [address, tokenPoolsListRes]);

    // AUTO CONNECT DEFAULT
    useEffect(() => {
        dispatch(autoConnect()); // eslint-disable-next-line
    }, [dispatch]);

    // AUTHENTICATION
    useEffect(() => {
        if (!loaded) return;

        if (logged) {
            dispatch(fetchUserInfo(isConnect ? address : userAddress))
                .then(res => {
                    if (!res?.avatar) {
                        dispatch(setUpdateAvatarVisible(true));
                    } else {
                        dispatch(setUpdateAvatarVisible(false));
                    }
                })
                .catch(err => err);

            const isTokenExpired = token && isJwtExpired(token);

            if (isTokenExpired) {
                dispatch(disconnect());
                history.push('/');
            }

            if (process.env.REACT_APP_IMX_PAGE_DISABELD === 'true') {
                const { pathname } = location;
                const pathnameArray = pathname.split('/');

                if (pathnameArray.includes('imx')) {
                    history.replace('/dashboard')
                }
            }
        }
        // eslint-disable-next-line
    }, [
        dispatch,
        loaded,
        logged,
        history,

        data,
        assignAddressRes
    ]);

    // HANDLE TOKEN DATA
    useEffect(() => {
        if (!loaded) return;
        if (!logged) return;
        if (!isConnect) return;
        if (!ethers.utils.isAddress(address)) return;
        if (isEmpty(tokenPoolsListRes)) return;

        getTokensInformation()
            .then(res => {
                dispatch(setTokensInfo(res));
            })
    }, [
        dispatch,
        loaded,
        logged,
        isConnect,
        address,
        getTokensInformation,
        tokenPoolsListRes,
        reloadHistories
    ]);

    useEffect(() => {
        if (!loaded) return;
        if (!logged) return;
        if (!isConnect) return;
        if (!ethers.utils.isAddress(address)) return;

        try {
            const undeadTokenAddress = process.env.REACT_APP_DEV === '1'
                ? process.env.REACT_APP_UNDEAD_TOKEN_ETHEREUM_TESTNET
                : process.env.REACT_APP_UNDEAD_TOKEN_ETHEREUM_MAINNET

            const undeadContract = new window.web3.eth.Contract(undeadTokenAbi, undeadTokenAddress);

            getBalanceOf(undeadContract, address)
                .then(res => {
                    dispatch(setUndeadBalance(Number(getBalanceOfToken(res))));
                })
                .catch(error => {
                    return error
                })

        } catch (error) {
            return error
        }
    }, [
        dispatch,
        loaded,
        logged,
        isConnect,
        address,
    ])
}