import Landing from '../pages/Landing';
import CreateAccount from '../pages/CreateAccount';
import LoginAccount from '../pages/LoginAccount';
import ForgotPassword from '../pages/ForgotPassword';
import TermOfService from '../pages/TermOfService';
import Dashboard from '../pages/Dashboard/Pages/Dashboard';

export const PAGE_LANDING_PATH = '/';
export const PAGE_CREATE_ACCOUNT_PATH = '/register-account';
export const PAGE_LOGIN_ACCOUNT_PATH = '/login';
export const PAGE_FORGOT_PASSWORD_PATH = '/forgot-password';
export const PAGE_DASHBOARD_PATH = '/dashboard';
export const PAGE_TERM_OF_SERVICE_PATH = '/privacy';

export const PublicRoutes = [
    { path: PAGE_LANDING_PATH, component: Landing },
    { path: PAGE_CREATE_ACCOUNT_PATH, component: CreateAccount },
    { path: PAGE_LOGIN_ACCOUNT_PATH, component: LoginAccount },
    { path: PAGE_FORGOT_PASSWORD_PATH, component: ForgotPassword },
    { path: PAGE_TERM_OF_SERVICE_PATH, component: TermOfService }
];

export const PrivateRoutes = [
    { path: PAGE_DASHBOARD_PATH, component: Dashboard }
];
