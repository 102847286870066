import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Upload
} from 'antd';
import cn from 'classnames';

import circle from './img/circle.png';
import camera from './img/camera.png';
import smallCircle from './img/edit-circle.png';
import pencil from './img/pencil.png';

import { FORMAT_IMAGE } from './contants';

import http from '../../../services/http';
import { setToast } from '../../../store/modules/App/actions';
import { disconnect } from '../../../store/modules/User/actions';

import style from './UploadMedia.module.scss';

class UploadAvatar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imageUrl: props.url || null,
            error: false,
            loading: false,
            fileList: props.defaultFileList,
            progress: 0,
            isPreviewVisible: false,
            thumbnail: ''
        };
    }

    uploadImage = async options => {
        const { uploadUrl, setUpload, setToast, disconnect, onClose, url } = this.props;
        const { onSuccess, onError, file, onProgress } = options;

        const fmData = new FormData();
        const config = {
            headers: { 'content-type': 'multipart/form-data' },
            onUploadProgress: event => {
                const percent = Math.floor((event.loaded / event.total) * 100);

                setUpload(true);

                this.setState({
                    progress: percent,
                    loading: true
                });

                if (percent === 100) {
                    setTimeout(() => this.setState({ progress: 0 }), 1000);
                }

                onProgress({ percent: (event.loaded / event.total) * 100 });
            }
        };

        fmData.append('file', file);

        try {
            const res = await http.post(
                uploadUrl,
                fmData,
                config
            );

            onSuccess('Ok');

            setUpload(false);

            this.setState({
                imageUrl: res?.data?.url,
                error: false,
                fileList: [res?.data],
                loading: false,
                thumbnail: res?.data?.thumbnail
            });

            this.props.onChange(false)(res?.data);
        } catch (err) {
            onError({ err });
            setUpload(false);
            this.setState({
                imageUrl: url,
                error: true,
                fileList: [],
                loading: false,
                thumbnail: ''
            });
            this.props.onChange(true)();

            if (err?.status >= 500) {
                setToast({ title: 'FAILED', detail: 'Something went wrong' });
            } else if (err?.status === 401) {
                setToast({ title: 'TOKEN IS EXPIRED', detail: '' });
                onClose && onClose();
                disconnect();
            } else if ((err?.status >= 400)) {
                setToast({ title: 'FAILED', detail: err?.data?.message });
            } else {
                setToast({ title: 'UPLOAD FAILED', detail: 'Error uploading picture, please choose a different picture' });
            }
        }
    };

    render() {
        const { fileList, imageUrl, loading, progress, thumbnail } = this.state;
        const { wrapperUpload } = this.props;

        return (
            <div className={style.container}>
                <div className={cn(style.content)}>
                    <Upload
                        name="file"
                        className={cn(style.uploadContainer)}
                        fileList={fileList}
                        showUploadList={false}
                        accept={FORMAT_IMAGE}
                        customRequest={this.uploadImage}
                    >
                        {imageUrl ? (
                            <div className={cn(style.cameraContainer, {
                                [style.customUpload]: wrapperUpload,
                                [style.loading]: loading
                            })}>
                                <img
                                    src={thumbnail || imageUrl}
                                    alt=""
                                    className={cn(style.avatarImg, {
                                        [style.customUpload]: wrapperUpload
                                    })}
                                />
                                {loading && <p className={style.progress}>{progress}%</p>}

                                <div className={style.verifiedContainer}>
                                    <img
                                        src={smallCircle}
                                        alt=""
                                        className={cn(style.verifiedImg, {
                                            [style.customUpload]: wrapperUpload
                                        })}
                                    />
                                    <img
                                        src={pencil}
                                        alt=""
                                        className={cn(style.pencil, {
                                            [style.customUpload]: wrapperUpload
                                        })}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className={cn(style.cameraContainer, {
                                [style.customUpload]: wrapperUpload,
                                [style.loading]: loading
                            })}>
                                <img src={circle} alt="" className={style.circle} />
                                {loading ? (
                                    <p className={style.progress}>{progress}%</p>
                                ) : (
                                    <img
                                        src={camera}
                                        alt=""
                                        className={cn(style.camera, {
                                            [style.customUpload]: wrapperUpload,
                                        })}
                                    />
                                )}
                            </div>
                        )}
                    </Upload>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({}),
    dispatch => bindActionCreators({
        setToast,
        disconnect
    }, dispatch)
)(UploadAvatar);

