import React from 'react';
import cn from 'classnames';

import Panel from '../Panel';
import style from './ContextMenu.module.scss';

export default function ContextMenu(props) {
    const { items, itemSelected, onChange, onVisible } = props;

    return (
        <Panel wrapperClass={style.container}>
            <ul className={style.items}>
                {items && items.map((item, i) => (
                    <li
                        className={cn(style.item, {
                            [style.disabled]: item.isDisabled,
                            [style.isSelected]: item.value === itemSelected.value
                        })}
                        key={i}
                        onClick={() => {
                            if (item.isDisabled) return;
                            onChange(item)
                            onVisible()
                        }}
                    >
                        {item.icon && <img src={item.icon} alt="" className={style.icon} />}
                        {item.label}
                    </li>
                ))}
            </ul>
        </Panel>
    )
}