import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { SHIELDS } from '../constants';

import styles from './HighTop.module.scss';

function HighTop(props) {
    const { tournamentType, tournamentScoreValue } = props;

    const { hightTopLeaderBoard = [] } = useSelector(state => state?.user ?? {});

    const label = tournamentScoreValue || (tournamentType === '2' ? "Zombies Killed" : 'XP GAINED');

    return (
        <div className={styles.list}>
            {isEmpty(hightTopLeaderBoard) ? (
                null
            ) : (
                hightTopLeaderBoard.map((item, index) => {
                    return (
                        <div key={item?.id} className={styles.item}>
                            <section className={styles.avatarContainer}>
                                <img src={item?.pfp} alt="" className={styles.avatar} />

                                <img src={SHIELDS[index]} alt="" className={styles.shieldIcon} />
                            </section>

                            <h2 className={styles.username}>{item?.username}</h2>
                            {/* <p className={styles.value}>TOTAL XP GAINED: {item?.xpGained}</p> */}
                            <p className={styles.value}>{label}: {item?.score}</p>
                            <p className={styles.value}>{item?.prize} ZBUX</p>
                        </div>
                    )
                })
            )}
        </div>
    );
}

export default React.memo(HighTop);