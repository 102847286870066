import React from 'react';

import open from './open.png';

import style from './LandingHamburger.module.scss';

function LandingHamburger(props) {
    const { isOpen, onClick } = props;

    return (
        <div
            onClick={() => onClick()}
            className={style.containerHamburger}
            style={{ visibility: isOpen && 'hidden' }}
        >
            <div id="bottom" className={style.bottomLayerMobile} />
            <div id="top" className={style.topLayerMobile}>
                <img src={open} alt="icon" />
            </div>
        </div>
    );
}

export default LandingHamburger;