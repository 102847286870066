import React from 'react';
import { useSelector } from 'react-redux';

import AccountSummary from './AccountSummary';
import FavoriteWeapon from './FavoriteWeapon';
import ChartElement from './ChartElement';

import PageSlug from '../../../../components/PageSlug';

import styles from './Stats.module.scss';

function StatsPage() {
    const userInfo = useSelector(state => state?.user?.userInfo?.result ?? {});

    return (
        <div className={styles.container}>
            <PageSlug
                slug='PLAYER STATS'
                description='Player Stats dashboard building in process, sample statistics shown below - check back soon for the update!'
                wrappedClass={styles.slug}
            />

            <div className={styles.content}>
                <section className={styles.left}>
                    <AccountSummary
                        userInfo={userInfo}
                    />
                </section>

                <section className={styles.right}>
                    <FavoriteWeapon />
                    <ChartElement />
                </section>
            </div>
        </div>
    );
}

export default StatsPage;