import { handleActions } from "redux-actions";

const initialState = {
    ownershipRes: {
        error: null,
        result: null,
        requesting: false
    },
    ownershipDetailRes: {
        error: null,
        result: null,
        requesting: false
    },
    ownershipProgressRes: {
        error: null,
        result: null,
        requesting: false
    },
    exclusivesBurnedRes: {
        error: null,
        result: null,
        requesting: false
    },
    onBurnedExclusivesRes: {
        error: null,
        result: null,
        requesting: false
    },
    onBoostExclusivesRes: {
        error: null,
        result: null,
        requesting: false
    },
    reloadOwnership: 0,
    reloadOwnershipDetail: 0
};
  
let ownershipReducer = handleActions({
    // RELOAD OWNERSHIP
    RELOAD_OWNERSHIP: (state, { payload }) => ({
        ...state,
        reloadOwnership: payload
    }),

    // RELOAD OWNERSHIP DETAIL
    RELOAD_OWNERSHIP_DETAIL: (state, { payload }) => ({
        ...state,
        reloadOwnershipDetail: payload
    }),

    /** ALL OWNERSHIP **/
    FETCH_ALL_OWNERSHIP_REQUEST: (state) => ({
        ...state,
        ownershipRes: {
            ...state.ownershipRes,
            requesting: true
        },
    }),
    FETCH_ALL_OWNERSHIP_SUCCESS: (state, { payload }) => ({
        ...state,
        ownershipRes: {
            ...state.ownershipRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    FETCH_ALL_OWNERSHIP_FAIL: (state, { payload }) => ({
        ...state,
        ownershipRes: {
            ...state.ownershipRes,
            requesting: false,
            error: payload.error
        }
    }),

    /** OWNERSHIP DETAIL **/
    FETCH_OWNERSHIP_DETAIL_REQUEST: (state) => ({
        ...state,
        ownershipDetailRes: {
            ...state.ownershipDetailRes,
            requesting: true
        },
    }),
    FETCH_OWNERSHIP_DETAIL_SUCCESS: (state, { payload }) => ({
        ...state,
        ownershipDetailRes: {
            ...state.ownershipDetailRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    FETCH_OWNERSHIP_DETAIL_FAIL: (state, { payload }) => ({
        ...state,
        ownershipDetailRes: {
            ...state.ownershipDetailRes,
            requesting: false,
            error: payload.error
        }
    }),

    /** PROGRESS OWNERSHIP **/
    PROGRESS_OWNERSHIP_REQUEST: (state) => ({
        ...state,
        ownershipProgressRes: {
            ...state.ownershipProgressRes,
            requesting: true
        },
    }),
    PROGRESS_OWNERSHIP_SUCCESS: (state, { payload }) => ({
        ...state,
        ownershipProgressRes: {
            ...state.ownershipProgressRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    PROGRESS_OWNERSHIP_FAIL: (state, { payload }) => ({
        ...state,
        ownershipProgressRes: {
            ...state.ownershipProgressRes,
            requesting: false,
            error: payload.error
        }
    }),

    /** FETCH_EXCLUSIVES_BURNED **/
    FETCH_EXCLUSIVES_BURNED_REQUEST: (state) => ({
        ...state,
        exclusivesBurnedRes: {
            ...state.exclusivesBurnedRes,
            requesting: true
        },
    }),
    FETCH_EXCLUSIVES_BURNED_SUCCESS: (state, { payload }) => ({
        ...state,
        exclusivesBurnedRes: {
            ...state.exclusivesBurnedRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    FETCH_EXCLUSIVES_BURNED_FAIL: (state, { payload }) => ({
        ...state,
        exclusivesBurnedRes: {
            ...state.exclusivesBurnedRes,
            requesting: false,
            error: payload.error
        }
    }),

    /** ON BURNED EXCLUSIVES **/
    EXCLUSIVES_BURNED_REQUEST: (state) => ({
        ...state,
        onBurnedExclusivesRes: {
            ...state.onBurnedExclusivesRes,
            requesting: true
        },
    }),
    EXCLUSIVES_BURNED_SUCCESS: (state, { payload }) => ({
        ...state,
        onBurnedExclusivesRes: {
            ...state.onBurnedExclusivesRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    EXCLUSIVES_BURNED_FAIL: (state, { payload }) => ({
        ...state,
        onBurnedExclusivesRes: {
            ...state.onBurnedExclusivesRes,
            requesting: false,
            error: payload.error
        }
    }),

    /** ON BOOST EXCLUSIVES **/
    EXCLUSIVES_BOOST_REQUEST: (state) => ({
        ...state,
        onBoostExclusivesRes: {
            ...state.onBoostExclusivesRes,
            requesting: true
        },
    }),
    EXCLUSIVES_BOOST_SUCCESS: (state, { payload }) => ({
        ...state,
        onBoostExclusivesRes: {
            ...state.onBoostExclusivesRes,
            result: payload.data,
            requesting: false,
            error: null
        },
    }),
    EXCLUSIVES_BOOST_FAIL: (state, { payload }) => ({
        ...state,
        onBoostExclusivesRes: {
            ...state.onBoostExclusivesRes,
            requesting: false,
            error: payload.error
        }
    })
}, initialState);

export default ownershipReducer;