import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import newTitle from '../../assets/landingLayout/new-title.png';
import newTitleMobile from '../../assets/landingLayout/new-title-mobile.png';

import VerifyEmailModal from '../VerifyEmail';
import ResetPasswordModal from '../ResetPassword';

import {
    PAGE_DASHBOARD_PATH,
    PAGE_CREATE_ACCOUNT_PATH,
    PAGE_LOGIN_ACCOUNT_PATH
} from '../../router/constants';
import LandingLayout from '../../layouts/LandingLayout';
import LandingHeaderCustomContainer from '../../containers/LandingHeaderCustomContainer';
import BaseBannerFooter from '../../layouts/LandingLayout/BaseBannerFooter';

import { getQueryParam } from '../../utils/index';
import { verifyEmail } from '../../store/modules/Wallet/actions';
import { setToast, setLogoutModalVisible } from '../../store/modules/App/actions';
import http from '../../services/http';

import styles from './Landing.module.scss';

export default function Landing() {
    const dispatch = useDispatch();
    const history = useHistory();

    const { search = '' } = history?.location;

    const [isVerifyEmailShow, setVerifyEmailShow] = useState(false);
    const [isResetPasswordShow, setResetPasswordShow] = useState(false);

    const { isMobile } = useSelector(state => state.app);
    const { logged } = useSelector(state => state?.user ?? {});

    useEffect(() => {
        if (!search) return;

        const action = getQueryParam('action');
        const token = getQueryParam('token');

        if (action === 'forgot-password') {
            setResetPasswordShow(true);
        }

        if (action === 'verify-email') {
            dispatch(verifyEmail({ token }))
                .then((res) => {
                    if (res?.status >= 500) {
                        dispatch(setToast({ title: 'FAILED', detail: 'Something went wrong' }));
                        setVerifyEmailShow(false);
                    } else if ((res?.status >= 400)) {
                        dispatch(setToast({ title: 'FAILED', detail: res?.data?.message }));
                        setVerifyEmailShow(false);
                    } else {
                        http.setAuthorizationHeader(token);
                        setVerifyEmailShow(true);
                    }
                })
                .catch(err => {
                    dispatch(setToast({ title: 'FAILED', detail: 'Something went wrong' }));
                    setVerifyEmailShow(false);

                    return err;
                })
        }
    }, [dispatch, search]);

    return (
        <LandingLayout>
            <div className={styles.container}>
                <section className={styles.contentBox}>
                    <LandingHeaderCustomContainer />
                    <BaseBannerFooter />

                    <section className={styles.titleBox}>
                        <img src={isMobile ? newTitleMobile : newTitle} alt="title" className={styles.title} />
                    </section>

                    <section className={styles.description}>
                        Wagyu Games is leading the charge on Kill-To-Earn <br />
                        Gaming. The Wagyu Games flagship product is set in the <br />
                        post-apocalyptic world with Undead Blocks.
                    </section>

                    <section className={styles.btnContainer}>
                        {logged ? (
                            <>
                                <div className={styles.loginBtn} onClick={() => history.push(PAGE_DASHBOARD_PATH)}>
                                    Go to Dashboard
                                </div>

                                <div className={styles.registerBtn} onClick={() => dispatch(setLogoutModalVisible(true))}>
                                    Disconnect Wallet
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={styles.registerBtn} onClick={() => history.push(PAGE_CREATE_ACCOUNT_PATH)}>
                                    Create Account (Download Undead Blocks)
                                </div>

                                <div className={styles.loginBtn} style={{ marginTop: '10rem' }} onClick={() => history.push(PAGE_LOGIN_ACCOUNT_PATH)}>
                                    Login to Wagyu Account
                                </div>
                            </>
                        )}
                    </section>
                </section>
            </div>

            <VerifyEmailModal
                isVisible={isVerifyEmailShow}
                onCancel={() => setVerifyEmailShow(false)}
            />

            <ResetPasswordModal
                isVisible={isResetPasswordShow}
                token={getQueryParam('token')}
                onCancel={() => setResetPasswordShow(false)}
            />
        </LandingLayout>
    )
}
