import React from 'react';

import TitleCard from '../UsedTo/TitleCard';

import gunIcon from '../img/gun.png';

import styles from './FavoriteWeapon.module.scss';

function FavoriteWeapon() {
    return (
        <div className={styles.container}>
            <section className={styles.header}>
                <TitleCard
                    title={'Favorite weapon'.toUpperCase()}
                    description={'AKM TOXIC'}
                />
                <TitleCard
                    title={'Weapon Kills'.toUpperCase()}
                    description={'900'}
                    rightDescription={true}
                />
            </section>

            <section className={styles.weapon}>
                <img src={gunIcon} alt='' />
            </section>
        </div>
    );
}

export default FavoriteWeapon;