import React, { useCallback, useRef } from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';

import styles from './List.module.scss';

function List(props) {
    const {
        items,
        selectedList,
        onSelect,
        loadingList,
        hasMore,
        setOffset
    } = props;

    const observer = useRef();
    const lastElement = useCallback(node => {
        if (loadingList) return;

        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && hasMore) {
                    setOffset(prevState => prevState + 20);
                }
            });
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        });

        if (node) observer.current.observe(node);
    }, [loadingList, hasMore, setOffset]);

    return (
        <div className={styles.container}>
            {!isEmpty(items) ? (
                <div className={styles.content}>
                    {items.map((item, index) => (
                        <div
                            key={item.id}
                            className={cn(styles.item, {
                                [styles.isSelected]: selectedList.includes(item.tokenId)
                            })}
                            style={{ background: `url(${item.thumbnail}) no-repeat center / 100%` }}
                            ref={items.length === index + 1 ? lastElement : null}
                            onClick={() => onSelect(item)}
                        >
                            <p className={styles.name}>{item.rewardWeapon} - {item.rewardSkin}</p>
                        </div>
                    ))}
                </div>
            ) : (
                <div className={styles.empty}>
                    <p className={styles.emptyText}>Your weapon skin is empty</p>
                </div>
            )}
        </div>
    )
}

export default React.memo(List);