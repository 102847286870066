import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from 'ethers';

import { CONNECTION_TYPES } from '../../../components/ConnectToWalletModal/constants';

export const isWalletConnected = () => {
    const connectorId = window.localStorage.getItem('connectorId');

    let walletConnected;

    if (connectorId === CONNECTION_TYPES.metamask) {
        walletConnected = !!window?.ethereum?.selectedAddress;
    }

    if (connectorId === CONNECTION_TYPES.walletconnect) {
        walletConnected = !!window?.provider?.accounts[0];
    }

    if (connectorId === CONNECTION_TYPES.gamestop) {
        walletConnected = !!window?.gamestop?.currentAddress;
    }

    return walletConnected;
}

export const getTokenOfBlockchain = (chainId) => {
    switch (chainId) {
        case '0x5':
        case '0x1':
        case '0x01':
        case '0x05':
            return 'ether';
        case '0x61':
        case '0x38':
            return 'bsc';
        case '0x89':
        case '0x13881':
            return 'polygon';
        default:
            return 'ether';
    }
}

export const getWalletConnectProvider = async () => {
    if (window.provider) {
        return window.provider;
    }

    const POLLING_INTERVAL = 12000;
    const provider = new WalletConnectProvider({
        infuraId: process.env.REACT_APP_INFURA_ID,
        rpc: {
            1: process.env.REACT_APP_ETHER_WEB3_PROVIDER_MAINNET_HTTPS,
            5: process.env.REACT_APP_ETHER_WEB3_PROVIDER_TESTNET_HTTPS,
            56: process.env.REACT_APP_BSC_WEB3_PROVIDER_MAINNET_HTTPS,
            97: process.env.REACT_APP_BSC_WEB3_PROVIDER_TESTNET_HTTPS,
            137: process.env.REACT_APP_POLYGON_WEB3_PROVIDER_MAINNET_HTTPS,
            80001: process.env.REACT_APP_POLYGON_WEB3_PROVIDER_TESTNET_HTTPS
        },
        bridge: 'https://bridge.walletconnect.org',
        qrcode: true,
        pollingInterval: POLLING_INTERVAL
    });

    const supportedChainIds = [1, 5, 56, 97, 137, 80001];

    if (!provider.wc.connected) {
        await provider.wc.createSession({
            chainId: process.env.REACT_APP_DEV === '1' ? supportedChainIds[1] : supportedChainIds[0]
        });
    }

    //  Enable session (triggers QR Code modal)
    await provider.enable()

    //  Create Web3
    const web3 = new Web3(provider);
    window.web3 = web3;
    window.web3.eth.transactionPollingTimeout = 2700;
    window.provider = provider;
    window.ethersProvider = new ethers.providers.Web3Provider(window.provider, 'any');

    return provider;
}

export const getWalletProvider = () => {
    const connectorId = window.localStorage.getItem('connectorId');

    let provider = window?.ethereum;

    if (connectorId === CONNECTION_TYPES.metamask) {
        provider = window?.ethereum;
    }

    if (connectorId === CONNECTION_TYPES.walletconnect) {
        provider = window?.provider;
    }

    if (connectorId === CONNECTION_TYPES.gamestop) {
        provider = window?.gamestop;
    }

    return provider;
}