import React from 'react';
import { Form, Formik, Field } from 'formik';
import * as yup from 'yup';
import cn from 'classnames';

import logo from '../../assets/landingLayout/logo.png';

import PasswordField from '../../components/Form/PasswordField';
import Spinner from '../../components/Spinner';

import styles from './ResetPassword.module.scss';

// eslint-disable-next-line
const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/;

const validationSchema = yup.object().shape({
    newPassword: yup
        .string()
        .matches(regexPassword, 'Password must contain lowercase, uppercase letters and at least 8 characters')
        .min(8)
        .required('Password is required')
});


function Step1(props) {
    const { onSubmit, requesting } = props;

    const initialValues = {
        newPassword: ''
    };

    return (
        <div className={styles.content}>
            <img src={logo} alt="" className={styles.logo} />

            <h2 className={styles.title}>Reset Password</h2>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(e, cb) => onSubmit(e, cb)}
            >
                {formikProps => {
                    // eslint-disable-next-line
                    const { values, errors, touched, handleSubmit } = formikProps;

                    const disabled = !values.newPassword || errors?.newPassword;

                    return (
                        <Form className={styles.form}>
                            <Field
                                component={PasswordField}
                                name={'newPassword'}
                                label={'New Password'}
                                placeholder={'New password...'}
                                errorWidth={styles.errorWidth}
                                required
                            />
            
                            <div
                                className={cn(styles.btnContainer, {
                                    [styles.disable]: disabled
                                })}
                                onClick={() => {
                                    !disabled && !requesting && handleSubmit()
                                }}
                            >
                                Reset
                                {requesting && <Spinner alignRight />}
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    );
}

export default Step1;