import cn from 'classnames';
import React from 'react';

import style from './Panel.module.scss';

export default function Panel(props) {
    const { wrapperClass, containerClass, maxHeight, children, ...restProps } = props;

    return (
        <div className={cn(style.container, wrapperClass)} {...restProps}>
            <div
                className={cn(style.scroll, containerClass)}
                style={{maxHeight: maxHeight ? `${maxHeight}px`: 'none'}}
            >
                {children}
            </div>
        </div>
    )
}