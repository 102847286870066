import React, { useEffect, useRef } from 'react';
import HCaptcha from "@hcaptcha/react-hcaptcha";

import styles from './Hcapcha.module.scss';

function HcapchaForm(props) {
    const { getToken, isSuccess, isError } = props;

    const captchaRef = useRef(null);

    useEffect(() => {
        if (isSuccess || isError) {
            captchaRef.current.resetCaptcha();
            getToken('');
        }
    }, [isSuccess, getToken, isError]);

    const handleOnVerify = token => {
        getToken && getToken(token);
    };
    
    return (
        <div className={styles.container}>
            <HCaptcha
                ref={captchaRef}
                sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
                onVerify={handleOnVerify}
                theme="dark"
            />
        </div>
    );
}

export default React.memo(HcapchaForm);